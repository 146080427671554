import React from 'react';

import { Container, Grid, Typography } from '@mui/material';

import { IKImage } from '../../components/IKImage';
import Trans from '../../components/Trans';
import useTenantTranslation from '../../hooks/useTenantTranslation';
import styled from '../../styled';
import { MIDDLE_MARGIN_PX, SMALL_MARGIN_PX, SUBMIDDLE_MARGIN_PX } from '../../theme';
import { TENANTS_CONFIG, TenantsRefs } from '../../utils/tenantsConfig';

const transformation = {
  height: 100,
  focus: 'top_left',
};

const logoTransformations = [transformation];

const Maintenance = () => {
  const { t } = useTenantTranslation();

  return (
    <Wrapper>
      <div>
        <GridContainer container spacing={4}>
          <StyledGrid item xs={12} md={5}>
            <BigLogoImg
              path={TENANTS_CONFIG[TenantsRefs.Xunl].getLogo('dark')}
              transformation={logoTransformations}
            />
          </StyledGrid>
          <Space item xs={0} md={7} />
        </GridContainer>
        <GridContainer container spacing={4}>
          <StyledGrid item xs={12} md={4}>
            <LogoImg
              path={TENANTS_CONFIG[TenantsRefs.Su].getLogo('dark')}
              transformation={logoTransformations}
            />
          </StyledGrid>
          <StyledGrid item xs={12} md={4}>
            <LogoImg
              path={TENANTS_CONFIG[TenantsRefs.Fu].getLogo('dark')}
              transformation={logoTransformations}
            />
          </StyledGrid>
          <StyledGrid item xs={12} md={4}>
            <LogoImg
              path={TENANTS_CONFIG[TenantsRefs.Dsu].getLogo('dark')}
              transformation={logoTransformations}
            />
          </StyledGrid>
        </GridContainer>
        <GridContainer container spacing={4}>
          <StyledGrid item xs={12} md={4}>
            <LogoImg
              path={TENANTS_CONFIG[TenantsRefs.Dukece].getLogo('dark')}
              transformation={[{ ...transformation, width: 235 }]}
            />
          </StyledGrid>
          <StyledGrid item xs={12} md={4}>
            <LogoImg
              path={TENANTS_CONFIG[TenantsRefs.Bpp].getLogo('dark')}
              transformation={[{ ...transformation, width: 200 }]}
            />
          </StyledGrid>
          <StyledGrid item xs={12} md={4} last>
            <LogoImg
              path={TENANTS_CONFIG[TenantsRefs.Em].getLogo('dark')}
              transformation={[{ ...transformation, width: 310 }]}
            />
          </StyledGrid>
        </GridContainer>
      </div>
      <div>
        <Title>{t('page.maintenance.wellBeBackSoon')}</Title>
        <Text>
          <Trans i18nKey="page.maintenance.sorryForTheInconvenience">
            Sorry for the inconvenience but we’re performing some maintenance at the moment. If you
            need to you can always
            <Link href="mailto:support@financeunlocked.com">contact us by email</Link>, otherwise
            we’ll be back online shortly!
          </Trans>
        </Text>
        <Text>— {t('page.maintenance.theUnlockedTeam')}</Text>
      </div>
    </Wrapper>
  );
};

export default Maintenance;

const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;

  && {
    max-width: 748px;
  }

  min-height: 100vh;
  width: 100%;
  padding-top: ${MIDDLE_MARGIN_PX};
  padding-bottom: ${MIDDLE_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: column;
    justify-content: flex-start;
  }
`;
const Title = styled(Typography)`
  margin-bottom: ${MIDDLE_MARGIN_PX};
  font-size: 36px;
  font-weight: 700;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 50px;
    margin: ${SUBMIDDLE_MARGIN_PX} 0;
  }
`;
const Text = styled(Typography)`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.common.gray};

  &:not(:last-child) {
    margin-bottom: ${SMALL_MARGIN_PX};
  }
`;
const Link = styled('a')`
  color: ${({ theme }) => theme.palette.common.primary};
`;
const LogoImg = styled(IKImage)`
  height: 60px;
  max-width: 100%;
  width: 100%;
  object-fit: contain;
`;
const BigLogoImg = styled(LogoImg)`
  height: 78px;
`;
const StyledGrid = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'last',
})<{ last?: boolean }>`
  position: relative;
  padding-bottom: ${SUBMIDDLE_MARGIN_PX};
  margin-bottom: ${SUBMIDDLE_MARGIN_PX};

  &:not(:first-of-type) {
    margin-top: -${({ theme }) => theme.spacing(4)};
  }

  &::after {
    content: '';
    display: ${({ last }) => (last ? 'none' : 'block')};
    height: 1px;
    width: calc(100% - 32px);
    background: ${({ theme }) => theme.palette.common.primary};
    position: absolute;
    left: 32px;
    bottom: 0;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    && {
      margin-top: 0;
    }

    &::after {
      width: 100%;
      display: block;
    }

    &:first-of-type {
      &::after {
        left: 32px;
      }
    }

    &:last-of-type {
      &::after {
        width: calc(100% - 32px);
      }
    }
  }
`;
const Space = styled(StyledGrid)`
  display: none;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: block;
  }
`;
const GridContainer = styled(Grid)`
  margin-top: initial;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: -${({ theme }) => theme.spacing(4)};
  }
`;
