import { useCallback, useEffect, useMemo } from 'react';

import { useLazyQuery } from '@apollo/client';

import {
  GET_B2B_LEARNER_SUBSCRIPTION_INFORMATION,
  GET_B2C_LEARNER_SUBSCRIPTION_INFORMATION,
  GET_COMPANY_OWNER_SUBSCRIPTION_INFORMATION,
  GET_MANAGER_SUBSCRIPTION_INFORMATION,
} from '../graphql/user';
import { USER_ROLES } from '../pages/const';
import { LearnerInfo, Maybe, Query } from '../types';

const useSubscriptionInfo = (userInfo?: Maybe<LearnerInfo>) => {
  const [loadCompanyOwnerSubData, { data: companyOwnerSubData }] = useLazyQuery<
    Pick<Query, 'getMySubscriptionCompanyOwnerChargebeeInfo'>
  >(GET_COMPANY_OWNER_SUBSCRIPTION_INFORMATION);

  const [loadManagerSubData, { data: managerSubData }] = useLazyQuery<
    Pick<Query, 'getMySubscriptionTeamManagerChargebeeInfo'>
  >(GET_MANAGER_SUBSCRIPTION_INFORMATION);

  const [loadB2BLeanerSubData, { data: b2bLearnerSubData }] = useLazyQuery<
    Pick<Query, 'getMySubscriptionB2BLearnerChargebeeInfo'>
  >(GET_B2B_LEARNER_SUBSCRIPTION_INFORMATION);

  const [loadB2CLearnerSubData, { data: b2cLearnerSubData }] = useLazyQuery<
    Pick<Query, 'getMySubscriptionB2CLearnerChargebeeInfo'>
  >(GET_B2C_LEARNER_SUBSCRIPTION_INFORMATION);

  const subscription = useMemo(() => {
    return (
      companyOwnerSubData?.getMySubscriptionCompanyOwnerChargebeeInfo ||
      managerSubData?.getMySubscriptionTeamManagerChargebeeInfo ||
      b2bLearnerSubData?.getMySubscriptionB2BLearnerChargebeeInfo ||
      b2cLearnerSubData?.getMySubscriptionB2CLearnerChargebeeInfo
    );
  }, [companyOwnerSubData, managerSubData, b2bLearnerSubData, b2cLearnerSubData]);

  const loadSubInfo = useCallback(
    (role: USER_ROLES) => {
      switch (role) {
        case USER_ROLES.CompanyOwner: {
          loadCompanyOwnerSubData();
          break;
        }
        case USER_ROLES.TeamManager: {
          loadManagerSubData();
          break;
        }
        case USER_ROLES.B2BLearner: {
          loadB2BLeanerSubData();
          break;
        }
        case USER_ROLES.B2CLearner: {
          loadB2CLearnerSubData();
          break;
        }
        default: {
          return;
        }
      }
    },
    [loadB2CLearnerSubData, loadB2BLeanerSubData, loadCompanyOwnerSubData, loadManagerSubData],
  );

  //fetch subscription info after authorization
  useEffect(() => {
    if (userInfo?.role && Object.values(USER_ROLES).includes(userInfo.role as USER_ROLES)) {
      loadSubInfo(userInfo.role as USER_ROLES);
    }
  }, [userInfo, loadSubInfo]);

  return subscription;
};

export default useSubscriptionInfo;
