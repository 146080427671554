import React, { FC } from 'react';

import { Typography } from '@mui/material';

import styled from '../../styled';
import { IKImage } from '../IKImage';

interface SliderCardProps {
  imageUrl?: string | null;
  labelText?: string | null;
}

export const SliderCard: FC<SliderCardProps> = ({ imageUrl, labelText }) => {
  return (
    <Wrapper>
      <Img path={imageUrl} />
      {labelText && (
        <Label>
          <MetaLabel>{labelText}</MetaLabel>
        </Label>
      )}
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  position: relative;
  border-radius: 10px;
`;
const Img = styled(IKImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const Label = styled('div')`
  background: ${({ theme }) => theme.palette.common.blue};
  position: absolute;
  top: 16px;
  left: 16px;
  padding: 5px 16px;
  border-radius: 5px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    top: 24px;
    left: 24px;
  }
`;
const MetaLabel = styled(Typography)`
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.common.white};
`;
