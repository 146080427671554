import React, { FC } from 'react';

import { Typography, useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import ChevronTopIcon from '../../assets/icons/ChevronTopIcon';
import useTenantTranslation from '../../hooks/useTenantTranslation';
import styled from '../../styled';
import {
  MIDDLE_MARGIN_PX,
  MIDDLE_RADIUS_PX,
  SMALL_MARGIN_PX,
  SUBMIDDLE_MARGIN_PX,
  TINY_MARGIN,
  TINY_MARGIN_PX,
} from '../../theme';
import { VideoCategory } from '../../types';
import AnimatedSkeleton from '../AnimatedSkeleton';
import { IKImage } from '../IKImage';
import { H3 } from '../UI/Texts';

export const IMG_TRANSFORMATION = [
  {
    width: 295,
    height: 514,
  },
];

interface CategoryCardProps {
  rootCategory?: VideoCategory | null;
  subcategories?: VideoCategory[] | null;
  opened?: boolean;
  setOpened?: (value: boolean) => void;
  className?: string;
  loading?: boolean;
}

const CategoryCard: FC<CategoryCardProps> = ({
  opened,
  rootCategory,
  subcategories,
  setOpened = () => {},
  className = '',
  loading,
}) => {
  const theme = useTheme();
  const { t } = useTenantTranslation();

  const hasSubcategories = Boolean(subcategories?.length);

  const handleClickViewMore = () => {
    setOpened(!opened);
  };

  const closeCard = () => {
    setOpened(false);
  };

  if (loading) {
    return (
      <Wrapper>
        <AnimatedSkeleton xs={'100%'} />
      </Wrapper>
    );
  }

  return (
    <Wrapper className={cx(className, 'categoryCard')} opened={opened} onMouseLeave={closeCard}>
      <ImgWrapper>
        <Image srcset={IMG_TRANSFORMATION} sizes="100vw" path={rootCategory?.image_url} />
      </ImgWrapper>
      <TextWrapper opened={opened}>
        <Category to={`/discover/${rootCategory?.slug}`}>
          <H3 variant="h3">{rootCategory?.title}</H3>
        </Category>
        {hasSubcategories && (
          <SubcategoriesWrapper>
            {subcategories?.map((item) => (
              <Subcategory key={item?.id} to={`/discover/${rootCategory?.slug}/${item?.slug}`}>
                <SubcategoryTitle>{item?.title}</SubcategoryTitle>
              </Subcategory>
            ))}
          </SubcategoriesWrapper>
        )}
      </TextWrapper>
      {hasSubcategories && (
        <BottomBlock opened={opened}>
          <ExpandBtn onClick={handleClickViewMore}>
            <ViewLess opened={opened}>{t('button.viewLess')}</ViewLess>
            <ViewMore opened={opened}>{t('button.viewMore')}</ViewMore>
            <StyledIcon opened={opened} color={theme.palette.common.violetSecondary} />
          </ExpandBtn>
        </BottomBlock>
      )}
    </Wrapper>
  );
};

export default CategoryCard;

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'opened',
})`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  height: ${({ opened }) => (opened ? 514 : 168)}px;
  background: ${({ theme }) => theme.palette.common.header.overlay};
  border-radius: ${MIDDLE_RADIUS_PX};
  transition: 0.5s;
  overflow: hidden;

  &::before {
    position: absolute;
    z-index: 2;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(
      0deg,
      ${({ theme }) => theme.palette.common.blockBackground.light}
        ${({ opened }) => (opened ? '0%' : '30.93%')},
      ${({ theme }) => alpha(theme.palette.common.blockBackground.light, 0.1)} 100%
    );
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 460px;

    &::before {
      background: linear-gradient(
        0deg,
        ${({ theme }) => theme.palette.common.blockBackground.light} 11.3%,
        ${({ theme }) => alpha(theme.palette.common.header.overlay, 0)} 100%
      );
    }
  }
`;

const ImgWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'wrapperWidth',
})`
  width: 100%;
  height: 514px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1;
  transition: 0.5s;

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 100%;
  }
`;

const Image = styled(IKImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Category = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'opened',
})`
  display: block;

  &:not(:only-child) {
    margin-bottom: ${({ opened }) => (opened ? SMALL_MARGIN_PX : TINY_MARGIN_PX)};
  }
`;

const SubcategoriesWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'wrapperWidth',
})`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 600px;
  }
`;

const Subcategory = styled(Link)`
  margin-bottom: ${TINY_MARGIN / 2}px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const SubcategoryTitle = styled(Typography)`
  min-height: 24px;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.common.white};
`;

const TextWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'opened',
})`
  position: relative;
  z-index: 2;
  padding: ${MIDDLE_MARGIN_PX} ${SMALL_MARGIN_PX} ${TINY_MARGIN_PX} ${SMALL_MARGIN_PX};
  overflow: hidden;
  max-height: ${({ opened }) => (opened ? '465px' : '113px')};
  transition: 0.5s;

  &:last-child {
    padding: ${MIDDLE_MARGIN_PX} ${SMALL_MARGIN_PX} ${SUBMIDDLE_MARGIN_PX} ${SMALL_MARGIN_PX};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    .categoryCard:hover & {
      max-height: 412px;
    }
  }
`;

const BottomBlock = styled('div', {
  shouldForwardProp: (prop) => prop !== 'wrapperWidth',
})`
  position: relative;
  width: 100%;
  z-index: 2;

  &::before {
    display: ${({ opened }) => (opened ? 'none' : 'block')};
    content: '';
    height: 36px;
    width: 100%;
    top: -27px;
    left: 0;
    position: absolute;
    background: linear-gradient(
      0deg,
      ${({ theme }) => theme.palette.common.blockBackground.light} 11.3%,
      ${({ theme }) => alpha(theme.palette.common.header.overlay, 0)} 100%,
      ${({ theme }) => alpha(theme.palette.common.blockBackground.light, 0)} 100%
    );
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    background: linear-gradient(
      0deg,
      ${({ theme }) => theme.palette.common.blockBackground.light} 11.3%,
      ${({ theme }) => alpha(theme.palette.common.header.overlay, 0)} 100%
    );

    .categoryCard:hover & {
      &::before {
        display: none;
      }
    }
  }
`;

const ExpandBtn = styled('button')`
  border: none;
  background: transparent;
  color: ${({ theme }) => theme.palette.common.violetSecondary};
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${TINY_MARGIN_PX} ${SMALL_MARGIN_PX} ${SUBMIDDLE_MARGIN_PX} ${SMALL_MARGIN_PX};
`;

const StyledIcon = styled(ChevronTopIcon, {
  shouldForwardProp: (prop) => prop !== 'wrapperWidth',
})`
  width: 12px;
  height: 12px;
  margin-left: ${TINY_MARGIN_PX};
  transform: ${({ opened }) => (opened ? 'rotate(0)' : 'rotate(180deg)')};
  transition: 0.5s;

  ${({ theme }) => theme.breakpoints.up('md')} {
    .categoryCard:hover & {
      transform: rotate(0);
    }
  }
`;

const ViewMore = styled('span', {
  shouldForwardProp: (prop) => prop !== 'opened',
})`
  display: ${({ opened }) => (opened ? 'none' : 'inline')};

  ${({ theme }) => theme.breakpoints.up('md')} {
    .categoryCard:hover & {
      display: none;
    }
  }
`;

const ViewLess = styled('span', {
  shouldForwardProp: (prop) => prop !== 'opened',
})`
  display: ${({ opened }) => (opened ? 'inline' : 'none')};

  ${({ theme }) => theme.breakpoints.up('md')} {
    .categoryCard:hover & {
      display: inline;
    }
  }
`;
