import React from 'react';

import { Container, Typography } from '@mui/material';
import parse from 'html-react-parser';

import styled, { css } from '../../styled';
import {
  LARGE_MARGIN_PX,
  MIDDLE_MARGIN_PX,
  MIDDLE_RADIUS_PX,
  SMALL_MARGIN_PX,
  SUBMIDDLE_MARGIN_PX,
  useIsWidthUp,
} from '../../theme';
import { LazyLoadImage } from '../LazyloadImage';
import SafeLink from '../SafeLink';
import { CustomButton } from '../UI/CustomButton';
import { IMAGE, TEXT } from './ImageTextArrayBlock';

const ImageTextArrayBlockSized = ({ data }) => {
  const background = data?.background;
  const heading = typeof data?.heading === 'string' ? data?.heading : data?.heading?.en;
  const imageItem = data?.items?.find((item) => item?.type === IMAGE);
  const textItem = data?.items?.find((item) => item?.type === TEXT);

  const isMobile = !useIsWidthUp('md');
  const shouldStartFromImage = data?.shouldStartFromImage;
  const hasButton = !!textItem?.button_url && !!textItem?.button_text;

  return (
    <Wrapper className="images-text-block--sized">
      <StyledContainer isReverse={!shouldStartFromImage} isDark={background === 'Dark'}>
        {imageItem && (
          <Images>
            <LazyLoadImage
              path={
                isMobile && imageItem?.mobile_image ? imageItem?.mobile_image : imageItem?.image
              }
              alt={imageItem?.alt}
              draggable={false}
              wrapperCss={lazyLoadImageWrapper}
            />
          </Images>
        )}
        {textItem && (
          <TextBlock>
            {heading && <Title variant="h2">{heading}</Title>}
            <TextWrapper lastItem={!hasButton}>{parse(textItem?.text)}</TextWrapper>
            {hasButton && (
              <SafeLink to={textItem?.button_url}>
                <CustomButton>{textItem?.button_text}</CustomButton>
              </SafeLink>
            )}
          </TextBlock>
        )}
      </StyledContainer>
    </Wrapper>
  );
};

export default ImageTextArrayBlockSized;

const TextWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'lastItem',
})`
  font-size: 18px;
  margin-bottom: ${({ lastItem }) => !lastItem && MIDDLE_MARGIN_PX};
  text-align: center;

  ${({ theme }) => theme.breakpoints.up('md')} {
    text-align: left;
  }

  & > *:last-of-type {
    margin-bottom: 0;
  }
`;
const TextBlock = styled('div')`
  text-align: center;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 50%;
    margin-left: ${SMALL_MARGIN_PX};
    padding: ${MIDDLE_MARGIN_PX};
    text-align: left;
  }
`;
const Images = styled('div')`
  height: 100%;
  margin-bottom: ${MIDDLE_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 50%;
    margin-bottom: 0;
    margin-right: ${SMALL_MARGIN_PX};
  }
`;
const Title = styled(Typography)`
  text-align: center;

  ${({ theme }) => theme.breakpoints.up('xs')} {
    font-size: 20px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 28px;
    text-align: left;
    margin-bottom: ${SMALL_MARGIN_PX};
  }
`;
const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDark',
})`
  padding-top: ${MIDDLE_MARGIN_PX};
  padding-bottom: ${MIDDLE_MARGIN_PX};
  padding-left: ${SMALL_MARGIN_PX};
  padding-right: ${SMALL_MARGIN_PX};
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-top: ${LARGE_MARGIN_PX};
    padding-bottom: ${LARGE_MARGIN_PX};
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    padding-left: 0;
    padding-right: 0;
  }

  &:first-child {
    margin-top: 76px;
  }
`;
const StyledContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'isReverse',
})`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.common.white};
  width: 100%;
  padding: ${SUBMIDDLE_MARGIN_PX} ${SUBMIDDLE_MARGIN_PX} ${MIDDLE_MARGIN_PX};
  background: ${({ isDark, theme }) =>
    isDark ? theme.palette.secondary.dark : theme.palette.secondary.light};
  border-radius: ${MIDDLE_RADIUS_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: ${({ isReverse }) => (isReverse ? 'row-reverse' : 'row')};
    padding: ${SUBMIDDLE_MARGIN_PX};
  }
`;
const lazyLoadImageWrapper = css`
  > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
