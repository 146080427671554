import React from 'react';

import { Svg, useSvgColor } from './styles';

const ReminderNewIcon = ({ color = '', ...props }) => {
  const svgColor = useSvgColor(color);
  return (
    <Svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      svgColor={svgColor}
      {...props}
    >
      <path
        d="M19.3167 13.6923V4.4225C19.3167 3.59361 18.6444 2.92184 17.8155 2.9225L15.6604 2.92422M13.5004 21.4823H6.20313C5.3747 21.4823 4.70312 20.8107 4.70312 19.9823V4.4225C4.70312 3.59361 5.37543 2.92184 6.20432 2.9225L8.35943 2.92422M9.8499 4.84844H14.1699C14.9931 4.84844 15.6604 4.18113 15.6604 3.35797V2.49047C15.6604 1.66731 14.9931 1 14.1699 1H9.8499C9.02674 1 8.35943 1.66731 8.35943 2.49047V3.35797C8.35943 4.18113 9.02674 4.84844 9.8499 4.84844Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7.84375 8.62891H15.8438"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.01562 11.9609H16.0156"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00781 15.293H12.6814"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3359 20.0156H22.3076"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3125 17.0273V22.999"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default ReminderNewIcon;
