export enum MegaMenuBlockTypes {
  LeftBlock = 'mega_menu_left_block',
  MiddleBlock = 'mega_menu_middle_block',
  RightBlock = 'mega_menu_right_block',
}

export enum MegaMenuLeftBlockItemsTypes {
  ListItem = 'link',
  BlockItem = 'list',
}
