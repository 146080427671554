import { gql } from '@apollo/client';

export const GET_FEATURED_VIDEOS = gql`
  query GetFeaturedVideos($user_id: ID!, $limit: Int) {
    listFeaturedVideos(user_id: $user_id, limit: $limit) {
      description
      video {
        id
        title
        preview_url
        video_url
        duration
        user {
          id
          name
          slug
        }
        difficulty {
          id
          title
          icon_url
        }
        categories {
          id
          title
          slug
          parent {
            id
            slug
          }
        }
      }
      title
    }
  }
`;

export const GET_RECENT_PATHWAYS = gql`
  query GetRecentPathways($options: RecentPathwaysOptions) {
    recentPathways(options: $options) {
      id
      name
      description
      image_url
      duration
      count_videos
      slug
      categories {
        id
        title
        slug
        parent {
          slug
          id
        }
      }
      difficulty {
        id
        icon_url
        title
      }
      videos {
        id
        title
        video_url
        preview_url
        duration
        user {
          name
          slug
        }
      }
      quizes {
        id
        title
        time_for_pass
      }
      available_quizes {
        id
        title
        time_for_pass
        count_of_questions
      }
    }
  }
`;

export const GET_RECOMMENDED_VIDEOS = gql`
  query GetRecommendedVideos($user_id: ID!) {
    listRecommendedVideos(user_id: $user_id) {
      id
      title
      duration
      description
      preview_url
      video_url
      user {
        id
        name
        slug
      }
      categories {
        id
        title
        slug
        parent {
          slug
          id
        }
      }
      difficulty {
        id
        icon_url
        title
      }
    }
  }
`;

export const GET_TOP_WATCHED_VIDEOS = gql`
  query GetTopWatchedVideos(
    $filter_type: TopWatchedVideosFilterType!
    $limit: Int
    $first: Int!
    $page: Int
  ) {
    getTopWatchedVideos(filter_type: $filter_type, limit: $limit, first: $first, page: $page) {
      data {
        id
        title
        duration
        description
        preview_url
        video_url
        user {
          id
          name
          slug
        }
        categories {
          id
          title
          slug
          parent {
            slug
            id
          }
        }
        difficulty {
          id
          icon_url
          title
        }
      }
      paginatorInfo {
        total
        currentPage
      }
    }
  }
`;

export const GET_WATCHLIST = gql`
  query GetWatchlist {
    GetWatchList {
      last_position
      chapter
      time
      completed
      progress_percentage
      video {
        id
        title
        preview_url
        duration
        video_url
      }
    }
  }
`;

export const GET_LIST_LATEST_VIDEOS = gql`
  query GetListLatestVideos($user_id: ID!) {
    listlatestVideo(user_id: $user_id) {
      chapter
      time
      last_position
      completed
      video {
        id
        title
        duration
        description
        preview_url
        video_url
        user {
          id
          name
          slug
        }
        categories {
          id
          title
          slug
          parent {
            slug
            id
          }
        }
        difficulty {
          id
          icon_url
          title
        }
      }
    }
  }
`;

export const GET_GETTING_STARTED_INFO_LEARNER = gql`
  query GetGettingStartedInfoLearnerTab {
    getGettingStartedInfoLearnerTab {
      dismissed_count
      show_getting_started_block
      overall_percent_progress
      steps {
        introduction_video_step {
          step_status
        }
        pathway_step {
          step_status
        }
        profile_step {
          step_status
        }
        video_and_review_step {
          review_exists
          step_status
          video_is_watched
        }
        goals_step {
          step_status
        }
      }
    }
  }
`;

export const GET_GETTING_STARTED_INFO_MANAGER = gql`
  query GetGettingStartedInfoManagerTab {
    getGettingStartedInfoManagerTab {
      dismissed_count
      show_getting_started_block
      overall_percent_progress
      steps {
        create_team_step {
          step_status
        }
        invite_learners_step {
          step_status
        }
        create_assignment_step {
          step_status
        }
      }
    }
  }
`;

export const GET_ENGAGEMENT_METRICS = gql`
  query GetEngagementMetrics {
    getEngagementMetrics {
      engagement_score_30_days_ago
      engagement_score_current
      engagement_score_max
    }
  }
`;

export const DISMISS_GETTING_STARTED_BLOCK = gql`
  mutation DismissGettingStartedBlock($input: DismissGettingStartedBlockInput) {
    dismissGettingStartedBlock(input: $input)
  }
`;

export const GET_ASSIGNMENTS_BLOCK = gql`
  query GetAssignmentsBlock {
    getAssignmentsBlock {
      ... on AssignmentForLearner {
        id
        due_date
        title
        type
        entities {
          id
          entity {
            ... on Pathway {
              id
              image_url
              count_videos
              slug
            }
          }
        }
        progress
      }
      ... on AssignmentForLearnerCPD {
        id
        due_date
        title
        type
        target_cpd
        cpd_credits
        start_date
      }
      ... on AssignmentForLearnerHours {
        id
        due_date
        title
        type
        target_hours
        watched_hours
      }
      ... on AssignmentForLearnerVideos {
        id
        due_date
        title
        type
        entities {
          id
          entity {
            ... on Video {
              id
              preview_url
              video_url
            }
          }
        }
        progress
        has_test
        quiz_attempt {
          passed
        }
        can_take_test
      }
    }
  }
`;

export const GET_ANNOUNCEMENTS_LIST = gql`
  query GetAnnouncementsList($first: Int!, $page: Int) {
    getAnnouncementsList(first: $first, page: $page) {
      data {
        ... on Video {
          id
          title
          video_url
          categories {
            title
          }
          user {
            name
          }
          is_highlighted
        }
        ... on NewVideoFromExpert {
          id
          title
          video_url
          categories {
            title
          }
          user {
            name
          }
          is_highlighted
        }
        ... on Pathway {
          id
          name
          categories {
            title
          }
          slug
          count_videos
          is_highlighted
        }
        ... on NotificationForAssignment {
          id
          created_at
          type
          assignment {
            id
            title
            due_date
            type
          }
          is_highlighted
        }
        ... on NotificationForCompletedPathway {
          pathway {
            id
            slug
            name
          }
        }
        ... on NotificationForCompletedCourse {
          course {
            id
            name
            slug
          }
        }
      }
      paginatorInfo {
        total
        currentPage
      }
    }
  }
`;

export const GET_ASSIGNMENT_LEARNING_BLOCK_MANAGER = gql`
  query GetAssignmentLearningBlockManager {
    getAssignmentLearningBlockManager {
      completed
      completed_percentage
      started
      started_percentage
      total
    }
  }
`;
