import { gql } from '@apollo/client';

export const GET_PARTNERS_TYPES = gql`
  query PartnersTypes {
    listPartnerTypes {
      id
      name
    }
  }
`;

export const GET_PARTNER_BY_ID = gql`
  query PartnerById($id: ID!) {
    getPartner(id: $id) {
      id
      title
      description
      image_url
    }
  }
`;

export const GET_LIST_PARTNERS_BY_FILTER = gql`
  query PartnersByType($filter: PartnerSearchFilter) {
    listPartners(filter: $filter) {
      id
      title
      description
      image_url
    }
  }
`;
