import React from 'react';
import 'objectFitPolyfill';
import 'react-app-polyfill/stable';

import { CacheProvider } from '@emotion/react';
import { init, browserTracingIntegration } from '@sentry/react';
import JSCookies from 'js-cookie';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';

import App from './App';
import IncognitoWarning from './components/IncognitoWarning/IncognitoWarning';
import RmContext from './context/RmContext';
import SsrContext from './context/SsrContext';
import StaticRouterProvider from './context/StaticRouterProvider';
import ThemeProvider from './context/ThemeProvider/ThemeProvider';
import createEmotionCache from './createEmotionCache';
import i18n from './i18n';
import * as serviceWorker from './serviceWorker';
import { checkIsLocalStorageBlocked } from './utils/checkIsLocalStorageBlocked';
import getHasAuthCookie from './utils/getHasAuthCookie';

const cache = createEmotionCache();

/**
 *  uncomment to enable msw intercepting
 */
// if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MSW) {
//   const { worker } = require('./browser');
//   worker.start();
// }

if (process.env.NODE_ENV === 'production') {
  init({
    release:
      process.env.REACT_APP_BUILD_TYPE === 'prod' || process.env.REACT_APP_BUILD_TYPE === 'preprod'
        ? `${process.env.npm_package_name}@${process.env.npm_package_version}`
        : undefined,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [browserTracingIntegration()],
    environment: process.env.REACT_APP_BUILD_TYPE,
    tracesSampleRate: process.env.REACT_APP_BUILD_TYPE === 'stage' ? 1.0 : 0.25,
    beforeSend(event) {
      const hydrationErrorMessage = 'Minified React error #421;';
      const hydrationError = event.exception?.values?.find((item) =>
        item?.value?.includes(hydrationErrorMessage),
      );

      if (hydrationError) {
        return null;
      }

      return event;
    },
  });
}

const hasAuthenticatedCookie = getHasAuthCookie(JSCookies.get());

const container = document.getElementById('root') as Element;
const root = createRoot(container);

const layout = (
  <StaticRouterProvider staticContext={{}}>
    <SsrContext.Provider value={{ tenantTheme: null, tenantThemeMode: null }}>
      <RmContext.Provider value={{ metadata: null }}>
        <CacheProvider value={cache}>
          <App
            type="client"
            client={undefined}
            location={undefined}
            hasAuthenticatedCookie={hasAuthenticatedCookie}
            host={window?.location?.hostname}
            i18n={i18n}
          />
        </CacheProvider>
      </RmContext.Provider>
    </SsrContext.Provider>
  </StaticRouterProvider>
);

if (process.env.NODE_ENV === 'development') {
  root.render(layout);
} else {
  checkIsLocalStorageBlocked()
    ? root.render(
        <ThemeProvider>
          <I18nextProvider i18n={i18n}>
            <IncognitoWarning />
          </I18nextProvider>
        </ThemeProvider>,
      )
    : hydrateRoot(container, layout);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (!process.env.REACT_APP_MSW) {
  serviceWorker.unregister();
}
