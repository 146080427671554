import { captureException } from '@sentry/react';

function assertNever(value: never) {
  const error = new Error('Unexpected value: ' + value);

  if (process.env.NODE_ENV !== 'production') {
    throw error;
  }

  captureException(error);
}

export default assertNever;
