import React, { useContext } from 'react';

import { Box, Typography, useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';

import LinkedInIcon from '../../assets/icons/LinkedInIcon';
import { TenantCtx } from '../../context/TenantProvider';
import useTenantTranslation from '../../hooks/useTenantTranslation';
import { IModal } from '../../modals/Modal';
import { StyledCloseIcon } from '../../modals/modalStyles';
import styled from '../../styled';
import { SMALL_MARGIN_PX } from '../../theme';
import Trans from '../Trans';
import { CardWrapper } from '../UI/CardWrapper';
import { ShareButton } from '../UI/ShareButton';

export enum LINKED_IN_CERTIFICATE_TYPE {
  Pathway = 'pathway',
  Course = 'course',
}

export const generateLinkedInUrl = (name, certDate, certUrl, linkedInId, certificateType) => {
  const date = new Date(certDate);
  const year = date.getFullYear();
  const month = date.getMonth();

  return `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${name}${
    certificateType === LINKED_IN_CERTIFICATE_TYPE.Pathway ? '+Pathway' : ''
  }&organizationId=${linkedInId}&issueYear=${year}&issueMonth=${month}&certUrl=${certUrl}`;
};

export interface LinkedInModalProps extends IModal {
  entityName?: string | null;
  resultHash?: string | null;
  passDate?: string | null;
  certUrl?: string | null;
  certificateType?: LINKED_IN_CERTIFICATE_TYPE;
}

const LinkedInModal: React.FC<LinkedInModalProps> = ({
  closeModal,
  entityName,
  passDate,
  resultHash,
  certUrl,
  certificateType = LINKED_IN_CERTIFICATE_TYPE.Pathway,
}) => {
  const theme = useTheme();
  const { t } = useTenantTranslation();
  const { tenant } = useContext(TenantCtx);
  const linkedInId = tenant?.linkedin_id;

  const linkedInUrl = generateLinkedInUrl(
    entityName,
    passDate,
    certUrl || `${window?.location?.origin}/share/${resultHash}`,
    linkedInId,
    certificateType,
  );

  return (
    <StyledCardWrapper data-testid="linkedinModal">
      <HeaderWrapper>
        <Title variant="h2">{t('page.test.addToLinkedIn')}</Title>
        <StyledCloseIcon
          data-testid="closeLinkedinModal"
          color={theme.palette.common.gray}
          onClick={closeModal}
        />
      </HeaderWrapper>
      <Description data-testid="linkedinModalSubtitle">
        <Trans i18nKey="page.test.addToLinkedInDescr">
          Add your certification for
          <strong>
            {{
              // @ts-ignore
              pathwayName: entityName,
            }}
          </strong>
          to LinkedIn following the steps below.
        </Trans>
      </Description>
      <List>
        <ListItem>
          <ListCounter>1</ListCounter>
          <a
            data-testid="linkedinLink"
            href={linkedInUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledShareButton>
              <LinkedInIcon color={theme.palette.common.swiper.buttonBorder} />
              {t('page.test.linkedIn')}
            </StyledShareButton>
          </a>
        </ListItem>
        <ListItem>
          <ListCounter>2</ListCounter>
          {t('page.test.credentialExpire')}
        </ListItem>
        <ListItem>
          <ListCounter>3</ListCounter>
          {t('page.test.credentialId')}
        </ListItem>
        <ListItem>
          <ListCounter>4</ListCounter>
          {t('page.test.clickSave')}
        </ListItem>
      </List>
    </StyledCardWrapper>
  );
};

export default LinkedInModal;

const HeaderWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${SMALL_MARGIN_PX};
`;
const Title = styled(Typography)`
  && {
    font-size: 24px;
    line-height: 1.2;
    font-weight: bold;

    ${({ theme }) => theme.breakpoints.up('md')} {
      font-size: 28px;
    }
  }
`;
const Description = styled(Typography)`
  font-size: 18px;
  font-weight: 22;
`;
const List = styled('ol')`
  margin: ${SMALL_MARGIN_PX} 0 0;
  padding: 0;
  list-style: none;
`;
const ListItem = styled('li')`
  display: flex;
  align-items: center;
  padding: ${SMALL_MARGIN_PX} 0;
  font-size: 16px;
  line-height: 1.5;

  &:last-child {
    padding: ${SMALL_MARGIN_PX} 0 0 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => alpha(theme.palette.common.answerItemBorder, 0.33)};
  }
`;
const ListCounter = styled(Typography)`
  font-size: 28px;
  line-height: 34px;
  color: ${({ theme }) => theme.palette.common.blue};
  margin-right: ${SMALL_MARGIN_PX};
  font-weight: 700;
`;
const StyledCardWrapper = styled(CardWrapper)`
  && {
    width: 100%;
    background: ${({ theme }) => theme.palette.common.answerItem};

    ${({ theme }) => theme.breakpoints.up('md')} {
      width: 900px;
    }

    &:focus {
      outline: none;
    }
  }
`;
const StyledShareButton = styled(ShareButton)`
  && {
    width: 185px;
    cursor: pointer;
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
