const downloadFile = async (fileName, fileUrl) => {
  const img = await fetch(fileUrl);
  const imgBlob = await img?.blob();
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(imgBlob);
  link.download = fileName;
  link.click();
};

export default downloadFile;
