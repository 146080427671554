import React, { FC } from 'react';

import { Element, useSvgColor, Svg } from './styles';
import { TempColorIconProps } from './types';

const ShareIcon: FC<TempColorIconProps> = ({ updatedColor, color = '', ...props }) => {
  const svgColor = useSvgColor(color);

  return (
    <Svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}
      svgColor={updatedColor || svgColor}
    >
      <Element as="circle" cx="5" cy="12.00001" r="3" />
      <Element as="circle" strokeWidth="2" cx="19" cy="5.00001" r="3" />
      <Element as="line" strokeWidth="2" x1="16.3164" y1="6.3418" x2="7.6836" y2="10.65821" />
      <Element as="line" strokeWidth="2" x1="7.6836" y1="13.3418" x2="16.3164" y2="17.65821" />
      <Element as="circle" strokeWidth="2" cx="19" cy="19.00001" r="3" />
    </Svg>
  );
};
export default ShareIcon;
