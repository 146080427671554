import React, { FC } from 'react';

import useTenantTranslation from '../../../../hooks/useTenantTranslation';
import styled from '../../../../styled';
import { SUBMIDDLE_MARGIN_PX } from '../../../../theme';
import { NotificationSettings } from '../../../../types';
import NotificationRadioItem from './NotificationRadioItem';

interface NotificationsRadioGroupProps {
  value: NotificationSettings;
  handleChange: (val: NotificationSettings) => void;
  className?: string;
}

const NotificationsRadioGroup: FC<NotificationsRadioGroupProps> = ({
  value,
  handleChange,
  className = '',
}) => {
  const { t } = useTenantTranslation();

  return (
    <Wrapper className={className}>
      <StyledNotificationRadioItem
        name={NotificationSettings.Auto}
        checked={NotificationSettings.Auto === value}
        label={t('page.assignedLearnings.auto')}
        tooltip={t('page.assignedLearnings.automaticDescription')}
        data-testid="notificationAutomatic"
        onChange={() => handleChange(NotificationSettings.Auto)}
      />
      <StyledNotificationRadioItem
        name={NotificationSettings.Week}
        checked={NotificationSettings.Week === value}
        label={t('page.assignedLearnings.week')}
        tooltip={t('page.assignedLearnings.weeklyDescription')}
        data-testid="notificationWeekly"
        onChange={() => handleChange(NotificationSettings.Week)}
      />
      <StyledNotificationRadioItem
        name={NotificationSettings.Month}
        checked={NotificationSettings.Month === value}
        label={t('page.assignedLearnings.month')}
        tooltip={t('page.assignedLearnings.monthlyDescription')}
        data-testid="notificationMonthly"
        onChange={() => handleChange(NotificationSettings.Month)}
      />
    </Wrapper>
  );
};

export default NotificationsRadioGroup;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: row;
  }
`;
const StyledNotificationRadioItem = styled(NotificationRadioItem)`
  margin-right: ${SUBMIDDLE_MARGIN_PX};

  &:last-child {
    margin-right: 0;
  }
`;
