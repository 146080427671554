import { phoneNumberRegExp } from '../../utils/regexps';
import { createValidation, createSchema } from '../../utils/validator';

export const requestDemoFormSchema = (t) =>
  createSchema({
    name: createValidation().required(t('error.fieldRequired')),
    email: createValidation().required(t('error.fieldRequired')).email(t('error.emailIncorrect')),
    companyName: createValidation().exist(t('error.fieldRequired')),
    phone: createValidation().match(phoneNumberRegExp, t('error.invalidPhone')),
  });
