import React from 'react';

import { Box, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

import styled, { css, CssUtilityType } from '../../../styled';
import { TINY_MARGIN_PX } from '../../../theme';

export interface CategoryLabelProps {
  name: string;
  bgColor?: string;
  textColor?: string;
  className?: string;
  textCss?: CssUtilityType;
  url?: string | null;
  withDots?: boolean;
  textMaxWidth?: `${string}px` | `${string}%`;
  Icon?: React.FC<any>;
}

const CategoryLabel: React.FC<CategoryLabelProps> = ({
  name,
  bgColor,
  textColor,
  className = '',
  url = '',
  withDots = true,
  textMaxWidth,
  Icon,
  ...rest
}) => {
  const theme = useTheme();
  bgColor = bgColor || theme.palette.common.category.secondary;
  textColor = textColor || theme.palette.common.category.color;

  if (url) {
    return (
      <CategoryLabelLink
        data-testid={rest['data-testid']}
        className={className}
        to={url}
        bgColor={bgColor}
        textColor={textColor}
        textMaxWidth={textMaxWidth}
        optionalStyles={withDots && dots(textMaxWidth)}
      >
        {Icon && <StyledIcon as={Icon} />}
        {name}
      </CategoryLabelLink>
    );
  }

  return (
    <CategoryLabelWrapper
      data-testid={rest['data-testid']}
      className={className}
      textColor={textColor}
      bgColor={bgColor}
    >
      {Icon && <StyledIcon as={Icon} />}
      {name}
    </CategoryLabelWrapper>
  );
};

export default CategoryLabel;

const createCategoryLabel = (tag) => styled(tag, {
  shouldForwardProp: (prop) =>
    prop !== 'bgColor' &&
    prop !== 'textColor' &&
    prop !== 'optionalStyles' &&
    prop !== 'textMaxWidth',
})`
  border-radius: 5px;
  background: ${({ bgColor }) => bgColor};
  padding: 4px 9px 5px;
  box-sizing: border-box;
  display: block;
  align-items: center;
  color: ${({ textColor }) => textColor};
  font-weight: bold;
  font-size: 14px;
  line-height: 1.4;

  && {
    ${({ optionalStyles }) => optionalStyles}
  }
`;
const CategoryLabelLink = createCategoryLabel(Link);
const CategoryLabelWrapper = createCategoryLabel(Box);

const StyledIcon = styled('div', {
  shouldForwardProp: (prop) => prop !== 'as',
})`
  width: 16px;
  height: 16px;
  margin-right: ${TINY_MARGIN_PX};
  margin-top: 2px;
`;
const dots =
  (textMaxWidth) =>
  ({ theme }) =>
    css`
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 100px;

      ${theme.breakpoints.up('md')} {
        max-width: ${textMaxWidth || '125px'};
      }
    `;
