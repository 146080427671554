import React from 'react';

import { FormControlLabel, Radio } from '@mui/material';

import styled from '../../styled';

const RadioButton = ({ label, ...rest }) => {
  return (
    <FormControlLabel
      data-testid={rest['data-testid']}
      label={label}
      control={<StyledRadio />}
      {...rest}
    />
  );
};

export default RadioButton;

const StyledRadio = styled(Radio)`
  & .MuiSvgIcon-root {
    fill: ${({ theme }) => theme.palette.common.white};
  }
  &.Mui-checked {
    color: ${({ theme }) => theme.palette.common.white};
  }
`;
