import { translate } from '../translate';
import { sToHM } from './sToHM';

export const secondsToHHHmmm = (sec) => {
  const n = Number(sec);
  const [hours, minutes] = sToHM(n);

  let res = '';
  if (hours) {
    res += translate('common.hour', { count: hours });
  }
  if (minutes) {
    res += ` ${translate('common.minute', { count: minutes })}`;
  }

  return res.trim();
};
