import { useContext, useMemo } from 'react';

import { useTenantContext } from '../context/TenantProvider';
import { ThemeCtx } from '../context/ThemeProvider/ThemeProvider';
import { getHeroImageSet, TenantsRefs } from './tenantsConfig';

// image url is memoized to prevent changing hero img while switching between tabs on discovery
function useRandomHeroImage() {
  const { mode } = useContext(ThemeCtx);
  const { tenant, fallbackTenantName } = useTenantContext();
  const tenantName = tenant?.name || fallbackTenantName;
  const imagesByTenant = useMemo(() => getHeroImageSet(mode), [mode]);
  const images = imagesByTenant[tenantName];
  const randomImage = useMemo(() => images[Math.floor(Math.random() * images.length)], [images]);

  if (process.env.REACT_APP_IS_E2E) {
    return imagesByTenant[TenantsRefs.Fu].at(1);
  }

  return randomImage;
}

export default useRandomHeroImage;
