import React, { FC, useContext, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { Loader } from '../../components/Loader';
import { TenantCtx } from '../../context/TenantProvider';
import useAuth from '../../hooks/useAuth';

const RegistrationPage: FC = () => {
  const navigate = useNavigate();
  const { loginWithRedirect, isAuthenticated, isLoading, isInitialized } = useAuth();
  const { tenant, config } = useContext(TenantCtx);

  useEffect(() => {
    if (isInitialized && !isLoading) {
      if (isAuthenticated) {
        navigate(config?.appMainPage, { replace: true });
      } else {
        loginWithRedirect({
          redirectUri: `/pricing`,
          mode: 'signUp',
        });
      }
    }
  }, [isInitialized, isLoading, isAuthenticated, loginWithRedirect, navigate, tenant, config]);

  return <Loader />;
};

export default RegistrationPage;
