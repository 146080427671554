import React from 'react';

import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import useTenantTranslation from '../../hooks/useTenantTranslation';
import { IModal } from '../../modals/Modal';
import { StyledCloseIcon } from '../../modals/modalStyles';
import styled from '../../styled';
import { SMALL_MARGIN_PX } from '../../theme';
import { CustomButton } from '../UI/CustomButton';

export interface ILeaveModal extends IModal {
  goBackPath: string;
  backBtnText: string;
  description: string;
}

const LeaveModal: React.FC<ILeaveModal> = ({
  goBackPath,
  backBtnText,
  description,
  closeModal,
}) => {
  const { t } = useTenantTranslation();

  return (
    <StyledContainer data-testid="leaveModal">
      <ModalContent>
        <StyledCloseIcon onClick={closeModal} />
        <Typography variant="h3">{t('page.test.leaveTitle')}</Typography>
        <ModalDescription>{description}</ModalDescription>
        <Controls>
          <Link to={goBackPath}>
            <CustomButton color="secondary">{backBtnText}</CustomButton>
          </Link>
          <CustomButton onClick={closeModal}>{t('button.continueTest')}</CustomButton>
        </Controls>
      </ModalContent>
    </StyledContainer>
  );
};

export default LeaveModal;

const StyledContainer = styled(Box)`
  display: flex;
  align-items: center;
  height: 100vh;
`;
const ModalContent = styled(Box)`
  background: ${({ theme }) => theme.palette.secondary.main};
  border-radius: 5px;
  max-width: 590px;
  padding: 32px 24px;
  text-align: center;
`;
const ModalDescription = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.gray};
  margin: 24px 0 50px;
`;
const Controls = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > a {
    margin-bottom: 9px;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: row;
    justify-content: center;

    & > a {
      margin-bottom: 0;
      margin-right: ${SMALL_MARGIN_PX};
    }
  }
`;
