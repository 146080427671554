const replaceByRegExp = (regExp, replacementFn) => (string) => {
  if (!string) {
    return [];
  }

  const matches = string.match(regExp);

  if (!matches) {
    return [string];
  }

  return string
    .split(regExp)
    .reduce((acc, item, index) => {
      const res = [...acc, item];
      const match = matches[index];

      if (match) {
        res.push(replacementFn(match));
      }

      return res;
    }, [])
    .filter(Boolean);
};

export default replaceByRegExp;
