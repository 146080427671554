import React, { useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import parse from 'html-react-parser';

import PlaySmallIcon from '../../assets/icons/PlaySmallIcon';
import { HERO_IMG_SRC_SET } from '../../const';
import Modal, { Modals } from '../../modals/Modal';
import styled, { css, CssUtilityType } from '../../styled';
import { LARGE_MARGIN_PX, MIDDLE_MARGIN_PX, SMALL_MARGIN_PX } from '../../theme';
import { parseHighlightedText } from '../../utils/stringUtils';
import useRandomHeroImage from '../../utils/useRandomHeroImage';
import IKImage from '../IKImage/IKImage';
import { PreloadImageLink } from '../PreloadImageLink';
import { Button } from '../UI/Button';
import { IconButton } from '../UI/IconButton';
import { BottomItem } from './components/BottomItem';

interface IntroBlockProps {
  data: any;
  video?: boolean;
  wrapperCss?: CssUtilityType;
  blockCss?: CssUtilityType;
  headerCss?: CssUtilityType;
  descriptionCss?: CssUtilityType;
  isSmall?: boolean;
  hasGradient?: boolean;

  [x: string]: any;
}

const IntroBlock: React.FC<IntroBlockProps> = ({
  data: introData,
  video = true,
  wrapperCss,
  blockCss,
  headerCss = '',
  descriptionCss = '',
  isSmall = undefined,
  hasGradient = undefined,
}) => {
  const background = introData?.background;
  const highlightColor = introData?.heading_highlight_color;
  const previewVideoRef = useRef<HTMLVideoElement>(null);
  const [isVideoVisible, setVideoVisibility] = useState(false);
  const playBtnTitle = introData?.watch_video_button_text;
  const bottomItems = introData?.usp_blocks;

  useEffect(() => {
    const previewVideo = previewVideoRef.current;

    if (previewVideo) {
      previewVideo.oncanplay = () => {
        setVideoVisibility(true);
      };

      previewVideo.onended = () => {
        setVideoVisibility(false);
      };
    }

    return () => {
      if (previewVideo) {
        previewVideo.onended = null;
        previewVideo.oncanplay = null;
      }
    };
  }, []);

  const heading =
    typeof introData?.heading === 'string' ? introData?.heading : introData?.heading?.en;
  const description =
    typeof introData?.description === 'string'
      ? introData?.description
      : introData?.description?.en;
  const randomHeroImage = useRandomHeroImage();
  const previewImg = introData?.preview_image_url || randomHeroImage;
  const buttonText = introData?.button_text;
  const buttonUrl = introData?.button_url;
  const videoUrl = introData?.video_url || '';
  const previewVideoUrl = introData?.preview_video_url;
  const gradient = typeof hasGradient === 'undefined' ? introData?.gradient : hasGradient;

  const isImageBlockOnlyWithHeading =
    typeof isSmall === 'undefined' ? !video && !buttonText && (description || heading) : isSmall;

  return (
    <>
      {previewImg && <PreloadImageLink path={previewImg} />}
      <Wrapper
        data-testid="introBlock"
        className="intro-block"
        additionalStyles={wrapperCss}
        video={video}
        background={background}
      >
        <Img
          path={previewImg}
          alt={heading}
          srcset={HERO_IMG_SRC_SET}
          sizes="100vw"
          draggable={false}
        />
        <PreviewVideo
          ref={previewVideoRef}
          autoPlay
          muted
          src={previewVideoUrl}
          optionalStyles={!isVideoVisible && hide}
        />
        <StyledContainer
          className="intro-block-container"
          optionalStyles={isImageBlockOnlyWithHeading && smallPadding}
          video={video}
        >
          <TextWrapper additionalStyles={blockCss}>
            {heading && (
              <Title
                variant="h1"
                component="h1"
                align="center"
                optionalStyles={isImageBlockOnlyWithHeading && smallMargin}
                additionalStyles={headerCss}
                highlightColor={highlightColor}
              >
                {parse(parseHighlightedText(heading))}
              </Title>
            )}

            {description && (
              <SubTitle align="center" additionalStyles={descriptionCss}>
                {description}
              </SubTitle>
            )}

            {video && (
              <Modal url={videoUrl} bumperUrl={null}>
                {({ openModal }) => (
                  <StyledIconButton
                    icon={<PlayIcon color={'currentColor'} />}
                    label={playBtnTitle}
                    onClick={() => openModal(Modals.VideoModal)}
                    size="large"
                  />
                )}
              </Modal>
            )}

            {buttonText && <CustomBtn text={buttonText} link={buttonUrl} />}
          </TextWrapper>
        </StyledContainer>

        {gradient && <Gradient />}

        {/* Only for Intro Image block desktop version */}

        {!video && <TopGradient />}
        <BottomGradient />
      </Wrapper>
      {bottomItems?.length > 0 && (
        <BottomItemsWrapper className={'dark-block'}>
          {bottomItems.map((item) => (
            <BottomItem key={item?.image} title={item?.label_text} imageUrl={item?.image} />
          ))}
        </BottomItemsWrapper>
      )}
    </>
  );
};

export default IntroBlock;

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'video' && prop !== 'background' && prop !== 'additionalStyles',
})`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: ${({ video }) => (video ? '650px' : '0px')};
  background: ${({ theme }) => theme.palette.common.blockBackground.main};
  z-index: 0;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 476px;
    max-height: 100%;
    background-color: ${({ theme }) => theme.palette.common.transparent};
    background: linear-gradient(
        180deg,
        ${({ theme }) => theme.palette.common.blockBackground.main} 0%,
        ${({ theme }) => alpha(theme.palette.common.blockBackground.main, 0)} 100%
      )
      0% 0% no-repeat;
    -webkit-background-origin: padding-box;
    -o-background-origin: padding-box;
    -moz-background-origin: padding-box;
    z-index: 3;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 242px;
    background-color: ${({ theme }) => theme.palette.common.transparent};
    background: linear-gradient(
        0deg,
        ${({ theme }) => theme.palette.common.blockBackground.main} 0%,
        ${({ theme }) => alpha(theme.palette.common.blockBackground.main, 0)} 100%
      )
      0% 0% no-repeat;
    -webkit-background-origin: padding-box;
    -o-background-origin: padding-box;
    -moz-background-origin: padding-box;
    z-index: 3;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: ${({ video }) => (video ? '80vh' : 'auto')};
    max-height: ${({ video }) => (video ? '900px' : 'none')};
    background: ${({ background, theme }) =>
      background === 'Dark'
        ? theme.palette.common.blockBackground.main
        : theme.palette.secondary.light};

    &::before {
      width: 70%;
      height: 101%;
      background-color: ${({ theme }) => theme.palette.common.transparent};
      background: linear-gradient(
          90deg,
          ${({ theme }) => theme.palette.common.blockBackground.main} 0%,
          ${({ theme }) => alpha(theme.palette.common.blockBackground.main, 0)} 100%
        )
        0% 0% no-repeat;
      -webkit-background-origin: padding-box;
      -o-background-origin: padding-box;
      -moz-background-origin: padding-box;
    }

    &::after {
      display: none;
    }
  }

  ${({ additionalStyles }) => additionalStyles}
`;
const Img = styled(IKImage)`
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  height: 100%;
  width: 100%;
  object-fit: cover;
`;
const PreviewVideo = styled('video', {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})`
  display: none;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: block;
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    min-width: 100%;
    min-height: 100%;
    right: 50%;
    width: auto;
    height: auto;
    top: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    opacity: 1;
    transition: opacity 1s ease;
  }

  && {
    ${({ optionalStyles }) => optionalStyles}
  }
`;
const hide = css`
  opacity: 0;
`;
const StyledContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'video' && prop !== 'optionalStyles',
})`
  padding-top: ${({ video }) => (video ? '0px' : '160px')};
  padding-bottom: ${({ video }) => (video ? '0px' : '90px')};
  z-index: 4;
`;
const smallPadding = css`
  padding-top: 160px;
  padding-bottom: 60px;
`;
const TextWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'additionalStyles',
})`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 435px;
  }

  ${({ additionalStyles }) => additionalStyles}
`;
const Title = styled(Typography, {
  shouldForwardProp: (prop) =>
    prop !== 'highlightColor' && prop !== 'additionalStyles' && prop !== 'optionalStyles',
})`
  && {
    margin-bottom: ${SMALL_MARGIN_PX};
    text-align: left;

    ${({ theme }) => theme.breakpoints.up('md')} {
      margin-bottom: ${MIDDLE_MARGIN_PX};
    }

    & span {
      color: ${({ highlightColor, theme }) => highlightColor || theme.palette.common.primary};
    }

    ${({ additionalStyles }) => additionalStyles}

    ${({ optionalStyles }) => optionalStyles}
  }
`;
const smallMargin = css`
  && {
    margin-bottom: 20px;
  }
`;
const SubTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'additionalStyles',
})`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 22px;
  width: 100%;
  text-align: left;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: ${MIDDLE_MARGIN_PX};
  }

  ${({ theme }) => theme.breakpoints.between('sm', 'lg')} {
    max-width: 600px;
  }

  ${({ additionalStyles }) => additionalStyles}
`;
const Gradient = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(
    at center,
    ${({ theme }) => theme.palette.common.blue} 0%,
    ${({ theme }) => theme.palette.common.primary} 100%
  );
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  opacity: 0.3;

  ${({ theme }) => theme.breakpoints.up('md')} {
    background-image: radial-gradient(
      at center left,
      ${({ theme }) => theme.palette.common.blue} 0%,
      ${({ theme }) => theme.palette.common.primary} 100%
    );
  }
`;
const TopGradient = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 300px;
  background: ${({ theme }) => theme.palette.common.transparent}
    linear-gradient(
      180deg,
      ${({ theme }) => theme.palette.common.blockBackground.main} 0%,
      ${({ theme }) => alpha(theme.palette.common.blockBackground.main, 0)} 100%
    )
    0% 0% no-repeat padding-box;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;
const BottomGradient = styled('div')`
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 373px;
  background: ${({ theme }) => theme.palette.common.transparent}
    linear-gradient(
      0deg,
      ${({ theme }) => theme.palette.common.blockBackground.main} 0%,
      ${({ theme }) => alpha(theme.palette.common.blockBackground.main, 0)} 100%
    )
    0% 0% no-repeat padding-box;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;
const BottomItemsWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  padding-bottom: ${MIDDLE_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-bottom: ${LARGE_MARGIN_PX};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const StyledIconButton = styled(IconButton)`
  margin-bottom: ${MIDDLE_MARGIN_PX};

  &:hover {
    border-bottom-color: ${({ theme }) => theme.palette.common.transparent};
  }
`;
const PlayIcon = styled(PlaySmallIcon)`
  width: 24px;
  height: 24px;
`;
const CustomBtn = styled(Button)`
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: unset;
  }
`;
