import React from 'react';

import { Autocomplete, Box, Typography } from '@mui/material';

import useTenantTranslation from '../../../../hooks/useTenantTranslation';
import useValidation from '../../../../hooks/useValidation';
import { IModal } from '../../../../modals/Modal';
import {
  CancelButton,
  ModalContent,
  ModalWrapper,
  StyledCloseIcon,
  StyledCustomButton,
  StyledFormField,
} from '../../../../modals/modalStyles';
import { LearnerInfo, Team } from '../../../../types';
import { learnerTeamFormSchema } from './validation';

export interface IChangeLearnerTeamModal extends IModal {
  learner?: LearnerInfo | null;
  teams: (Team | null)[];
  currentTeam?: Team | null;
  onSubmit: (value: { team: Team }) => void;
}

const ChangeLearnerTeamModal: React.FC<IChangeLearnerTeamModal> = ({
  teams,
  learner,
  currentTeam,
  closeModal,
  onSubmit,
}) => {
  const { t } = useTenantTranslation();
  const { values, errors, handleSubmit, handleAutocompleteChange } = useValidation(
    {
      team: currentTeam,
    },
    learnerTeamFormSchema,
    onSubmit,
  );

  return (
    <ModalWrapper data-testid="changeTeamModal">
      <StyledCloseIcon onClick={closeModal} />
      <Typography variant="h3">
        {t('page.account.changeTeamFor', { learner: learner?.name })}
      </Typography>
      <ModalContent>
        <Autocomplete
          data-testid="teamField"
          options={teams || []}
          getOptionLabel={(option) => option?.name || ''}
          value={values.team}
          onChange={handleAutocompleteChange('team')}
          renderInput={(params) => (
            <StyledFormField
              {...params}
              name="team"
              variant="outlined"
              label={t('page.assignedLearnings.team')}
              error={!!errors?.team}
              helperText={errors?.team}
            />
          )}
        />
      </ModalContent>
      <Box>
        <CancelButton onClick={closeModal} color="secondary">
          {t('button.cancel')}
        </CancelButton>
        <StyledCustomButton data-testid="changeTeamBtn" onClick={handleSubmit}>
          {t('button.change')}
        </StyledCustomButton>
      </Box>
    </ModalWrapper>
  );
};

export default ChangeLearnerTeamModal;
