import PlaySmallIcon from '../../../../assets/icons/PlaySmallIcon';
import RepeatIcon from '../../../../assets/icons/RepeatIcon';
import styled from '../../../../styled';
import { SMALL_MARGIN_PX } from '../../../../theme';
import { CustomButton } from '../../../UI/CustomButton';

export const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${SMALL_MARGIN_PX};
  padding: 24px;
  background-color: ${({ theme }) => theme.palette.common.card.background.light};
  flex-direction: column;
  border-radius: 5px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
  }
  ${({ optionalStyles }) => optionalStyles}
`;
export const StyledButton = styled(CustomButton)``;
const createStyledIcon = (tag) => styled(tag)`
  width: 14px;
  height: 14px;
  margin-right: 9px;
  color: currentColor;
`;
export const StyledPlaySmallIcon = createStyledIcon(PlaySmallIcon);
export const StyledRepeatIcon = createStyledIcon(RepeatIcon);
