import React, { FC } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { SwiperOptions } from 'swiper/types/swiper-options';

import styled from '../../styled';
import { BIG_MARGIN_PX } from '../../theme';
import { Background, CourseListBlock } from '../../types';
import CourseSlide from './CourseSlide';

const getSwiperProps = (hasFewItems: boolean): SwiperOptions => ({
  observer: false,
  slidesPerView: 1,
  ...(hasFewItems && {
    pagination: {
      clickable: true,
    },
    navigation: true,
    mousewheel: {
      forceToAxis: true,
      invert: true,
      releaseOnEdges: true,
    },
  }),
});

interface CoursesBlockProps {
  data?: CourseListBlock;
}

const CoursesBlock: FC<CoursesBlockProps> = ({ data }) => {
  const isDark = data?.background === Background.Dark;
  const hasFewItems = data?.items ? data?.items?.length > 1 : false;
  const swiperProps = getSwiperProps(hasFewItems);

  return (
    <Wrapper isDark={isDark} hasFewItems={hasFewItems}>
      {Boolean(data?.items) && (
        <Swiper {...swiperProps}>
          {data?.items?.map((item) => (
            <SwiperSlide key={item?.course?.id}>
              <CourseSlide
                title={item?.course?.name}
                description={item?.course?.description}
                icon={item?.course?.svg_icon}
                imageUrl={item?.course?.image_url}
                foregroundImageUrl={item?.course?.foreground_image_url}
                foregroundImageName={item?.course?.foreground_image_seo_name}
                slug={item?.course?.slug}
                isDarkSlide={isDark}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </Wrapper>
  );
};

export default CoursesBlock;

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDark' && prop !== 'hasFewItems',
})`
  padding-bottom: ${({ hasFewItems }) => (hasFewItems ? BIG_MARGIN_PX : 0)};
  ${({ theme }) => theme.breakpoints.up('sm')} {
    background: ${({ isDark, theme }) =>
      isDark ? theme.palette.common.blueDark : theme.palette.common.blackSecondary};
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-bottom: 0;
    & .swiper-button-prev,
    & .swiper-button-next {
      transform: translateY(-50%);
      height: 100%;
      margin-top: 0;
      width: 108px;
    }
    & .swiper-button-prev {
      left: 0;
    }
    & .swiper-button-next {
      right: 0;
    }
    & .swiper-pagination {
      position: absolute;
      bottom: ${BIG_MARGIN_PX} !important;
    }
  }
`;
