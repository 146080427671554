import React, { useState } from 'react';

import { SerializedStyles } from '@emotion/react';
import LazyLoad from 'react-lazyload';

import styled from '../../styled';
import { IKImage } from '../IKImage';

interface LazyLoadImageProps {
  offset?: number;
  placeholderQuality?: number;
  placeholderBlur?: number;
  path: string;
  transformation?: any[] | null;
  imageWrapperClass?: string;
  wrapperCss?: SerializedStyles | ((props: any) => SerializedStyles);
  objectFitPolyfill?: string;

  [key: string]: any;
}

const LazyLoadImage: React.FC<LazyLoadImageProps> = ({
  offset = 300,
  placeholderQuality = 10,
  placeholderBlur = 10,
  path,
  transformation = null,
  srcset = [],
  imageWrapperClass,
  wrapperCss,
  sizes = null,
  objectFitPolyfill,
  ...props
}) => {
  const [removedPlaceholder, setRemovedPlaceholder] = useState(false);

  const updatedSrcset = srcset.map((item) => ({
    ...item,
    quality: placeholderQuality,
    blur: placeholderBlur,
  }));

  const removePlaceholder = () => {
    setRemovedPlaceholder(true);

    if (window?.objectFitPolyfill && objectFitPolyfill) {
      window.objectFitPolyfill();
    }
  };

  return (
    <>
      {!removedPlaceholder && (
        <IKImage
          path={path}
          transformation={transformation}
          srcset={updatedSrcset}
          sizes={sizes}
          {...props}
        />
      )}
      <StyledLazyLoad offset={offset} className={imageWrapperClass} optionalStyles={wrapperCss}>
        <IKImage
          path={path}
          transformation={transformation}
          srcset={srcset}
          sizes={sizes}
          onLoad={removePlaceholder}
          onError={removePlaceholder}
          data-object-fit={objectFitPolyfill}
          {...props}
        />
      </StyledLazyLoad>
    </>
  );
};

export default LazyLoadImage;

const StyledLazyLoad = styled(LazyLoad, {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})`
  ${({ optionalStyles }) => optionalStyles}
`;
