import React, { useContext, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { useTheme } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import CloseIcon from '../../assets/icons/CloseIcon';
import { TenantCtx } from '../../context/TenantProvider';
import { GET_GETTING_STARTED_INFO_LEARNER } from '../../graphql/dashboard';
import useIsSsku from '../../hooks/tenant/useIsSsku';
import useTenantTranslation from '../../hooks/useTenantTranslation';
import { Modals } from '../../modals/Modal';
import { PrimaryButton, SecondaryButton } from '../../modals/modalStyles';
import { HOME_ROUTE } from '../../routes';
import {
  BIG_MARGIN_PX,
  LARGE_MARGIN_PX,
  MIDDLE_MARGIN_PX,
  MIDDLE_RADIUS_PX,
  SMALL_MARGIN_PX,
  SUBMIDDLE_MARGIN_PX,
} from '../../theme';
import { Query, StepStatus } from '../../types';
import { TenantsRefs } from '../../utils/tenantsConfig';
import { StepHeader } from './components/StepHeader';
import { steps } from './components/Steps';

export const TOTAL_STEPS = 4;

export const OnboardingModal = ({
  handleSkipOnboarding,
  openModal,
  closeModal,
  values,
  handleStep,
  currentStep,
  onboardingComplete,
  finishOnboarding,
  setOnboardingShowed,
  onAnyButtonClick,
  ...props
}) => {
  const { t } = useTenantTranslation();
  const navigate = useNavigate();
  const { tenant } = useContext(TenantCtx);
  const isSsku = useIsSsku();
  const theme = useTheme();

  const isOnFirstStep = !currentStep;
  const isOnLastStep = currentStep === TOTAL_STEPS;

  const { data: gettingStartedData } = useQuery<Pick<Query, 'getGettingStartedInfoLearnerTab'>>(
    GET_GETTING_STARTED_INFO_LEARNER,
    {
      fetchPolicy: 'network-only',
      skip: onboardingComplete || tenant?.name === TenantsRefs.Dsu,
    },
  );

  const hasGettingStarted =
    gettingStartedData?.getGettingStartedInfoLearnerTab?.show_getting_started_block;
  const introVideoStepCompleted =
    gettingStartedData?.getGettingStartedInfoLearnerTab?.steps?.introduction_video_step
      ?.step_status === StepStatus.Completed;

  const disabled = useMemo(
    () => ({
      1: !isSsku && !values?.categories?.length,
      2:
        values?.answers &&
        Object.values(values?.answers).filter((value) => value).length !==
          Object.keys(values?.answers).length,
      3: !values?.timeForLearningGoals,
    }),
    [isSsku, values],
  );

  const primaryButtonText = isOnLastStep
    ? onboardingComplete
      ? t('button.finish')
      : t('modal.onboarding.continueOnboarding')
    : isOnFirstStep
    ? t('modal.onboarding.start')
    : t('modal.onboarding.continue');

  const secondaryButtonText = onboardingComplete
    ? t('button.cancel')
    : isOnLastStep
    ? t('modal.onboarding.startLearning')
    : t('modal.onboarding.setGoalsLater');

  const handleCloseCompleted = () => {
    finishOnboarding();
    closeModal?.();
  };

  const handleCloseSkipped = () => {
    handleSkipOnboarding();
    closeModal?.();
  };

  const handlePrimaryButton = () => {
    setOnboardingShowed(true);

    if (!isOnLastStep) {
      handleStep();
      return;
    }

    handleCloseCompleted();

    if (!onboardingComplete) {
      navigate(HOME_ROUTE);

      if (!introVideoStepCompleted) {
        openModal?.(Modals.VideoModal, {
          url: tenant?.introduction_video_url,
        });
      }
    }
  };

  const handleSecondaryButton = () => {
    setOnboardingShowed(true);

    if (onboardingComplete) {
      handleCloseSkipped();
      return;
    }

    if (!isOnLastStep) {
      openModal(Modals.SetGoalsLaterModal, { handleSkipOnboarding });
      return;
    }

    handleCloseCompleted();
  };

  const primaryButton = (
    <PrimaryButton
      disabled={disabled[currentStep]}
      data-testid="primaryBtn"
      onClick={handlePrimaryButton}
    >
      {primaryButtonText}
    </PrimaryButton>
  );

  return (
    <Wrapper data-testid="onboardingModal">
      <>
        <StyledCloseIcon
          color={theme.palette.common.text.white}
          data-testid="closeIcon"
          onClick={() =>
            isOnLastStep || onboardingComplete
              ? handleCloseSkipped()
              : openModal?.(Modals.SetGoalsLaterModal)
          }
        />
        <ImageWrapper isSsku={isSsku} />
        <Content>
          <StepHeader currentStep={currentStep} onboardingComplete={onboardingComplete} />
          {steps[currentStep]({
            values,
            handleStep,
            onboardingComplete,
            ...props,
          })}
          <Controls>
            {onboardingComplete && isOnLastStep ? null : (
              <SecondaryButton
                data-testid="secondaryBtn"
                color="secondary"
                onClick={handleSecondaryButton}
              >
                {secondaryButtonText}
              </SecondaryButton>
            )}
            {isOnLastStep
              ? onboardingComplete
                ? primaryButton
                : // todo: remove hardcode
                  tenant?.name !== TenantsRefs.Dsu && hasGettingStarted && primaryButton
              : primaryButton}
          </Controls>
        </Content>
      </>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  position: relative;
  height: calc(100% - ${SUBMIDDLE_MARGIN_PX} * 2);
  display: flex;
  flex-direction: column;
  border-radius: ${MIDDLE_RADIUS_PX};
  padding: 0;
  background: ${({ theme }) => theme.palette.common.card.dark.bg};
  max-width: 1024px;
  width: 100%;
  overflow: hidden;
  margin: 0 ${SUBMIDDLE_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    height: 620px;
  }
`;
const Content = styled('div')`
  padding: ${LARGE_MARGIN_PX} ${SMALL_MARGIN_PX} ${MIDDLE_MARGIN_PX};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.up('md')} {
    overflow: unset;
    min-width: 510px;
    padding: ${BIG_MARGIN_PX} ${LARGE_MARGIN_PX};
    margin-top: 0;
  }
`;

const ImageWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSsku',
})<{ isSsku: boolean }>`
  position: relative;
  width: 100%;
  height: 0;
  background-size: cover;
  background-image: none;
  background-position: center;

  &:after {
    position: absolute;
    content: '';
    height: 150px;
    width: 100%;
    background-size: cover;
    background-position-y: center;
    background-image: ${({ theme }) =>
      `url("https://img-cdn.financeunlocked.com/onboarding_foreground${
        theme.isSsku ? '_ssku' : ''
      }.png")`};
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 151px;
    width: 100%;
    background: ${({ theme }) =>
      `linear-gradient(0deg, ${theme.palette.common.card.dark.bg} 0%, ${alpha(
        theme.palette.common.card.dark.bg,
        0.9,
      )} 30%, ${alpha(theme.palette.common.card.dark.bg, 0)} 120%)`};
    pointer-events: none;
    z-index: 1;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 320px;
    height: 100%;
    display: block;
    background-image: ${({ theme, isSsku }) =>
      `${
        !isSsku
          ? `linear-gradient(270deg, ${theme.palette.common.blackSecondary} 0%, ${alpha(
              theme.palette.common.card.dark.bg,
              0,
            )} 200%),`
          : ''
      } url("https://img-cdn.financeunlocked.com/onboarding_foreground${
        theme.isSsku ? '_ssku' : ''
      }.png")`};

    &:after,
    &:before {
      display: none;
    }
  }
`;

const Controls = styled('div')`
  margin-top: auto;
  display: flex;
  flex-direction: column-reverse;
  justify-content: end;
  gap: ${SUBMIDDLE_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: row;
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: ${SMALL_MARGIN_PX};
  top: ${SMALL_MARGIN_PX};
  display: block;
  cursor: pointer;
  height: 24px;
  width: 24px;
  z-index: 2;

  ${({ theme }) => theme.breakpoints.up('md')} {
    right: 32px;
    top: 32px;
  }
`;
