import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { useOnlineContentConnector } from '../context/OnlineContentConnectorContext';

const useInitWithoutNavigation = () => {
  const { updateData } = useOnlineContentConnector();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location?.search);
    const withoutNavigation = searchParams.get('withoutNavigation');
    if (withoutNavigation === 'true') {
      updateData({ withoutNavigation: true });
      sessionStorage.setItem('withoutNavigation', 'true');
    } else if (withoutNavigation === 'false') {
      updateData({ withoutNavigation: false });
      sessionStorage.removeItem('withoutNavigation');
    }
  }, [location, updateData]);

  useEffect(() => {
    if (sessionStorage.getItem('withoutNavigation')) {
      updateData({ withoutNavigation: true });
    }
  }, [updateData]);
};

export default useInitWithoutNavigation;
