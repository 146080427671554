import React from 'react';

import { Element, Svg, useSvgColor } from './styles';

const ChevronRightIcon = ({ color = '', ...props }) => {
  const svgColor = useSvgColor(color);
  return (
    <Svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}
      svgColor={svgColor}
    >
      <Element as="polyline" points="7 22 17 12 7 2" />
    </Svg>
  );
};

export default ChevronRightIcon;
