import React, { FC } from 'react';

import { Typography } from '@mui/material';

import useTenantTranslation from '../../../../hooks/useTenantTranslation';
import styled from '../../../../styled';
import { SMALL_MARGIN_PX } from '../../../../theme';

interface CardProgressLabelProps {
  current: number;
  total: number;
}

const CardProgressLabel: FC<CardProgressLabelProps> = ({ current, total, ...rest }) => {
  const { t } = useTenantTranslation();
  return (
    <StyledTypo data-testid={rest?.['data-testid']}>
      {t('page.pathway.progressLabel', { current, total })}
    </StyledTypo>
  );
};

export default CardProgressLabel;

const StyledTypo = styled(Typography)`
  position: absolute;
  font-size: 14px;
  font-weight: normal;
  right: 9px;
  bottom: ${SMALL_MARGIN_PX};
  color: ${({ theme }) => theme.palette.common.text.secondaryToWhite};
  z-index: 1;
`;
