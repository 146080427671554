import React, { useContext } from 'react';

import { RM_PAGES } from '../const';
import { TenantCtx } from '../context/TenantProvider';

type PageMetadata = {
  page: RM_PAGES;
};

interface IRMContext extends PageMetadata {
  tenantName?: string | null;
}

const RmContext = React.createContext<{
  metadata: null | IRMContext;
}>({ metadata: null });

export const useRmMetadata = (pageMetadata: PageMetadata) => {
  const context = useContext(RmContext);
  const { tenant } = useContext(TenantCtx);

  context.metadata = { tenantName: tenant?.name, ...pageMetadata };
};

export default RmContext;
