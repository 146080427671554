import React, { useContext } from 'react';

import { TenantCtx } from '../../../context/TenantProvider';
import styled from '../../../styled';
import { VideoDifficulty } from '../../../types';
import { TenantsRefs } from '../../../utils/tenantsConfig';
import IKImage from '../../IKImage/IKImage';
import { LightTooltip } from '../LightTooltip';

interface DifficultyIconProps {
  difficulty: VideoDifficulty;
}

const DifficultyIcon: React.FC<DifficultyIconProps> = ({ difficulty, ...rest }) => {
  const { tenant } = useContext(TenantCtx);
  return (
    <StyledLightTooltip placement="top" title={difficulty?.title} {...rest}>
      <DifIconWrap>
        <DifIcon
          path={difficulty?.icon_url}
          alt={difficulty?.title}
          draggable={false}
          invertDifficulty={tenant?.name !== TenantsRefs.Ssku}
        />
      </DifIconWrap>
    </StyledLightTooltip>
  );
};

export default DifficultyIcon;

const StyledLightTooltip = styled(LightTooltip)`
  height: 29px;
`;
const DifIconWrap = styled('div')`
  height: 29px;
  width: 29px;
  min-width: 28px;
  max-width: 28px;
  border-radius: 5px;
  background: ${({ theme }) => theme.palette.common.category.secondary};
  box-sizing: border-box;
`;
const DifIcon = styled(IKImage, {
  shouldForwardProp: (prop) => prop !== 'invertDifficulty',
})<{ invertDifficulty: boolean }>`
  width: 100%;
  height: 100%;
  padding: 7px;
  filter: ${({ invertDifficulty }) => invertDifficulty && 'invert(1)'};
`;
