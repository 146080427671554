import { useCallback, useState } from 'react';

import downloadFile from '../utils/downloadFile';

function useDownloadFile() {
  const [loading, setLoading] = useState(false);

  const download = useCallback((name, url) => {
    setLoading(true);
    downloadFile(name, url)
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  return { download, loading };
}

export default useDownloadFile;
