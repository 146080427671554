import React, { useRef } from 'react';

import { styled, Theme } from '@mui/material/styles';

import { css } from '../../../styled';
import Select from '../../Select/Select';

const StyledSelect = ({ ...props }) => {
  const selectRef = useRef<HTMLDivElement>(null);

  return (
    <SelectWrapper ref={selectRef}>
      <CustomizedSelect
        anchorRef={selectRef}
        selectBtnCss={selectBtnCss}
        selectHeaderCss={selectHeaderCss}
        widthByAnchor
        {...props}
      />
    </SelectWrapper>
  );
};

export default StyledSelect;

const SelectWrapper = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  marginBottom: 24,
  borderRadius: '999em',
  background: theme.palette.common.violetAnalyticsCard,
  justifyContent: 'space-around',
  width: '100%',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    width: 'auto',
  },
}));
const CustomizedSelect = styled(Select)`
  width: 100%;
`;
const selectBtnCss = css`
  width: 100%;
`;
const selectHeaderCss = css`
  width: 100%;
  justify-content: space-between;
`;
