import React from 'react';

import { Typography } from '@mui/material';

import styled from '../../../../styled';

interface CardAccompanyingInfoProps {
  text: string;
  className?: string;
}

const CardAccompanyingInfo: React.FC<CardAccompanyingInfoProps> = ({
  text,
  className = '',
  ...rest
}) => {
  return (
    <HelperText className={className} {...rest}>
      {text}
    </HelperText>
  );
};

export default CardAccompanyingInfo;

const HelperText = styled(Typography)`
  font-size: 14px;
  line-height: 1.2;
  font-weight: normal;
  color: ${({ theme }) => theme.palette.common.text.meta};
`;
