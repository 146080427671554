import React, { useContext, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import CustomBlocks from '../../blocks/CustomBlocks/CustomBlocks';
import { RM_PAGES } from '../../const';
import { GtmCtx } from '../../containers/CommonLayout/CommonLayout';
import { useRmMetadata } from '../../context/RmContext';
import { Block, LearnerInfo, Tenant } from '../../types';
import { GtmEvents, sendEventToGTM } from '../../utils/gtmUtils';

interface CustomPageProps {
  blocks: (Block | null)[] | null;
  tenant?: Tenant | null;
  userInfo?: LearnerInfo;
  preventLoader?: boolean;
  blocksLoading?: boolean;
  canonicalUrl?: string;
}

const CustomPage: React.FC<CustomPageProps> = ({
  blocks,
  preventLoader,
  tenant,
  userInfo,
  blocksLoading,
  canonicalUrl,
}) => {
  const location = useLocation();
  const { isInitialized: isGtmInitialized } = useContext(GtmCtx);
  useRmMetadata({ page: RM_PAGES.CustomPage });

  useEffect(() => {
    if (isGtmInitialized) {
      sendEventToGTM(GtmEvents.customPageVisit);
    }
  }, [isGtmInitialized, location.pathname]);

  return (
    <CustomBlocks
      blocks={blocks}
      preventLoader={preventLoader}
      tenant={tenant}
      userInfo={userInfo}
      blocksLoading={blocksLoading}
      canonicalUrl={canonicalUrl}
    />
  );
};

export default CustomPage;
