const redirectToNewSlug = (
  redirect: ((url) => void) | undefined,
  path: string,
  currSlug: string,
  newSlug: string | null | undefined,
) => {
  if (redirect && newSlug && newSlug !== currSlug) {
    //replace last match
    const lastMatchIndex = path.lastIndexOf(currSlug);
    if (lastMatchIndex >= 0) {
      const url =
        path.substring(0, lastMatchIndex) +
        newSlug +
        path.substring(lastMatchIndex + currSlug.length, path.length);
      redirect(url);
      return true;
    }
  }

  return false;
};

export default redirectToNewSlug;
