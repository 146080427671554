import styled from '../../../../styled';
import { SMALL_RADIUS_PX } from '../../../../theme';

const CardGradient = styled('div')`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 66px;
  bottom: 0;
  left: 0;
  border-radius: 0 0 ${SMALL_RADIUS_PX} ${SMALL_RADIUS_PX};
  background: ${({ theme }) => theme.palette.common.card.progressGradient};
`;

export default CardGradient;
