import React from 'react';

import { useTheme } from '@mui/material';

import { Svg } from './styles';

const InstagramIcon = ({ color = '', ...props }) => {
  const theme = useTheme();
  const svgColor = color || theme.palette.common.violet;

  return (
    <Svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      svgColor={svgColor}
      {...props}
    >
      <path
        fill="currentColor"
        id="Icon_awesome-instagram"
        data-name="Icon awesome-instagram"
        d="M12,5.85A6.15,6.15,0,1,0,18.15,12h0A6.14,6.14,0,0,0,12,5.85ZM12,16a4,4,0,1,1,4-4h0A4,4,0,0,1,12,16ZM19.84,5.59a1.44,1.44,0,1,1-1.43-1.43,1.43,1.43,0,0,1,1.43,1.43Zm4.08,1.46A7.13,7.13,0,0,0,22,2,7.22,7.22,0,0,0,17,.08C15,0,9,0,7.05.08A7.17,7.17,0,0,0,2,2a7.16,7.16,0,0,0-1.94,5C0,9,0,15,.08,17A7.13,7.13,0,0,0,2,22a7.22,7.22,0,0,0,5,1.94C9,24,15,24,17,23.92A7.11,7.11,0,0,0,22,22a7.22,7.22,0,0,0,1.94-5C24,15,24,9,23.92,7.06Zm-2.56,12a4.1,4.1,0,0,1-2.28,2.29c-1.58.62-5.33.48-7.08.48s-5.5.14-7.07-.48a4,4,0,0,1-2.28-2.29C2,17.49,2.16,13.75,2.16,12S2,6.5,2.65,4.92A4,4,0,0,1,4.93,2.64C6.51,2,10.26,2.16,12,2.16s5.5-.14,7.07.48a4.07,4.07,0,0,1,2.28,2.29c.63,1.58.48,5.32.48,7.07s.15,5.5-.47,7.07Z"
      />
    </Svg>
  );
};

export default InstagramIcon;
