import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import useScriptLoader, { SCRIPTS } from '../context/ScriptLoader/useScriptLoader';
import { LearnerInfo } from '../types';
import UserCom from '../utils/UserCom';
import { getRoleForUserCom, getUserComUserId, handleUserComPayload } from '../utils/userComUtils';
import useAuth from './useAuth';

const useInitUserCom = (userInfo?: LearnerInfo | null) => {
  const prefix = '/rxd';
  const location = useLocation();
  const [isInitializedUserCom, setIsInitializedUserCom] = useState(false);
  const { isLoaded: isUserComLoaded } = useScriptLoader(SCRIPTS.USER_COM);
  const { isInitialized: isInitializedAuth, isLoading: isLoadingAuth, isAuthenticated } = useAuth();

  useEffect(() => {
    if (
      isUserComLoaded &&
      isInitializedAuth &&
      !isLoadingAuth &&
      UserCom.checkHasPageHit() &&
      location?.pathname?.slice(0, prefix.length) !== prefix &&
      !isInitializedUserCom
    ) {
      if (isAuthenticated) {
        if (userInfo) {
          const role = getRoleForUserCom(userInfo?.role);
          setIsInitializedUserCom(true);
          UserCom.pageHit({
            user_id: getUserComUserId(userInfo.id),
            email: userInfo?.email,
            referrer_url: document?.referrer,
            ...(!userInfo?.has_subscription && { has_subscription: false }),
            had_subscription_bool: Boolean(userInfo?.had_subscription),
            owned_subscription: Boolean(userInfo?.owned_subscription),
            ...(userInfo?.name && { name: userInfo.name }),
            team_id:
              userInfo.teams?.length && userInfo?.teams?.[0]?.id ? userInfo?.teams?.[0]?.id : '',
            team_name:
              userInfo.teams?.length && userInfo?.teams?.[0]?.name
                ? userInfo?.teams?.[0]?.name
                : '',
            company_name: userInfo?.company?.name ? userInfo.company.name : '',
            ...(role && { role }),
            is_registered_user: true,
            registration_date: userInfo?.registration_date,
            active_days_count: userInfo?.active_days_count,
            ...(userInfo?.chargebee_tenant_name && {
              tenant_name: userInfo?.chargebee_tenant_name,
            }),
            onPayloadReceived: handleUserComPayload(userInfo),
          });
        }
      } else {
        setIsInitializedUserCom(true);
        UserCom.pageHit({
          referrer_url: document?.referrer,
          onPayloadReceived: handleUserComPayload(null),
        });
      }
    }
  }, [
    isAuthenticated,
    isInitializedAuth,
    isLoadingAuth,
    userInfo,
    isUserComLoaded,
    location,
    isInitializedUserCom,
  ]);
};

export default useInitUserCom;
