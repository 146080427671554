import React, { FC } from 'react';

import { Typography } from '@mui/material';

import IKImage from '../../components/IKImage/IKImage';
import styled from '../../styled';
import {
  MIDDLE_MARGIN_PX,
  MIDDLE_RADIUS_PX,
  SMALL_MARGIN_PX,
  SUBMIDDLE_MARGIN_PX,
  TINY_MARGIN_PX,
} from '../../theme';

interface TestimonialCardProps {
  isDark?: boolean;
  personAvatar?: string | null;
  personAvatarSeoName?: string | null;
  personName?: string;
  jobTitle?: string;
  companyLogo?: string | null;
  companyLogoSeoName?: string | null;
  companyName?: string | null;
  quote?: string;
  linkUrl?: string | null;
  linkTitle?: string | null;
}

const TestimonialCard: FC<TestimonialCardProps> = ({
  isDark,
  personAvatar,
  personAvatarSeoName,
  personName,
  jobTitle,
  companyName,
  companyLogo,
  companyLogoSeoName,
  quote,
  linkUrl,
  linkTitle,
}) => {
  return (
    <Card isDark={isDark}>
      <InfoRow>
        {personAvatar && (
          <ProfileImg
            srcset={[{ width: 80, quality: 80 }]}
            sizes="80px"
            path={personAvatar}
            alt={personAvatarSeoName}
          />
        )}
        <div>
          <UserName variant="h3">{personName}</UserName>
          <InfoTypo>{jobTitle}</InfoTypo>
          <InfoTypo>{companyName}</InfoTypo>
        </div>
        {companyLogo && (
          <CompLogo
            path={companyLogo}
            alt={companyLogoSeoName}
            srcset={[{ width: 80, quality: 80 }]}
            sizes="80px"
          />
        )}
      </InfoRow>
      <TextContent>{quote}</TextContent>
      {linkUrl && (
        <StyledLink href={linkUrl} target="_blank" rel="noopener noreferrer">
          {linkTitle}
        </StyledLink>
      )}
    </Card>
  );
};

export default TestimonialCard;

const Card = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDark',
})`
  height: 100%;
  padding: ${SUBMIDDLE_MARGIN_PX} ${SMALL_MARGIN_PX};
  box-sizing: border-box;
  border-radius: ${MIDDLE_RADIUS_PX};
  position: relative;
  background: ${({ theme }) => theme.palette.common.blackSecondary};

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${MIDDLE_MARGIN_PX};
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    background: ${({ isDark, theme }) =>
      isDark ? theme.palette.secondary.dark : theme.palette.secondary.light};
  }
`;

const InfoRow = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: ${SUBMIDDLE_MARGIN_PX};
`;
const ProfileImg = styled(IKImage)`
  width: 48px;
  height: 48px;
  min-width: 48px;
  margin-right: ${TINY_MARGIN_PX};
  object-fit: cover;
  border-radius: 50%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 80px;
    min-width: 80px;
    height: 80px;
    margin-right: ${SMALL_MARGIN_PX};
  }
`;
const CompLogo = styled(IKImage)`
  width: 48px;
  min-width: 48px;
  height: 48px;
  object-fit: contain;
  margin-left: auto;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 80px;
    min-width: 80px;
    height: 80px;
  }
`;
const UserName = styled(Typography)`
  font-size: 18px;
  margin-bottom: 0;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 18px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: ${TINY_MARGIN_PX};
    font-size: 20px;
  }
`;
const InfoTypo = styled(Typography)`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.common.gray};
`;
const TextContent = styled(Typography)`
  margin-bottom: ${SMALL_MARGIN_PX};
  font-size: 16px;
  word-break: break-word;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 18px;
  }
`;
const StyledLink = styled('a')`
  color: ${({ theme }) => theme.palette.common.violet};
  font-size: 14px;
`;
