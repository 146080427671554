import React from 'react';

import { Svg, useSvgColor } from './styles';

const VideoNewIcon = ({ color = '', ...props }) => {
  const svgColor = useSvgColor(color);
  return (
    <Svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      svgColor={svgColor}
      {...props}
    >
      <path
        d="M1 6.4V17C1 18.6569 2.34315 20 4 20H14M1 6.4V4.7C1 3.76112 1.76112 3 2.7 3V3M1 6.4H6.3125M21.4 6.4V6C21.4 4.34315 20.0569 3 18.4 3H16.725M21.4 6.4H20.125M21.4 6.4V13M12.05 3L15.5208 6.4M12.05 3H7.375M12.05 3H16.725M15.5208 6.4H10.9167M15.5208 6.4H20.125M7.375 3L10.9167 6.4M7.375 3H2.7M10.9167 6.4H6.3125M16.725 3L20.125 6.4M2.7 3L6.3125 6.4"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M14.5 11.634C15.1667 12.0189 15.1667 12.9811 14.5 13.366L10.75 15.5311C10.0833 15.916 9.25 15.4349 9.25 14.6651L9.25 10.3349C9.25 9.56513 10.0833 9.08401 10.75 9.46891L14.5 11.634Z"
        stroke="currentColor"
      />
      <path
        d="M17 18.9883H22.9717"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9766 16V21.9717"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default VideoNewIcon;
