import React from 'react';

import { Box, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import useTenantTranslation from '../../hooks/useTenantTranslation';
import {
  ModalContent,
  ModalWrapper,
  PrimaryButton,
  SecondaryButton,
  StyledCloseIcon,
} from '../../modals/modalStyles';
import { SMALL_MARGIN_PX } from '../../theme';

export const SetGoalsLaterModal = ({ closeAllModals, closeModal, handleSkipOnboarding }) => {
  const { t } = useTenantTranslation();
  const theme = useTheme();

  const onSubmitHandler = () => {
    handleSkipOnboarding();
    closeAllModals();
  };

  return (
    <StyledWrapper>
      <StyledCloseIcon color={theme.palette.common.text.white} onClick={closeModal} />
      <Typography variant="h3">{t('modal.setGoalsLater.confirmTitle')}</Typography>
      <ModalContent>
        <Text variant="h4">{t('modal.setGoalsLater.progressLost')}</Text>
      </ModalContent>
      <Box>
        <SecondaryButton onClick={closeModal} color="secondary">
          {t('button.cancel')}
        </SecondaryButton>
        <StyledPrimaryButton onClick={onSubmitHandler}>
          {t('modal.onboarding.setGoalsLater')}
        </StyledPrimaryButton>
      </Box>
    </StyledWrapper>
  );
};

const StyledWrapper = styled(ModalWrapper)`
  background: ${({ theme }) => theme.palette.common.card.dark.bg};
  color: ${({ theme }) => theme.palette.common.text.white};
`;
const Text = styled(Typography)`
  font-weight: normal;
  color: ${({ theme }) => theme.palette.common.text.white};
`;
const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: ${SMALL_MARGIN_PX};
`;
