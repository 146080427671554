import React, { FC, useContext } from 'react';

import { Box } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import { TenantCtx } from '../../../context/TenantProvider';
import useTenantTranslation from '../../../hooks/useTenantTranslation';
import styled from '../../../styled';
import { LARGE_MARGIN_PX, MIDDLE_MARGIN_PX } from '../../../theme';
import IKImage from '../../IKImage/IKImage';

interface FullScreenBackgroundWrapProps {
  children: React.ReactNode;
  logoUrl?: string | null;
  backgroundUrl?: string;
  'data-testid'?: string;
}

const FullScreenBackgroundWrap: FC<FullScreenBackgroundWrapProps> = ({
  logoUrl,
  backgroundUrl = '/subscriptionBg.png',
  children,
  ...rest
}) => {
  const { t } = useTenantTranslation();
  const { config } = useContext(TenantCtx);

  return (
    <Box data-testid={rest['data-testid']}>
      <Logo to={config?.appMainPage}>
        {logoUrl && <IKImage path={logoUrl || ''} draggable={false} alt={'logo'} />}
      </Logo>
      <Container>
        <ImgWrapper>
          <Img path={backgroundUrl} alt={t('page.subscription.subscribe')} />
        </ImgWrapper>
        <ContentWrap>{children}</ContentWrap>
      </Container>
    </Box>
  );
};

export default FullScreenBackgroundWrap;

const Logo = styled(Link)`
  position: absolute;
  height: 60px;
  z-index: 3;
  top: 30px;
  left: 30px;
  transition: all 0.3s;

  & > img {
    height: 100%;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    left: 50%;
    transform: translateX(-50%);
  }
`;
const Container = styled(Box)`
  min-height: 100vh;
  display: flex;
  justify-content: flex-end;
  position: relative;
`;
const ImgWrapper = styled(Box)`
  position: absolute;
  width: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
`;
const Img = styled(IKImage)`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;
const ContentWrap = styled('main')`
  position: relative;
  padding: ${MIDDLE_MARGIN_PX} 0;
  z-index: 2;
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) =>
    theme.isSsku && theme.palette.mode === 'light'
      ? alpha(theme.palette.common.blackPure, 0.5)
      : alpha(theme.palette.common.card.overlay.contrast, 0.9)};
  backdrop-filter: blur(20px);
  transition: width 0.3s;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    justify-content: flex-start;
    padding: 130px 0 ${LARGE_MARGIN_PX};
  }
`;
