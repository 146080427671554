import React, { useState } from 'react';

import { styled } from '@mui/material';
import { m, useTransform } from 'framer-motion';

import { layers } from '../theme';

const Resizable = ({
  wMin,
  wMax,
  children,
  mX,
  mY,
  mW,
  mH,
  wrapperRef,
  onResize,
  display,
  offsetTop,
}) => {
  const [isDraggingResizeCorner, setIsDraggingResizeCorner] = useState(false);
  const mXofTopCorner = useTransform(mX, (v) => v + mW.get());
  const mYofBottomCorner = useTransform(mY, (v) => v + mH.get());

  const dragLeftCorner = (event, info) => {
    const newWidth = mW.get() - info.delta.x;
    if (newWidth > wMin && newWidth < wMax) {
      mW.set(newWidth);
    }
  };

  const dragRightCorner = (event, info) => {
    const newWidth = mW.get() + info.delta.x;
    if (newWidth > wMin && newWidth < wMax) {
      mW.set(newWidth);
    }
  };

  const dragEndHandler = () => {
    setIsDraggingResizeCorner(false);
    const playerWrapperRect = wrapperRef.current?.getBoundingClientRect();
    const w = playerWrapperRect?.width || 0;
    const h = playerWrapperRect?.height || 0;

    mXofTopCorner.jump(mX.get() + w);
    mYofBottomCorner.jump(mY.get() + h);
    onResize({ w, h });
  };

  const props = {
    offsetTop: offsetTop,
    dragElastic: 0,
    dragMomentum: false,
    drag: true,
    onDragStart: () => setIsDraggingResizeCorner(true),
    onDragEnd: dragEndHandler,
    visible: display,
  };

  return (
    <>
      <ResizeLTCorner {...props} onDrag={dragLeftCorner} style={{ x: mX, y: mY }} />
      <ResizeLBCorner {...props} onDrag={dragLeftCorner} style={{ x: mX, y: mYofBottomCorner }} />
      {children(isDraggingResizeCorner)}
      <ResizeRTCorner {...props} onDrag={dragRightCorner} style={{ x: mXofTopCorner, y: mY }} />
      <ResizeRBCorner
        {...props}
        onDrag={dragRightCorner}
        style={{ x: mXofTopCorner, y: mYofBottomCorner }}
      />
    </>
  );
};

export default Resizable;

const ResizeLTCorner = styled(m.div, {
  shouldForwardProp: (prop) => prop !== 'visible' && prop !== 'offsetTop',
})<{ visible: boolean; offsetTop: number }>`
  position: fixed;
  display: ${({ visible }) => !visible && 'none'};
  z-index: ${layers.pip + 1};
  padding: 10px;
  margin-top: ${({ offsetTop }) => offsetTop - 10}px;
  margin-left: -10px;
  cursor: nwse-resize;
`;
const ResizeRTCorner = styled(ResizeLTCorner)`
  cursor: nesw-resize;
`;
const ResizeLBCorner = styled(ResizeRTCorner)`
  margin-top: ${({ offsetTop }) => -offsetTop - 10}px;
`;
const ResizeRBCorner = styled(ResizeLTCorner)`
  margin-top: ${({ offsetTop }) => -offsetTop - 10}px;
`;
