import { useEffect, useState } from 'react';

const useIsSSR = () => {
  const [isSSR, setIsSSR] = useState(true);

  useEffect(() => {
    const isSSR = !!window?.isSSR;
    if (!isSSR) {
      setIsSSR(false);
    }
  }, []);

  return isSSR;
};

export default useIsSSR;
