import { useMemo } from 'react';

import qs from 'qs';
import { useLocation } from 'react-router-dom';

function useQueryParams() {
  const { search } = useLocation();

  return useMemo(() => qs.parse(search.slice(1)), [search]);
}

export default useQueryParams;
