import { isNumber, isWorker, roundToDecimal } from "./utils";
import { onFCP, onLCP, onFID, onCLS, onINP, onTTFB, FIDMetricWithAttribution, CLSMetricWithAttribution, LCPMetricWithAttribution, FCPMetricWithAttribution, INPMetricWithAttribution } from 'web-vitals/attribution';

export class WebVitalsObserver {

  private vitalsSent = false;
  private metricQueue = {};

  constructor() {

    if (isWorker()) {
      return;
    }

    try {
      onCLS(this.addToQueue, { reportAllChanges: true });
      onFID(this.addToQueue, { reportAllChanges: true });
      onFCP(this.addToQueue, { reportAllChanges: true });
      onLCP(this.addToQueue, { reportAllChanges: true });
      onINP(this.addToQueue, { reportAllChanges: true });
      onTTFB(this.addToQueue, { reportAllChanges: true });
    }
    catch (e) {
      /* Google's library is blowing up */
    }

  }

  addToQueue = (metric) => {
    this.metricQueue[metric.name] = metric;
  }

  getVitals(url: string): RM.VitalsEntry {
    if (this.vitalsSent) {
      return null;
    }

    if (Object.keys(this.metricQueue).length === 0) {
      return null;
    }

    var resultVitals: RM.VitalsEntry = {
      url: url
    };

    if (this.metricQueue["FCP"]) {
      let fcp = this.metricQueue["FCP"] as FCPMetricWithAttribution;
      resultVitals.fcp = roundToDecimal(fcp.value);
      resultVitals.fcpLoadState = fcp.attribution?.loadState;
    }
    if (this.metricQueue["LCP"]) {
      let lcp = this.metricQueue["LCP"] as LCPMetricWithAttribution;
      resultVitals.lcp = roundToDecimal(lcp.value);
      if (lcp.attribution) {
        resultVitals.lcpElement = lcp.attribution.element;
        resultVitals.lcpUrl = lcp.attribution.url;
        resultVitals.lcpElementRenderDelay = isNumber(lcp.attribution.elementRenderDelay) ? roundToDecimal(lcp.attribution.elementRenderDelay) : null;
        resultVitals.lcpResourceLoadDelay = isNumber(lcp.attribution.resourceLoadDelay) ? roundToDecimal(lcp.attribution.resourceLoadDelay) : null;
        resultVitals.lcpResourceLoadTime = isNumber(lcp.attribution.resourceLoadTime) ? roundToDecimal(lcp.attribution.resourceLoadTime) : null;
      }
    }
    if (this.metricQueue["CLS"]) {
      let cls = this.metricQueue["CLS"] as CLSMetricWithAttribution;
      resultVitals.cls = roundToDecimal(cls.value, 5);
      if (cls.attribution) {
        resultVitals.clsLargestShiftTarget = cls.attribution.largestShiftTarget;
        resultVitals.clsLargestShiftTime = isNumber(cls.attribution.largestShiftTime) ? roundToDecimal(cls.attribution.largestShiftTime) : null;
        resultVitals.clsLargestShiftValue = isNumber(cls.attribution.largestShiftValue) ? roundToDecimal(cls.attribution.largestShiftValue, 5) : null;
        resultVitals.clsLargestShiftLoadState = cls.attribution.loadState;
      }
    }
    if (this.metricQueue["FID"]) {
      let fid = this.metricQueue["FID"] as FIDMetricWithAttribution;
      resultVitals.fid = roundToDecimal(fid.value);
      if (fid.attribution) {
        resultVitals.fidEventTarget = fid.attribution.eventTarget;
        resultVitals.fidEventTime = isNumber(fid.attribution.eventTime) ? roundToDecimal(fid.attribution.eventTime) : null;
        resultVitals.fidEventType = fid.attribution.eventType;
        resultVitals.fidLoadState = fid.attribution.loadState
      }
    }

    if (this.metricQueue["INP"]) {
      let inp = this.metricQueue["INP"] as INPMetricWithAttribution;
      resultVitals.inp = roundToDecimal(inp.value);
      if (inp.attribution) {
        resultVitals.inpEventTarget = inp.attribution.eventTarget;
        resultVitals.inpEventTime = isNumber(inp.attribution.eventTime) ? roundToDecimal(inp.attribution.eventTime) : null;
        resultVitals.inpEventType = inp.attribution.eventType;
        resultVitals.inpLoadState = inp.attribution.loadState;
      }
    }

    if (this.metricQueue["TTFB"]) {
      resultVitals.ttfb = roundToDecimal(this.metricQueue["TTFB"].value);
    }

    // The Web-Vital lib doesn't return a CLS if it is 0, but we know we are in
    // Chrome-land because we have an LCP.
    if (resultVitals.lcp && !resultVitals.cls) {
      resultVitals.cls = 0;
    }

    return resultVitals;
  }

  sentVitals() {
    this.vitalsSent = true;
  }
}
