import { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';

import { GET_GLOSSARY_TERMS_BY_IDS } from '../../graphql/discover';
import useVerse from '../../hooks/useVerse';
import { Query, QueryGetGlossaryTermsByIdsArgs } from '../../types';

const useHotspotsClick = () => {
  const { onHotspotClick } = useVerse();
  const [glossary, setGlossary] = useState<{ id: string } | null>(null);

  const [getTermsByIds, { data: termsData }] = useLazyQuery<
    Pick<Query, 'getGlossaryTermsByIds'>,
    QueryGetGlossaryTermsByIdsArgs
  >(GET_GLOSSARY_TERMS_BY_IDS, {
    onError: () => {
      window.dispatchEvent(new Event('termError'));
    },
  });

  useEffect(() => {
    onHotspotClick((payload) => {
      if (payload && typeof payload === 'string') {
        const jsonString = payload.replaceAll('&quot;', '"');
        try {
          const payloadData = JSON.parse(jsonString);
          switch (payloadData?.type) {
            case 'glossary': {
              if (payloadData?.data?.id) {
                window.dispatchEvent(new Event('loadingTerm'));
                const id = payloadData?.data?.id?.toString() || '';
                getTermsByIds({
                  variables: {
                    ids: [id],
                  },
                });
                setGlossary({ id });
              }
              break;
            }
            default:
              break;
          }
        } catch (e) {
          window.open(window.location.origin + payload);
        }
      }
    });
  }, [onHotspotClick, getTermsByIds, setGlossary]);

  useEffect(() => {
    if (
      glossary?.id &&
      termsData?.getGlossaryTermsByIds?.length &&
      glossary?.id === termsData?.getGlossaryTermsByIds?.[0]?.id
    ) {
      const term = termsData?.getGlossaryTermsByIds?.[0];
      let content = '';
      if (term?.content) {
        try {
          const blocks = JSON.parse(term?.content);
          content =
            blocks
              ?.filter((block) => block?.layout === 'html')
              .reduce((acc, block) => {
                return acc + block?.attributes?.html;
              }, '') || '';
        } catch (e) {}
      }

      window.dispatchEvent(
        new CustomEvent('loadedTerm', {
          detail: {
            title: term?.title,
            subtitle: term?.subtitle,
            definition: term?.definition,
            summary: content,
          },
        }),
      );
    }
  }, [glossary, termsData]);
};

export default useHotspotsClick;
