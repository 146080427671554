const getCertificateBaseUrl = (tenantName) => `/certificates/${tenantName}/`;

export const getCertificatePDFUrl = (hash, tenantName) =>
  `${getCertificateBaseUrl(tenantName)}${hash}.pdf`;

export const getCertificatePNGUrl = (hash, tenantName) =>
  `${getCertificateBaseUrl(tenantName)}${hash}.png`;

export const getSocialShareImage = (hash, tenantName) =>
  `${getCertificateBaseUrl(tenantName)}social-img/${hash}.png`;

const getCourseCertificateBaseUrl = (tenantName) => `/certificates/${tenantName}/courses/`;

export const getCourseCertificatePNGUrl = (hash, tenantName) =>
  `${getCourseCertificateBaseUrl(tenantName)}${hash}.png`;

export const getCourseSocialShareImage = (hash, tenantName) =>
  `${getCourseCertificateBaseUrl(tenantName)}social-img/${hash}.png`;

export const getCourseCertificatePDFUrl = (hash, tenantName) =>
  `${getCourseCertificateBaseUrl(tenantName)}${hash}.pdf`;

const getAslCertificateBaseUrl = (tenantName) => `/certificates/${tenantName}/assmnts/`;

export const getAslCertificatePDFUrl = (hash, tenantName) =>
  `${getAslCertificateBaseUrl(tenantName)}${hash}.pdf`;

export const getAslCertificatePNGUrl = (hash, tenantName) =>
  `${getAslCertificateBaseUrl(tenantName)}${hash}.png`;

export const getAslSocialShareImage = (hash, tenantName) =>
  `${getAslCertificateBaseUrl(tenantName)}social-img/${hash}.png`;

export const generateCertificateName = (tenant, pathwayName) => {
  return `${tenant}-${pathwayName}.pdf`;
};
