import { Box, TextField } from '@mui/material';

import CloseIcon from '../assets/icons/CloseIcon';
import { CustomButton } from '../components/UI/CustomButton';
import styled from '../styled';
import { MIDDLE_MARGIN_PX, SMALL_MARGIN_PX, SUBMIDDLE_MARGIN_PX } from '../theme';

export const Wrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;
export const Modal = styled(Box)`
  background: ${({ theme }) => theme.palette.secondary.main};
  border-radius: 5px;
  max-width: 590px;
  padding: 32px 24px;
  text-align: center;

  & a {
    margin-right: ${SMALL_MARGIN_PX};
  }
`;
export const StyledCloseIcon = styled(CloseIcon)`
  display: block;
  cursor: pointer;
  height: 14px;
  margin-bottom: ${SMALL_MARGIN_PX};
  margin-left: auto;
  width: 14px;
`;
export const ModalContent = styled(Box)`
  color: ${({ theme }) => theme.palette.common.gray};
  font-size: 20px;
  line-height: 1.5;
  margin-top: ${MIDDLE_MARGIN_PX};
  margin-bottom: ${MIDDLE_MARGIN_PX};
  text-align: center;
`;
export const StyledFormField = styled(TextField)`
  width: 100%;
  ${({ theme }) => theme.breakpoints.up('md')} {
    min-width: 412px;
  }
`;
export const StyledCustomButton = styled(CustomButton)`
  min-width: 150px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }
`;
export const CancelButton = styled(StyledCustomButton)`
  margin-right: ${SMALL_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-bottom: ${SMALL_MARGIN_PX};
    margin-right: 0;
  }
`;
export const PrimaryButton = styled(CustomButton)`
  &.btnContainedPrimary {
    &:hover {
      color: ${({ theme }) => theme.isSsku && theme.palette.common.text.white};
    }
  }
`;
export const SecondaryButton = styled(CustomButton)`
  &.btnContainedSecondary {
    color: ${({ theme }) => theme.isSsku && theme.palette.common.text.white};
    background: ${({ theme }) => theme.isSsku && 'transparent'};
    &:hover {
      background: ${({ theme }) => theme.isSsku && theme.palette.primary.light};
    }
  }
`;
export const ModalWrapper = styled('div')`
  position: relative;
  background: ${({ theme }) => theme.palette.secondary.main};
  border-radius: 5px;
  min-width: 456px;
  max-width: 590px;
  padding: ${MIDDLE_MARGIN_PX} ${SUBMIDDLE_MARGIN_PX};
  text-align: center;
`;
