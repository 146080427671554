import { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import { useAlert } from '../../../context/AlertProvider';
import {
  CREATE_TEAM,
  DELETE_TEAM,
  REMOVE_LEARNER_FROM_TEAM,
  UPDATE_MEMBER_ROLE,
  UPDATE_TEAM,
} from '../../../graphql/user';
import useTenantTranslation from '../../../hooks/useTenantTranslation';
import {
  Mutation,
  MutationCreateTeamArgs,
  MutationDeleteTeamArgs,
  MutationUpdateMemberRoleArgs,
  MutationUpdateTeamArgs,
  TeamRole,
} from '../../../types';

const useTeamActions = (id?: string) => {
  const { t } = useTenantTranslation();
  const { showAlert } = useAlert();

  const [createTeam] = useMutation<Pick<Mutation, 'createTeam'>, MutationCreateTeamArgs>(
    CREATE_TEAM,
    {
      onCompleted: () => {
        showAlert({ message: t('alert.newTeamCreated') });
      },
    },
  );
  const [updateTeam] = useMutation<Pick<Mutation, 'updateTeam'>, MutationUpdateTeamArgs>(
    UPDATE_TEAM,
    {
      onCompleted: () => {
        showAlert({ message: t('alert.teamNameChanged') });
      },
    },
  );
  const [deleteTeam] = useMutation<Pick<Mutation, 'deleteTeam'>, MutationDeleteTeamArgs>(
    DELETE_TEAM,
    {
      onCompleted: () => {
        showAlert({ message: t('alert.teamRemoved') });
      },
    },
  );
  const [updateMemberRole] = useMutation<
    Pick<Mutation, 'updateMemberRole'>,
    MutationUpdateMemberRoleArgs
  >(UPDATE_MEMBER_ROLE);
  const [removeLearnerFromTeam] = useMutation<Pick<Mutation, 'removeLearnerFromTeam'>>(
    REMOVE_LEARNER_FROM_TEAM,
    {
      onCompleted: () => {
        showAlert({ message: t('alert.learnerRemovedFromTeam') });
      },
    },
  );

  const create = useCallback(
    ({ name }, refetchQueries: string[]) =>
      createTeam({
        refetchQueries,
        variables: { input: { name } },
      }),
    [createTeam],
  );
  const update = useCallback(
    ({ name }, teamId, refetchQueries: string[]) =>
      updateTeam({
        refetchQueries,
        variables: { input: { name, team_id: Number(teamId) || Number(id) } },
      }),
    [updateTeam, id],
  );
  const remove = useCallback(
    (teamId, refetchQueries: string[]) =>
      deleteTeam({
        refetchQueries,
        variables: { input: { team_id: Number(teamId) || Number(id) } },
      }),
    [deleteTeam, id],
  );
  const updateRole = useCallback(
    ({ memberId, teamId, role }, refetchQueries: string[]) =>
      updateMemberRole({
        refetchQueries,
        variables: {
          input: {
            team_id: Number(teamId) || Number(id),
            member_id: Number(memberId),
            member_role: role,
          },
        },
      }),
    [updateMemberRole, id],
  );
  const becomeAManager = useCallback(
    async (teamId, memberId, refetchQueries: string[]) => {
      await updateMemberRole({
        refetchQueries,
        variables: {
          input: {
            team_id: Number(teamId) || Number(id),
            member_id: Number(memberId),
            member_role: TeamRole.Manager,
          },
        },
      });
      showAlert({ message: t('alert.teamRoleUpdated', { role: t('page.account.managerRole') }) });
    },
    [updateMemberRole, id, showAlert, t],
  );
  const removeMember = useCallback(
    (memberId, teamId, refetchQueries: string[]) =>
      removeLearnerFromTeam({
        refetchQueries,
        variables: {
          team_id: Number(teamId),
          member_id: Number(memberId),
        },
      }),
    [removeLearnerFromTeam],
  );

  return {
    create,
    update,
    remove,
    removeMember,
    updateRole,
    becomeAManager,
  };
};

export default useTeamActions;
