import React, { FC, useContext } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import SSRRedirect from '../../SsrRedirect';
import { Loader } from '../../components/Loader';
import { TenantCtx } from '../../context/TenantProvider';
import useAuth from '../../hooks/useAuth';
import useGetLoginDomainByConnection from '../../hooks/useGetLoginDomainByConnection';
import { LearnerInfo } from '../../types';

//TODO replace to request
const SSO_CONNECTIONS = {
  cbi: {
    connection: 'CBI-Sustainability-Unlocked',
    tenant: 'sustainabilityunlocked',
  },
  zatca: {
    connection: 'Edcas-for-Zatca-Eacademy',
    tenant: 'euromoney',
  },
};

interface SsoLoginPageProps {
  userInfo?: LearnerInfo;
  userLoading: boolean;
}

const SsoLoginPage: FC<SsoLoginPageProps> = ({ userInfo, userLoading }) => {
  const navigate = useNavigate();
  const { ssoKey } = useParams();
  const { loginWithRedirect, updateLoginDomain, loginDomain, updatingClient } = useAuth();
  const { tenant, tenantLoading, config } = useContext(TenantCtx);

  const ssoKeyLower = ssoKey ? ssoKey.toLowerCase() : '';

  const { domain, loadingDomain } = useGetLoginDomainByConnection(
    SSO_CONNECTIONS?.[ssoKeyLower]?.tenant === tenant?.name
      ? SSO_CONNECTIONS[ssoKeyLower].connection
      : '',
  );

  if (tenantLoading) {
    return <Loader />;
  } else if (!userLoading && userInfo) {
    navigate(config?.appMainPage, { replace: true });
  } else if (
    !ssoKeyLower ||
    !SSO_CONNECTIONS[ssoKeyLower] ||
    SSO_CONNECTIONS[ssoKeyLower].tenant !== tenant?.name
  ) {
    return <SSRRedirect to="/404" />;
  } else if (updatingClient || loadingDomain) {
    return <Loader />;
  } else if (domain && loginDomain !== domain) {
    updateLoginDomain(domain || '');
  } else {
    loginWithRedirect({
      redirectUri: config?.appMainPage,
      isDirectUrl: true,
      connection: SSO_CONNECTIONS[ssoKeyLower].connection,
    });
  }

  return <Loader />;
};

export default SsoLoginPage;
