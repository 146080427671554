import React from 'react';

import { useTheme } from '@mui/material';

import { Svg } from './styles';

const CalendarAddIcon = ({ color = '', ...props }) => {
  const theme = useTheme();
  const svgColor = color || theme.palette.common.white;

  return (
    <Svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      svgColor={svgColor}
      {...props}
    >
      <path
        d="M16.7046 1.91602V6.61963M7.29736 1.91602V6.61963M2.59375 10.3232H21.4082M13.501 22.0823H4.94556C4.32182 22.0823 3.72363 21.8345 3.28258 21.3934C2.84153 20.9524 2.59375 20.3542 2.59375 19.7305V6.61963C2.59375 5.99589 2.84153 5.3977 3.28258 4.95665C3.72363 4.5156 4.32182 4.26782 4.94556 4.26782H19.0564C19.6801 4.26782 20.2783 4.5156 20.7194 4.95665C21.1604 5.3977 21.4082 5.99589 21.4082 6.61963V13.6818"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3398 19.832H22.3115"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3164 16.8438V22.8154"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default CalendarAddIcon;
