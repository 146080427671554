import React, { FC, useEffect } from 'react';

import { Box, Typography } from '@mui/material';

import useTenantTranslation from '../../../../hooks/useTenantTranslation';
import useValidation from '../../../../hooks/useValidation';
import { IModal } from '../../../../modals/Modal';
import {
  CancelButton,
  ModalContent,
  ModalWrapper,
  StyledCloseIcon,
  StyledCustomButton,
  StyledFormField,
} from '../../../../modals/modalStyles';
import useTeamActions from '../../hooks/useTeamActions';
import { teamNameFormSchema } from '../AllTeamView/validation';

export interface EditTeamModalProps extends IModal {
  currentName: string;
  id: string;
}

const EditTeamModal: FC<EditTeamModalProps> = ({ currentName, closeModal, id }) => {
  const { t } = useTenantTranslation();
  const { update } = useTeamActions();
  const { values, errors, handleChange, handleSubmit, submitted, submitting } = useValidation(
    {
      name: currentName,
    },
    teamNameFormSchema,
    (formValues) => update(formValues, id, ['GetTeamsListPaginated']),
  );

  useEffect(() => {
    if (submitted) {
      closeModal?.();
    }
  }, [submitted, closeModal]);

  return (
    <ModalWrapper data-testid="editTeamModal">
      <StyledCloseIcon onClick={closeModal} />
      <Typography variant="h3">{t('page.account.editTeam')}</Typography>
      <ModalContent>
        <StyledFormField
          inputProps={{ 'data-testid': 'teamNameField' }}
          name="name"
          variant="outlined"
          label={t('input.label.teamName')}
          value={values.name}
          onChange={handleChange}
          error={!!errors.name}
          helperText={errors.name}
        />
      </ModalContent>
      <Box>
        <CancelButton data-testid="cancelEdit" onClick={closeModal} color="secondary">
          {t('button.cancel')}
        </CancelButton>
        <StyledCustomButton
          data-testid="submitEdit"
          disabled={submitting}
          loading={submitting}
          onClick={handleSubmit}
        >
          {t('button.save')}
        </StyledCustomButton>
      </Box>
    </ModalWrapper>
  );
};

export default EditTeamModal;
