export const splitIntoChunks = <T>(arr: T[] = [], chunkLength: number): T[][] => {
  const res: T[][] = [];

  for (let i = 0; i < arr.length; i += chunkLength) {
    const tempArray = arr.slice(i, i + chunkLength);
    res.push(tempArray);
  }

  return res;
};
