import { useContext, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';

import { UserInfoCtx } from '../../containers/CommonLayout/CommonLayout';
import { useWithoutNavigation } from '../../context/OnlineContentConnectorContext';
import { GET_LEARNER_REVIEW_ON_ENTITY } from '../../graphql/pathways';
import { CREATE_REVIEW } from '../../graphql/videos';
import {
  Mutation,
  Pathway,
  Query,
  QueryGetLearnerReviewOnEntityArgs,
  ReviewableType,
  ReviewCreateInput,
} from '../../types';

interface useReviewArgs {
  id: Pathway['id'];
  type?: ReviewableType;
  skip?: boolean;
}

const useReview = ({ id, type = ReviewableType.Pathway, skip }: useReviewArgs) => {
  const [messageFormAvailable, setMessageFormAvailable] = useState(false);
  const [messageSubmitted, setMessageSubmitted] = useState(false);
  const { userInfo } = useContext(UserInfoCtx);
  const withoutNavigation = useWithoutNavigation();

  const {
    data: reviewData,
    loading,
    called,
    refetch: refetchReviewData,
  } = useQuery<Pick<Query, 'getLearnerReviewOnEntity'>, QueryGetLearnerReviewOnEntityArgs>(
    GET_LEARNER_REVIEW_ON_ENTITY,
    {
      variables: {
        entity_type: type,
        entity_id: Number(id),
      },
      skip: skip || !id || !userInfo || withoutNavigation,
    },
  );

  const [createReview, { loading: createReviewLoading }] = useMutation<
    Pick<Mutation, 'createReview'>,
    { input: ReviewCreateInput }
  >(CREATE_REVIEW);

  const submitReview = async (options: { stars: number; message?: string }) => {
    const { stars, message } = options;
    const trimmedMessage = message ? message.trim() : message;
    const hasMessage = trimmedMessage !== undefined;

    await createReview({
      variables: {
        input: {
          comment: hasMessage ? trimmedMessage : null,
          stars_rating: stars,
          reviewable_type: type,
          reviewable_id: Number(id),
        },
      },
    });

    if (hasMessage) {
      setMessageFormAvailable(false);
      setMessageSubmitted(true);
    }

    if (!hasMessage && !messageFormAvailable) {
      setMessageFormAvailable(true);
    }
  };

  return {
    status: reviewData?.getLearnerReviewOnEntity?.status,
    loaded: called && !loading,
    refetchStatus: refetchReviewData,
    submitReview,
    submitLoading: createReviewLoading,
    messageSubmitted,
    messageFormAvailable,
  };
};

export default useReview;
