import { SerializedStyles } from '@emotion/react/dist/emotion-react.cjs';
import { styled as muiStyled, css as muiCss, Theme } from '@mui/material/styles';

// @ts-ignore
const styled = (...rest) => muiStyled(...rest);
export const css = (...args) => muiCss(...args);

export type CssUtilityType = SerializedStyles | (({ theme }: { theme: Theme }) => SerializedStyles);

export default styled;
