import React, { forwardRef } from 'react';

import styled from '../../../styled';

interface CardWrapperProps {
  className?: string;
  background?: 'light' | 'dark';
  children: React.ReactNode;
}

const CardWrapper = forwardRef<HTMLDivElement, CardWrapperProps & React.HTMLProps<HTMLDivElement>>(
  ({ children, background = 'light', className = '', ...props }, ref) => {
    const clickable = !!props.onClick;

    return (
      <Wrapper
        // @ts-ignore
        ref={ref}
        className={className}
        clickable={clickable}
        background={background}
        {...props}
      >
        {children}
      </Wrapper>
    );
  },
);

export default CardWrapper;

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'clickable' && prop !== 'background',
})`
  position: relative;
  padding: 24px;
  background: ${({ background, theme }) =>
    background === 'dark'
      ? theme.palette.common.card.background.dark
      : theme.palette.common.card.background.light};
  border-radius: 5px;
  cursor: ${({ clickable }) => clickable && 'pointer'};
  transition: background 0.3s;

  &:hover {
    ${({ clickable, background, theme }) =>
      clickable &&
      'background: ' +
        (background === 'dark'
          ? theme.palette.common.card.background.dark
          : theme.palette.common.card.background.light) +
        ';'}
  }
`;
