import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import parse from 'html-react-parser';

import styled from '../../styled';
import { MIDDLE_MARGIN_PX, SMALL_MARGIN_PX } from '../../theme';
import { parseHighlightedText } from '../../utils/stringUtils';
import SafeLink from '../SafeLink';
import { CustomButton } from '../UI/CustomButton';

interface QuoteItemProps {
  title?: string | null;
  subtitle?: string | null;
  isSingleItem?: boolean;
  highLightColor?: string | null;
  buttonLink?: string | null;
  buttonText?: string | null;
}

const QuoteItem: FC<QuoteItemProps> = ({
  title,
  subtitle,
  isSingleItem = true,
  highLightColor,
  buttonLink,
  buttonText,
}) => {
  return (
    <Wrapper data-testid="quoteItem" className="quote-item__wrapper" isSingleItem={isSingleItem}>
      <Title
        data-testid="quoteTitle"
        className="quote-item__title"
        isSingleItem={isSingleItem}
        highLightColor={highLightColor}
      >
        “&nbsp;{parse(parseHighlightedText(title))}&nbsp;”
      </Title>
      {subtitle && (
        <>
          <Divider className="quote-item__divider" isSingleItem={isSingleItem} />
          <Subtitle className="quote-item__subtitle">{subtitle}</Subtitle>
        </>
      )}
      {buttonText && buttonLink && (
        <StyledSafeLink data-testid="quoteLink" to={buttonLink}>
          <StyledButton>{buttonText}</StyledButton>
        </StyledSafeLink>
      )}
    </Wrapper>
  );
};

export default QuoteItem;

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSingleItem',
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ isSingleItem }) => (!isSingleItem ? 'margin: 0 45px;' : '')}
  ${({ theme }) => theme.breakpoints.up('md')} {
    ${({ isSingleItem }) => (!isSingleItem ? 'margin: 0 60px;' : '')}
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    ${({ isSingleItem }) => (!isSingleItem ? 'margin: 0 auto;' : '')}
    ${({ isSingleItem }) => (!isSingleItem ? 'width: 800px;' : '')}
  }
`;
const Title = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'highLightColor' && prop !== 'isSingleItem',
})`
  margin-bottom: ${SMALL_MARGIN_PX};
  color: ${({ theme }) => theme.palette.common.white};
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  width: 100%;
  & span {
    color: ${({ highLightColor, theme }) => highLightColor || theme.palette.common.primary};
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: ${({ isSingleItem }) => (isSingleItem ? 36 : 40)}px;
    line-height: ${({ isSingleItem }) => (isSingleItem ? 46 : 52)}px;
    margin-bottom: ${({ isSingleItem }) => (isSingleItem ? MIDDLE_MARGIN_PX : SMALL_MARGIN_PX)};
  }
`;
const Divider = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSingleItem',
})`
  max-width: 300px;
  width: 100%;
  height: 1px;
  background: ${({ theme }) => alpha(theme.palette.common.white, 0.33)};
  margin-bottom: ${SMALL_MARGIN_PX};
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: ${({ isSingleItem }) => (isSingleItem ? 'none' : 'block')};
  }
`;
const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  width: 100%;
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 20px;
    line-height: 30px;
  }
`;
const StyledSafeLink = styled(SafeLink)`
  margin-top: ${SMALL_MARGIN_PX};
  width: 100%;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: unset;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: ${MIDDLE_MARGIN_PX};
  }
`;
const StyledButton = styled(CustomButton)`
  width: 100%;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: unset;
  }
`;
