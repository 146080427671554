import React from 'react';

import { useTheme } from '@mui/material';

import { Svg } from './styles';

const RatingIcon = ({ color = '', ...props }) => {
  const theme = useTheme();
  const svgColor = color || theme.palette.common.blue;

  return (
    <Svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      svgColor={svgColor}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56 8H8C5.79086 8 4 9.79086 4 12V44C4 46.2091 5.79086 48 8 48H23.1359C24.7902 48 26.3712 48.6831 27.5051 49.8878L32 54.6637L36.4949 49.8878C37.6288 48.6831 39.2098 48 40.8641 48H56C58.2091 48 60 46.2091 60 44V12C60 9.79086 58.2091 8 56 8ZM8 4C3.58172 4 0 7.58172 0 12V44C0 48.4183 3.58172 52 8 52H23.1359C23.6873 52 24.2143 52.2277 24.5923 52.6293L30.5436 58.9526C31.3333 59.7916 32.6667 59.7916 33.4564 58.9526L39.4077 52.6293C39.7857 52.2277 40.3127 52 40.8641 52H56C60.4183 52 64 48.4183 64 44V12C64 7.58172 60.4183 4 56 4H8Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.7714 23.3704C28.7103 23.4971 28.5855 23.5844 28.4415 23.6013L21.7285 24.3847C21.3743 24.4261 21.2321 24.8475 21.4934 25.0814L26.4461 29.5149C26.5524 29.6101 26.6001 29.7513 26.5722 29.8884L25.2715 36.2785C25.2029 36.6157 25.5751 36.8762 25.887 36.7091L31.7961 33.5433C31.9229 33.4753 32.0771 33.4753 32.2039 33.5433L38.113 36.7091C38.4249 36.8762 38.7971 36.6157 38.7285 36.2785L37.4278 29.8884C37.3999 29.7513 37.4476 29.6101 37.5539 29.5149L42.5066 25.0814C42.7679 24.8475 42.6257 24.4261 42.2715 24.3847L35.5585 23.6013C35.4145 23.5844 35.2897 23.4971 35.2286 23.3704L32.3804 17.4646C32.2301 17.1529 31.7699 17.1529 31.6196 17.4646L28.7714 23.3704ZM37.5836 20.5832L35.4233 16.1037C34.0706 13.2988 29.9295 13.2987 28.5767 16.1037L26.4164 20.5832L21.3249 21.1774C18.1366 21.5495 16.8569 25.342 19.2091 27.4477L22.9656 30.8104L21.9791 35.6571C21.3614 38.6921 24.7116 41.0359 27.5181 39.5324L32 37.1312L36.4819 39.5324C39.2884 41.036 42.6386 38.692 42.0209 35.6571L41.0344 30.8104L44.7909 27.4477C47.1431 25.3421 45.8635 21.5495 42.6751 21.1774L37.5836 20.5832Z"
        fill="currentColor"
      />
    </Svg>
  );
};

export default RatingIcon;
