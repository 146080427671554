import React from 'react';

import parse from 'html-react-parser';

const HTMLBlock = ({ data }) => {
  const htmlCode = data?.html_code;

  return <div className={'html-block'}>{parse(htmlCode)}</div>;
};

export default HTMLBlock;
