import { parse, isValid } from 'date-fns';

type InputParams = Parameters<typeof parse>;

const parseWithCheck = (...args: InputParams): Date | null => {
  const date = parse(...args);
  return isValid(date) ? date : null;
};

export default parseWithCheck;
