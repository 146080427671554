import { useContext, useEffect, useRef, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import Cookies from 'js-cookie';
import { useLocation, useParams } from 'react-router-dom';

import usePip from '../../../components/Pip/usePip';
import { CAPTIONS_LANG_COOKIE_NAME, PLAY_RATE_COOKIE_NAME } from '../../../const';
import { UserInfoCtx } from '../../../containers/CommonLayout/CommonLayout';
import { RXDContext } from '../../../context/RXDContext';
import { useSsrContext } from '../../../context/SsrContext';
import { UPDATE_LEARNER_INFO } from '../../../graphql/user';
import { GET_VIDEO_BY_SLUG_OR_ID, GET_VIDEO_VERSE_ID_BY_SLUG_OR_ID } from '../../../graphql/videos';
import useAuth from '../../../hooks/useAuth';
import useEventListener from '../../../hooks/useEventListener';
import useVerse, { VerseEvents } from '../../../hooks/useVerse';
import {
  Mutation,
  MutationUpdateLearnerInfoArgs,
  Query,
  QueryListVideosArgs,
} from '../../../types';
import { checkConsent } from '../../../utils/checkConsent';
import getQueryStringParams from '../../../utils/getQueryStringParams';
import redirectToNewSlug from '../../../utils/redirectToNewSlug';
import sendAnalyticEvent from '../../../utils/sendAnalyticEvent';

const VIDEO_LANG = 'en';
const controlActionsToAnalyticEvents = {
  play: 'Video_Player_Start',
  pause: 'Video_Player_Pause',
  rewind: 'Video_Player_Rewind',
  fastForward: 'Video_Player_Fforward',
  nextChapter: 'Video_Player_Next_Chap',
  previousChapter: 'Video_Player_Prev_Chap',
  chapterSelectMenu: 'Video_Player_Chapterselect_menu',
  chapterSelectPlayer: 'Video_Player_Chapterselect_player',
  mute: 'Video_Player_Mute',
  fullScreen: 'Video_Player_Fullscreen',
  hotspot: 'Video_Player_Hotspot',
  hotspotTimeline: 'Video_Player_Hotspot_Timeline',
};

export enum VideoPlayerEvents {
  bumperFinished = 'bumperFinished',
}

const useVideoPlayer = (playerWrapperRef, slug?: string) => {
  const { isAuthenticated } = useAuth();
  const [videoFinished, setVideoFinished] = useState(false);
  const [bumperFinished, setBumperFinished] = useState(false);
  const [videoPlayerLoaded, setVideoPlayerLoaded] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const playerRef = useRef<HTMLFrameElement>(null);
  const contentWindow = playerRef.current?.contentWindow;
  const { seek, goToChapter, goToChapterId, onVideoClose, play, setCaptionsLang, setPlaybackRate } =
    useVerse(playerRef);
  const { rxdUserInfo } = useContext(RXDContext);
  const { userInfo } = useContext(UserInfoCtx);
  const pipControls = usePip(isVideoPlaying, playerWrapperRef);
  const { redirect } = useSsrContext();
  const location = useLocation();
  const params = useParams();
  const pathname = location?.pathname;
  const search = location?.search;
  const videoSlug = slug || params?.slug;
  const isIdInSlug = Number.isInteger(Number(videoSlug));
  const queryParams = getQueryStringParams(search);
  const chapter = Number(queryParams?.chapter);
  const chapterId = Number(queryParams?.chapterId);
  const time = Number(queryParams?.time);
  const userPreferredLanguage = userInfo?.settings?.language;
  const enableCaptionsByDefault = !!userPreferredLanguage && userPreferredLanguage !== VIDEO_LANG;

  const {
    data: videoData,
    loading: loadingVideo,
    called: calledVideoQuery,
    error: videoError,
  } = useQuery<Pick<Query, 'listVideos'>, QueryListVideosArgs>(GET_VIDEO_BY_SLUG_OR_ID, {
    variables: {
      filter: {
        video_url: isIdInSlug ? undefined : [videoSlug],
        ids: isIdInSlug ? [videoSlug] : undefined,
        include_test_publish_status: true,
      },
    },
    ...(rxdUserInfo && {
      context: {
        headers: {
          'Lms-Auth': rxdUserInfo?.partnerToken,
        },
      },
    }),
  });

  const video = videoData?.listVideos?.length ? videoData?.listVideos[0] : null;
  const videoId = video?.id ? video.id : '';

  const { data: verseIdData, loading: loadingVerseId } = useQuery<
    Pick<Query, 'listVideos'>,
    QueryListVideosArgs
  >(GET_VIDEO_VERSE_ID_BY_SLUG_OR_ID, {
    variables: {
      filter: {
        video_url: isIdInSlug ? undefined : [videoSlug || ''],
        ids: isIdInSlug ? [videoSlug || ''] : undefined,
        include_test_publish_status: true,
      },
    },
    skip: !userInfo?.has_subscription || Boolean(video?.verse_video_id),
    fetchPolicy: 'network-only',
  });

  const verseVideoId =
    video?.verse_video_id ||
    (verseIdData?.listVideos?.length ? verseIdData?.listVideos[0] : null)?.verse_video_id;
  const loadingVerseVideoId = loadingVideo || loadingVerseId || !video?.verse_video_id;

  const [updateLearnerInfo] = useMutation<
    Pick<Mutation, 'updateLearnerInfo'>,
    MutationUpdateLearnerInfoArgs
  >(UPDATE_LEARNER_INFO);

  if (!isIdInSlug && video && videoSlug) {
    redirectToNewSlug(redirect, location.pathname, videoSlug, video?.video_url);
  }

  useEventListener(VerseEvents.LastChapterFinished, () => {
    setVideoFinished(true);
  });

  useEventListener(VideoPlayerEvents.bumperFinished, () => {
    setBumperFinished(true);
  });

  useEventListener(VerseEvents.VideoStartedPlaying, () => {
    setIsVideoPlaying(true);
  });

  useEventListener(VerseEvents.VideoFinishedPlaying, () => {
    setIsVideoPlaying(false);
  });

  // reset after if video changed
  useEffect(() => {
    if (videoId) {
      setVideoFinished(false);
      setBumperFinished(false);
      setIsVideoPlaying(false);
    }
  }, [videoId]);

  useEffect(() => {
    //works only after 'Story Viewed' event
    if (videoPlayerLoaded) {
      if (!isNaN(chapterId)) {
        goToChapterId(chapterId);
      } else if (!isNaN(chapter)) {
        goToChapter(chapter);
      }

      if (!isNaN(time)) {
        seek(time);
      }
    }
  }, [chapter, chapterId, time, videoPlayerLoaded, goToChapter, goToChapterId, seek]);

  const pipControlsToggle = pipControls.toggle;

  useEffect(() => {
    const handlePlayerLoaded = (event) => {
      const contentWindow = playerRef.current?.contentWindow;
      if (!contentWindow) {
        return;
      }

      if (event?.data?.event === 'Story Viewed') {
        contentWindow.postMessage({ action: 'checkIfVersePlayerLoaded' }, '*');
        setVideoPlayerLoaded(true);
        return;
      }

      if (event?.data.action === 'pipClick') {
        pipControlsToggle();
      }

      if (event?.data.action === 'VersePlayerLoaded') {
        const rate = Cookies.get(PLAY_RATE_COOKIE_NAME);
        let lang = userPreferredLanguage || Cookies.get(CAPTIONS_LANG_COOKIE_NAME);

        if (!lang && enableCaptionsByDefault) {
          lang = window.__LANG_CODE__;
        }

        if (lang) {
          setCaptionsLang(lang);
        }

        if (rate) {
          setPlaybackRate(rate);
        }
      }

      if (event?.data.action === 'captionsLang') {
        const lang = event.data.value;
        checkConsent(CAPTIONS_LANG_COOKIE_NAME) && Cookies.set(CAPTIONS_LANG_COOKIE_NAME, lang);
        isAuthenticated &&
          updateLearnerInfo({ variables: { input: { captions: lang === 'off' ? null : lang } } });
        sendAnalyticEvent(`Video_Player_Cap_${lang.charAt(0).toUpperCase() + lang.slice(1)}`);
      }

      if (event?.data.action === 'playbackRate') {
        const rate = event.data.value;
        checkConsent(PLAY_RATE_COOKIE_NAME) && Cookies.set(PLAY_RATE_COOKIE_NAME, rate);
        sendAnalyticEvent(`Video_Player_speed_${rate}`);
      }

      if (
        event?.data.action === 'controlAction' &&
        controlActionsToAnalyticEvents[event.data?.value]
      ) {
        const analyticEvent = controlActionsToAnalyticEvents[event.data?.value];
        analyticEvent && sendAnalyticEvent(analyticEvent);
      }
    };

    if (window) {
      window.addEventListener('message', handlePlayerLoaded);
    }

    return () => {
      if (window) {
        window.removeEventListener('message', handlePlayerLoaded);
      }
    };
  }, [
    updateLearnerInfo,
    userPreferredLanguage,
    setCaptionsLang,
    setPlaybackRate,
    playerRef,
    enableCaptionsByDefault,
    pipControlsToggle,
    isAuthenticated,
  ]);

  useEffect(() => {
    return () => onVideoClose();
  }, [pathname, onVideoClose]);

  useEffect(() => {
    if (videoPlayerLoaded) {
      setVideoPlayerLoaded(false);
    }
  }, [contentWindow, videoPlayerLoaded]);

  return {
    video,
    loadingVideo,
    videoLoaded: calledVideoQuery && !loadingVideo,
    videoFinished: video?.show_bumper ? videoFinished && bumperFinished : videoFinished,
    setVideoFinished,
    videoError,
    playerRef,
    play,
    goToChapter,
    verseVideoId,
    loadingVerseVideoId,
    isVideoPlaying,
    pipControls,
  };
};

export default useVideoPlayer;
