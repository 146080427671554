import { useEffect, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { useLocation } from 'react-router-dom';

// Helps to track that all queries have been fetched on the client after the initial page load
const useQueriesInProgress = () => {
  const client = useApolloClient();
  const [inProgress, setInProgress] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (!inProgress) {
      return;
    }

    const interval = setInterval(async () => {
      const observableQueriesMap = client.getObservableQueries();
      const observableQueries = Array.from(observableQueriesMap.values());
      const queriesResults = await Promise.all(observableQueries.map((query) => query.result()));
      const pendingStatuses = queriesResults.filter((result) => result.loading);
      const loaded = pendingStatuses.length === 0;

      if (loaded) {
        setInProgress(false);
        clearInterval(interval);
      }
    }, 200);

    return () => clearInterval(interval);
  }, [client, inProgress]);

  useEffect(() => {
    setInProgress(true);
  }, [location]);

  return { inProgress };
};

export default useQueriesInProgress;
