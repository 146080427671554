import { useState } from 'react';

import { debounce } from '@mui/material';

import { HEADER_HEIGHT } from '../theme';
import { isElementAboveViewport } from '../utils/positionOnScreen/isElementAboveViewport';
import useEventListener from './useEventListener';

const useIsAboveViewport = (elementRef) => {
  const [above, setAbove] = useState(false);

  useEventListener(
    'scroll',
    debounce(() => {
      setAbove(isElementAboveViewport(elementRef.current, -HEADER_HEIGHT));
    }, 100),
  );

  return above;
};

export default useIsAboveViewport;
