import React from 'react';

import { Container, Typography } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SwiperOptions } from 'swiper/types/swiper-options';

import styled from '../../styled';
import {
  BIG_MARGIN,
  BIG_MARGIN_PX,
  BREAKPOINTS_VALUES,
  LARGE_MARGIN,
  LARGE_MARGIN_PX,
  MIDDLE_MARGIN_PX,
  SUBMIDDLE_MARGIN,
  SUBMIDDLE_MARGIN_PX,
  useIsWidthUp,
} from '../../theme';
import { Background } from '../../types';
import { splitIntoChunks } from '../../utils/arrayUtils/splitIntoChunks';
import Flipper from '../Flipper/Flipper';
import TeamMember from '../TeamMember/TeamMember';

const swiperSetting: SwiperOptions = {
  slidesPerView: 2,
  spaceBetween: 20,
  pagination: {
    clickable: true,
  },
  breakpoints: {
    [BREAKPOINTS_VALUES.sm]: {
      slidesPerView: 3,
    },
  },
};

const MeetTheTeam = ({ data }) => {
  const isDark = data?.background === Background.Dark;
  const isMobile = !useIsWidthUp('md');

  return (
    <Wrapper isDark={isDark}>
      <Container>
        <Title variant={'h2'}>{data?.heading}</Title>
        {!isMobile ? (
          <CardsWrapper>
            {data?.members?.map((member) =>
              member?.secondary_image ? (
                <StyledFlipper key={member.id}>
                  <TeamMember
                    title={member.name}
                    jobTitle={member.employee_job_title}
                    imageUrl={member.avatar}
                    isDark={!isDark}
                    link={member.linkedin_url}
                    linkTitle="Linkedin"
                  />
                  <TeamMember
                    title={member.name}
                    jobTitle={member.employee_job_title}
                    imageUrl={member.secondary_image}
                    isDark={!isDark}
                    link={member.linkedin_url}
                    linkTitle="Linkedin"
                  />
                </StyledFlipper>
              ) : (
                <Card>
                  <StyledTeamMember
                    title={member.name}
                    jobTitle={member.employee_job_title}
                    imageUrl={member.avatar}
                    isDark={!isDark}
                    link={member.linkedin_url}
                    linkTitle="Linkedin"
                  />
                </Card>
              ),
            )}
          </CardsWrapper>
        ) : (
          <Swiper {...swiperSetting}>
            {splitIntoChunks<any>(data?.members || [], 2).map((chunk, index) => (
              <SwiperSlide key={index}>
                <div>
                  {chunk.map((member) =>
                    member?.secondary_image ? (
                      <StyledFlipper key={member.id}>
                        <TeamMember
                          title={member.name}
                          jobTitle={member.employee_job_title}
                          imageUrl={member.avatar}
                          isDark={!isDark}
                          link={member.linkedin_url}
                          linkTitle="Linkedin"
                        />
                        <TeamMember
                          title={member.name}
                          jobTitle={member.employee_job_title}
                          imageUrl={member.secondary_image}
                          isDark={!isDark}
                          link={member.linkedin_url}
                          linkTitle="Linkedin"
                        />
                      </StyledFlipper>
                    ) : (
                      <Card>
                        <StyledTeamMember
                          title={member.name}
                          jobTitle={member.employee_job_title}
                          imageUrl={member.avatar}
                          isDark={!isDark}
                          link={member.linkedin_url}
                          linkTitle="Linkedin"
                        />
                      </Card>
                    ),
                  )}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </Container>
    </Wrapper>
  );
};

export default MeetTheTeam;

const StyledFlipper = styled(Flipper)`
  height: 310px;
  width: 100%;
  margin: 0 0 24px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 350px;
    width: calc((100% / 4) - 24px);
    margin: 0 12px 24px;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: calc((100% / 5) - 24px);
  }
`;

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDark',
})`
  padding: ${BIG_MARGIN_PX} 0 ${BIG_MARGIN - SUBMIDDLE_MARGIN}px;
  background: ${({ isDark, theme }) =>
    isDark ? theme.palette.common.blueDark : theme.palette.common.blackSecondary};

  & .swiper {
    padding-top: ${SUBMIDDLE_MARGIN_PX};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${LARGE_MARGIN_PX} 0 ${LARGE_MARGIN - SUBMIDDLE_MARGIN}px;
  }
`;
const Title = styled(Typography)`
  && {
    text-align: center;
    margin-bottom: 0;
  }
`;
const CardsWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin: ${MIDDLE_MARGIN_PX} -12px 0;
`;
const Card = styled('div')`
  height: 310px;
  width: 100%;
  margin: 0 0 24px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 350px;
    width: calc((100% / 4) - 24px);
    margin: 0 12px 24px;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: calc((100% / 5) - 24px);
  }
`;
const StyledTeamMember = styled(TeamMember)`
  height: 100%;
`;
