//i18n helpers
import i18n from '../i18n';
import { hostToTenantName } from './hostToTenantName/hostToTenantName';
import replaceTenantVariables from './translations/replaceTenantVariables';
import { translateByTenant } from './translations/translateByTenant';

const tenantName = hostToTenantName(window?.location?.hostname || '');
const translationFn = (key: string, vars?): string => i18n.t(key, { ...vars });

const createTranslation = translateByTenant(tenantName, translationFn);
const replaceVariables = replaceTenantVariables(tenantName, translationFn);

export const translate = (key: string, options?) =>
  replaceVariables(createTranslation(key, options));
