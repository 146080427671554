import React, { FC, useMemo } from 'react';

import styled from '../../styled';
import { ContentItemListBlock, ContentItemType, Orientation } from '../../types';
import { VideosBlock } from '../VideosBlock';

interface ContentListBlockProps {
  data?: ContentItemListBlock | null;
  withArrows?: boolean;
  classes?: {
    wrapper?: string;
    container?: string;
    heading?: string;
    videoWrapper?: string;
  };
  titleToLeft?: boolean;
  loading?: boolean;
  wrapperId?: string;
  withChunksOnMobile?: boolean;
}

const ContentListBlock: FC<ContentListBlockProps> = ({ data, ...rest }) => {
  const formattedData = useMemo(() => {
    return {
      ...data,
      orientation: Orientation.Lanscape,
      items: data?.content_items?.map((item) => ({
        type: item?.type,
        ranking: item?.ranking,
        ...(item?.type === ContentItemType.Video && {
          video: item?.entity,
        }),
        ...(item?.type === ContentItemType.Pathway && {
          pathway: item?.entity,
        }),
      })),
    };
  }, [data]);

  return <StyledVideosBlock data={formattedData} {...rest} />;
};

export default ContentListBlock;

const StyledVideosBlock = styled(VideosBlock)`
  & .swiper {
    ${({ theme }) => theme.breakpoints.up('md')} {
      padding-top: 35px;
    }
  }
`;
