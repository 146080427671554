import Cookies from 'js-cookie';

import {
  ALGOLIA_USER_TOKEN_NAME,
  ANALYTICS_ROWS_PER_PAGE,
  ASSIGNMENT_ROWS_PER_PAGE,
  CAPTIONS_LANG_COOKIE_NAME,
  LANG_COOKIE_KEY,
  MODE_COOKIE_KEY,
  MY_ACCOUNT_ROWS_PER_PAGE,
  PLAY_RATE_COOKIE_NAME,
  IS_USER_SAW_ONBOARDING_MODAL,
} from '../const';
import { hostToTenantName } from './hostToTenantName/hostToTenantName';
import { TENANTS_CONFIG } from './tenantsConfig';

enum Type {
  Cookies = 'cookies',
  LocalStorage = 'localStorage',
}
enum ConsentType {
  Preferences = 'preferences',
  Statistics = 'statistics',
  Marketing = 'marketing',
}

const consentConfig: {
  [x: string]: {
    consentType: ConsentType;
    type: Type;
  };
} = {
  [CAPTIONS_LANG_COOKIE_NAME]: {
    consentType: ConsentType.Preferences,
    type: Type.Cookies,
  },
  [PLAY_RATE_COOKIE_NAME]: {
    consentType: ConsentType.Preferences,
    type: Type.Cookies,
  },
  [LANG_COOKIE_KEY]: {
    consentType: ConsentType.Preferences,
    type: Type.Cookies,
  },
  [ANALYTICS_ROWS_PER_PAGE]: {
    consentType: ConsentType.Preferences,
    type: Type.LocalStorage,
  },
  [MODE_COOKIE_KEY]: {
    consentType: ConsentType.Preferences,
    type: Type.LocalStorage,
  },
  [MY_ACCOUNT_ROWS_PER_PAGE]: {
    consentType: ConsentType.Preferences,
    type: Type.LocalStorage,
  },
  [ASSIGNMENT_ROWS_PER_PAGE]: {
    consentType: ConsentType.Preferences,
    type: Type.LocalStorage,
  },
  [ALGOLIA_USER_TOKEN_NAME]: {
    consentType: ConsentType.Statistics,
    type: Type.LocalStorage,
  },
  [IS_USER_SAW_ONBOARDING_MODAL]: {
    consentType: ConsentType.Preferences,
    type: Type.LocalStorage,
  },
};

const consentConfigArr = Object.keys(consentConfig).map((key) => ({
  name: key,
  ...consentConfig[key],
}));

export const checkConsent = (name: string) => {
  const tenantName = hostToTenantName(window?.location?.hostname || '');
  if (tenantName && TENANTS_CONFIG[tenantName]?.hasConsentCheck) {
    //check if Cookiebot is installed
    if (window?.Cookiebot) {
      return consentConfig[name]
        ? window.Cookiebot.consent[consentConfig[name]?.consentType]
        : true;
    }

    return false;
  }

  return true;
};

export const removeProhibitedCookiesAndLocalStorageItems = () => {
  consentConfigArr.forEach((item) => {
    if (window?.Cookiebot?.consent[item.consentType] === false) {
      if (item.type === Type.Cookies) {
        Cookies.remove(item.name);
      } else {
        localStorage.removeItem(item.name);
      }
    }
  });
};
