import React, { ReactNode } from 'react';

import { Box } from '@mui/material';

import styled from '../../../../styled';
import { BIG_MARGIN_PX, MIDDLE_MARGIN_PX } from '../../../../theme';

interface CardInfoProps {
  className?: string;
  children: ReactNode;
}

const CardInfo: React.FC<CardInfoProps> = ({ className, children }) => {
  return <StyledInfo className={className}>{children}</StyledInfo>;
};

export default CardInfo;

const StyledInfo = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-left: ${MIDDLE_MARGIN_PX};
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin-left: ${BIG_MARGIN_PX};
  }
`;
