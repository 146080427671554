import React, { FC, ReactNode } from 'react';

import { Link } from 'react-router-dom';

interface ConditionalBlockLinkProps {
  withLink: boolean;
  to?: string;
  onClick?: () => void;
  className?: string;
  children: ReactNode;
}

const ConditionalBlockLink: FC<ConditionalBlockLinkProps> = ({
  withLink,
  children,
  to,
  ...props
}) => {
  return withLink && to ? (
    <Link to={to} {...props}>
      {children}
    </Link>
  ) : (
    <div className={props?.className || ''} onClick={props?.onClick}>
      {children}
    </div>
  );
};

export default ConditionalBlockLink;
