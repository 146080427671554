import React from 'react';

import { Svg, useSvgColor } from './styles';

const PlayLargeIcon = ({ color = '', ...props }) => {
  const svgColor = useSvgColor(color);

  return (
    <Svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      svgColor={svgColor}
      {...props}
    >
      <path
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5.9638,21.3698A1.99988,1.99988,0,0,1,3,19.61741V4.382A1.99919,1.99919,0,0,1,5.9638,2.63c3.70979,2.04,10.0388,5.522,13.85,7.618a1.99923,1.99923,0,0,1,0,3.504C16.0026,15.848,9.67359,19.3294,5.9638,21.3698Z"
      />
    </Svg>
  );
};

export default PlayLargeIcon;
