import { compareAsc } from 'date-fns';

import { Maybe, NotificationSettings, Pathway, Scalars, Team, Video } from '../../types';

export enum VIEW_TYPES {
  TYPE_SELECT = 'type_select',
  FORM = 'form',
  REVIEW = 'review',
  LIST = 'list',
  DETAILS = 'details',
  USERS_DETAILS = 'users_details',
}

export type UserStatus = {
  userId?: Maybe<Scalars['String']>;
  userName?: Scalars['String'];
  status?: Scalars['String'];
  status_date?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['Int']>;
  test_percent_complete?: Maybe<Scalars['Float']>;
  quiz_percentage_complete?: Maybe<Scalars['Int']>;
  stars?: Maybe<Array<Maybe<Scalars['String']>>>;
  progress: number;
  isCpdLearning?: boolean;
};

export const checkIsNotificationOff = (notificationStatus) => {
  return Boolean(!notificationStatus || notificationStatus === NotificationSettings.Off);
};

export enum ASSIGNMENT_STATUSES {
  ASSIGNED = 'Assigned',
  ACKNOWLEDGED = 'Acknowledged',
  STARTED = 'Started',
  WATCHED = 'Watched',
  COMPLETED = 'Completed',
}

export const ASL_ROWS_PER_PAGE = 'ASL_ROWS_PER_PAGE';
export type SelectedEntity = {
  type: Scalars['String'];
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  videos_count?: Scalars['Int'];
  stars?: string[];
};
export type ItemType = Video | Pathway;

export interface FormFields {
  title: string;
  description: string;
  startDate?: null | Date;
  dueDate: null | Date;
  credits?: null | number;
  teams: Team[];
  itemsList?: (null | ItemType)[];
}

export const RELEASE_3_85_0_DATE = '2023-06-29 07:00:00';
export const beforeDate = (currDate, releaseDate) => compareAsc(currDate, releaseDate) < 0;
