import React from 'react';

import { Container, styled } from '@mui/material';

import CustomBlockWrapper from '../../components/CustomBlockWrapper';
import { IKImage } from '../../components/IKImage';
import { BodyText, H1 } from '../../components/UI/Texts';
import { LARGE_MARGIN_PX, SMALL_MARGIN_PX, SUBMIDDLE_MARGIN_PX, TINY_MARGIN_PX } from '../../theme';
import PrivacyBlock from '../PrivacyBlock';
import Form from './Form';

const LetsTalkBlock = ({ data }) => {
  const {
    background,
    title,
    subtitle,
    button_text,
    preview_image_url,
    seo_image_name,
    book_time_link,
    book_time_link_title,
    is_form_extended,
    usercom_form_id,
    form_title_automation,
  } = data;
  return (
    <CustomBlockWrapper className="" background={background}>
      <StyledContainer>
        {preview_image_url && (
          <ImgWrapper>
            <StyledImage alt={seo_image_name || ''} path={preview_image_url} />
          </ImgWrapper>
        )}
        <ContentWrapper hasImage={Boolean(preview_image_url)}>
          <TextWrapper>
            <StyledTitle>{title}</StyledTitle>
            <BodyText>{subtitle}</BodyText>
          </TextWrapper>
          <Form
            buttonText={button_text}
            bookTimeLink={book_time_link}
            bookTimeLinkTitle={book_time_link_title}
            isFormExtended={is_form_extended}
            usercomFormId={usercom_form_id}
            formTitleAutomation={form_title_automation}
          />
          <StyledPrivacyBlock />
        </ContentWrapper>
      </StyledContainer>
    </CustomBlockWrapper>
  );
};

export default LetsTalkBlock;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
  }
`;

const ImgWrapper = styled('div')`
  display: none;
  position: relative;
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 50%;
    display: flex;
    padding-right: ${LARGE_MARGIN_PX};
    align-items: center;
  }
`;

const ContentWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hasImage',
})<{ hasImage: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  ${({ hasImage }) => !hasImage && 'max-width: 800px;'}
  ${({ hasImage }) => !hasImage && 'margin: 0 auto;'}
    
    ${({ theme }) => theme.breakpoints.up('md')} {
    ${({ hasImage }) => hasImage && 'width: 50%;'}
  }
`;

const StyledImage = styled(IKImage)`
  max-width: 100%;
`;

const TextWrapper = styled('div')`
  margin-bottom: ${SMALL_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: ${SUBMIDDLE_MARGIN_PX};
  }
`;

const StyledTitle = styled(H1)`
  margin-bottom: ${SMALL_MARGIN_PX};
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: ${TINY_MARGIN_PX};
  }
`;

const StyledPrivacyBlock = styled(PrivacyBlock)`
  text-align: center;
  ${({ theme }) => theme.breakpoints.up('md')} {
    text-align: left;
  }
`;
