import { gql } from '@apollo/client';

export const GET_TENANT_INFO = gql`
  query GetTenantInfo {
    getTenantInfo {
      id
      name
      logo_url
      custom_css
      partner_type_id
      portal_url
      gtm_id
      title
      circle_image_url
      linkedin_id
      auth0_app_id
      chargebee_site
      linkedin_link
      twitter_link
      facebook_link
      instagram_link
      twitter_handle
      twitter_hashtags_array
      fb_name
      video_bumper_url
      book_a_demo_page_link
      introduction_video_url
      welcome_video {
        video_url
        verse_video_id
      }
      footer_partners {
        id
        image_url
        title
        url_link
      }
    }
  }
`;
