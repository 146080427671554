import { useEffect, useState } from 'react';

const useIsIE = () => {
  const [isIE, setIsIE] = useState(false);

  useEffect(() => {
    //@ts-ignore
    const isIE = /*@cc_on!@*/ false || !!document?.documentMode;

    setIsIE(isIE);
  }, [setIsIE]);

  return isIE;
};

export default useIsIE;
