import React, { useContext, FC } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { alpha, styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import { UserInfoCtx } from '../../../containers/CommonLayout/CommonLayout';
import useIsSsku from '../../../hooks/tenant/useIsSsku';
import useTenantTranslation from '../../../hooks/useTenantTranslation';
import { MIDDLE_MARGIN_PX, SMALL_MARGIN_PX } from '../../../theme';
import UserCom from '../../../utils/UserCom';
import { PROFILE_LINKS, PROFILE_LINKS_SSKU } from '../const';

interface UserOverlayContentProps {
  isScrolled?: boolean;
  handleClickLink: () => void;
  handleClickContactBtn: () => void;
}

const UserOverlayContent: FC<UserOverlayContentProps> = ({
  isScrolled,
  handleClickLink,
  handleClickContactBtn,
}) => {
  const { userInfo } = useContext(UserInfoCtx);
  const { t } = useTenantTranslation();
  const isSsku = useIsSsku();

  const userName = userInfo?.name;
  const teamName = userInfo?.teams?.[0]?.name || null;

  return (
    <Wrapper>
      <UserInfoWrapper scrolled={isScrolled}>
        <Username>{userName}</Username>
        {teamName && <Team>{teamName}</Team>}
      </UserInfoWrapper>
      <AccordionLinks>
        {(isSsku ? PROFILE_LINKS_SSKU : PROFILE_LINKS).map((item) => (
          <LinkWrapper className="header_linkWrapper" key={item.url}>
            <StyledLink to={item.url} onClick={handleClickLink}>
              {t(item.title)}
            </StyledLink>
          </LinkWrapper>
        ))}
        {
          // @ts-ignore
          !!UserCom.checkHasUserCom() && (
            <LinkWrapper>
              <StyledLinkDiv onClick={handleClickContactBtn}>{t('header.contact')}</StyledLinkDiv>
            </LinkWrapper>
          )
        }
      </AccordionLinks>
    </Wrapper>
  );
};

export default UserOverlayContent;

const Wrapper = styled('div')`
  margin-bottom: ${MIDDLE_MARGIN_PX};
`;
const UserInfoWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'scrolled',
})<{ scrolled?: boolean }>`
  margin-top: ${({ scrolled }) => (scrolled ? 0 : SMALL_MARGIN_PX)};
  padding-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => alpha(theme.palette.common.white, 0.15)};
  margin-bottom: 24px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 0;
  }
`;
const Username = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.common.white};
    font-size: 16px;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 2px;
    z-index: 2;

    ${({ theme }) => theme.breakpoints.up('xl')} {
      margin-left: ${SMALL_MARGIN_PX};
    }
  }
`;
const Team = styled(Typography)`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.common.gray};
  line-height: 1.5;
`;

const AccordionLinks = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;

  & .header_linkWrapper:last-child {
    margin-bottom: 0;
  }
`;
const LinkWrapper = styled(Box)`
  z-index: 1;
  position: relative;
  margin-bottom: 24px;
`;
const createLinkStyles = (component) => styled(component)`
  color: ${({ theme }) => theme.palette.common.white};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.2;
`;
const StyledLink = createLinkStyles(Link);
const StyledLinkDiv = createLinkStyles('div');
