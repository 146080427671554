import React from 'react';

import { Typography } from '@mui/material';

import styled from '../../../../styled';
import { SMALL_MARGIN_PX } from '../../../../theme';

interface CardLabelProps {
  className?: string;
  children?: React.ReactNode;
}

const CardLabel: React.FC<CardLabelProps> = ({ className, children, ...rest }) => {
  return (
    <StyledLabel className={className} {...rest}>
      {children}
    </StyledLabel>
  );
};

export default CardLabel;

const StyledLabel = styled(Typography)`
  position: absolute;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  right: 9px;
  bottom: ${SMALL_MARGIN_PX};
  color: ${({ theme }) => theme.palette.common.text.cardLabel};
  z-index: 1;
`;
