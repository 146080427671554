import React, { FC } from 'react';

import { styled } from '@mui/material/styles';

import { SMALL_MARGIN_PX, SUBMIDDLE_MARGIN_PX } from '../../../theme';
import { MegaMenuRightSubBlock } from '../../../types';
import LinkWithArrow from './LinkWithArrow';
import MegaMenuContentCard from './MegaMenuContentCard';

interface RightMegaMenuProps {
  items?: (MegaMenuRightSubBlock | null)[] | null;
  link?: string | null;
  linkText?: string | null;
}

const RightMegaMenu: FC<RightMegaMenuProps> = ({ items, link, linkText }) => {
  return (
    <div>
      {Boolean(items?.length) && (
        <CardsWrapper>
          {items?.map((item, i) => (
            <MegaMenuContentCard
              key={i}
              title={item?.title}
              description={item?.description}
              imageUrl={item?.image_url}
              withPlayIcon={item?.play_icon}
              link={item?.link}
            />
          ))}
        </CardsWrapper>
      )}
      {Boolean(link && linkText) && <StyledLinkWithArrow title={linkText} link={link} />}
    </div>
  );
};

export default RightMegaMenu;

const CardsWrapper = styled('div')`
  & > * {
    margin-bottom: ${SUBMIDDLE_MARGIN_PX};
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledLinkWithArrow = styled(LinkWithArrow)`
  display: none;
  ${({ theme }) => theme.breakpoints.up('xl')} {
    margin-top: ${SMALL_MARGIN_PX};
    display: flex;
  }
`;
