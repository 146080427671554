import React from 'react';

import { Element, useSvgColor, Svg } from './styles';

const HandsetIcon = ({ color = '', ...props }) => {
  const svgColor = useSvgColor(color);
  return (
    <Svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      svgColor={svgColor}
    >
      <Element
        d="M11.9416 14.9913C12.4299 15.4823 13.2687 15.3394 13.6989 14.7967C14.1603 14.2145 14.7848 13.6834 15.4309 13.9C16.326 14.2 17.3204 14.4 18.3149 14.4H18.6133C18.7127 14.4 18.8122 14.4 19.011 14.4C19.5083 14.4 20.0055 14.6 20.4033 15C20.8011 15.4 21 15.9 21 16.4V19C21 19.5 20.8011 20 20.4033 20.4C19.9061 20.8 19.4088 21 18.9116 21H18.3149C14.2376 21 10.3591 19.4 7.57459 16.5C4.59116 13.6 3 9.7 3 5.7V5C3 4.5 3.1989 4 3.59669 3.6C3.99448 3.2 4.49171 3 4.98895 3H7.47514C7.97238 3 8.46961 3.2 8.8674 3.6C9.36464 4 9.56354 4.5 9.56354 5V5.6C9.56354 6.6 9.76243 7.5 10.0608 8.5C10.1602 8.8 10.2597 9.2 10.1602 9.5C10.0608 9.8 9.86188 10.2 9.66298 10.4V10.4C9.10499 10.9611 8.68615 11.7177 9.24414 12.2788L11.9416 14.9913Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default HandsetIcon;
