import { ReviewStatus } from '../types';

export enum PATHWAY_ACTIONS {
  CONTINUE_WATCHING = 'continue_watching',
  TAKE_TEST = 'take_test',
  WATCH_AGAIN = 'watch_again',
  PASSED_TEST = 'passed_test',
  WATCH_NEW_CONTENT = 'watch_new_content',
  LEAVE_REVIEW = 'leave_review',
  NOTHING = 'nothing',
}

interface CalculatePathwayStatus {
  watchedVideos: number;
  totalVideos: number;
  hash?: string | null;
  testId?: number | null;
  isMaxStars?: boolean;
  reviewStatus?: ReviewStatus | null;
}

export const calculatePathwayStatus = ({
  watchedVideos,
  totalVideos,
  hash,
  testId,
  isMaxStars,
  reviewStatus,
}: CalculatePathwayStatus): PATHWAY_ACTIONS => {
  const watchedAllVideos = watchedVideos === totalVideos;
  if (isMaxStars && !reviewStatus) {
    return PATHWAY_ACTIONS.LEAVE_REVIEW;
  }
  if (isMaxStars && reviewStatus) {
    return PATHWAY_ACTIONS.NOTHING;
  }
  if (hash && watchedAllVideos) {
    return PATHWAY_ACTIONS.PASSED_TEST;
  }
  if (hash && !watchedAllVideos) {
    return PATHWAY_ACTIONS.WATCH_NEW_CONTENT;
  }
  if (testId && watchedAllVideos) {
    return PATHWAY_ACTIONS.TAKE_TEST;
  }
  if (watchedAllVideos) {
    return PATHWAY_ACTIONS.WATCH_AGAIN;
  }
  return PATHWAY_ACTIONS.CONTINUE_WATCHING;
};
