import React, { useContext, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { Navigate, useParams } from 'react-router-dom';

import NotFoundPage from '../../../blocks/NotFoundPage/NotFoundPage';
import { Loader } from '../../../components/Loader';
import RouteWithCode from '../../../components/RouteWithCode/RouteWithCode';
import { DEFAULT_DISCOVER_URL, LAST_VIEWED_PATHWAY_SLUG_COOKIE_NAME } from '../../../const';
import { UserInfoCtx } from '../../../containers/CommonLayout/CommonLayout';
import { GET_PATHWAY_PAGE_INFO, GET_PATHWAY_PAGE_PROGRESS } from '../../../graphql/pathways';
import useIsSSR from '../../../hooks/useIsSSR';
import {
  Query,
  QueryGetPathwayLearnerPageInfoArgs,
  QueryGetPathwayPageInfoArgs,
} from '../../../types';
import Cookie from '../../../utils/cookies';
import { formatProgressData } from '../../../utils/videoProgressUtils';
import { formatPathwayVideos } from '../../helpers';

const ContinueWatching = () => {
  const params = useParams();
  const slug = params?.slug;
  const { userInfo } = useContext(UserInfoCtx);
  const isSSR = useIsSSR();

  const { data: pathwayProgressData, loading: loadingProgressData } = useQuery<
    Pick<Query, 'getPathwayLearnerPageInfo'>,
    QueryGetPathwayLearnerPageInfoArgs
  >(GET_PATHWAY_PAGE_PROGRESS, {
    variables: {
      filter: {
        slug: [slug || ''],
      },
    },
    skip: !userInfo || isSSR || !slug,
    fetchPolicy: 'network-only',
  });

  const {
    data: pathwayData,
    loading: loadingPathwayData,
    called,
  } = useQuery<Pick<Query, 'getPathwayPageInfo'>, QueryGetPathwayPageInfoArgs>(
    GET_PATHWAY_PAGE_INFO,
    {
      variables: {
        filter: {
          slug: [slug || ''],
        },
      },
      fetchPolicy: 'network-only',
      skip: !slug,
    },
  );

  useEffect(() => {
    Cookie.set(LAST_VIEWED_PATHWAY_SLUG_COOKIE_NAME, slug);
  }, [slug]);

  const progressData = formatProgressData(pathwayProgressData?.getPathwayLearnerPageInfo?.progress);
  const pathwayVideos = formatPathwayVideos(
    pathwayData?.getPathwayPageInfo?.pathway?.videos,
    progressData,
  );

  const firstStartedVideo = pathwayVideos.find((video) => !video.completed && video.progress > 0);
  const firstNotStartedVideo = pathwayVideos.find(
    (video) => !video.completed && video.progress === 0,
  );
  const videoToContinuePathway = firstStartedVideo || firstNotStartedVideo;
  const link =
    videoToContinuePathway?.link ||
    (pathwayProgressData?.getPathwayLearnerPageInfo?.pathway?.available_quizes?.[0]
      ? `${DEFAULT_DISCOVER_URL}/pathways/${slug}/test/${pathwayProgressData?.getPathwayLearnerPageInfo?.pathway?.available_quizes?.[0]?.id}`
      : undefined);

  if (called && !pathwayData?.getPathwayPageInfo && !loadingPathwayData) {
    return (
      <RouteWithCode code={404}>
        <NotFoundPage />
      </RouteWithCode>
    );
  }

  if (loadingProgressData || loadingPathwayData || !userInfo) {
    return <Loader />;
  }

  if (link) {
    return <Navigate to={link} replace />;
  }

  return <Loader />;
};

export default ContinueWatching;
