import ImageKit from 'imagekit-javascript';

const IMAGEKIT_URL = process.env.REACT_APP_IMAGEKIT_URL || 'https://img-cdn.financeunlocked.com/';

export const imagekit = new ImageKit({
  publicKey: 'public_0teR1Ro6SaVmRWm/ug/AXd2gI+E=',
  urlEndpoint: IMAGEKIT_URL,
});

export const generateImageKitUrl = ({ path, transformation }) => {
  return imagekit.url({
    path,
    ...(transformation && { transformation }),
  });
};

export const generateSrcset = (path, srcset) => {
  let generatedSrcset = '';

  srcset.forEach(
    ({ width, height, crop, focus, quality, blur, aspectRatio, cropMode, x, y, cm }, index) => {
      const isLastItem = srcset.length > 0 && srcset.length - 1 === index;

      const IKUrl = generateImageKitUrl({
        path,
        transformation: [
          {
            width: `${width}`,
            ...(height && { height: `${height}` }),
            ...(crop && { crop }),
            ...(focus && { focus }),
            ...(quality && { quality }),
            ...(blur && { blur }),
            ...(aspectRatio && { aspectRatio }),
            ...(cropMode && { cropMode }),
            ...(cm && { cm }),
            ...(x && { x }),
            ...(y && { y }),
          },
        ],
      });

      const twiceScaledImgUrl = generateImageKitUrl({
        path,
        transformation: [
          {
            width: `${width * 2}`,
            ...(height && { height: `${height * 2}` }),
            ...(crop && { crop }),
            ...(focus && { focus }),
            ...(quality && { quality }),
            ...(blur && { blur }),
          },
        ],
      });

      generatedSrcset = generatedSrcset.concat(`${IKUrl} ${width}w, `);
      generatedSrcset = generatedSrcset.concat(
        `${twiceScaledImgUrl} ${width * 2}w${!isLastItem ? ',' : ''}`,
      );
    },
  );

  return generatedSrcset;
};

export const uploadFileToImageKit = (
  file,
  { folder = '/logos', useUniqueFileName = true } = {},
) => {
  const imagekit = new ImageKit({
    publicKey: process.env.REACT_APP_IMAGEKIT_PUBLIC_KEY,
    urlEndpoint: process.env.REACT_APP_IMAGEKIT_ENDPOINT_URL,
    authenticationEndpoint: `${process.env.REACT_APP_HTTP_API_URL}/api/imagekit/token`,
  });

  return new Promise<{ filePath: string }>((resolve, reject) => {
    imagekit.upload(
      {
        file,
        fileName: file?.name,
        useUniqueFileName,
        folder,
      },
      (err, result) => {
        if (err) {
          reject(err);
        }
        resolve(result);
      },
    );
  });
};
