import React from 'react';

import { useTheme } from '@mui/material';

import { Element, Svg } from './styles';

const AccountIcon = ({ color = '', ...props }) => {
  const theme = useTheme();
  const svgColor = color || theme.palette.common.white;

  return (
    <Svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}
      svgColor={svgColor}
    >
      <Element d="M22,21.996A7.99609,7.99609,0,0,0,14.004,14H9.996A7.99609,7.99609,0,0,0,2,21.996V22H22Z" />
      <Element
        as="circle"
        cx="12"
        cy="8"
        r="6"
        transform="translate(-0.08813 0.1338) rotate(-0.63652)"
      />
    </Svg>
  );
};

export default AccountIcon;
