import React, { FC, ReactNode } from 'react';

import { alpha } from '@mui/material/styles';

import useTenantTranslation from '../../hooks/useTenantTranslation';
import styled from '../../styled';

type SwitchSize = 'default' | 'small';

interface SwitchProps {
  value: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onOff?: boolean;
  'data-testid'?: string;
  id?: string;
  size?: SwitchSize;
  icon?: ReactNode;
}

const Switch: FC<SwitchProps> = ({
  value = false,
  onChange = () => {},
  onOff = false,
  id = 'switch',
  icon,
  size = 'default',
  'data-testid': dataTestid,
  ...rest
}) => {
  const { t } = useTenantTranslation();

  return (
    <Wrapper {...rest}>
      <Input
        size={size}
        data-testid={dataTestid || 'switcher'}
        type="checkbox"
        id={id}
        onChange={onChange}
        checked={value}
      />
      <Label className="switch_label" size={size} htmlFor={id} onOff={onOff}>
        {t('input.label.toggle')}
        <Slider size={size}>{icon}</Slider>
      </Label>
    </Wrapper>
  );
};

export default Switch;

const Wrapper = styled('div')`
  display: flex;
  font-size: 0;
`;

const inputSizeMap: Record<SwitchSize, { left: string }> = {
  default: { left: 'calc(100% - 28px)' },
  small: { left: 'calc(100% - 22px)' },
};

const Input = styled('input')`
  height: 0;
  width: 0;
  visibility: hidden;
  margin: 0;

  &:checked + label span {
    left: ${({ size }) => inputSizeMap[size].left};
    background: ${({ theme }) => theme.palette.common.primary};
  }
`;

const labelSizeMap: Record<SwitchSize, { width: string; height: string }> = {
  default: { width: '58px', height: '32px' },
  small: { width: '46px', height: '24px' },
};

const Label = styled('label', {
  shouldForwardProp: (prop) => prop !== 'onOff',
})<{ size: SwitchSize; onOff: boolean }>`
  cursor: pointer;
  text-indent: -9999px;
  width: ${({ size }) => labelSizeMap[size].width};
  height: ${({ size }) => labelSizeMap[size].height};
  background: ${({ theme }) => theme.palette.common.violetAnalyticsCard};
  display: block;
  font-size: 16px;
  border-radius: 999em;
  position: relative;
`;

const sliderSizesMap: Record<
  SwitchSize,
  {
    width: string;
    height: string;
    top: string;
    left: string;
  }
> = {
  default: { width: '24px', height: '24px', top: '4px', left: '4px' },
  small: { width: '20px', height: '20px', top: '2px', left: '2px' },
};

const Slider = styled('span')<{ size: SwitchSize }>`
  position: absolute;
  top: ${({ size }) => sliderSizesMap[size].top};
  left: ${({ size }) => sliderSizesMap[size].left};
  width: ${({ size }) => sliderSizesMap[size].width};
  height: ${({ size }) => sliderSizesMap[size].height};
  background: ${({ onOff, theme }) =>
    onOff ? alpha(theme.palette.common.white, 0.3) : theme.palette.common.primary};
  border-radius: 100%;
  transition: 0.3s;
`;
