import React, { forwardRef } from 'react';

import { generateImageKitUrl, generateSrcset } from '../../modules/imageKit';
import styled from '../../styled';

export interface InterfaceIKImage {
  path: string;
  transformation?: any[] | null;

  [key: string]: any;
}

const IKImage = React.memo(
  forwardRef(
    (
      { path, transformation = null, srcset = [], sizes = null, ...props }: InterfaceIKImage,
      ref: React.Ref<HTMLImageElement>,
    ) => {
      const re = /(?:\.([^.]+))?$/;
      const extensionFromPathObj = re.exec(path);
      const extensionFromPath = extensionFromPathObj && extensionFromPathObj[1];

      if (!path) {
        return <Img ref={ref} src="" alt={props?.alt || ''} {...props} />;
      }

      const IKUrl = generateImageKitUrl({
        path,
        transformation,
      });

      if (extensionFromPath && extensionFromPath.toLowerCase() === 'svg') {
        return <Img ref={ref} src={IKUrl} alt={props?.alt || ''} {...props} />;
      }

      let generatedSrcset = generateSrcset(path, srcset);

      return (
        <Img
          ref={ref}
          src={IKUrl}
          srcSet={generatedSrcset}
          alt={props?.alt || ''}
          sizes={sizes}
          {...props}
        />
      );
    },
  ),
);

export default IKImage;

const Img = styled('img')`
  ${({ css }) => css}
`;
