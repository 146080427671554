import React, { RefObject, useRef, useState } from 'react';

function useTooltipOffset() {
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (wrapperRef?.current) {
      const centerX = wrapperRef.current.clientWidth / 2;
      const height = wrapperRef.current.clientHeight;
      const clientX = event.clientX;
      const clientY = event.clientY;
      const elX = wrapperRef.current.getBoundingClientRect().x;
      const elY = wrapperRef.current.getBoundingClientRect().y;
      const mouseXPosOnEl = clientX - elX;
      const mouseYPosOnEl = clientY - elY;

      setOffset({ x: mouseXPosOnEl - centerX, y: mouseYPosOnEl - height });
    }
  };

  return [offset, wrapperRef, handleMouseEnter] as [
    { x: number; y: number },
    RefObject<HTMLDivElement>,
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
  ];
}

export default useTooltipOffset;
