import React, { FC } from 'react';

import styled from '../../../../styled';
import { CategoryLabel } from '../../../UI/CategoryLabel';
import { CategoryLabelProps } from '../../../UI/CategoryLabel/CategoryLabel';

const CardImageLabelProps: FC<CategoryLabelProps> = (props) => <StyledCategoryLabel {...props} />;

export default CardImageLabelProps;

const StyledCategoryLabel = styled(CategoryLabel)`
  position: absolute;
  z-index: 1;
  top: 12px;
  left: 12px;
`;
