import { useEffect, useState } from 'react';

import TagManager from 'react-gtm-module';

import { LearnerInfo } from '../types';
import { GtmEvents, sendEventToGTM } from '../utils/gtmUtils';
import useAuth from './useAuth';

const useInitGtm = (
  location,
  gtmId?: string | null,
  userInfo?: LearnerInfo | null,
  userLoading?: boolean,
) => {
  const [isInitializedGtm, setIsInitializedGtm] = useState(false);

  const { isInitialized, isLoading } = useAuth();

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      typeof document !== 'undefined' &&
      gtmId !== undefined &&
      gtmId !== null &&
      isInitialized &&
      !isLoading &&
      !userLoading
    ) {
      setIsInitializedGtm(true);
      const tagManagerArgs = {
        gtmId,
        ...(userInfo?.id && {
          dataLayer: {
            userid: userInfo?.id,
            companyId: userInfo?.company?.id || null,
            email: userInfo?.email,
          },
        }),
      };

      TagManager.initialize(tagManagerArgs);
    }
  }, [gtmId, isInitialized, isLoading, userInfo, userLoading]);

  useEffect(() => {
    if (isInitializedGtm && !userInfo) {
      sendEventToGTM(GtmEvents.loggedOutPageVisit);
    }
  }, [isInitializedGtm, location.pathname, userInfo]);

  return isInitializedGtm;
};

export default useInitGtm;
