import React from 'react';

import { Typography } from '@mui/material';

import styled from '../../../../styled';
import { SMALL_MARGIN_PX } from '../../../../theme';

interface CardDescriptionProps {
  text: string;
  className?: string;
  lines?: number;
}

const CardDescription: React.FC<CardDescriptionProps> = ({
  text,
  lines = 6,
  className,
  ...rest
}) => {
  return (
    <Description lines={lines} className={className} {...rest}>
      {text}
    </Description>
  );
};

export default CardDescription;

const Description = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'lines',
})<{ lines: number }>`
  display: -webkit-box;
  font-size: 18px;
  line-height: 1.5;
  font-weight: normal;
  margin-bottom: ${SMALL_MARGIN_PX};
  color: ${({ theme }) => theme.palette.common.text.secondaryToPrimary};
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${({ lines }) => lines};
  -webkit-box-orient: vertical;
  word-break: break-word;
`;
