import React, { FC } from 'react';

import { Container } from '@mui/material';

import styled, { css } from '../../styled';
import {
  BIG_MARGIN,
  BIG_MARGIN_PX,
  LARGE_MARGIN_PX,
  MIDDLE_MARGIN_PX,
  SMALL_MARGIN_PX,
  SUBMIDDLE_MARGIN_PX,
} from '../../theme';
import { Background, Maybe, Scalars, WhatBusinessStand } from '../../types';
import { BodyText, H2, H3 } from '../UI/Texts';
import Card from './Card';

export type CardType = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
};

interface CardsBlockProps {
  data: WhatBusinessStand;
}

const CardsBlock: FC<CardsBlockProps> = ({ data }) => {
  const { background, section_first, section_second } = data;

  return (
    <Wrapper
      className={background === Background.Dark ? 'dark-block' : 'light-block'}
      background={background}
    >
      <StyledContainer>
        {section_first?.title && (
          <Heading variant="h2" align="center">
            {section_first?.title}
          </Heading>
        )}
        {section_first?.description && (
          <Description align="center">{section_first?.description}</Description>
        )}
        {section_second?.title && (
          <Title variant="h3" align="center">
            {section_second?.title}
          </Title>
        )}
        {section_second?.usp_icon_block && section_second.usp_icon_block.length > 0 && (
          <CardsWrapper>
            {section_second.usp_icon_block.map((item) => (
              <CardWrapper key={item?.usp_icon_image}>
                <Card
                  title={item?.usp_title}
                  description={item?.usp_description}
                  imageUrl={item?.usp_icon_image}
                  background={background}
                  wrapperStyles={cardStyles}
                />
              </CardWrapper>
            ))}
          </CardsWrapper>
        )}
      </StyledContainer>
    </Wrapper>
  );
};

export default CardsBlock;

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'background',
})`
  padding-top: ${MIDDLE_MARGIN_PX};
  padding-bottom: ${MIDDLE_MARGIN_PX};
  background: ${({ theme }) => theme.palette.secondary.dark};

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-top: ${LARGE_MARGIN_PX};
    padding-bottom: ${LARGE_MARGIN_PX};
    background: ${({ background, theme }) =>
      background === 'Dark' ? theme.palette.secondary.dark : theme.palette.secondary.light};
  }
`;
const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  && {
    max-width: 832px;
  }
`;
const Heading = styled(H2)`
  & {
    margin-bottom: ${SMALL_MARGIN_PX};

    ${({ theme }) => theme.breakpoints.up('md')} {
      margin-bottom: ${MIDDLE_MARGIN_PX};
    }
  }
`;
const Description = styled(BodyText)`
  color: ${({ theme }) => theme.palette.common.white};
  margin-bottom: ${MIDDLE_MARGIN_PX};
`;
const Title = styled(H3)`
  & {
    margin-bottom: ${SUBMIDDLE_MARGIN_PX};

    ${({ theme }) => theme.breakpoints.up('md')} {
      margin-bottom: ${BIG_MARGIN_PX};
    }
  }
`;
const CardsWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    align-items: stretch;
    width: calc(100% + ${BIG_MARGIN}px);
    margin-left: ${BIG_MARGIN / 2}px;
    margin-right: ${BIG_MARGIN / 2}px;
    margin-bottom: -${BIG_MARGIN / 2}px;
  }
`;
const CardWrapper = styled('div')`
  max-width: 320px;
  width: 100%;
  position: relative;
  margin-bottom: ${SUBMIDDLE_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-grow: 0;
    flex-basis: 33.33%;
    max-width: 33.33%;
    padding: ${BIG_MARGIN / 2}px;
    padding-top: 0;
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
const cardStyles = ({ theme }) => css`
  width: 100%;
  ${theme.breakpoints.up('md')} {
    height: 100%;
  }
`;
