import React, { FC, ReactNode } from 'react';

import { Box } from '@mui/material';

import useIsSsku from '../../hooks/tenant/useIsSsku';
import styled from '../../styled';
import { IKImage } from '../IKImage';

interface LoaderProps {
  global?: boolean;
  children?: ReactNode;
  isSsku?: boolean;
}

const Loader: FC<LoaderProps> = (props) => {
  const { global = true, children, isSsku: isRootLoaderSsku, ...rest } = props;
  const isSsku = useIsSsku() || isRootLoaderSsku;

  return (
    <PageLoaderPro data-testid={rest['data-testid'] || 'mainLoader'} global={global}>
      <CenterLoader>
        <CustomLoader>
          {isSsku ? (
            <>
              <CustomLoaderImg
                path="/logo-animation/ssku-logo.png"
                className="fuLogo3"
                alt="logo-animation"
              />
              <Logo path="/logo-animation/ssku-logo-inner.png" alt="logo-animation" />
            </>
          ) : (
            <>
              <CustomLoaderImg path="/logo-animation/fu-logo-1.png" alt="logo-animation" />
              <CustomLoaderImg
                path="/logo-animation/fu-logo-2.png"
                className="fuLogo2"
                alt="logo-animation"
              />
              <CustomLoaderImg
                path="/logo-animation/fu-logo-3.png"
                className="fuLogo3"
                alt="logo-animation"
              />
            </>
          )}
        </CustomLoader>
        {children}
      </CenterLoader>
    </PageLoaderPro>
  );
};
export default Loader;

const PageLoaderPro = styled('div', {
  shouldForwardProp: (prop) => prop !== 'global',
})`
  position: ${({ global }) => (global ? 'fixed' : 'absolute')};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ global }) => (global ? 9999 : 1000)};
  text-align: center;
  color: ${({ theme }) => theme.palette.common.white};
  background: ${({ global, theme }) =>
    global ? theme.palette.common.blueDark : theme.palette.common.transparent};
`;
const CenterLoader = styled(Box)`
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`;
const CustomLoader = styled(Box)`
  width: 100px;
  height: 100px;
  display: inline-block;
  position: relative;
`;
const CustomLoaderImg = styled(IKImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  outline: 1px solid transparent;

  &.fuLogo2 {
    animation-name: fu-rotate;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  &.fuLogo3 {
    animation-name: fu-rotate-backwards;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
`;
const Logo = styled(IKImage)`
  position: absolute;
  left: 50%;
  top: calc(50% - 2px);
  transform: translate(-50%, -50%);
  object-fit: contain;
  width: 52px;
`;
