import React, { createContext, useState } from 'react';

type stateType = {
  rxdUserInfo: any;
  setRxdUserInfo: Function;
};

const STATE: stateType = {
  rxdUserInfo: null,
  setRxdUserInfo: () => {},
};

export const RXDContext = createContext(STATE);

export const RXDController = ({ children }) => {
  const [rxdUserInfo, setRxdUserInfo] = useState(null);

  return (
    <RXDContext.Provider
      value={{
        rxdUserInfo,
        setRxdUserInfo,
      }}
    >
      {children}
    </RXDContext.Provider>
  );
};
