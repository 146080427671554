import { AuthState, User } from './AuthState';

type Action =
  | { type: 'CLIENT_INITIALISED' }
  | { type: 'LOGIN_POPUP_STARTED' }
  | {
      type: 'INITIALISED' | 'LOGIN_POPUP_COMPLETE' | 'GET_ACCESS_TOKEN_COMPLETE';
      user?: User;
    }
  | { type: 'LOGOUT' }
  | { type: 'ERROR'; error: Error }
  | { type: 'UPDATE_USER_INFO'; userInfo?: User }
  | { type: 'UPDATE_LOGIN_DOMAIN'; loginDomain: string };

/**
 * Handles how that state changes in the `useAuth0` hook.
 */
export const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'CLIENT_INITIALISED':
      return {
        ...state,
        isInitialized: true,
        updatingClient: false,
      };
    case 'UPDATE_USER_INFO':
      return {
        ...state,
        userInfo: { ...state.userInfo, ...action.userInfo },
      };
    case 'UPDATE_LOGIN_DOMAIN':
      return {
        ...state,
        loginDomain: action.loginDomain,
        updatingClient: true,
      };
    case 'LOGIN_POPUP_STARTED':
      return {
        ...state,
        isLoading: true,
      };
    case 'LOGIN_POPUP_COMPLETE':
    case 'INITIALISED':
      return {
        ...state,
        isAuthenticated: !!action.user,
        user: action.user,
        userSub: action.user?.sub,
        isLoading: false,
        error: undefined,
      };
    case 'GET_ACCESS_TOKEN_COMPLETE':
      if (state.user?.updated_at === action.user?.updated_at) {
        return state;
      }
      return {
        ...state,
        isAuthenticated: !!action.user,
        user: action.user,
        userSub: action.user?.sub,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: undefined,
      };
    case 'ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
  }
};
