import { PathwayItem } from '../../types';
import { SECONDS_IN_HOUR, SECONDS_IN_MINUTE } from '../../utils/const';
import { translate } from '../../utils/translate';

export type CategoryType = {
  value: string;
  display: string;
};

export const secondsToPathwaysFormat = (d) => {
  d = Number(d);
  let h = Math.floor(d / SECONDS_IN_HOUR);
  let m = Math.floor((d % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE);

  let hDisplay = h > 0 ? translate('common.hour', { count: h }) : '';
  let mDisplay = m > 0 ? translate('common.minute', { count: m }) : '';

  return hDisplay + ' ' + mDisplay;
};

export const splitPathwayItemsByCategories = (pathways: PathwayItem[]) => {
  const result = new Map<string | undefined, PathwayItem[]>();
  const categoriesArray: CategoryType[] = [];
  pathways.forEach((pathway) => {
    if (pathway?.pathway?.categories) {
      pathway.pathway.categories.forEach((category) => {
        let currentCategory: CategoryType = category?.parent
          ? {
              value: category.parent?.id || '',
              display: category.parent?.title || '',
            }
          : {
              value: category?.id || '',
              display: category?.title || '',
            };

        let catIndex = categoriesArray.findIndex((cat) => cat.value === currentCategory.value);

        if (catIndex === -1) {
          categoriesArray.push(currentCategory);
          result.set(currentCategory.value, [pathway]);
        } else {
          currentCategory = categoriesArray[catIndex];
          const currentArr = result.get(currentCategory.value);
          result.set(currentCategory.value, currentArr ? [...currentArr, pathway] : [pathway]);
        }
      });
    }
  });

  const sortedCategories = categoriesArray.sort((a, b) => a.display.localeCompare(b.display));

  return {
    result,
    categoriesArray: sortedCategories,
  };
};
