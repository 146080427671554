import React, { FC } from 'react';

import { Container, Box } from '@mui/material';
import cx from 'classnames';

import styled from '../../styled';
import { LARGE_MARGIN_PX, MIDDLE_MARGIN_PX, SMALL_MARGIN_PX, TINY_MARGIN_PX } from '../../theme';
import { CtaBlock as CtaBlockType } from '../../types';
import SafeLink from '../SafeLink';
import { CustomButton } from '../UI/CustomButton';
import { BodyText, H2 } from '../UI/Texts';

interface CtaBlockProps {
  data: CtaBlockType;
}

const CtaBlock: FC<CtaBlockProps> = ({ data }) => {
  const background = data?.background;
  const isExtendedCta = Boolean(data?.title_text || data?.sub_title);

  return data?.link && data?.title ? (
    <Wrapper
      className={cx(
        background === 'Dark' ? 'dark-block' : 'light-block',
        'cta-block',
        !isExtendedCta && 'cta-btn-block',
      )}
      background={background}
    >
      <StyledContainer className="cta-block__container" isExtendedCta={isExtendedCta}>
        {isExtendedCta && (
          <TextBlock>
            {data?.title_text && <Title variant="h2">{data?.title_text}</Title>}
            {data?.sub_title && <Description>{data?.sub_title}</Description>}
          </TextBlock>
        )}
        <StyledSafeLink
          to={data?.link || '#'}
          className="cta-block__link"
          isExtendedCta={isExtendedCta}
        >
          <StyledButton btnClass="cta-block__button">{data?.title}</StyledButton>
        </StyledSafeLink>
      </StyledContainer>
    </Wrapper>
  ) : null;
};

export default CtaBlock;

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'background',
})`
  padding-top: ${MIDDLE_MARGIN_PX};
  padding-bottom: ${MIDDLE_MARGIN_PX};
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-top: ${LARGE_MARGIN_PX};
    padding-bottom: ${LARGE_MARGIN_PX};
    background: ${({ background, theme }) =>
      background === 'Dark' ? theme.palette.secondary.dark : theme.palette.secondary.light};
  }
`;
const StyledContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'isExtendedCta',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  && {
    max-width: 952px;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    justify-content: ${({ isExtendedCta }) => (isExtendedCta ? 'space-between' : 'center')};
    flex-direction: row;
  }
`;
const TextBlock = styled('div')`
  margin-bottom: ${SMALL_MARGIN_PX};
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: 0;
  }
`;
const Title = styled(H2)`
  text-align: center;
  ${({ theme }) => theme.breakpoints.up('md')} {
    text-align: left;
  }
`;
const Description = styled(BodyText)`
  text-align: center;
  &:not(:only-child) {
    margin-top: ${SMALL_MARGIN_PX};
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    text-align: left;
    &:not(:only-child) {
      margin-top: ${TINY_MARGIN_PX};
    }
  }
`;
const StyledSafeLink = styled(SafeLink, {
  shouldForwardProp: (prop) => prop !== 'isExtendedCta',
})`
  flex-shrink: 0;
  ${({ theme }) => theme.breakpoints.up('md')} {
    ${({ isExtendedCta }) => isExtendedCta && `margin-left: ${MIDDLE_MARGIN_PX};`}
  }
`;
const StyledButton = styled(CustomButton)`
  width: 100%;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: unset;
  }
`;
