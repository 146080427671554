import React, { FC, useContext } from 'react';

import { Typography } from '@mui/material';

import { TenantCtx } from '../../context/TenantProvider';
import useTenantTranslation from '../../hooks/useTenantTranslation';
import styled from '../../styled';
import { LARGE_MARGIN_PX, MIDDLE_MARGIN_PX, SMALL_MARGIN_PX } from '../../theme';
import { IKImage } from '../IKImage';
import { CustomButton } from '../UI/CustomButton';
import { BodyText } from '../UI/Texts';

const LOGO_TRANSFORMATION = [
  {
    height: 120,
  },
];

interface LoginWithPopupTriggerViewProps {
  isSso?: boolean;
  handleLogin: () => void;
}

const LoginWithPopupTriggerView: FC<LoginWithPopupTriggerViewProps> = ({
  isSso = false,
  handleLogin,
}) => {
  const { t } = useTenantTranslation();
  const { tenant } = useContext(TenantCtx);

  return (
    <CenteredWrapper>
      <CenteredContainer>
        <Logo path={tenant?.logo_url || ''} transformation={LOGO_TRANSFORMATION} />
        <Title variant="h2">{t(isSso ? 'page.login.loginWithSso' : 'page.login.login')}</Title>
        <Description>
          {t(isSso ? 'page.login.loginWithSsoDescription' : 'page.login.loginDescription', {
            tenantTitle: tenant?.title,
          })}
        </Description>
        <CustomButton data-testid="continueLogin" onClick={handleLogin}>
          {t('button.continue')}
        </CustomButton>
      </CenteredContainer>
    </CenteredWrapper>
  );
};

export default LoginWithPopupTriggerView;

const CenteredWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  z-index: 1200;
  background: ${({ theme }) => theme.palette.common.blueDark};
  /* to hide header */
  position: absolute;
  top: 0;
  left: 0;
`;

const CenteredContainer = styled('div')`
  max-width: 650px;
  padding: ${LARGE_MARGIN_PX} ${SMALL_MARGIN_PX};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Logo = styled(IKImage)`
  margin-bottom: ${MIDDLE_MARGIN_PX};
  width: auto;
  height: 58px;
`;

const Title = styled(Typography)`
  text-transform: uppercase;
  margin-bottom: ${SMALL_MARGIN_PX};
  text-align: center;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: ${MIDDLE_MARGIN_PX};
  }
`;

const Description = styled(BodyText)`
  margin-bottom: ${MIDDLE_MARGIN_PX};
  text-align: center;
  color: ${({ theme }) => theme.palette.common.white};
`;
