import React, { FC } from 'react';

import { styled } from '@mui/material/styles';

import UserLearningDueDate from '../../../../components/UserLearningBlock/components/UserLearningDueDate/UserLearningDueDate';
import { DEFAULT_DISCOVER_URL } from '../../../../const';
import useTenantTranslation from '../../../../hooks/useTenantTranslation';
import { DiscoverRoutes, LEARNER_AL_ROUTE } from '../../../../routes';
import { SMALL_MARGIN_PX } from '../../../../theme';
import { AnnouncementEntity, NotificationType } from '../../../../types';
import assertNever from '../../../../utils/errors/assertNever';
import Notification, { AnnouncementType } from './Notification';

interface NotificationFactoryProps {
  item: AnnouncementEntity;
  onClick?: () => void;
}

const NotificationFactory: FC<NotificationFactoryProps> = ({ item, onClick }) => {
  const { t } = useTenantTranslation();

  switch (item.__typename) {
    case undefined:
      return null;
    case 'NotificationForAssignment':
      const assignment = item.assignment;
      const isNew = item.type === NotificationType.New;
      const title = `${t(
        isNew ? 'page.assignedLearnings.newAssignment' : 'common.assignmentReminder',
      )}: ${assignment?.title}`;

      return (
        <StyledNotification
          key={item.id}
          id={item.id}
          link={`${LEARNER_AL_ROUTE}#${assignment?.id}`}
          title={title}
          subtitle={<StyledUserLearningDueDate dueDate={assignment?.due_date} />}
          type={item.type}
          highlighted={item.is_highlighted}
          onClick={onClick}
        />
      );
    case 'Pathway':
      return (
        <StyledNotification
          key={item.id}
          id={item.id}
          title={`${t('common.newPathway')}: ${item.name}`}
          subtitle={[
            `${item.count_videos} ${t('common.videoModules')}`,
            item.categories?.[0]?.title,
          ]
            .filter(Boolean)
            .join(' • ')}
          link={`${DEFAULT_DISCOVER_URL}${DiscoverRoutes.Pathways}/${item.slug}`}
          type={AnnouncementType.Pathway}
          highlighted={item.is_highlighted}
          onClick={onClick}
        />
      );
    case 'Video':
    case 'NewVideoFromExpert':
      return (
        <StyledNotification
          key={item.id}
          id={item.id}
          title={`${t('common.newVideo')}: ${item.title}`}
          subtitle={[item.user?.name, item.categories?.[0]?.title].filter(Boolean).join(' • ')}
          link={`${DiscoverRoutes.Videos}/${item.video_url}`}
          type={
            item.__typename === 'Video' ? AnnouncementType.Video : AnnouncementType.FollowedVideo
          }
          highlighted={item.is_highlighted}
          onClick={onClick}
        />
      );
    case 'NotificationForCompletedPathway':
      return (
        <StyledNotification
          key={item.pathway?.id}
          id={item.pathway?.id}
          title={t('common.reviewRecentPathway')}
          subtitle={item.pathway?.name}
          link={`${DEFAULT_DISCOVER_URL}${DiscoverRoutes.Pathways}/${item.pathway?.slug}#review-form`}
          type={AnnouncementType.PathwayReview}
          onClick={onClick}
        />
      );
    case 'NotificationForCompletedCourse':
      return (
        <StyledNotification
          key={item.course?.id}
          id={item.course?.id}
          title={t('common.reviewRecentCourse')}
          subtitle={item.course?.name}
          link={`${DiscoverRoutes.Courses}/${item.course?.slug}#review-form`}
          type={AnnouncementType.CourseReview}
          onClick={onClick}
        />
      );
    default:
      assertNever(item);
      return null;
  }
};

export default NotificationFactory;

const StyledUserLearningDueDate = styled(UserLearningDueDate)`
  && {
    color: ${({ theme }) => theme.palette.common.text.fadedSubtitle};
    font-size: inherit;
  }
`;
const StyledNotification = styled(Notification)`
  &:not(:last-of-type) {
    margin-bottom: ${SMALL_MARGIN_PX};
  }
`;
