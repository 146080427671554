import React from 'react';

import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import ChevronRightIcon from '../../../assets/icons/ChevronRightIcon';
import { MetaText } from '../../../components/UI/Texts';
import { TINY_MARGIN_PX } from '../../../theme';

const LinkWithArrow = ({ link, title, ...rest }) => {
  const theme = useTheme();
  return (
    <StyledLink to={link} {...rest}>
      <Title>{title}</Title>
      <StyledIcon color={theme.palette.common.tabs.indicator} />
    </StyledLink>
  );
};

export default LinkWithArrow;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const Title = styled(MetaText)`
  color: ${({ theme }) => theme.palette.common.tabs.indicator};
  margin-right: ${TINY_MARGIN_PX};
`;

const StyledIcon = styled(ChevronRightIcon)`
  width: 16px;
  height: 16px;
`;
