import React from 'react';

const Moon = (props) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.2451 11.6136C18.165 11.5333 18.0644 11.4763 17.9543 11.4488C17.8442 11.4212 17.7287 11.4242 17.6201 11.4574C16.4284 11.8176 15.1612 11.8479 13.9537 11.5448C12.7461 11.2417 11.6434 10.6167 10.7631 9.73631C9.88272 8.85595 9.2577 7.75325 8.95462 6.54569C8.65153 5.33814 8.68174 4.07098 9.04202 2.87924C9.07546 2.77065 9.07866 2.65499 9.05128 2.54472C9.0239 2.43444 8.96698 2.33371 8.88664 2.25337C8.80629 2.17303 8.70557 2.1161 8.59529 2.08873C8.48502 2.06135 8.36936 2.06455 8.26077 2.09799C6.61313 2.60271 5.16666 3.61423 4.12718 4.98861C3.21812 6.19553 2.66362 7.63203 2.52598 9.13672C2.38833 10.6414 2.67298 12.1547 3.34796 13.5065C4.02294 14.8583 5.0615 15.9951 6.34697 16.7893C7.63243 17.5834 9.11386 18.0033 10.6248 18.0019C12.3876 18.0073 14.1035 17.4343 15.5092 16.3706C16.8836 15.3312 17.8951 13.8847 18.3998 12.2371C18.4329 12.1289 18.436 12.0137 18.4087 11.9039C18.3815 11.7941 18.3249 11.6938 18.2451 11.6136ZM14.7576 15.3722C13.4339 16.3693 11.7944 16.8546 10.1412 16.7389C8.48796 16.6231 6.93218 15.914 5.76025 14.7422C4.58833 13.5704 3.87914 12.0146 3.76325 10.3614C3.64735 8.70819 4.13255 7.06874 5.12952 5.74486C5.77905 4.8871 6.61879 4.19178 7.58264 3.71361C7.52774 4.09895 7.50006 4.48767 7.49983 4.87689C7.50211 7.03108 8.35886 9.09638 9.8821 10.6196C11.4053 12.1429 13.4706 12.9996 15.6248 13.0019C16.0148 13.0018 16.4044 12.9741 16.7905 12.9191C16.3118 13.8831 15.616 14.7229 14.7576 15.3722Z"
        fill="white"
      />
    </svg>
  );
};

export default Moon;
