import React, { FC } from 'react';

import { useParams } from 'react-router-dom';

import { Loader } from '../../../components/Loader';
import { SeoBlock } from '../../../components/SeoBlock';
import { RM_PAGES } from '../../../const';
import { useRmMetadata } from '../../../context/RmContext';
import { getAslSocialShareImage } from '../../../utils/certificateUtils';

interface AslSharePageProps {
  loadingTenant?: boolean;
  tenantName?: string | null;
}
const AslSharePage: FC<AslSharePageProps> = ({ loadingTenant, tenantName }) => {
  const { hash } = useParams();
  useRmMetadata({ page: RM_PAGES.AslSharePage });

  if (loadingTenant) {
    return <Loader />;
  }

  return (
    <SeoBlock
      data={{
        title: '',
        description: '',
        share_image: getAslSocialShareImage(hash, tenantName),
      }}
    >
      <meta httpEquiv="refresh" content={`2;url=${window?.location?.origin}/`} />
    </SeoBlock>
  );
};

export default AslSharePage;
