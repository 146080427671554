import React, { useCallback } from 'react';

import { Container } from '@mui/material';

import { useIsWidthUp } from '../theme';

const useRestrictedContainer = () => {
  const isMl = useIsWidthUp('ml');

  const RestrictedContainer = useCallback(
    ({ children, ...rest }) => (
      <Container maxWidth={isMl ? 'ml' : 'xl'} {...rest}>
        {children}
      </Container>
    ),
    [isMl],
  );

  return RestrictedContainer;
};

export default useRestrictedContainer;
