import React, { FC, useState } from 'react';

import { SerializedStyles } from '@emotion/react/dist/emotion-react.cjs';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import MuiDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { TextField } from '@mui/material';
import { PopperPlacementType } from '@mui/material/Popper';
import { alpha, Theme } from '@mui/material/styles';

import useOverflowHidden from '../../hooks/useOverflowHidden';
import styled from '../../styled';
import { TINY_MARGIN } from '../../theme';

export const dateFormat = 'dd/MM/yyyy';

interface IDatePicker {
  label: string;
  value: Date | null;
  error?: Date | null | string;
  onChange: (date: Date | null, keyboardInputValue?: string | undefined) => void;
  maxDate?: Date;
  minDate?: Date;
  onError?: (error: any) => void;
  textFieldCss?: SerializedStyles | (({ theme }: { theme: Theme }) => SerializedStyles);
  flip?: boolean;
  placement?: PopperPlacementType;
}

const DatePicker: FC<IDatePicker> = ({
  label,
  value,
  error,
  onChange,
  maxDate,
  minDate,
  onError,
  textFieldCss,
  flip = false,
  placement = 'bottom-start',
  ...rest
}) => {
  const [open, setOpen] = useState(false);

  useOverflowHidden(open);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDatePicker
        data-testid={rest['data-testid']}
        label={label}
        inputFormat={dateFormat}
        maxDate={maxDate}
        minDate={minDate}
        value={value}
        onChange={onChange}
        onError={onError}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderInput={(props) => (
          <StyledTextField
            {...props}
            helperText={error}
            error={!!error}
            inputProps={{ ...props?.inputProps, readOnly: true }}
            onClick={() => setOpen(true)}
            autoComplete="off"
            optionalStyles={textFieldCss}
          />
        )}
        PopperProps={{
          placement,
          modifiers: [
            { name: 'flip', enabled: flip },
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                tether: true,
                rootBoundary: 'viewport',
                padding: TINY_MARGIN,
              },
            },
          ],
        }}
        components={{
          OpenPickerIcon: () => (
            <StyledSvg focusable="false" viewBox="0 0 24 24" aria-hidden="true" open={open}>
              <path d="M7 10l5 5 5-5z" />
            </StyledSvg>
          ),
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})`
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  & .MuiButtonBase-root {
    padding: 2px;
    margin-right: 0;
  }
  ${({ optionalStyles }) => optionalStyles}
`;

const StyledSvg = styled('svg', {
  shouldForwardProp: (prop) => prop !== 'open',
})`
  width: 24px;
  height: 24px;
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'initial')};
  & path {
    fill: ${({ open, theme }) =>
      open ? theme.palette.common.blue : alpha(theme.palette.common.white, 0.33)};
  },
`;
