const getHasAuthCookie = (cookies) => {
  if (cookies) {
    const keys = Object.keys(cookies);
    const authCookieRegExp = new RegExp(/^auth0\..+\.is\.authenticated$/);

    let res: string | null = null;
    keys.forEach((item) => {
      const match = item.match(authCookieRegExp);
      if (match && match[0]) {
        res = match[0];
      }
    });

    return res && cookies[res] === 'true';
  }

  return false;
};

export default getHasAuthCookie;
