import { alpha, css, Theme } from '@mui/material/styles';

import { MIDDLE_RADIUS_PX, SMALL_MARGIN_PX, SUBMIDDLE_MARGIN_PX, TINY_MARGIN_PX } from './theme';

const globalStyles = (theme: Theme) => css`
  html,
  body {
    height: 100%;
    background-color: ${theme.palette.common.blueDark} !important;
  }

  body {
    position: relative;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${theme.typography.headerFontFamily};
  }

  a {
    text-decoration: none;
  }

  #root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  .special-header {
    font-family: ${theme.typography.specialFontFamily};
  }

  .light-block + .light-block,
  .dark-block + .dark-block {
    padding-top: 0;
  }

  .light-block + .light-block.cta-btn-block,
  .dark-block + .dark-block.cta-btn-block {
    margin-top: -36px;
  }

  .landscape-container .swiper-wrapper {
    height: 382px;
    align-items: flex-end;
  }

  .landscape-container .swiper {
    margin-top: -66px;
  }

  .news-slider .swiper,
  .pathways-container .swiper,
  .partners-swiper .swiper {
    overflow: unset;
  }

  .swiper {
    display: flex;
    flex-direction: column;
  }

  .swiper-pagination {
    position: static;
    margin-top: 36px;
  }

  .swiper-pagination-analytic {
    position: static;
    text-align: center;
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background: transparent;
    border: 2px solid ${theme.palette.common.swiper.buttonBorder};
  }

  .swiper-pagination-bullet-active {
    width: 12px;
    height: 12px;
    background: ${theme.palette.common.swiper.buttonBg};
    border: 2px solid ${theme.palette.common.swiper.buttonBg};
  }

  .swiper-pagination-bullet:only-child {
    display: none;
  }

  .swiper-button-prev {
    left: -5px;
  }

  .swiper-button-next {
    right: -5px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none;
    opacity: 0.33;
    width: 60px;
    height: 172px;
    transform: translateY(-35%);
    transition: opacity 0.3s ease-out;
  }

  .swiper-button-next:focus,
  .swiper-button-prev:focus {
    outline: none;
  }

  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    opacity: 1;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    color: ${theme.palette.common.header.color};
    z-index: 3;
    width: 25px;
    height: 43px;
  }

  .swiper-button-next.swiper-button-disabled,
  .swiper-button-prev.swiper-button-disabled {
    opacity: 1;
  }

  .swiper-button-next.swiper-button-disabled::after,
  .swiper-button-prev.swiper-button-disabled::after {
    color: rgba(255, 255, 255, 0.25);
  }

  .swiper-button-next.swiper-button-lock,
  .swiper-button-prev.swiper-button-lock {
    display: none;
  }

  .dark-block.images-text-block + .dark-block,
  .light-block.images-text-block + .light-block {
    margin-top: 0;
  }

  .images-text-block--sized + .images-text-block--sized {
    padding-top: 0;
  }

  #usercom-widget #usercom-launcher-dot-frame[name] {
    z-index: 1050;
  }

  @media (min-width: 800px) {
    .swiper-button-next,
    .swiper-button-prev {
      display: flex;
    }

    .dark-block.images-text-block:not(.images-text-block__with-bg-image) + .dark-block,
    .light-block.images-text-block:not(.images-text-block__with-bg-image) + .light-block {
      margin-top: -24px;
    }
  }

  /* reset styling for MathJax */

  .CtxtMenu_ContextMenu.CtxtMenu_Menu,
  .CtxtMenu_Info.CtxtMenu_MousePost {
    box-shadow: none;
  }

  @media only screen and (max-width: 800px) {
    .landscape-container .swiper-wrapper {
      height: auto;
    }

    .landscape-container .swiper {
      margin-top: 0;
    }

    .dark-block.images-text-block + .dark-block,
    .light-block.images-text-block + .light-block {
      margin-top: 0;
    }

    .swiper-pagination {
      margin-top: 18px;
    }
  }

  @media only screen and (max-width: 500px) {
    .light-block + .light-block.cta-btn-block,
    .dark-block + .dark-block.cta-btn-block {
      margin-top: 0;
    }
  }

  @keyframes pulse-white {
    0% {
      transform: scale(1);
    }

    70% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }

  @-webkit-keyframes fu-rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @-moz-keyframes fu-rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @-o-keyframes fu-rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes fu-rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes fu-rotate-backwards {
    0% {
      transform: rotate(360deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  @-moz-keyframes fu-rotate-backwards {
    0% {
      transform: rotate(360deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  @-o-keyframes fu-rotate-backwards {
    0% {
      transform: rotate(360deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  @keyframes fu-rotate-backwards {
    0% {
      transform: rotate(360deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  .wrapperWithStyledTable table:not(.MuiTable-root) {
    width: initial;
    margin: 24px 0;
    border: 1px solid ${alpha(theme.palette.common.violet, 0.33)};
    border-collapse: collapse;
  }

  .wrapperWithStyledTable table:not(.MuiTable-root) td {
    padding: 6px 18px;
    border: 1px solid ${alpha(theme.palette.common.violet, 0.33)};
  }

  //used body to prioritise custom styles over cookiebot styles
  body {
    //general
    #CybotCookiebotDialog {
      background: ${theme.palette.common.violetAnalyticsCard};
    }

    #CybotCookiebotDialog *,
    #CybotCookiebotDialogBodyUnderlay * {
      font-family: ${theme.typography.fontFamily};
    }

    #CybotCookiebotDialog,
    #CybotCookiebotDialogBodyUnderlay {
      & h1,
      & h2,
      & h3,
      & h4,
      & h5,
      & h6,
      & .CybotCookiebotDialogBodyLevelButtonDescription {
        font-family: ${theme.typography.headerFontFamily};
        color: ${theme.palette.common.white};
      }
    }

    #CybotCookiebotDialog h2 {
      font-size: 20px;
      line-height: 1;
      margin-bottom: ${TINY_MARGIN_PX};
    }

    #CybotCookiebotDialog {
      #CybotCookiebotDialogBodyContentText,
      #CybotCookiebotDialogDetailBodyContentTextAbout {
        color: ${theme.palette.common.white};
        font-size: 18px;
        line-height: 1.5;
      }
    }

    #CybotCookiebotDialogBodyContent,
    #CybotCookiebotDialogTabContentDetails,
    #CybotCookiebotDialogDetailBodyContentTextAbout {
      padding: 20px;
    }

    #CybotCookiebotDialogPoweredbyCybot svg {
      fill: white;
    }

    #CybotCookiebotDialogBodyLevelButtonsSelectPane
      label:not([for='CybotCookiebotDialogBodyLevelButtonNecessary'])
      .CybotCookiebotDialogBodyLevelButtonDescription:hover {
      color: white;
    }

    #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails a {
      color: ${theme.palette.common.violet};
      font-weight: normal;
      font-size: 14px;
      &::after {
        margin-top: 2px;
      }
    }

    #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails a::after {
      border-color: ${theme.palette.common.violet};
    }

    #CybotCookiebotDialogHeader,
    #CybotCookiebotDialog .CookieCard,
    #CybotCookiebotDialog .CybotCookiebotScrollContainer,
    #CybotCookiebotDialogBodyLevelButtonsSelectPane {
      border-bottom: 1px solid ${alpha(theme.palette.common.white, 0.13)};
    }

    @media screen and (min-width: 1280px) {
      #CybotCookiebotDialog.CybotEdge .CybotCookiebotDialogContentWrapper {
        align-items: center;
      }
      #CybotCookiebotDialog.CybotEdge .CybotCookiebotDialogBodyBottomWrapper {
        margin-top: ${SMALL_MARGIN_PX};
      }
      #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieTypeIntro {
        padding-left: 32px;
        font-size: 18px;
        color: ${theme.palette.common.white};
        line-height: 1.5;
      }
      #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
        .CybotCookiebotDialogDetailBodyContentCookieTypeTableContainer,
      #CybotCookiebotDialogDetailBodyContentIABv2Tabs .CollapseCard {
        margin-left: 32px;
        margin-top: ${SMALL_MARGIN_PX};
      }
      #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
        .CybotCookiebotDialogDetailBodyContentCookieInfo.CybotCookiebotDialogShow:first-of-type {
        margin-top: ${SMALL_MARGIN_PX};
      }
      #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
        .CybotCookiebotDialogDetailBodyContentCookieGroup,
      #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
        .CybotCookiebotDialogDetailBodyContentCookieInfo,
      #CybotCookiebotDialogDetailBulkConsent,
      #CybotCookiebotDialogDetailBulkConsentListWrapper {
        padding: ${SUBMIDDLE_MARGIN_PX};
      }
      #CybotCookiebotDialogDetailBodyContentCookieContainerTypes {
        padding: 0;
      }
    }

    @media screen and (min-width: 601px) {
      #CybotCookiebotDialogBodyLevelButtonsSelectPane
        .CybotCookiebotDialogBodyLevelButtonWrapper:not(:last-of-type) {
        border-right: 1px solid ${alpha(theme.palette.common.white, 0.33)};
      }
    }

    #CybotCookiebotDialogNav {
      border-bottom: 0;
    }

    #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink,
    #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover {
      color: ${alpha(theme.palette.common.white, 0.33)};
      border-bottom: 3px solid ${alpha(theme.palette.common.violet, 0.3)};
      font-size: 20px;
    }

    #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
      color: ${theme.palette.common.white};
      border-width: 3px;
      border-color: ${theme.palette.common.violet};
    }

    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
      .CybotCookiebotDialogDetailBodyContentCookieInfoFooter {
      border-top: 1px solid ${alpha(theme.palette.common.white, 0.13)};
    }

    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
      .CybotCookiebotDialogDetailBodyContentCookieInfo.CybotCookiebotDialogShow:first-of-type:before {
      border-top: none;
    }

    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
      .CybotCookiebotDialogDetailBodyContentCookieInfo,
    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
      .CybotCookiebotDialogDetailBodyContentCookieGroup {
      border: none;
      margin-bottom: ${SMALL_MARGIN_PX};
    }
    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
      .CybotCookiebotDialogDetailBodyContentCookieGroup {
      &,
      &:hover,
      &:not(.open):hover {
        background: ${theme.palette.common.blackSecondary};
      }
    }
    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
      .CybotCookiebotDialogDetailBodyContentCookieInfo {
      &,
      &:hover,
      &:not(.open):hover {
        background: ${theme.palette.common.blueDark};
      }
    }

    .CybotCookiebotFader {
      background-image: linear-gradient(
        to top,
        ${theme.palette.common.violetAnalyticsCard},
        ${alpha(theme.palette.common.violetAnalyticsCard, 0.8)},
        transparent
      ) !important;
    }

    #CybotCookiebotDialogDetailBodyContentTextAbout,
    #CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieTypeIntro,
    #CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieInfoFooterContent {
      color: ${theme.palette.common.text.secondaryAccent};
    }

    #CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieInfoFooterContent > b,
    #CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieInfoDescription {
      color: ${theme.palette.common.white};
    }

    #CybotCookiebotDialogDetailBodyContentTextAbout,
    #CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieTypeIntro,
    #CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieInfoDescription,
    #CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieInfoFooterContent {
      font-size: 18px;
      line-height: 1.5;
    }

    #CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieInfoTitle,
    #CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieInfoFooterContent b,
    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
      .CybotCookiebotDialogDetailBodyContentCookieProvider:not(
        .CybotCookiebotDialogDetailBodyContentCookieInfoCount
      ) {
      &,
      &:hover,
      & > div:not(.CybotCookiebotDialogDetailBodyContentCookieInfoCount) {
        color: ${theme.palette.common.white};
        font-size: 20px;
        line-height: 1;
      }
    }

    #CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a,
    #CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonIABHeaderViewPartnersLink,
    #CybotCookiebotDialog #CybotCookiebotDialogDetailBulkConsentList dt a,
    #CybotCookiebotDialog #CybotCookiebotDialogDetailFooter a,
    #CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonIABDescription a,
    #CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieLink,
    #CybotCookiebotDialogDetailBodyContentTextAbout a,
    CybotExternalLinkArrow {
      color: ${theme.palette.common.violet};
    }

    #CybotCookiebotDialog .CybotExternalLinkArrow {
      display: none;
    }

    #CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieProvider {
      &::after,
      &:hover::after {
        border-color: ${theme.palette.common.violet};
      }

      & .CybotCookiebotDialogDetailBodyContentCookieInfoCount,
      &:hover .CybotCookiebotDialogDetailBodyContentCookieInfoCount {
        background: ${theme.palette.common.card.overlay.contrast};
        color: ${theme.palette.common.violet};
        font-size: 12px;
      }
    }

    .CybotCookiebotDialogDetailBodyContentCookieContainerButton:before,
    .CybotCookiebotDialogDetailBodyContentIABv2Tab:before {
      top: 0.6rem;
    }

    #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton {
      &,
      &:hover,
      & > label {
        color: ${theme.palette.common.white};
        font-size: 20px;
        line-height: 1;
      }

      &::before {
        border-color: ${theme.palette.common.violet};
        margin-right: ${TINY_MARGIN_PX};
      }

      & > span,
      &:hover > span {
        font-size: 12px;
        background: ${theme.palette.common.card.overlay.contrast};
        color: ${theme.palette.common.violetSecondary};
      }

      &:hover {
        &::before {
          border-color: ${theme.palette.common.violet};
        }

        & > label {
          color: ${theme.palette.common.white};
        }
      }
    }

    #CybotCookiebotDialog #CybotCookiebotDialogDetailBulkConsentLink {
      &,
      &:hover {
        color: ${theme.palette.common.white};
        font-size: 20px;
        line-height: 1;
        margin-bottom: ${SMALL_MARGIN_PX};
      }

      & > span,
      &:hover > span {
        background: ${theme.palette.common.card.overlay.contrast};
        color: ${theme.palette.common.violet};
        font-size: 12px;
      }

      &::after,
      &:hover::after {
        border-color: ${theme.palette.common.violet};
      }
    }

    #CybotCookiebotDialog .CybotCookiebotDialogDetailBulkConsentDescription {
      font-size: 18px;
      line-height: 1.5;
      color: ${theme.palette.common.white};
    }

    #CybotCookiebotDialog #CybotCookiebotDialogDetailBulkConsent {
      background: ${theme.palette.common.blackSecondary};

      #CybotCookiebotDialogDetailBulkConsentListWrapper {
        background: ${theme.palette.common.blueDark};
        & > span {
          font-size: 20px;
          line-height: 1;
          padding-bottom: ${SMALL_MARGIN_PX};
        }
        & dt > a {
          font-size: 18px;
          line-height: 1.5;
        }
      }

      &,
      & #CybotCookiebotDialogDetailBulkConsentListWrapper {
        border: none;
      }

      & #CybotCookiebotDialogDetailBulkConsentListWrapper span {
        border-bottom: 1px solid ${alpha(theme.palette.common.white, 0.13)};
        color: ${theme.palette.common.white};
      }
    }

    #CybotCookiebotDialog #CybotCookiebotDialogDetailFooter {
      color: ${theme.palette.common.white};
      &,
      & a {
        font-size: 18px;
        line-height: 1.5;
      }
    }

    #CybotCookiebotDialogPoweredByText a {
      color: ${theme.palette.common.white};
    }

    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes
      .CybotCookiebotDialogDetailBodyEmptyCategoryMessage {
      border: none;
      background: ${theme.palette.common.blackSecondary};
      font-size: 18px;
      color: #ffffff;
      line-height: 1.5;
      font-weight: normal;
    }

    //switcher

    #CybotCookiebotDialog {
      .CybotCookiebotDialogBodyLevelButtonSlider {
        background-color: ${theme.palette.common.card.overlay.contrast};
      }

      .CybotCookiebotDialogBodyLevelButtonSlider:before {
        transition: all 0.3s ease 0s;
        background: ${alpha(theme.palette.common.white, 0.3)};
      }

      input:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
        background-color: ${theme.palette.common.card.overlay.contrast};
      }

      input:checked + .CybotCookiebotDialogBodyLevelButtonSlider:before {
        background-color: ${theme.palette.common.primary};
      }

      .CybotCookiebotDialogBodyLevelButtonSliderWrapper:hover
        input[type='checkbox']:not(:disabled)
        + .CybotCookiebotDialogBodyLevelButtonSlider {
        opacity: 1;
      }

      form input[type='checkbox'][disabled]:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
        background-color: ${theme.palette.common.card.overlay.contrast};
        opacity: 0.5;
      }
    }

    //buttons

    #CybotCookiebotDialogFooter {
      #CybotCookiebotDialogBodyButtonAccept,
      #CybotCookiebotDialogBodyLevelButtonAccept,
      #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
      .CybotCookiebotDialogBodyButton {
        font-size: 16px;
        border-radius: 50px;
        border: 3px solid ${theme.palette.common.primary};
        background: transparent;
        color: ${theme.palette.common.white};
        padding: 12px 29px;
        text-transform: uppercase;
        transition: all 0.3s ease;
        line-height: 1;

        &:hover {
          color: ${theme.palette.common.card.overlay.contrast};
          background: ${theme.palette.common.primary};
        }

        & .CybotCookiebotDialogArrow {
          margin-bottom: 3px;
          transition: border-color 0.3s ease;
        }
      }

      #CybotCookiebotDialogBodyButtonDecline,
      #CybotCookiebotDialogBodyLevelButtonCustomize,
      #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
        border: 3px solid ${theme.palette.common.white};
        background: transparent;

        &:hover {
          background: ${theme.palette.common.white};
          color: ${theme.palette.common.card.overlay.contrast};
          & .CybotCookiebotDialogArrow {
            border-color: ${theme.palette.common.card.overlay.contrast};
          }
        }
      }
    }

    //scrollbar

    #CybotCookiebotDialog {
      .CybotCookiebotScrollContainer {
        .CybotCookiebotScrollbarContainer {
          display: none;
        }

        & > div:first-of-type {
          &::-webkit-scrollbar-thumb {
            background: ${alpha(theme.palette.common.blue, 0.33)};
          }

          ::-webkit-scrollbar-track {
            background: transparent;
            width: 8px;
          }
        }
      }
    }

    #CookiebotWidget {
      * {
        font-family: ${theme.typography.fontFamily};
      }
      #CookiebotWidget-widgetContent,
      .CookiebotWidget-consent-details .CookiebotWidget-consent-details-box {
        background: ${theme.palette.common.violetAnalyticsCard};
      }
      .CookiebotWidget-state,
      .CookiebotWidget-header strong,
      .CookiebotWidget-consent-details .CookiebotWidget-consent-details-box > strong {
        font-family: ${theme.typography.headerFontFamily};
        color: ${theme.palette.common.white};
        line-height: 1.2;
      }
      .CookiebotWidget-body .CookiebotWidget-consents-list .CookiebotWidget-state {
        font-size: 20px;
        margin-bottom: 18px;
      }
      .CookiebotWidget-consent-details .CookiebotWidget-consent-details-box {
        padding: ${SMALL_MARGIN_PX} ${SUBMIDDLE_MARGIN_PX};
        & > strong,
        & > div {
          font-size: 18px;
        }
        & > div {
          color: ${theme.palette.common.text.secondaryAccent};
          margin-bottom: ${SMALL_MARGIN_PX};
        }
      }
      .CookiebotWidget-header {
        border-bottom: 1px solid ${alpha(theme.palette.common.white, 0.13)};
        padding: ${SMALL_MARGIN_PX} ${SUBMIDDLE_MARGIN_PX};
        strong {
          font-size: 28px;
        }
        .CookiebotWidget-close {
          width: 24px;
          height: 24px;
          margin-top: 4px;
          svg {
            fill: ${theme.palette.common.white};
          }
        }
      }
      #CookiebotWidget-buttons {
        border-top: 1px solid ${alpha(theme.palette.common.white, 0.13)};
      }
      .CookiebotWidget-body .CookiebotWidget-consents-list li,
      .CookiebotWidget-date,
      .CookiebotWidget-consent-id {
        color: ${theme.palette.common.white};
        font-size: 18px;
      }
      .CookiebotWidget-body .CookiebotWidget-consents-list li {
        margin-bottom: ${SMALL_MARGIN_PX};
      }
      .CookiebotWidget-body .CookiebotWidget-consents-list li svg,
      .CookiebotWidget-body .CookiebotWidget-consents-list li.CookiebotWidget-approved svg {
        fill: ${theme.palette.common.violet};
      }
      .CookiebotWidget-consent-details button {
        color: ${theme.palette.common.violet};
        font-size: 18px;
        line-height: 1;
        & svg {
          fill: ${theme.palette.common.violet};
        }
      }
      .CookiebotWidget-body .CookiebotWidget-main-logo svg {
        fill: ${theme.palette.common.white};
      }
      .CybotCookiebotScrollbarContainer {
        display: none;
      }
      #CookiebotWidget-buttons button {
        font-size: 16px;
        border-radius: 50px;
        background: transparent;
        color: ${theme.palette.common.white};
        padding: 12px 29px;
        text-transform: uppercase;
        transition: all 0.3s ease;
        line-height: 1;

        &#CookiebotWidget-btn-change {
          background: transparent;
          border: 3px solid ${theme.palette.common.primary};
          color: ${theme.palette.common.white};
          &:hover {
            color: ${theme.palette.common.card.overlay.contrast};
            background: ${theme.palette.common.primary};
          }
        }
        &#CookiebotWidget-btn-withdraw {
          background: transparent;
          border: 3px solid ${theme.palette.common.white};
          color: ${theme.palette.common.white};
          &:hover:not(:disabled) {
            color: ${theme.palette.common.card.overlay.contrast};
            background: ${theme.palette.common.white};
            color: ${theme.palette.common.card.overlay.contrast};
          }
          &:disabled {
            border: 3px solid ${theme.palette.common.white};
            color: ${theme.palette.common.white};
          }
        }
      }
      .CookiebotWidget-body-inner {
        &::-webkit-scrollbar-thumb {
          background: ${alpha(theme.palette.common.blue, 0.33)};
        }

        ::-webkit-scrollbar-track {
          background: transparent;
        }
      }
      .CookiebotWidget-body-inner {
        padding: 12px 20px;
      }
      #CookiebotWidget-buttons {
        padding: ${SMALL_MARGIN_PX} ${SUBMIDDLE_MARGIN_PX};
      }
      @media screen and (min-width: 601px) {
        .CookiebotWidget-body-inner {
          padding: 12px 20px;
        }
        #CookiebotWidget-buttons {
          padding: ${SMALL_MARGIN_PX} ${SUBMIDDLE_MARGIN_PX};
        }
      }
    }
  }

  #consentDeclarationWrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 16px;
    padding-right: 16px;
    .CookieDeclaration {
      p,
      #CookieDeclarationUserStatusLabelOn,
      #CookieDeclarationUserStatusLabelOff,
      #CookieDeclarationUserStatusLabelMulti,
      .CookieDeclarationTableCell {
        color: ${theme.palette.common.gray};
        font-size: 18px;
      }
      .CookieDeclarationTypeHeader {
        font-size: 20px;
        color: ${theme.palette.common.white};
        margin-bottom: ${SMALL_MARGIN_PX};
        margin-top: ${SMALL_MARGIN_PX};
      }
      a {
        color: ${theme.palette.common.violet};
        font-size: 18px;
      }
      .CookieDeclarationType {
        border: none;
        padding: 0;
      }
      .CookieDeclarationTableHeader {
        font-size: 18px;
      }
      .CookieDeclarationTableHeader,
      .CookieDeclarationTableCell {
        border-bottom: 1px solid ${alpha(theme.palette.common.white, 0.13)};
      }
      #CookieDeclarationUserStatusPanel {
        background: ${theme.palette.common.violetAnalyticsCard};
        padding: ${SUBMIDDLE_MARGIN_PX};
        border-radius: ${MIDDLE_RADIUS_PX};
      }
      ${theme.breakpoints.down('xl')} {
        .CookieDeclarationTable {
          display: block;
          overflow-x: scroll;
          width: 100%;
        }
      }
    }
  }
`;

export const scrollbar = ({ theme }) => css`
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 18px 18px ${alpha(theme.palette.common.violetAnalyticsCard, 0.33)};
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    box-shadow: inset 0 0 18px 18px ${theme.palette.common.violetAnalyticsCard};
  }
`;

export default globalStyles;
