import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { GlobalStyles, PaletteMode } from '@mui/material';
import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';

import { MODE_COOKIE_KEY } from '../../const';
import useIsSsku from '../../hooks/tenant/useIsSsku';
import useIsDeviceInPortraitMode from '../../hooks/useIsDeviceInPortraitMode';
import globalStyles from '../../styles';
import { defaultTenantTheme, santanderTheme, TenantTheme, tenantThemes } from '../../theme';
import { checkConsent } from '../../utils/checkConsent';
import Cookies from '../../utils/cookies';
import assertNever from '../../utils/errors/assertNever';
import { TenantsRefs } from '../../utils/tenantsConfig';
import SsrContext from '../SsrContext';

const StyledEngineProviderOnlyForDevMode = ({ children }) =>
  process.env.NODE_ENV === 'development' ? (
    <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>
  ) : (
    children
  );

export const DEFAULT_THEME_MODE = 'dark';
const noOp = () => {};
export const ThemeCtx = createContext<{
  isDeviceInPortraitMode: boolean;
  mode: PaletteMode;
  toggleColorMode: () => void;
}>({
  isDeviceInPortraitMode: false,
  mode: DEFAULT_THEME_MODE,
  toggleColorMode: noOp,
});

const isValidMode = (mode: string | null) => mode === 'light' || mode === 'dark';
export const getTenantTheme = (tenantName: TenantsRefs) => {
  switch (tenantName) {
    case TenantsRefs.Fu:
    case TenantsRefs.Su:
    case TenantsRefs.Em:
    case TenantsRefs.Dsu:
    case TenantsRefs.Xunl:
    case TenantsRefs.Bpp:
    case TenantsRefs.Dukece:
      return defaultTenantTheme;
    case TenantsRefs.Ssku:
      return santanderTheme;
    default:
      return assertNever(tenantName);
  }
};

interface IThemeProvider {
  children: ReactNode;
  initialTenantTheme?: TenantTheme;
  initialMode?: PaletteMode;
  hasGlobalStyles?: boolean;
}

const ThemeProvider: FC<IThemeProvider> = ({
  children,
  initialTenantTheme,
  initialMode,
  hasGlobalStyles = true,
}) => {
  const { tenantTheme: tenantThemeFromSsr, tenantThemeMode: modeFromSsr } = useContext(SsrContext);
  const isSsku = useIsSsku();
  const location = useLocation();
  const [mode, setMode] = React.useState<PaletteMode>(
    modeFromSsr ||
      initialMode ||
      (isSsku ? Cookies.get(MODE_COOKIE_KEY) || DEFAULT_THEME_MODE : DEFAULT_THEME_MODE),
  );
  const fallbackTenantTheme = isSsku ? santanderTheme : defaultTenantTheme;
  const [selectedTenantTheme, setSelectedTenantTheme] = useState<TenantTheme>(
    tenantThemeFromSsr || initialTenantTheme || fallbackTenantTheme,
  );
  const theme = selectedTenantTheme[mode];
  const isDeviceInPortraitMode = useIsDeviceInPortraitMode();

  const memoizedValue = useMemo(
    () => ({
      isDeviceInPortraitMode,
      mode,
      toggleColorMode: () => setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light')),
    }),
    [setMode, mode, isDeviceInPortraitMode],
  );

  useEffect(() => {
    checkConsent(MODE_COOKIE_KEY) && Cookies.set(MODE_COOKIE_KEY, mode);
  }, [mode]);

  useEffect(() => {
    if (!location.search) return;

    const searchParams = new URLSearchParams(location.search);
    const themeIndexParam = searchParams.get('themeIndex');

    if (!themeIndexParam) {
      return;
    }

    const themeIndex = Number(themeIndexParam);

    if (!Number.isInteger(themeIndex)) {
      return;
    }

    const tenantTheme = tenantThemes[themeIndex];

    if (!tenantTheme) {
      return;
    }

    const modeFromParams = searchParams.get('mode');
    const sanitizedMode = (
      isValidMode(modeFromParams) ? modeFromParams : DEFAULT_THEME_MODE
    ) as PaletteMode;

    setSelectedTenantTheme(tenantTheme);
    setMode(sanitizedMode);
  }, [setSelectedTenantTheme, setMode, location.search]);

  const memoizedGlobalStyles = useMemo(() => globalStyles(theme), [theme]);

  return (
    <StyledEngineProviderOnlyForDevMode>
      <MuiThemeProvider theme={theme}>
        <ThemeCtx.Provider value={memoizedValue}>
          {hasGlobalStyles && <GlobalStyles styles={memoizedGlobalStyles} />}
          {children}
        </ThemeCtx.Provider>
      </MuiThemeProvider>
    </StyledEngineProviderOnlyForDevMode>
  );
};

export default ThemeProvider;
