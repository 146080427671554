import React from 'react';

import { Typography } from '@mui/material';

import styled from '../../../../styled';
import { SMALL_MARGIN_PX } from '../../../../theme';

interface CardHeaderProps {
  text: string;
  className?: string;
  onClick?: () => {};
}

const CardTitle: React.FC<CardHeaderProps> = ({ text, className, onClick }) => {
  return (
    <Header variant="h3" className={className} onClick={() => onClick && onClick()}>
      {text}
    </Header>
  );
};

export default CardTitle;

const Header = styled(Typography)`
  & {
    font-size: 18px;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: ${SMALL_MARGIN_PX};

    ${({ theme }) => theme.breakpoints.up('md')} {
      font-size: 20px;
    }
  }
`;
