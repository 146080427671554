import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { DEFAULT_LOCALE } from './const';
import { getLoadPath } from './utils/translationUtils';

const loadPath = getLoadPath();

export const availableLangs = [DEFAULT_LOCALE, 'es', 'pt'] as const;
export type Language = (typeof availableLangs)[number];

export const i18nConfig = {
  lng: window?.__LANG_CODE__ || DEFAULT_LOCALE,
  fallbackLng: DEFAULT_LOCALE, // lang that will be used in case detected lang is not defined
  interpolation: {
    escapeValue: false,
    skipOnVariables: false,
  },
  parseMissingKeyHandler: () => '',
  returnedObjectHandler: () => '',
};

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    ...i18nConfig,
    backend: {
      loadPath,
    },
  });

export default i18n;
