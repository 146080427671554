import React from 'react';

import { Helmet } from 'react-helmet';

const JSBlock = ({ data }) => {
  const jsCode = data?.js_code;
  const type = data?.customScriptType || 'application/javascript';

  return (
    <Helmet>
      <script type={type}>{jsCode}</script>
    </Helmet>
  );
};

export default JSBlock;
