import React, { FC, useMemo } from 'react';

import styled from '../styled';
import AnimatedSkeleton from './AnimatedSkeleton';

interface IAnimatedSkeletonMultiline {
  lines: number;
  lastLineWidth?: number;
  className?: string;
  spacer: string;
  [key: string]: any;
}

const AnimatedSkeletonMultiline: FC<IAnimatedSkeletonMultiline> = ({
  lines,
  lastLineWidth = 40,
  className,
  spacer,
  ...rest
}) => {
  const arr = useMemo(() => new Array(lines).fill(null), [lines]);

  return (
    <div className={className}>
      {arr.map((line, index) => (
        <StyledSkeleton
          key={index}
          w={index === arr.length - 1 ? lastLineWidth : 100}
          mb={index < arr.length - 1 && spacer}
          {...rest}
        />
      ))}
    </div>
  );
};

export default AnimatedSkeletonMultiline;

const StyledSkeleton = styled(AnimatedSkeleton, {
  shouldForwardProp: (prop) => prop !== 'w' && prop !== 'mb',
})`
  width: ${({ w }) => w}%;
  margin-bottom: ${({ mb }) => !!mb && mb};
`;
