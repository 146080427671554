import React from 'react';

import { Trans as I18nTrans } from 'react-i18next';

import useTenantTranslation from '../hooks/useTenantTranslation';
import { OTHER_TENANTS } from '../utils/translations/translateByTenant';

const Trans = ({ i18nKey, ...rest }) => {
  const { t } = useTenantTranslation();
  const tenantSpecificKey = `${i18nKey}.${OTHER_TENANTS}`;

  if (t(tenantSpecificKey)) {
    return <I18nTrans i18nKey={tenantSpecificKey} {...rest} />;
  }

  return <I18nTrans i18nKey={i18nKey} {...rest} />;
};

export default Trans;
