export const replaceQuotes = (str: string) => str.replace(/"/g, '&#34;');

export const parseHighlightedText = (
  string,
  formatter = (match: string) => `<span>${match.slice(2, -2)}</span>`,
) => {
  if (string) {
    const pattern = new RegExp(/\*\*(?:(?!\*\*).)+\*\*/g);
    return string.replace(pattern, (match) => formatter(match));
  } else {
    return null;
  }
};

export const generateRandomString = (length: number): string => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;

  const result: string[] = [];

  for (let i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  return result.join('');
};

export const splitBySpace = (val) => (val ? val.split(' ') : []);
