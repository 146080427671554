import React from 'react';

import { Element, Svg, useSvgColor } from './styles';

const OnlineSmallIcon = ({ color = '', ...props }) => {
  const svgColor = useSvgColor(color);

  return (
    <Svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}
      svgColor={svgColor}
    >
      <Element d="M21.18006,8H15.66C15.04,4.48,13.64,2,12,2A10.0575,10.0575,0,0,1,21.18006,8Z" />
      <Element d="M12,2C10.36,2,8.96009,4.48,8.34009,8h-5.52A10.0572,10.0572,0,0,1,12,2Z" />
      <Element d="M8,12a22.75782,22.75782,0,0,0,.34009,4h-5.52a10.1653,10.1653,0,0,1,0-8h5.52A22.75681,22.75681,0,0,0,8,12Z" />
      <Element d="M12,22a10.0572,10.0572,0,0,1-9.17993-6h5.52C8.96009,19.52,10.36,22,12,22Z" />
      <Element d="M22,12a10.21241,10.21241,0,0,1-.81994,4H15.66A22.76973,22.76973,0,0,0,16,12a22.76872,22.76872,0,0,0-.34-4h5.52A10.212,10.212,0,0,1,22,12Z" />
      <Element d="M21.18006,16A10.0575,10.0575,0,0,1,12,22c1.64,0,3.04-2.48,3.66-6Z" />
      <Element d="M15.66,16c-.62,3.52-2.02,6-3.66,6s-3.03991-2.48-3.65991-6Z" />
      <Element d="M16,12a22.76973,22.76973,0,0,1-.34,4H8.34009A22.75782,22.75782,0,0,1,8,12a22.75681,22.75681,0,0,1,.34009-4h7.32A22.76872,22.76872,0,0,1,16,12Z" />
      <Element d="M15.66,8H8.34009C8.96009,4.48,10.36,2,12,2S15.04,4.48,15.66,8Z" />
    </Svg>
  );
};

export default OnlineSmallIcon;
