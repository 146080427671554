import React, { createContext, useCallback, useState, useContext } from 'react';

export interface IOnlineContentConnector {
  userGuid?: string;
  sessionToken?: string;
  callbackUrl?: string;
  fqdn?: string;
  subdomain?: string;
  registrationNumber?: string;
  source?: string;
  withNavigation?: boolean;
  nocc?: boolean;
  withoutNavigation?: boolean;
}

export interface OnlineContentConnectorCtx extends IOnlineContentConnector {
  updateData: (data: IOnlineContentConnector) => void;
}

const STATE = {
  updateData: () => {},
};

export const ONLINE_CONTENT_CONNECTOR_ATTRIBUTES = [
  'userGuid',
  'sessionToken',
  'callbackUrl',
  'fqdn',
  'subdomain',
  'registrationNumber',
  'source',
  'withNavigation',
  //used to disable sending progress events to OCC
  'nocc',
];

export const OnlineContentConnectorContext = createContext<OnlineContentConnectorCtx>(STATE);

export const useOnlineContentConnector = () => useContext(OnlineContentConnectorContext);
export const useIsOLCO = () => {
  const { userGuid, withNavigation } = useOnlineContentConnector();

  // checking connection is it online content connector
  return {
    isOLCO: Boolean(userGuid),
    isOLCOWithoutNavigation: Boolean(userGuid) && !withNavigation,
  };
};
export const useWithoutNavigation = () => {
  const { withoutNavigation } = useOnlineContentConnector();
  const { isOLCOWithoutNavigation } = useIsOLCO();
  return isOLCOWithoutNavigation || withoutNavigation;
};

export const OnlineContentConnectorController = ({ children }) => {
  const [data, setData] = useState<IOnlineContentConnector>({});

  const updateData = useCallback((data: IOnlineContentConnector) => {
    setData((prevState) => ({ ...data, ...prevState }));
  }, []);

  return (
    <OnlineContentConnectorContext.Provider
      value={{
        ...data,
        updateData,
      }}
    >
      {children}
    </OnlineContentConnectorContext.Provider>
  );
};
