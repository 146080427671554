import React, { FC } from 'react';

import { useTheme } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import CheckIcon from '../../../assets/icons/CheckIcon';
import ChevronRightIcon from '../../../assets/icons/ChevronRightIcon';
import ConditionalBlockLink from '../../../components/ConditionalBlockLink/ConditionalBlockLink';
import { IKImage } from '../../../components/IKImage';
import { MetaText } from '../../../components/UI/Texts';
import useTenantTranslation from '../../../hooks/useTenantTranslation';
import { MIDDLE_RADIUS_PX, SMALL_MARGIN_PX, TINY_MARGIN_PX } from '../../../theme';
import { Maybe } from '../../../types';

const SRCSET = [{ width: 32 }];

interface DarkCardProps {
  title?: string | null;
  subtitle?: string | null;
  iconUrl?: string | null;
  link?: string | null;
  listItems?: Maybe<string>[];
  description?: Maybe<string>;
  'data-testid'?: string;
}

const DarkCard: FC<DarkCardProps> = ({
  title,
  subtitle,
  iconUrl,
  link,
  listItems,
  description,
  ...rest
}) => {
  const theme = useTheme();
  const { t } = useTenantTranslation();

  return (
    <Wrapper data-testid={rest['data-testid']} withLink={Boolean(link)} to={link || undefined}>
      <CardHeader>
        {iconUrl && <Icon srcset={SRCSET} sizes="100vw" path={iconUrl} />}
        <TextWrapper>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </TextWrapper>
      </CardHeader>
      {description && <Description>{description}</Description>}
      {listItems && (
        <>
          <ListItems>
            {listItems.map((item, index) => (
              <ListItem key={index}>
                <StyledCheckIcon color={theme.palette.common.violet} />
                <MetaText>{item}</MetaText>
              </ListItem>
            ))}
          </ListItems>
          {link && (
            <CardSubLabel>
              <CardSubLabelText>{t('button.moreDetails')}</CardSubLabelText>
              <StyledIcon color={theme.palette.common.tabs.indicator} />
            </CardSubLabel>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default DarkCard;

const Wrapper = styled(ConditionalBlockLink)`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.common.blueDark};
  border-radius: ${MIDDLE_RADIUS_PX};
  cursor: ${({ withLink }) => (withLink ? 'pointer' : 'default')};
  padding: ${SMALL_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    background: ${({ theme }) => theme.palette.common.blockBackground.main};
  }
`;

const Icon = styled(IKImage)`
  width: 32px;
  height: 32px;
  margin-right: ${TINY_MARGIN_PX};
`;

const TextWrapper = styled('div')``;

const Title = styled(MetaText)`
  color: ${({ theme }) => theme.palette.common.white};
  font-weight: bold;
`;

const Subtitle = styled(MetaText)`
  color: ${({ theme }) =>
    theme.isSsku
      ? theme.palette.common.text.secondaryToPrimary
      : alpha(theme.palette.common.white, 0.33)};
`;

const CardHeader = styled('div')`
  display: flex;
`;
const ListItems = styled('ul')`
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  gap: ${TINY_MARGIN_PX};
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin: 0 0 ${SMALL_MARGIN_PX} 0;
  }
`;
const ListItem = styled('li')`
  display: flex;
  gap: 16px;
`;
const StyledCheckIcon = styled(CheckIcon)`
  min-width: 16px;
  height: 16px;
`;
const Description = styled(MetaText)`
  color: ${({ theme }) => theme.palette.common.white};
  margin: ${SMALL_MARGIN_PX} 0;
`;
const CardSubLabel = styled('div')`
  display: flex;
  align-items: center;
`;
const CardSubLabelText = styled(MetaText)`
  color: ${({ theme }) => theme.palette.common.tabs.indicator};
  margin-right: ${TINY_MARGIN_PX};
`;
const StyledIcon = styled(ChevronRightIcon)`
  width: 16px;
  height: 16px;
`;
