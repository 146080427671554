import React from 'react';

import { styled } from '@mui/material/styles';

import { Element } from './styles';

const ExpandPipIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Element
        d="M23 12V5C23 3.89543 22.015 3 20.8 3H3.2C1.98497 3 1 3.89543 1 5V18C1 19.1046 1.98497 20 3.2 20H13"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <FilledElement d="M14.5 6L4.5 6.00007C4.22386 6.00008 4 6.22393 4 6.50007L4.00001 12.5C4.00001 12.7761 4.22387 13 4.50001 13H14.5C14.7761 13 15 12.7761 15 12.5V6.5C15 6.22386 14.7761 6 14.5 6Z" />
      <Element
        d="M18 17L22.7407 17M18 17L18 21.8148M18 17L21.3333 20.3333L23 22"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExpandPipIcon;

const FilledElement = styled(Element)`
  fill: currentColor;
  stroke: none;
`;
