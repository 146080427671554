import React, { FC, useContext, useEffect } from 'react';

import { Loader } from '../../components/Loader';
import { TenantCtx } from '../../context/TenantProvider';
import useAuth from '../../hooks/useAuth';

const ResetPasswordPage: FC = () => {
  const { loginWithRedirect, isInitialized } = useAuth();
  const { tenant, config } = useContext(TenantCtx);

  useEffect(() => {
    if (isInitialized) {
      loginWithRedirect({
        redirectUri: config?.appMainPage,
        mode: 'forgotPassword',
        isDirectUrl: true,
      });
    }
  }, [isInitialized, loginWithRedirect, tenant, config]);

  return <Loader />;
};

export default ResetPasswordPage;
