import { useEffect } from 'react';

const useOverflowHidden = (condition) => {
  useEffect(() => {
    if (condition) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.removeAttribute('style');
    }
  }, [condition]);
};

export default useOverflowHidden;
