import React, { FC } from 'react';

import { Switch } from '../../../../components/Switch';
import { H3 } from '../../../../components/UI/Texts';
import useTenantTranslation from '../../../../hooks/useTenantTranslation';
import styled, { CssUtilityType } from '../../../../styled';
import { Label, SwitchWrapper } from './styles';

interface ISwitcherWithTitle {
  id?: string;
  handleClick: () => void;
  className?: string;
  wrapperCss?: CssUtilityType;
  title?: string;
  disabled?: boolean;
  active: boolean;
}

const SwitcherWithTitle: FC<ISwitcherWithTitle> = ({
  id,
  handleClick,
  className = '',
  wrapperCss,
  title,
  disabled = false,
  active,
}) => {
  const { t } = useTenantTranslation();

  return (
    <Wrapper className={className} optionalStyles={wrapperCss}>
      {title && <Title variant="h3">{title}:</Title>}
      <SwitchWrapper disabled={disabled}>
        <Label disabled={disabled} onClick={!disabled ? handleClick : undefined}>
          {t('common.off')}
        </Label>
        <Switch id={id} value={active} onChange={!disabled ? handleClick : undefined} onOff />
        <Label disabled={disabled} active onClick={!disabled ? handleClick : undefined}>
          {t('common.on')}
        </Label>
      </SwitchWrapper>
    </Wrapper>
  );
};

export default SwitcherWithTitle;

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})`
  display: flex;
  align-items: center;
  opacity: ${({ disabled }) => disabled && 0.5};

  ${({ optionalStyles }) => optionalStyles}
`;
const Title = styled(H3)`
  && {
    font-size: 18px;
    margin-bottom: 0;

    ${({ theme }) => theme.breakpoints.up('md')} {
      font-size: 20px;
    }
  }
`;
