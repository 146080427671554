import React, { ReactNode } from 'react';

import { SerializedStyles } from '@emotion/react';
import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';

import styled, { css } from '../../../../styled';
import { BREAKPOINTS_VALUES, SMALL_MARGIN_PX } from '../../../../theme';
import { IKImage } from '../../../IKImage';
import { LazyLoadImage } from '../../../LazyloadImage';

interface CardImgProps {
  imgPath: string;
  wrapperCss?: SerializedStyles | (({ theme }: { theme: Theme }) => SerializedStyles);
  imageCss?: SerializedStyles | (({ theme }: { theme: Theme }) => SerializedStyles);
  withLazyLoad?: boolean;
  onClick?: () => {};
  children?: ReactNode;
}

const CardImg: React.FC<CardImgProps> = ({
  withLazyLoad = false,
  wrapperCss,
  children,
  imgPath,
  imageCss,
  onClick,
  ...rest
}) => {
  return (
    <Wrapper optionalStyles={wrapperCss} onClick={() => onClick && onClick()}>
      {withLazyLoad ? (
        <StyledLazyloadImage
          path={imgPath}
          srcset={[
            { width: 470, height: 320 },
            { width: 770, height: 520 },
            { width: 400, height: 275 },
          ]}
          sizes={`(max-width:${BREAKPOINTS_VALUES['sm']}px) 470px, (max-width:${BREAKPOINTS_VALUES['md']}px) 770px, 400px`}
          optionalStyles={imageCss}
          data-testid={rest['data-testid']}
          wrapperCss={lazyLoadWrapperCss}
        />
      ) : (
        <StyledIKImage
          path={imgPath}
          srcset={[
            { width: 470, height: 320 },
            { width: 770, height: 520 },
            { width: 400, height: 275 },
          ]}
          sizes={`(max-width:${BREAKPOINTS_VALUES['sm']}px) 470px, (max-width:${BREAKPOINTS_VALUES['md']}px) 770px, 400px`}
          optionalStyles={imageCss}
          data-testid={rest['data-testid']}
        />
      )}
      {children}
    </Wrapper>
  );
};

export default CardImg;

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})`
  display: block;
  flex: 1;
  margin-bottom: ${SMALL_MARGIN_PX};
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  height: 195px;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    filter: grayscale(100%);
    pointer-events: none;
  }
  ${({ theme }) => theme.breakpoints.up('sm')} {
    height: 274px;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: 0;
  }
  & {
    ${({ optionalStyles }) => optionalStyles}
  }
`;
const StyledIKImage = styled(IKImage, {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})`
  width: 100%;
  height: 100%;
  object-fit: cover;

  ${({ optionalStyles }) => optionalStyles}
`;
const StyledLazyloadImage = styled(LazyLoadImage, {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})`
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${({ optionalStyles }) => optionalStyles}
`;
const lazyLoadWrapperCss = css`
  width: 100%;
  height: 100%;
`;
