import { useEffect, useRef } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';

/**
 * if anchor === null -> hook will scroll to any hash that defined in url
 * if anchor === string -> hook will scroll only to specific hash
 * */
const useAnchor = (
  anchor: string | null,
  options?: { cb?: () => void; skip?: boolean; duration?: number; delay?: number },
) => {
  const { cb, skip = false, duration = 1000, delay = 150 } = options || {};
  const cbRef = useRef(cb);
  const location = useLocation();
  const urlHash = location?.hash ? location.hash.slice(1) : null;
  const navigate = useNavigate();
  const historyRef = useRef(navigate);
  historyRef.current = navigate;

  useEffect(() => {
    window.HISTORY_PUSH = (to) => {
      historyRef.current(to);
    };
  }, [historyRef]);

  useEffect(() => {
    if (skip) {
      return;
    }

    if (urlHash === anchor || anchor === null) {
      cbRef?.current?.();

      if (urlHash) {
        scroller.scrollTo(urlHash, {
          duration: duration,
          delay: delay,
          smooth: 'easeInOutQuart',
          offset: -80,
        });
      }
    }
  }, [skip, anchor, urlHash, cbRef, duration, delay]);
};

export default useAnchor;
