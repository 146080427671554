import React, { FC } from 'react';

import { useTheme } from '@mui/material';

import { Element, Svg } from './styles';
import { TempColorIconProps } from './types';

const RepeatIcon: FC<TempColorIconProps> = ({ updatedColor, color = '', ...props }) => {
  const theme = useTheme();
  const svgColor = color || theme.palette.common.white;
  return (
    <Svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}
      svgColor={updatedColor || svgColor}
    >
      <Element d="M2.7984,8l.31579-.588A9.99958,9.99958,0,1,1,2.0494,13" />
      <Element as="polyline" points="2 4 2 8 6 8" />
    </Svg>
  );
};

export default RepeatIcon;
