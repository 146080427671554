import { useEffect, useRef } from 'react';

import useScriptLoader, { SCRIPTS } from '../context/ScriptLoader/useScriptLoader';

const useShareTeams = () => {
  const teamsShareRef = useRef<HTMLDivElement | null>(null);
  const { isLoaded: isTeamsScriptLoaded } = useScriptLoader(SCRIPTS.TEAMS_SHARE);

  useEffect(() => {
    if (isTeamsScriptLoaded) {
      //used to call microsoft library for share link generation
      //@ts-ignore
      window?.shareToMicrosoftTeams?.renderButtons && window.shareToMicrosoftTeams.renderButtons();
    }
  }, [isTeamsScriptLoaded]);

  const handleTeamsShare = () => {
    const child = teamsShareRef?.current?.children?.item(0);
    //@ts-ignore
    if (child?.href) {
      //@ts-ignore
      const teamsShareLink = child?.href;
      window.open(teamsShareLink, 'Share to Microsoft Teams', 'width=700,height=600');
    }
  };

  return {
    handleTeamsShare,
    teamsShareRef,
  };
};

export default useShareTeams;
