import { styled } from '@mui/material/styles';

export const createDropdownLinkStyles = (component) => styled(component)`
  border: none;
  background: transparent;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.common.white};
  font-weight: bold;
  font-size: 14px;
  padding: 9px 24px;
  width: 100%;
  transition: background 0.3s;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.palette.common.card.lightAccent};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
