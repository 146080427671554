import { PaletteMode, useMediaQuery, useTheme } from '@mui/material';
import { common } from '@mui/material/colors';
import { alpha, Breakpoint, createTheme, css, Theme } from '@mui/material/styles';

import { generateImageKitUrl } from './modules/imageKit';
import { TenantsRefs } from './utils/tenantsConfig';

declare module '@mui/material/styles/createTypography' {
  interface TypographyOptions {
    defaultFontFamily: string;
    headerFontFamily: string;
    specialFontFamily: string;
    fontLink: string;
  }

  interface Typography {
    defaultFontFamily: string;
    headerFontFamily: string;
    specialFontFamily: string;
    fontLink: string;
  }
}

export const BREAKPOINTS_VALUES = {
  xs: 0,
  sm: 500,
  md: 800,
  lg: 1100,
  xl: 1200,
  xxl: 1536,
  ml: 2200,
};

export const RESTRICTED_CONTAINER_WIDTH = 1600;

export const colorsPalette = {
  blackPure: '#000000',
  blackSecondary: '#23202E',
  white: '#ffffff',
  gray: '#C9C4D4',
  blue: '#6869ED',
  blueDark: '#1b1925',
  blueLight: '#20A4F3',
  violet: '#8686f1',
  violetSecondary: '#a2a4ee',
  violetSecondaryMid: '#b8b9fa',
  violetSecondaryLight: '#DCDCFF',
  violetAnalyticsCard: '#2D293B',
  primary: '#7EFCF0',
  pink: '#E5BFEE',
  green: '#86E380',
  failedError: '#F06965',
  yellow: '#E6FB7A',
  coral: '#F45B69',
  linkedInBlue: '#0077B7',
  transparent: 'transparent',
  successOptional: '#86E380',
};

const santanderDarkPalette = {
  blackPure: '#000000',
  blackSecondary: '#71808B',
  white: '#ffffff',
  gray: '#CEDEE7',
  blue: '#CC0000',
  blueDark: '#222222',
  blueLight: '#3366FF',
  violet: '#9BC3D3',
  violetSecondary: '#990000',
  violetSecondaryMid: '#990000',
  violetSecondaryLight: '#990000',
  violetAnalyticsCard: '#6F7779',
  primary: '#EC0001',
  pink: '#23779A',
  green: '#63BA68',
  failedError: '#990000',
  yellow: '#FFCC33',
  coral: '#9E3667',
  linkedInBlue: '#0077B7',
  transparent: 'transparent',
  successOptional: '#63BA68',
  bgOverlay: '#909090',
  softDisabled: '#F0F0F0',
  hover: '#2D2D2D',
  error: '#F67F7F',
  lightGray: '#2D2D2D',
};

const santanderLightPalette = {
  blackPure: '#000000',
  blackSecondary: '#F5F9FB',
  white: '#000000',
  gray: '#6F7779',
  blue: '#9BC3D3',
  blueDark: '#FFFFFF',
  blueLight: '#3366FF',
  violet: '#CC0000',
  violetSecondary: '#990000',
  violetSecondaryMid: '#990000',
  violetSecondaryLight: '#990000',
  violetAnalyticsCard: '#CEDEE7',
  primary: '#EC0001',
  pink: '#23779A',
  green: '#63BA68',
  failedError: '#990000',
  yellow: '#FFCC33',
  coral: '#9E3667',
  linkedInBlue: '#0077B7',
  transparent: 'transparent',
  successOptional: '#008437',
  bgOverlay: '#909090',
  bgStrong: '#8F8F8F',
  blueSky: '#DEEDF2',
  hover: '#F6F6F6',
  error: '#F67F7F',
  santanderDarkPalette,
};

const userAvatar = {
  coral: { background: colorsPalette.coral, color: colorsPalette.blueDark },
  blue: { background: colorsPalette.violet, color: colorsPalette.blueDark },
  blueLight: { background: colorsPalette.blueLight, color: colorsPalette.blueDark },
  yellow: { background: colorsPalette.yellow, color: colorsPalette.blueDark },
  pink: { background: colorsPalette.pink, color: colorsPalette.blueDark },
};

const defaultColorsByAreas = {
  subcategories: colorsPalette.violetAnalyticsCard,
  divider: {
    main: colorsPalette.blue,
    discover: colorsPalette.violet,
  },
  accent: colorsPalette.blue,
  accentFaded: colorsPalette.violet,
  textField: {
    hoverBorder: colorsPalette.blue,
    activeLabel: colorsPalette.blue,
  },
  accentShadow: colorsPalette.blackPure,
  accentMain: colorsPalette.blue,
  accentLight: colorsPalette.violet,
  answerItem: colorsPalette.blackSecondary,
  answerItemBorder: colorsPalette.blue,
  secondaryShadow: colorsPalette.blackPure,
  tooltip: { background: colorsPalette.violetSecondary, color: colorsPalette.white },
  star: colorsPalette.white,
  highlightBg: colorsPalette.blue,
  status: { error: colorsPalette.failedError, brightError: colorsPalette.failedError },
  blockBackground: {
    main: colorsPalette.blueDark,
    lightText: alpha(colorsPalette.gray, 0.3),
    contrast: colorsPalette.blackSecondary,
    light: colorsPalette.blackSecondary,
    lightContrast: colorsPalette.violetAnalyticsCard,
    lightBorder: colorsPalette.blackSecondary,
    accentText: colorsPalette.violetAnalyticsCard,
  },
  text: {
    white: colorsPalette.white,
    gray: colorsPalette.gray,
    violetSecondaryToWhite: colorsPalette.violetSecondary,
    fadedSubtitle: colorsPalette.gray,
    subtitle: colorsPalette.gray,
    secondaryAccent: colorsPalette.gray,
    secondaryToPrimary: colorsPalette.gray,
    secondaryAdditional: colorsPalette.gray,
    secondaryToWhite: colorsPalette.gray,
    meta: colorsPalette.violet,
    metaSecond: colorsPalette.blue,
    metaDark: alpha(colorsPalette.white, 0.3),
    cardLabel: alpha(colorsPalette.white, 0.3),
    title: colorsPalette.white,
    accordionTitle: colorsPalette.white,
    duration: colorsPalette.violet,
    label: colorsPalette.violet,
    accordionItemSubTitle: colorsPalette.gray,
  },
  success: {
    main: colorsPalette.green,
  },
  card: {
    accent: {
      bd: colorsPalette.blue,
    },
    dark: {
      bg: colorsPalette.blackSecondary,
    },
    overlay: {
      video: colorsPalette.blueDark,
      main: colorsPalette.blackSecondary,
      contrast: colorsPalette.blueDark,
    },
    secondary: colorsPalette.violet,
    divider: colorsPalette.violetAnalyticsCard,
    accentDivider: colorsPalette.violet,
    lightAccent: colorsPalette.violetAnalyticsCard,
    lighterAccent: colorsPalette.violetAnalyticsCard,
    shadowValue: `0px 3px 5px ${alpha(colorsPalette.blackPure, 0.3)}`,
    background: {
      light: colorsPalette.violetAnalyticsCard,
      lightInverted: colorsPalette.violetAnalyticsCard,
      dark: colorsPalette.blackSecondary,
    },
    progressBackground: alpha(colorsPalette.white, 0.07),
    progressGradient: `linear-gradient(0deg, ${colorsPalette.blackSecondary} 0%, ${alpha(
      colorsPalette.blackSecondary,
      0.9,
    )} 28.85%, ${alpha(colorsPalette.blackSecondary, 0.0)} 100%)`,
    smallCardGradient: `linear-gradient(0deg, ${colorsPalette.blackSecondary} 0%, ${alpha(
      colorsPalette.blackSecondary,
      0.9,
    )} 17.24%, ${alpha(colorsPalette.violetAnalyticsCard, 0)} 100%)`,
    hoverGradient: `linear-gradient(
      0deg,
      ${colorsPalette.violetAnalyticsCard} 0%,
      ${alpha(colorsPalette.violetAnalyticsCard, 0.9)} 10%,
      ${alpha(colorsPalette.violetAnalyticsCard, 0)} 50%
    )
    0% 0% no-repeat`,
    smallCardBg: colorsPalette.blackSecondary,
    accordionBackground: colorsPalette.violetAnalyticsCard,
    certificateShadowValue: '',
    border: colorsPalette.gray,
    pathwayBorderMain: alpha(colorsPalette.gray, 0.1),
    pathwayBorderLight: alpha(colorsPalette.gray, 0.1),
    hover: colorsPalette.violetAnalyticsCard,
  },
  button: {
    collapseSecondary: colorsPalette.gray,
    background: colorsPalette.transparent,
    color: colorsPalette.blueDark,
    root: {
      bg: colorsPalette.blackSecondary,
      hover: alpha(colorsPalette.gray, 0.15),
      color: colorsPalette.gray,
    },
    secondary: {
      contained: {
        bg: alpha(colorsPalette.white, 0.2),
        hover: {
          bg: alpha(colorsPalette.white, 0.08),
        },
        border: colorsPalette.gray,
        color: colorsPalette.white,
      },
      outlined: {
        bg: colorsPalette.transparent,
        border: colorsPalette.gray,
        hover: { bg: colorsPalette.gray, color: colorsPalette.blueDark },
      },
    },
  },
  panel: {
    secondary: colorsPalette.violetAnalyticsCard,
    background: colorsPalette.blackSecondary,
  },
  category: {
    primaryColor: colorsPalette.blueDark,
    color: colorsPalette.blueDark,
    secondary: colorsPalette.violet,
    pathway: colorsPalette.pink,
    cpd: colorsPalette.violetAnalyticsCard,
  },
  header: {
    shadowValue: `0px 3px 6px ${alpha(colorsPalette.blackPure, 0.4)}`,
    input: { bg: colorsPalette.violetAnalyticsCard, border: colorsPalette.transparent },
    switcher: colorsPalette.primary,
    notificationAccent: colorsPalette.coral,
    text: colorsPalette.white,
    background: colorsPalette.blueDark,
    staticBackground: colorsPalette.transparent,
    accentBackground: colorsPalette.white,
    accentText: alpha(colorsPalette.white, 0.33),
    color: colorsPalette.white,
    overlay: colorsPalette.blueDark,
    switcherBg: colorsPalette.violetAnalyticsCard,
    userAvatar,
  },
  icon: {
    active: colorsPalette.blue,
    gray: colorsPalette.gray,
    accordionIcon: colorsPalette.violet,
    autocompleteSearch: colorsPalette.white,
    primary: colorsPalette.primary,
  },
  footer: {
    bg: colorsPalette.blueDark,
    title: colorsPalette.white,
    hover: colorsPalette.white,
  },
  tabs: {
    underline: alpha(colorsPalette.violet, 0.3),
    indicator: colorsPalette.violet,
  },
  quiz: {
    bg: colorsPalette.violetAnalyticsCard,
  },
  hint: {
    color: colorsPalette.violetSecondary,
  },
  swiper: {
    buttonBg: colorsPalette.primary,
    buttonBorder: colorsPalette.white,
  },
  input: {
    searchColor: colorsPalette.gray,
  },
  scrollbar: {
    thumb: alpha(colorsPalette.blue, 0.33),
    track: colorsPalette.transparent,
  },
  progressBar: {
    color: colorsPalette.violet,
    circular: {
      trail: colorsPalette.gray,
      path: colorsPalette.blue,
    },
    line: {
      color: colorsPalette.blue,
    },
  },
  confetti: [
    colorsPalette.primary,
    colorsPalette.blue,
    alpha(colorsPalette.blue, 0.33),
    colorsPalette.gray,
    colorsPalette.pink,
    colorsPalette.blueLight,
  ],
  primaryAlternative: colorsPalette.primary,
};

const santanderDarkColorsByAreas = {
  subcategories: santanderDarkPalette.blackPure,
  divider: {
    main: santanderDarkPalette.violetAnalyticsCard,
    discover: santanderDarkPalette.violetSecondary,
  },
  accent: santanderDarkPalette.primary,
  accentFaded: santanderDarkPalette.primary,
  textField: {
    hoverBorder: santanderDarkPalette.violetAnalyticsCard,
    activeLabel: santanderDarkPalette.white,
  },
  accentShadow: santanderDarkPalette.blackPure,
  accentMain: santanderDarkPalette.blue,
  accentLight: santanderDarkPalette.blue,
  answerItem: santanderDarkPalette.blueDark,
  answerItemBorder: santanderDarkPalette.blackPure,
  secondaryShadow: santanderDarkPalette.blackPure,
  tooltip: { background: santanderDarkPalette.primary, color: santanderDarkPalette.white },
  star: santanderDarkPalette.primary,
  highlightBg: santanderDarkPalette.violetSecondary,
  status: { error: santanderDarkPalette.error, brightError: santanderDarkPalette.error },
  blockBackground: {
    main: santanderDarkPalette.blueDark,
    lightText: santanderDarkPalette.white,
    contrast: santanderDarkPalette.blue,
    light: santanderDarkPalette.blackPure,
    lightContrast: santanderDarkPalette.lightGray,
    lightBorder: santanderDarkPalette.blackPure,
    accentText: santanderDarkPalette.primary,
  },
  text: {
    white: santanderDarkPalette.white,
    gray: santanderDarkPalette.gray,
    violetSecondaryToWhite: santanderDarkPalette.white,
    fadedSubtitle: santanderDarkPalette.white,
    subtitle: santanderDarkPalette.primary,
    secondaryAccent: santanderDarkPalette.white,
    secondaryToPrimary: santanderDarkPalette.white,
    secondaryAdditional: santanderDarkPalette.white,
    secondaryToWhite: santanderDarkPalette.white,
    meta: santanderDarkPalette.bgOverlay,
    metaSecond: santanderLightPalette.blackSecondary,
    metaDark: santanderDarkPalette.bgOverlay,
    cardLabel: santanderDarkPalette.white,
    title: santanderDarkPalette.primary,
    accordionTitle: santanderDarkPalette.blackPure,
    duration: santanderDarkPalette.bgOverlay,
    label: santanderDarkPalette.white,
    accordionItemSubTitle: santanderDarkPalette.bgOverlay,
  },
  success: {
    main: santanderLightPalette.primary,
  },
  card: {
    accent: {
      bd: santanderDarkPalette.blue,
    },
    dark: {
      bg: santanderDarkPalette.blueDark,
    },
    overlay: {
      video: santanderDarkPalette.blueDark,
      main: santanderDarkPalette.blackPure,
      contrast: santanderDarkPalette.blueDark,
    },
    secondary: santanderDarkPalette.primary,
    divider: santanderDarkPalette.violetAnalyticsCard,
    accentDivider: santanderDarkPalette.violetAnalyticsCard,
    lightAccent: santanderDarkPalette.blueDark,
    lighterAccent: santanderDarkPalette.blueDark,
    shadowValue: `0px 3px 5px ${alpha(santanderLightPalette.blackPure, 0.3)}`,
    background: {
      dark: santanderDarkPalette.blackPure,
      light: santanderDarkPalette.blackPure,
      lightInverted: santanderDarkPalette.blackPure,
    },
    progressBackground: santanderDarkPalette.failedError,
    progressGradient: `linear-gradient(0deg, ${alpha(
      santanderDarkPalette.blackPure,
      0.9,
    )} 0%, ${alpha(colorsPalette.blackPure, 0.0)} 100%)`,
    smallCardGradient: `linear-gradient(0deg, ${santanderDarkPalette.blackPure} 10.45%, ${alpha(
      santanderDarkPalette.blackPure,
      0,
    )} 100%)`,
    hoverGradient: `linear-gradient(
      0deg,
      ${santanderDarkPalette.hover} 0%,
      ${alpha(santanderDarkPalette.hover, 0.9)} 10%,
      ${alpha(santanderDarkPalette.hover, 0)} 50%
    )
    0% 0% no-repeat`,
    smallCardBg: santanderDarkPalette.blackPure,
    accordionBackground: santanderDarkPalette.softDisabled,
    certificateShadowValue: '',
    border: santanderDarkPalette.gray,
    pathwayBorderMain: santanderDarkPalette.blueDark,
    pathwayBorderLight: santanderDarkPalette.hover,
    hover: santanderDarkPalette.hover,
  },
  button: {
    collapseSecondary: santanderDarkPalette.primary,
    background: santanderDarkPalette.primary,
    color: santanderDarkPalette.white,
    root: {
      bg: santanderDarkPalette.blueDark,
      hover: alpha(santanderDarkPalette.white, 0.1),
      color: santanderDarkPalette.white,
    },
    secondary: {
      contained: {
        bg: santanderDarkPalette.blueDark,
        hover: {
          bg: alpha(santanderDarkPalette.blueDark, 0.8),
        },
        border: santanderDarkPalette.blue,
        color: santanderDarkPalette.white,
      },
      outlined: {
        bg: santanderDarkPalette.blueDark,
        border: santanderDarkPalette.blue,
        hover: { bg: santanderDarkPalette.blue, color: santanderDarkPalette.white },
      },
    },
  },
  panel: {
    secondary: santanderDarkPalette.primary,
    background: santanderDarkPalette.blackPure,
  },
  category: {
    primaryColor: santanderDarkPalette.white,
    color: santanderDarkPalette.white,
    secondary: santanderDarkPalette.violetSecondary,
    pathway: santanderDarkPalette.coral,
    cpd: santanderDarkPalette.blackPure,
  },
  header: {
    shadowValue: `0px 3px 6px ${alpha(santanderDarkPalette.blackPure, 0.4)}`,
    input: {
      bg: alpha(santanderDarkPalette.blackPure, 0.35),
      border: santanderDarkPalette.transparent,
    },
    switcher: santanderDarkPalette.blackPure,
    notificationAccent: santanderDarkPalette.gray,
    text: santanderDarkPalette.white,
    background: santanderDarkPalette.primary,
    staticBackground: santanderDarkPalette.primary,
    accentBackground: santanderDarkPalette.violetSecondary,
    accentText: santanderDarkPalette.white,
    color: santanderDarkPalette.white,
    overlay: santanderDarkPalette.blackPure,
    switcherBg: santanderDarkPalette.violetSecondary,
    userAvatar,
  },
  icon: {
    active: santanderDarkPalette.primary,
    gray: santanderDarkPalette.bgOverlay,
    accordionIcon: santanderDarkPalette.primary,
    autocompleteSearch: santanderDarkPalette.bgOverlay,
    primary: santanderDarkPalette.white,
  },
  footer: {
    bg: santanderDarkPalette.blackPure,
    title: santanderDarkPalette.blue,
    hover: santanderDarkPalette.blue,
  },
  tabs: {
    underline: santanderDarkPalette.blueDark,
    indicator: santanderDarkPalette.primary,
  },
  quiz: {
    bg: santanderDarkPalette.blueDark,
  },
  hint: {
    color: santanderDarkPalette.white,
  },
  swiper: {
    buttonBg: santanderDarkPalette.primary,
    buttonBorder: santanderDarkPalette.white,
  },
  input: {
    searchColor: santanderDarkPalette.softDisabled,
  },
  scrollbar: {
    thumb: santanderDarkPalette.bgOverlay,
    track: santanderDarkPalette.blueDark,
  },
  progressBar: {
    color: santanderDarkPalette.primary,
    circular: {
      trail: santanderDarkPalette.violetSecondary,
      path: santanderDarkPalette.primary,
    },
    line: {
      color: santanderDarkPalette.primary,
    },
  },
  confetti: ['#FEE5E5', '#F67F7F', '#EC0001', '#CC0000', '#23779A'],
  primaryAlternative: santanderDarkPalette.white,
};

const santanderLightColorsByAreas = {
  subcategories: santanderLightPalette.pink,
  divider: {
    main: santanderLightPalette.blue,
    discover: santanderLightPalette.blue,
  },
  accent: santanderLightPalette.primary,
  accentFaded: santanderLightPalette.primary,
  textField: {
    hoverBorder: santanderLightPalette.pink,
    activeLabel: santanderLightPalette.pink,
  },
  accentShadow: santanderLightPalette.primary,
  accentMain: santanderLightPalette.violet,
  accentLight: santanderLightPalette.violet,
  answerItem: santanderLightPalette.blackSecondary,
  answerItemBorder: santanderLightPalette.blue,
  secondaryShadow: santanderLightPalette.blue,
  tooltip: {
    background: santanderLightPalette.primary,
    color: santanderLightPalette.blackSecondary,
  },
  star: santanderLightPalette.primary,
  highlightBg: santanderLightPalette.violetAnalyticsCard,
  status: { error: santanderLightPalette.violet, brightError: santanderLightPalette.error },
  blockBackground: {
    main: santanderLightPalette.blackSecondary,
    lightText: santanderLightPalette.blackPure,
    contrast: santanderLightPalette.blackSecondary,
    light: santanderLightPalette.blueDark,
    lightContrast: santanderLightPalette.blueSky,
    lightBorder: santanderLightPalette.violetAnalyticsCard,
    accentText: santanderLightPalette.blue,
  },
  text: {
    white: santanderLightPalette.blueDark,
    gray: santanderLightPalette.gray,
    violetSecondaryToWhite: santanderLightPalette.blueDark,
    fadedSubtitle: santanderLightPalette.gray,
    subtitle: santanderLightPalette.pink,
    secondaryAccent: santanderLightPalette.blackPure,
    secondaryToPrimary: santanderLightPalette.blackPure,
    secondaryAdditional: santanderLightPalette.pink,
    secondaryToWhite: santanderLightPalette.blueDark,
    meta: santanderLightPalette.pink,
    metaSecond: santanderLightPalette.blackSecondary,
    metaDark: santanderLightPalette.bgStrong,
    cardLabel: santanderLightPalette.blueDark,
    title: santanderLightPalette.blackPure,
    accordionTitle: santanderLightPalette.blueDark,
    duration: santanderLightPalette.gray,
    label: santanderLightPalette.white,
    accordionItemSubTitle: santanderDarkPalette.pink,
  },
  success: {
    main: santanderDarkPalette.primary,
  },
  card: {
    accent: {
      bd: santanderDarkPalette.pink,
    },
    dark: {
      bg: santanderDarkPalette.blueDark,
    },
    overlay: {
      video: santanderLightPalette.santanderDarkPalette.blueDark,
      main: santanderLightPalette.blackSecondary,
      contrast: santanderLightPalette.blackPure,
    },
    secondary: santanderLightPalette.pink,
    divider: santanderLightPalette.blueSky,
    accentDivider: santanderLightPalette.blue,
    lightAccent: santanderLightPalette.violetAnalyticsCard,
    lighterAccent: santanderLightPalette.blackSecondary,
    shadowValue: `0px 3px 5px ${alpha(santanderLightPalette.gray, 0.3)}`,
    background: {
      dark: santanderLightPalette.blackSecondary,
      light: santanderLightPalette.blueDark,
      lightInverted: santanderLightPalette.blackSecondary,
    },
    progressBackground: santanderDarkPalette.failedError,
    progressGradient: `linear-gradient(0deg, ${alpha(
      santanderLightPalette.blackPure,
      0.9,
    )} 0%, ${alpha(santanderLightPalette.blackPure, 0.0)} 100%)`,
    smallCardGradient: `linear-gradient(0deg, ${santanderLightPalette.blackPure} 10.45%, ${alpha(
      santanderLightPalette.blackPure,
      0,
    )} 100%)`,
    hoverGradient: `linear-gradient(
      0deg,
      ${santanderLightPalette.hover} 0%,
      ${alpha(santanderLightPalette.hover, 0.9)} 10%,
      ${alpha(santanderLightPalette.hover, 0)} 50%
    )
    0% 0% no-repeat`,
    smallCardBg: santanderLightPalette.blackPure,
    accordionBackground: santanderLightPalette.pink,
    // certificateShadowValue: `0px 3px 40px 0px ${alpha(santanderLightPalette.gray, 0.08)}`,
    certificateShadowValue: `0px 3px 40px 0px ${alpha(santanderDarkPalette.blackPure, 0.08)}`,
    border: santanderLightPalette.pink,
    pathwayBorderMain: santanderLightPalette.blueSky,
    pathwayBorderLight: santanderLightPalette.blueSky,
    hover: santanderLightPalette.hover,
  },
  button: {
    collapseSecondary: santanderLightPalette.primary,
    background: santanderLightPalette.primary,
    color: santanderLightPalette.blueDark,
    root: {
      bg: santanderLightPalette.blueSky,
      hover: alpha(santanderLightPalette.blackSecondary, 0.15),
      color: santanderLightPalette.blue,
    },
    secondary: {
      contained: {
        bg: santanderLightPalette.blueDark,
        hover: {
          bg: alpha(santanderLightPalette.blueDark, 0.8),
        },
        border: santanderLightPalette.violet,
        color: santanderLightPalette.blackPure,
      },
      outlined: {
        bg: santanderLightPalette.blueDark,
        border: santanderLightPalette.violet,
        hover: { bg: santanderLightPalette.violet, color: santanderLightPalette.blueDark },
      },
    },
  },
  panel: {
    secondary: santanderLightPalette.primary,
    background: santanderLightPalette.transparent,
  },
  category: {
    primaryColor: santanderLightPalette.blueDark,
    color: santanderLightPalette.blueDark,
    secondary: santanderLightPalette.violetSecondary,
    pathway: santanderLightPalette.coral,
    cpd: santanderLightPalette.blueSky,
  },
  header: {
    shadowValue: `none`,
    input: {
      bg: santanderLightPalette.blackSecondary,
      border: santanderLightPalette.blue,
    },
    switcher: santanderLightPalette.pink,
    notificationAccent: santanderLightPalette.primary,
    text: santanderLightPalette.gray,
    background: santanderLightPalette.blueDark,
    staticBackground: santanderLightPalette.blueDark,
    accentBackground: santanderLightPalette.blue,
    accentText: santanderLightPalette.gray,
    color: santanderLightPalette.primary,
    overlay: santanderLightPalette.blueDark,
    switcherBg: santanderLightPalette.blueSky,
    userAvatar,
  },
  icon: {
    active: santanderLightPalette.pink,
    gray: santanderLightPalette.bgOverlay,
    accordionIcon: santanderLightPalette.blueDark,
    autocompleteSearch: santanderLightPalette.bgOverlay,
    primary: santanderLightPalette.violet,
  },
  footer: {
    bg: santanderLightPalette.blackSecondary,
    title: santanderLightPalette.pink,
    hover: santanderLightPalette.pink,
  },
  tabs: {
    underline: alpha(santanderLightPalette.violet, 0.3),
    indicator: santanderLightPalette.violet,
  },
  quiz: {
    bg: santanderLightPalette.blueSky,
  },
  hint: {
    color: santanderLightPalette.blackPure,
  },
  swiper: {
    buttonBg: santanderLightPalette.pink,
    buttonBorder: santanderLightPalette.pink,
  },
  input: {
    searchColor: santanderLightPalette.blackPure,
  },
  scrollbar: {
    thumb: santanderLightPalette.violetAnalyticsCard,
    track: santanderLightPalette.blackSecondary,
  },
  progressBar: {
    color: santanderLightPalette.primary,
    circular: {
      trail: santanderLightPalette.error,
      path: santanderLightPalette.primary,
    },
    line: {
      color: santanderLightPalette.primary,
    },
  },
  confetti: ['#FEE5E5', '#F67F7F', '#EC0001', '#CC0000', '#23779A'],
  primaryAlternative: santanderLightPalette.blackSecondary,
};

type ColorsPalette = typeof colorsPalette;

export const layers = {
  menu: 1050,
  pip: 90,
  stickyFooter: 100,
  toastNotification: 1500,
  modal: 1200,
  modalOverlay: 1150,
  dropdown: 1020,
};

export const HEADER_HEIGHT = 120;
export const HEADER_HEIGHT_PX = `${HEADER_HEIGHT}px`;

export const INNER_BOTTOM_SPACE_DESK = 36;

export const TINY_MARGIN = 8;
export const SMALL_MARGIN = 18;
export const SUBMIDDLE_MARGIN = 24;
export const MIDDLE_MARGIN = 36;
export const BIG_MARGIN = 54;
export const LARGE_MARGIN = 72;

export const TINY_MARGIN_PX = `${TINY_MARGIN}px`;
export const SMALL_MARGIN_PX = `${SMALL_MARGIN}px`;
export const SUBMIDDLE_MARGIN_PX = `${SUBMIDDLE_MARGIN}px`;
export const MIDDLE_MARGIN_PX = `${MIDDLE_MARGIN}px`;
export const BIG_MARGIN_PX = `${BIG_MARGIN}px`;
export const LARGE_MARGIN_PX = `${LARGE_MARGIN}px`;

export const MIDDLE_RADIUS = 8;
export const BIG_RADIUS = 16;
export const LARGE_RADIUS = 32;

export const SMALL_RADIUS_PX = '5px';
export const MIDDLE_RADIUS_PX = `${MIDDLE_RADIUS}px`;
export const BIG_RADIUS_PX = `${BIG_RADIUS}px`;
export const LARGE_RADIUS_PX = `${LARGE_RADIUS}px`;

const SKELETON_TEXT_HEIGHT_TINY = 18;
const SKELETON_TEXT_HEIGHT_SMALL = 24;
const SKELETON_TEXT_HEIGHT = 36;
const SKELETON_TEXT_HEIGHT_BIG = 54;
export const SKELETON_TEXT_HEIGHT_TINY_PX = `${SKELETON_TEXT_HEIGHT_TINY}px`;
export const SKELETON_TEXT_HEIGHT_SMALL_PX = `${SKELETON_TEXT_HEIGHT_SMALL}px`;
export const SKELETON_TEXT_HEIGHT_PX = `${SKELETON_TEXT_HEIGHT}px`;
export const SKELETON_TEXT_HEIGHT_BIG_PX = `${SKELETON_TEXT_HEIGHT_BIG}px`;

const typographyTokens = {
  fontFamily: '"sofia-pro", sans-serif',
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  defaultFontFamily: '"sofia-pro", sans-serif',
  headerFontFamily: '"sofia-pro", sans-serif',
  specialFontFamily: '"sofia-pro", sans-serif',
  fontLink: 'https://use.typekit.net/ein6xai.css',
};

const santanderTypographyTokens = {
  fontFamily: '"Santander Micro Text", sans-serif',
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  defaultFontFamily: '"Santander Micro Text", sans-serif',
  headerFontFamily: '"Santander Headline", sans-serif',
  specialFontFamily: '"Embers", sans-serif',
  fontLink: generateImageKitUrl({
    path: '/fonts/skillsunlockedFonts.css',
    transformation: undefined,
  }),
};

const themeCommonTokens = {
  breakpoints: { values: BREAKPOINTS_VALUES },
  components: {
    MuiContainer: {
      styleOverrides: {},
    },
  },
};

const themeColorTokens = {
  common: {
    ...colorsPalette,
    ...defaultColorsByAreas,
  },
  primary: {
    light: colorsPalette.primary,
    main: colorsPalette.primary,
    dark: colorsPalette.primary,
    contrastText: common.black,
  },
  secondary: {
    light: colorsPalette.blackSecondary,
    main: colorsPalette.blackSecondary,
    dark: colorsPalette.blueDark,
    contrastText: common.white,
  },
};

const santanderLightColorTokens = {
  common: {
    ...santanderLightPalette,
    ...santanderLightColorsByAreas,
  },
  primary: {
    light: santanderLightPalette.primary,
    main: santanderLightPalette.primary,
    dark: santanderLightPalette.primary,
    contrastText: common.black,
  },
  secondary: {
    light: santanderLightPalette.blackSecondary,
    main: santanderLightPalette.blackSecondary,
    dark: santanderLightPalette.blueDark,
    contrastText: common.white,
  },
};

const santanderDarkColorTokens = {
  common: {
    ...santanderDarkPalette,
    ...santanderDarkColorsByAreas,
  },
  primary: {
    light: santanderDarkPalette.primary,
    main: santanderDarkPalette.primary,
    dark: santanderDarkPalette.primary,
    contrastText: common.black,
  },
  secondary: {
    light: santanderDarkPalette.blackSecondary,
    main: santanderDarkPalette.blackSecondary,
    dark: santanderDarkPalette.blueDark,
    contrastText: common.white,
  },
};

const getDefaultDesignTokens = (mode: PaletteMode) => ({
  name: TenantsRefs.Fu,
  isSsku: false,
  palette: {
    mode,
    ...(mode === 'light' ? themeColorTokens : themeColorTokens),
  },
  typography: typographyTokens,
  ...themeCommonTokens,
});

const getSantanderDesignTokens = (mode: PaletteMode) => ({
  name: TenantsRefs.Ssku,
  isSsku: true,
  palette: {
    mode,
    ...(mode === 'light' ? santanderLightColorTokens : santanderDarkColorTokens),
  },
  typography: santanderTypographyTokens,
  ...themeCommonTokens,
});

export const getDefaultTransition = (type = 'all') => {
  return `${type} .3s ease`;
};

export type TenantTheme = Record<'dark' | 'light', Theme>;
export const defaultTenantTheme: TenantTheme = {
  dark: createTheme(getDefaultDesignTokens('dark')),
  light: createTheme(getDefaultDesignTokens('light')),
};
export const santanderTheme: TenantTheme = {
  dark: createTheme(getSantanderDesignTokens('dark')),
  light: createTheme(getSantanderDesignTokens('light')),
};

export const tenantThemes: TenantTheme[] = [defaultTenantTheme, santanderTheme];

const createComponentOverrides = (theme: Theme) => ({
  MuiContainer: {
    root: {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 16,
        paddingRight: 16,
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: 24,
        paddingRight: 24,
      },
      [theme.breakpoints.up('xl')]: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
    maxWidthLg: {
      [theme.breakpoints.up('sm')]: {
        maxWidth: 1200,
      },
    },
  },
  MuiAppBar: {
    root: ({ theme }) => css`
      color: ${theme.palette.common.header.text};
    `,
  },
  MuiAlert: {
    filledSuccess: {
      color: alpha(theme.palette.common.blackPure, 0.87),
      backgroundColor: theme.palette.common.primary,
    },
    filledError: {
      backgroundColor: '#f44336',
    },
  },
  MuiTabs: {
    flexContainer: {
      borderBottom: `2px solid ${theme.palette.common.tabs.underline}`,
    },
    indicator: {
      backgroundColor: `${theme.palette.common.tabs.indicator}`,
    },
  },
  MuiTab: {
    root: {
      minWidth: 0,
      marginRight: 15,
      fontSize: 20,
      fontWeight: 'bold',
      textTransform: 'none',

      [theme.breakpoints.up('sm')]: {
        minWidth: 0,
      },
    },
  },
  MuiSelect: {
    select: {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
  MuiList: {
    root: {
      background: theme.palette.common.violetAnalyticsCard,
    },
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: 8,
      borderColor: alpha(theme.palette.common.white, 0.33),
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.common.textField.hoverBorder,
        borderWidth: 1,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.common.textField.hoverBorder,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.common.failedError,
      },
    },
    input: {
      color: theme.palette.common.gray,
      fontSize: 18,
      lineHeight: '26px',
      '&:-webkit-autofill': {
        color: theme.palette.common.gray,
        boxShadow: `0 0 0 100px ${theme.palette.common.violetAnalyticsCard} inset`,
      },
    },
    notchedOutline: {
      '& legend': {
        fontSize: 14,
      },
    },
    adornedEnd: {
      paddingRight: 7,
    },
  },
  MuiInputLabel: {
    root: {
      color: alpha(theme.palette.common.white, 0.33),
      fontSize: 18,
      lineHeight: '26px',
      '&.Mui-focused': {
        color: theme.palette.common.textField.activeLabel,
      },
      '&.Mui-error': {
        color: theme.palette.common.failedError,
      },
    },
    outlined: {
      transform: 'translate(14px, 16px) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -10px) scale(0.75)',
      },
    },
  },
  MuiFormHelperText: {
    root: {
      '&.Mui-error': {
        color: theme.palette.common.failedError,
      },
    },
  },
  MuiAutocomplete: {
    listbox: {
      '& .MuiAutocomplete-option.Mui-focused': {
        backgroundColor: theme.palette.common.card.hover,
      },
    },
    tag: {
      borderRadius: 16,
    },
    clearIndicator: {
      display: 'none',
    },
    popupIndicator: {
      color: alpha(theme.palette.common.white, 0.33),
    },
    popupIndicatorOpen: {
      color: theme.palette.common.blue,
    },
    paper: {
      margin: 0,
      backgroundColor: theme.palette.common.blockBackground.light,
      backgroundImage: 'none',
    },
    option: {
      fontSize: 18,
      lineHeight: '26px',
      color: theme.palette.common.text.secondaryToPrimary,
      padding: '16px 8px',
      '&.Mui-focused': {
        backgroundColor: theme.palette.common.card.hover,
      },
    },
  },
  MuiCalendarPicker: {
    root: {
      backgroundColor: [theme.palette.secondary.light],
      '& > div:first-child': {
        padding: 0,
        '& > div:first-child': {
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          pointerEvents: 'none',
          '& .MuiButtonBase-root': {
            display: 'none',
          },
        },
        '& > div:last-child': {
          width: '100%',
          justifyContent: 'space-between',
          '& .MuiIconButton-root': {
            background: theme.palette.common.violetAnalyticsCard,
            '&:hover': {
              background: alpha(theme.palette.common.white, 0.08),
            },
            '& .Mui-disabled': {
              background: 'transparent',
            },
          },
        },
      },
      '& .PrivatePickersSlideTransition-root ': {
        minHeight: 240,
      },
    },
  },
  MuiPickersBasePicker: {
    pickerView: {
      backgroundColor: [theme.palette.secondary.light],
    },
  },
  MuiPickersCalendarHeader: {
    iconButton: {
      backgroundColor: theme.palette.common.violetAnalyticsCard,
    },
  },
  MuiPickersDay: {
    root: {
      fontSize: 14,
      backgroundColor: [theme.palette.secondary.light],
      '&:hover': {
        backgroundColor: theme.palette.common.violetAnalyticsCard,
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.common.primary,
      },
    },
    today: {
      border: 'none !important',
      color: theme.palette.common.primary,
      '&:hover': {
        backgroundColor: theme.palette.common.violetAnalyticsCard,
      },
      '&:focus': {
        color: theme.palette.common.blueDark,
        backgroundColor: theme.palette.common.primary,
      },
    },
  },
  MuiAccordion: {
    root: {
      backgroundImage: 'none',
    },
  },
  MuiLink: {
    root: {
      textDecoration: 'none',
    },
  },
  MuiButton: {
    root: ({ theme }) => css`
      &:hover {
        background: ${alpha(theme.palette.common.button.root.color, 0.15)};
      }
    `,
  },
  MuiIconButton: {
    root: {
      padding: 12,
    },
  },
});

tenantThemes
  .flatMap((tenantTheme) => Object.values(tenantTheme))
  .forEach((theme) => {
    for (const tKey in createComponentOverrides(theme)) {
      if (theme.components) {
        theme.components[tKey] = {};
        theme.components[tKey].styleOverrides = createComponentOverrides(theme)[tKey];
      }
    }

    theme.typography.h1 = {
      fontSize: 32,
      lineHeight: '40px',
      fontWeight: 'bold',
      marginBottom: 0,
      fontFamily: theme.typography.headerFontFamily,
      [theme.breakpoints.up('sm')]: {
        fontSize: 40,
        lineHeight: 1.2,
        marginBottom: 0,
      },
    };

    theme.typography.h2 = {
      fontSize: 24,
      lineHeight: '29px',
      fontWeight: 'bold',
      marginBottom: 22,
      fontFamily: theme.typography.headerFontFamily,
      [theme.breakpoints.up('sm')]: {
        fontSize: 28,
        lineHeight: 1.2,
        marginBottom: 0,
      },
    };

    theme.typography.h3 = {
      fontSize: 18,
      lineHeight: '22px',
      fontWeight: 'bold',
      marginBottom: 8,
      fontFamily: theme.typography.headerFontFamily,
      [theme.breakpoints.up('sm')]: {
        fontSize: 24,
        lineHeight: 1.2,
        marginBottom: 0,
      },
    };

    theme.typography.h4 = {
      fontSize: 22,
      lineHeight: '22px',
      fontWeight: 'bold',
      marginBottom: 0,
      fontFamily: theme.typography.headerFontFamily,
      [theme.breakpoints.up('sm')]: {
        fontSize: 20,
        lineHeight: '22px',
        marginBottom: 0,
      },
    };

    theme.typography.h5 = {
      fontFamily: theme.typography.headerFontFamily,
    };

    theme.typography.h6 = {
      fontFamily: theme.typography.headerFontFamily,
    };
  });

export function useIsWidthUp(breakpoint) {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up(breakpoint));
}

export function useIsWidthBetween(start, end) {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.between(start, end));
}

export function useBreakpoint(defaultWidth: Breakpoint = 'sm') {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  const breakpoint: Breakpoint =
    keys.reduce((output: null | Breakpoint, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || defaultWidth;

  return breakpoint;
}

type AdditionalCommonColors = typeof defaultColorsByAreas;

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true;
    ml: true;
  }

  interface CommonColors extends ColorsPalette, AdditionalCommonColors {}

  interface Theme {
    isSsku: boolean;
    name: TenantsRefs;
  }
}

// todo: remove "| string" after refactoring
export type CommonColor = Theme['palette']['common'] | string;

export default defaultTenantTheme.dark;
