import React, { createContext, FC, lazy, Suspense, useContext, useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';
import { Route, Routes, useLocation } from 'react-router-dom';

import { Loader } from '../../components/Loader';
import RequireAuth from '../../components/RequireAuth';
import {
  AUTH_APP_ID_KEY,
  DEFAULT_LOCALE,
  LANG_COOKIE_KEY,
  PLAY_RATE_COOKIE_NAME,
} from '../../const';
import { WithAlert } from '../../context/AlertProvider';
import { LanguageCtx } from '../../context/LanguageProvider';
import useScriptLoader, { SCRIPTS } from '../../context/ScriptLoader/useScriptLoader';
import { TenantCtx } from '../../context/TenantProvider';
import { GET_LEARNER_INFO } from '../../graphql/user';
import useIsSsku from '../../hooks/tenant/useIsSsku';
import useAuth from '../../hooks/useAuth';
import useInitGtm from '../../hooks/useInitGtm';
import useInitUserCom from '../../hooks/useInitUserCom';
import useInitWithoutNavigation from '../../hooks/useInitWithoutNavigation';
import useLang from '../../hooks/useLang';
import useOnboarding from '../../hooks/useOnboarding';
import useOnlineContentConnectorParams from '../../hooks/useOnlineContentConnectorParams';
import useScrollToTopOnRouteChange from '../../hooks/useScrollToTopOnRouteChange';
import useSyncSskuConsentCookies from '../../hooks/useSyncSskuConsentCookies';
import useTimedOutSession from '../../hooks/useTimedOutSession';
import useUpdateUserComSubInfo from '../../hooks/useUpdateUserComSubInfo';
import { Language } from '../../i18n';
import { ModalCtx, Modals } from '../../modals/Modal';
import AuthErrorPage from '../../pages/AuthErrorPage/AuthErrorPage';
import Maintenance from '../../pages/Maintenance/Maintenance';
import { JOIN_TEAM_ROUTE, MAINTENANCE } from '../../routes';
import styled from '../../styled';
import { LearnerInfo, Query } from '../../types';
import UserCom, { UserComEvents } from '../../utils/UserCom';
import { checkConsent } from '../../utils/checkConsent';
import checkDomainsAvailability from '../../utils/checkDomainsAvailability';
import { Layout } from '../Layout';
import { RXDLayout } from '../RXDLayout';
import useHotspotsClick from './useHotspotsClick';

const InvitePage = lazy(() => import('../../pages/InvitePage/InvitePage'));
const SubscriptionPage = lazy(() => import('../../pages/SubscriptionPage/SubscriptionPage'));

interface CommonLayoutProps {
  setAuthAppId: React.Dispatch<React.SetStateAction<string>>;
  hasAuthenticatedCookie: boolean;
}

export const UserInfoCtx = createContext<{
  userInfo?: LearnerInfo | null;
  userLoading: boolean;
}>({ userInfo: null, userLoading: false });

export function useUserInfoContext() {
  const context = useContext(UserInfoCtx);

  if (!context) {
    throw new Error('useUserInfoContext must be used within a UserInfoCtx');
  }

  return context;
}

export const GtmCtx = createContext<{
  isInitialized: boolean;
}>({ isInitialized: false });

const CommonLayout: FC<CommonLayoutProps> = ({ setAuthAppId, hasAuthenticatedCookie }) => {
  const [checkedDomains, setCheckedDomains] = useState(false);
  const { isInitialized, isAuthenticated, isLoading } = useAuth();
  const { tenant, tenantLoading, config } = useContext(TenantCtx);
  const { openModal } = useContext(ModalCtx);
  const { showOnboardingFlow, handleStep, learnerGoals, goalOptions, onboardingShowed } =
    useOnboarding();
  const location = useLocation();
  useLang();
  useOnlineContentConnectorParams();
  useInitWithoutNavigation();
  const { language, changeLanguage } = useContext(LanguageCtx);
  const [shouldSyncLang, setShouldSyncLang] = useState(true);
  const [isNewSession, setIsNewSession] = useState(false);
  const theme = useTheme();
  const { isLoaded: isUserComLoaded } = useScriptLoader(SCRIPTS.USER_COM);
  const isSsku = useIsSsku();

  useScrollToTopOnRouteChange();
  useHotspotsClick();

  const customCss = tenant?.custom_css;

  useEffect(() => {
    if (!isUserComLoaded) {
      return;
    }

    const isSameSession = window.sessionStorage.getItem('sameSession');

    if (!isSameSession) {
      window.sessionStorage.setItem('sameSession', 'true');
      UserCom.sendEvent(UserComEvents.newSession);
    }
  }, [isUserComLoaded]);

  useSyncSskuConsentCookies();

  useEffect(() => {
    window.HYDRATED = true;
    const event = new Event('HYDRATED');
    window.dispatchEvent(event);
  }, []);

  const {
    data: learnerData,
    loading: learnerLoading,
    refetch: refetchUserInfo,
  } = useQuery<Pick<Query, 'getLearnerInfo'>>(GET_LEARNER_INFO, {
    errorPolicy: 'all',
    skip: !isInitialized || !isAuthenticated,
  });

  const userInfo = learnerData?.getLearnerInfo || undefined;
  const usersLang = userInfo?.settings?.language as Language;
  const langSwitchingAllowed = config?.langSwitchingAllowed;

  useEffect(() => {
    if (!userInfo || !langSwitchingAllowed || !shouldSyncLang) {
      return;
    }

    const targetLang = isNewSession
      ? usersLang || DEFAULT_LOCALE
      : Cookies.get(LANG_COOKIE_KEY) || usersLang || DEFAULT_LOCALE;
    if (targetLang !== language) {
      changeLanguage(targetLang);
    }

    setShouldSyncLang(false);
  }, [
    usersLang,
    language,
    userInfo,
    changeLanguage,
    langSwitchingAllowed,
    shouldSyncLang,
    setShouldSyncLang,
    isNewSession,
  ]);

  const isGtmInitialized = useInitGtm(location, tenant?.gtm_id, userInfo, learnerLoading);
  useInitUserCom(userInfo);

  //if going to change this hook, please check after update that updated user and plan info is sent to UserCom after buying a subscription,
  // removed same functionality from ThankYouPage to avoid double sending
  useUpdateUserComSubInfo(userInfo);

  useEffect(() => {
    if (!UserCom.checkHasUserCom()) {
      return;
    }

    const followings = userInfo?.followings?.map((following) => following?.followed_entity?.name);
    if (followings?.length) {
      UserCom.updateClient({
        followings: JSON.stringify(followings),
      });
    }
  }, [userInfo]);

  useEffect(() => {
    const idFromStorage = localStorage.getItem(AUTH_APP_ID_KEY);
    const idFromRequest = tenant?.auth0_app_id || '';
    if (idFromStorage !== idFromRequest && idFromRequest) {
      setAuthAppId(idFromRequest);
      localStorage.setItem(AUTH_APP_ID_KEY, idFromRequest);
    }
  }, [tenant, setAuthAppId]);

  useEffect(() => {
    if (learnerData?.getLearnerInfo && !checkedDomains) {
      setCheckedDomains(true);
      checkDomainsAvailability([
        {
          name: 'company_id',
          value: learnerData?.getLearnerInfo?.company?.id || '',
        },
      ]);
    }
  }, [learnerData, checkedDomains]);

  useEffect(() => {
    if (showOnboardingFlow?.showSetGoalsFlow && !onboardingShowed && isSsku && isAuthenticated) {
      openModal?.(Modals.OnboardingModal);
    }
  }, [
    goalOptions,
    handleStep,
    isAuthenticated,
    learnerGoals,
    onboardingShowed,
    openModal,
    showOnboardingFlow?.showSetGoalsFlow,
    isSsku,
  ]);

  useTimedOutSession(() => {
    checkConsent(PLAY_RATE_COOKIE_NAME) && Cookies.set(PLAY_RATE_COOKIE_NAME, 1, { expires: 0 });
    // reset cookie lang for new session
    setIsNewSession(true);
  });

  const waitForUser = hasAuthenticatedCookie && (!isInitialized || isLoading || learnerLoading);

  return (
    <WithAlert>
      <UserInfoCtx.Provider value={{ userInfo, userLoading: learnerLoading }}>
        <GtmCtx.Provider value={{ isInitialized: isGtmInitialized }}>
          <Wrapper>
            <CssBaseline />
            <Helmet>
              <style>{customCss}</style>
              {!tenantLoading && tenant?.title && (
                <meta property="og:site_name" content={tenant?.title} />
              )}
              <link
                rel="stylesheet"
                type="text/css"
                href={theme.typography?.fontLink || 'https://use.typekit.net/ein6xai.css'}
              />
            </Helmet>
            <Routes>
              <Route
                path={JOIN_TEAM_ROUTE}
                element={
                  <Suspense fallback={<Loader />}>
                    <InvitePage
                      userInfo={userInfo}
                      userLoading={learnerLoading}
                      refetchUserInfo={refetchUserInfo}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/subscription/:id"
                element={
                  <RequireAuth withSubscription={false}>
                    <Suspense fallback={<Loader />}>
                      <SubscriptionPage logoUrl={tenant?.logo_url} userInfo={userInfo} />
                    </Suspense>
                  </RequireAuth>
                }
              />
              <Route
                path="/rxd/*"
                element={
                  <RXDLayout tenant={tenant} userInfo={userInfo} userLoading={learnerLoading} />
                }
              />
              <Route path="/auth-error" element={<AuthErrorPage />} />
              <Route path={MAINTENANCE} element={<Maintenance />} />
              <Route
                path="/*"
                element={
                  <Layout
                    tenant={tenant}
                    loadingTenant={tenantLoading}
                    userInfo={userInfo}
                    userLoading={learnerLoading}
                    waitForUser={waitForUser}
                  />
                }
              />
            </Routes>
          </Wrapper>
        </GtmCtx.Provider>
      </UserInfoCtx.Provider>
    </WithAlert>
  );
};

export default CommonLayout;

const Wrapper = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.common.blockBackground.main};
  flex-grow: 1;
`;
