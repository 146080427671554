import { useEffect } from 'react';

function useClickOutside(selector, cb) {
  useEffect(() => {
    const clickHandler = (e) => {
      const target = e.target as HTMLElement;
      const element = target?.closest(selector);

      if (!element) {
        cb();
      }
    };

    document.addEventListener('click', clickHandler, { capture: true });

    return () => document.removeEventListener('click', clickHandler, { capture: true });
  }, [selector, cb]);
}

export default useClickOutside;
