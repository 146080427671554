import { SerializedStyles } from '@emotion/react/dist/emotion-react.cjs';

export enum PATHWAY_STATUSES {
  NOT_WATCHED = 'not-watched',
  STARTED = 'started',
  WATCHED = 'watched',
  HAS_TEST_SCORE = 'has-test-score',
  COMPLETED = 'completed',
}

export enum PUBLISH_STATUSES {
  Draft = 'draft',
  Test = 'test',
  Published = 'published',
  ComingSoon = 'coming_soon',
}

export enum USER_ROLES {
  CompanyOwner = 'company_owner',
  CompanyManager = 'company_manager',
  TeamManager = 'team_manager',
  B2BLearner = 'b2b_learner',
  B2CLearner = 'b2c_learner',
  LearnerWithoutSubscription = 'learner_without_subscription',
}

export const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
export const ANSWERS_VIEW = 'answers';
export const START_VIEW = 'start';
export const TESTS_VIEW = 'tests';
export const TEST_RESULT = 'result';

export enum QUESTION_TYPES {
  check = 'checkbox',
  radio = 'radio',
  ordered = 'ordered',
}

export enum VideoStatus {
  Watched,
  Active,
  Completed,
  NotWatched,
}

export const HITS_TYPES = {
  VIDEO: '200',
  PATHWAY: '100',
  NEWS: '400',
  GLOSSARY: '300',
  COURSE: '250',
};

export interface ITestCard {
  id: string;
  canPass: boolean;
  caption?: string;
  link?: string;
  iconCss?: SerializedStyles;
}

export enum ASSIGNED_LEARNING_TYPES {
  CPD = 'cpd',
  PATHWAYS = 'pathways',
  HOURS = 'hours',
  VIDEOS = 'videos',
}
