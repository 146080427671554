import React from 'react';

import { SerializedStyles } from '@emotion/react/dist/emotion-react.cjs';
import { Box, useTheme } from '@mui/material';
import { alpha, Theme } from '@mui/material/styles';

import PlayLargeIcon from '../../../../assets/icons/PlayLargeIcon';
import styled from '../../../../styled';

interface CardPlayIconProps {
  wrapperCss?: SerializedStyles | (({ theme }: { theme: Theme }) => SerializedStyles);
  iconCss?: SerializedStyles | (({ theme }: { theme: Theme }) => SerializedStyles);
}

const CardPlayIcon: React.FC<CardPlayIconProps> = ({ wrapperCss, iconCss, ...rest }) => {
  const theme = useTheme();
  return (
    <Wrapper optionalStyles {...rest}>
      <StyledPlayIcon iconCss={iconCss} color={theme.palette.secondary.contrastText} />
    </Wrapper>
  );
};

export default CardPlayIcon;

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})`
  position: absolute;
  width: 80px;
  height: 80px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    border-radius: 50%;
    background: ${({ theme }) => alpha(theme.palette.common.card.overlay.contrast, 0.5)};
  }
  ${({ optionalStyles }) => optionalStyles}
`;

const StyledPlayIcon = styled(PlayLargeIcon, {
  shouldForwardProp: (prop) => prop !== 'optionalStyles' && prop !== 'iconCss',
})`
  width: 21px;
  height: 24px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 33px;
    height: 37px;
    margin-left: 8px;
  }
  ${({ optionalStyles }) => optionalStyles}
`;
