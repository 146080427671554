import React, { createContext, useCallback, useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';

import { IS_USER_SAW_ONBOARDING_MODAL } from '../const';
import {
  GET_GOAL_OPTIONS,
  GET_LEARNER_GOALS,
  GET_SHOW_GOALS_FLOW,
  SET_LEARNER_GOALS,
} from '../graphql/goals';
import useAuth from '../hooks/useAuth';
import useValidation from '../hooks/useValidation';
import Modal from '../modals/Modal';
import {
  GoalsOptions,
  LearnerGoalInfo,
  Maybe,
  Mutation,
  MutationSetGoalsForLearnerArgs,
  Query,
  SetGoalsAnswers,
} from '../types';
import { checkConsent } from '../utils/checkConsent';

export interface IOnboardingContext {
  onboardingComplete: boolean;
  handleStep: (step?: number) => void;
  currentStep: number;
  validationProps: ReturnType<typeof useValidation<OnboardingValidationValues>>;
  goalOptions: GoalsOptions | undefined;
  learnerGoals: Maybe<LearnerGoalInfo> | undefined;
  showOnboardingFlow: Pick<Query, 'showSetGoalsFlow'> | undefined;
  onboardingShowed: boolean;
  setOnboardingShowed: (isSawOnboarding?: boolean) => void;
}

export interface OnboardingValidationValues {
  categories?: number[];
  answers: {
    main_reason: number | null;
    education_level: number | null;
    employment_status: number | null;
  };
  timeForLearningGoals: number | null;
}

export const OnboardingCtx = createContext<IOnboardingContext>({
  onboardingComplete: false,
  handleStep: () => {},
  currentStep: 0,
  validationProps: {} as ReturnType<typeof useValidation<OnboardingValidationValues>>,
  goalOptions: undefined,
  learnerGoals: undefined,
  showOnboardingFlow: undefined,
  onboardingShowed: false,
  setOnboardingShowed: () => {},
});

const initialGoals = {
  main_reason: null,
  education_level: null,
  employment_status: null,
};

export const initialValues = {
  categories: [],
  answers: initialGoals,
  timeForLearningGoals: null,
};

const OnboardingProvider = ({ children }) => {
  const [onboardingComplete, setOnboardingComplete] = useState<boolean>(false);
  const [onboardingShowed, setOnboardingShowed] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const { isAuthenticated } = useAuth();

  const { data: showOnboardingFlow } = useQuery<Pick<Query, 'showSetGoalsFlow'>>(
    GET_SHOW_GOALS_FLOW,
    {
      skip: !isAuthenticated,
    },
  );
  const { data: learnerGoalsData, refetch } = useQuery<Pick<Query, 'showLearnerGoals'>>(
    GET_LEARNER_GOALS,
    {
      skip: !isAuthenticated,
    },
  );
  const { data: goalOptionsData } = useQuery<Pick<Query, 'getGoalsOptions'>>(GET_GOAL_OPTIONS, {
    skip: !isAuthenticated,
    fetchPolicy: 'no-cache',
  });

  const [setLearnerGoals] = useMutation<
    Pick<Mutation, 'setGoalsForLearner'>,
    MutationSetGoalsForLearnerArgs
  >(SET_LEARNER_GOALS);

  const goalOptions = goalOptionsData?.getGoalsOptions;
  const learnerGoals = learnerGoalsData?.showLearnerGoals;

  const validationProps = useValidation<OnboardingValidationValues>(
    initialValues,
    () => {},
    () => {},
  );

  const { values, setValuesManually, setFieldManually } = validationProps;

  const handleSetFieldsManually = useCallback(
    (fields) => {
      const { categories, learning_goal_time_mins, answers } = fields;
      setValuesManually({
        categories: categories?.map(({ id }) => +id),
        timeForLearningGoals: learning_goal_time_mins,
        answers: {
          main_reason: +answers?.main_reason?.id,
          education_level: +answers?.education_level?.id,
          employment_status: +answers?.employment_status?.id,
        },
      });
    },
    [setValuesManually],
  );

  const handleResetGoals = () => {
    onboardingComplete && learnerGoals
      ? handleSetFieldsManually(learnerGoals)
      : setValuesManually(initialValues);
  };

  useEffect(() => {
    const hasUserSkippedOnboarding = localStorage.getItem(IS_USER_SAW_ONBOARDING_MODAL);
    if (hasUserSkippedOnboarding) {
      setOnboardingShowed(true);
    }
  }, []);

  const setIsUserSawOnboardingModal = (isSawOnboarding = true) => {
    setOnboardingShowed(isSawOnboarding);

    if (checkConsent(IS_USER_SAW_ONBOARDING_MODAL)) {
      if (isSawOnboarding) {
        localStorage.setItem(IS_USER_SAW_ONBOARDING_MODAL, '1');
        return;
      }

      localStorage.removeItem(IS_USER_SAW_ONBOARDING_MODAL);
    }
  };

  useEffect(() => {
    if (learnerGoals && !values?.timeForLearningGoals) {
      handleSetFieldsManually(learnerGoals);
    }
  }, [handleSetFieldsManually, learnerGoals, values?.timeForLearningGoals]);

  useEffect(() => {
    showOnboardingFlow?.showSetGoalsFlow === false && setOnboardingComplete(true);
  }, [showOnboardingFlow, showOnboardingFlow?.showSetGoalsFlow]);

  useEffect(() => {
    onboardingComplete && setCurrentStep(1);
  }, [onboardingComplete]);

  const handleSetLearnerGoals = useCallback(() => {
    setLearnerGoals({
      variables: {
        input: validationProps.values as SetGoalsAnswers,
      },
      onCompleted: () => refetch(),
    });
  }, [refetch, setLearnerGoals, validationProps.values]);

  const handleStep = (step?: number) => {
    setCurrentStep(step !== undefined ? step : (prevStep) => prevStep + 1);
  };

  const handleSkipOnboarding = () => {
    setCurrentStep(Number(onboardingComplete));
    handleResetGoals();
    setOnboardingShowed(true);
  };

  const finishOnboarding = () => {
    setCurrentStep(1);
    setOnboardingComplete(true);
  };

  const onboardingValue = {
    onboardingComplete,
    currentStep,
    handleStep,
    validationProps,
    goalOptions,
    learnerGoals,
    showOnboardingFlow,
    onboardingShowed,
    setOnboardingShowed: setIsUserSawOnboardingModal,
  };

  return (
    <OnboardingCtx.Provider value={onboardingValue}>
      <Modal
        handleSkipOnboarding={handleSkipOnboarding}
        currentStep={currentStep}
        validationProps={validationProps}
        onboardingComplete={onboardingComplete}
        setOnboardingShowed={setIsUserSawOnboardingModal}
        finishOnboarding={finishOnboarding}
        values={values}
        handleStep={handleStep}
        learnerGoals={learnerGoals}
        goalOptions={goalOptions}
        preventCloseOnOutsideClick
        setFieldManually={setFieldManually}
        handleSetLearnerGoals={handleSetLearnerGoals}
      >
        {children}
      </Modal>
    </OnboardingCtx.Provider>
  );
};

export default OnboardingProvider;
