import React, { useCallback, useEffect, useReducer, useState } from 'react';

import {
  Auth0Client,
  Auth0ClientOptions,
  CacheLocation,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
  PopupConfigOptions,
  PopupLoginOptions,
  RedirectLoginOptions as Auth0RedirectLoginOptions,
} from '@auth0/auth0-spa-js';
import { captureException } from '@sentry/react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

import { LOGIN_DOMAIN_COOKIE_NAME } from '../../const';
import { hostToTenantName } from '../../utils/hostToTenantName/hostToTenantName';
import { generateRandomString } from '../../utils/stringUtils';
import { TENANTS_CONFIG } from '../../utils/tenantsConfig';
import { RedirectLoginOptions } from './AuthContext';
import { AuthContext } from './AuthContext';
import { initialAuthState } from './AuthState';
import { reducer } from './reducer';
import { getCryptoSubtle, hasAuthParams, loginError, tokenError } from './utils';

const REFRESH_COOKIE_NAME = 'migrationStorage';

const algoritm = {
  name: 'RSA-OAEP',
  modulusLength: 4096,
  publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
  hash: 'SHA-256',
};

function hexDecode(string) {
  let bytes = [];
  string.replace(/../g, function (pair) {
    //@ts-ignore
    bytes.push(parseInt(pair, 16));
  });
  return new Uint8Array(bytes).buffer;
}

function arrayBufferToHex(arrayBuffer) {
  if (
    typeof arrayBuffer !== 'object' ||
    arrayBuffer === null ||
    typeof arrayBuffer.byteLength !== 'number'
  ) {
    throw new TypeError('Expected input to be an ArrayBuffer');
  }

  const view = new Uint8Array(arrayBuffer);
  let result = '';
  let value;

  for (let i = 0; i < view.length; i++) {
    value = view[i].toString(16);
    result += value.length === 1 ? '0' + value : value;
  }

  return result;
}

const hexedPublicKey = process.env.REACT_APP_PUBLIC_KEY;

export type AppState = {
  returnTo?: string;
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export interface AuthProviderOptions {
  children?: React.ReactNode;
  onRedirectCallback?: (appState: AppState, history?: any) => void;
  loginCallback?: () => void;
  skipRedirectCallback?: boolean;
  domain: string;
  issuer?: string;
  clientId: string;
  redirectUri?: string;
  leeway?: number;
  cacheLocation?: CacheLocation;
  useRefreshTokens?: boolean;
  authorizeTimeoutInSeconds?: number;
  advancedOptions?: {
    defaultScope?: string;
  };
  maxAge?: string | number;
  audience?: string;
  organization?: string;
  invitation?: string;
  isSsku: boolean;

  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export interface ExtendedPopupLoginOptions extends PopupLoginOptions {
  redirectUri?: string;
}

const getTenantConfig = () => TENANTS_CONFIG[hostToTenantName(window?.location?.hostname || '')];

/**
 * @ignore
 */
const toAuth0LoginRedirectOptions = (
  opts?: RedirectLoginOptions,
): Auth0RedirectLoginOptions | undefined => {
  if (!opts) {
    return;
  }
  const { redirectUri, ...validOpts } = opts;
  return {
    ...validOpts,
    redirect_uri: redirectUri,
  };
};

/**
 * @ignore
 */
const defaultOnRedirectCallback = (appState?: AppState, navigate?): void => {
  let returnTo = getTenantConfig().appMainPage;
  const redirectCode = appState?.redirectCode;
  const isDirectUrl = appState?.isDirectUrl;

  let searchParams = '';

  if (appState?.onlineContentConnectorData) {
    const newUrlParams = new URLSearchParams();
    Object.entries(appState.onlineContentConnectorData).forEach(([key, val]) => {
      if (val && typeof val === 'string') {
        newUrlParams.append(key, val);
      }
    });

    searchParams = `?${newUrlParams.toString()}`;
  }

  if (!isDirectUrl) {
    if (redirectCode) {
      //TODO remove later, used to fix redirect while login from old client
      const redirectCodeName = localStorage.getItem(redirectCode)
        ? redirectCode
        : redirectCode?.replace('returnTo_', '');
      const returnUrl = localStorage.getItem(redirectCodeName);
      returnTo = returnUrl || returnTo;
      localStorage.removeItem(redirectCodeName);
    }

    if (navigate) {
      navigate(`${returnTo}${searchParams}`, { replace: true });
    }
  } else {
    if (window?.location?.search && navigate) {
      navigate(`${window?.location?.pathname}${searchParams}`, { replace: true });
    }
  }
};

/**
 * ```jsx
 * <Auth0Provider
 *   domain={domain}
 *   clientId={clientId}
 *   redirectUri={window.location.origin}>
 *   <MyApp />
 * </Auth0Provider>
 * ```
 *
 * Provides the Auth0Context to its child components.
 */
let AuthProvider = (opts: AuthProviderOptions): JSX.Element => {
  const {
    children,
    skipRedirectCallback,
    onRedirectCallback = defaultOnRedirectCallback,
    loginCallback,
    clientId,
    isSsku,
    ...clientOpts
  } = opts;
  const [client, setClient] = useState<Auth0Client | null>(null);
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const tenantConfig = getTenantConfig();

  const navigate = useNavigate();

  useEffect(() => {
    //create client or update it if domain changed
    if (
      (!client && clientId) ||
      //@ts-ignore
      (client && !client?.domainUrl?.includes(state.loginDomain))
    ) {
      const opts: Auth0ClientOptions = {
        ...clientOpts,
        client_id: clientId,
        domain: state.loginDomain || '',
      };

      setClient(() => new Auth0Client(opts));
      dispatch({ type: 'CLIENT_INITIALISED' });
    }
  }, [client, clientId, clientOpts, state.loginDomain]);

  useEffect(() => {
    (async (): Promise<void> => {
      if (client) {
        try {
          if (hasAuthParams() && !skipRedirectCallback) {
            const { appState } = await client.handleRedirectCallback();
            loginCallback && loginCallback();
            onRedirectCallback(appState, navigate);
          } else {
            await client?.checkSession();
          }
          const user = await client?.getUser();
          dispatch({ type: 'INITIALISED', user });
        } catch (error) {
          dispatch({ type: 'ERROR', error: loginError(error as Error) });
        }
      }
    })();
  }, [client, onRedirectCallback, skipRedirectCallback, navigate, loginCallback]);

  const loginWithRedirect = useCallback(
    (opts: RedirectLoginOptions = {}): Promise<void> => {
      if (isSsku && process.env.REACT_APP_BUILD_TYPE === 'prod') {
        opts.connection = opts.connection || tenantConfig.auth.connection;
      }

      if (client) {
        const redirectUri = opts?.redirectUri;
        const mode = opts?.mode || 'login';
        const isDirectUrl = opts?.isDirectUrl;

        const redirectUrl = redirectUri || tenantConfig.appMainPage;

        let appState = {};

        if (!isDirectUrl) {
          //redirect random code
          const redirectCode = `returnTo_${generateRandomString(20)}`;
          //add redirect url with code to local storage
          localStorage.setItem(redirectCode, redirectUrl);

          appState = {
            redirectCode: redirectCode,
          };
        } else {
          appState = {
            isDirectUrl: true,
          };
        }

        appState = {
          ...appState,
          ...opts?.appState,
        };

        return client.loginWithRedirect(
          toAuth0LoginRedirectOptions({
            ...opts,
            redirectUri: `${window?.location?.origin}${
              isDirectUrl ? redirectUrl : tenantConfig.appMainPage
            }`,
            appState,
            mode: mode,
          }),
        );
      } else {
        return new Promise<void>((res) => {
          res();
        });
      }
    },
    [client, isSsku, tenantConfig],
  );

  const loginWithPopup = useCallback(
    async (options?: ExtendedPopupLoginOptions, config?: PopupConfigOptions): Promise<void> => {
      if (client) {
        dispatch({ type: 'LOGIN_POPUP_STARTED' });

        let loginOptions;
        if (options) {
          const { redirectUri, ...restOptions } = options;
          loginOptions = restOptions;
        } else {
          loginOptions = options;
        }

        try {
          await client.loginWithPopup(loginOptions, config);
        } catch (error) {
          dispatch({ type: 'ERROR', error: loginError(error as Error) });
          return;
        }
        const user = await client.getUser();
        dispatch({ type: 'LOGIN_POPUP_COMPLETE', user });
        if (options?.redirectUri) {
          navigate(options.redirectUri, { replace: true });
        }
      } else {
        return new Promise<void>((res) => {
          res();
        });
      }
    },
    [client, navigate],
  );

  const logout = useCallback(
    (opts: LogoutOptions = {}): void => {
      Cookies.remove(LOGIN_DOMAIN_COOKIE_NAME);
      client?.logout({
        returnTo: isSsku ? tenantConfig?.auth.logoutUrl : window?.location?.origin,
        ...opts,
      });
      if (opts.localOnly) {
        dispatch({ type: 'LOGOUT' });
      }
    },
    [client, isSsku, tenantConfig],
  );

  const getAccessTokenSilently = useCallback(
    async (opts?: GetTokenSilentlyOptions): Promise<string> => {
      let token;
      try {
        token = await client?.getTokenSilently(opts);
      } catch (error) {
        throw tokenError(error as Error);
      } finally {
        dispatch({
          type: 'GET_ACCESS_TOKEN_COMPLETE',
          user: await client?.getUser(),
        });
      }

      const authCookieName = `@@auth0spajs@@::${clientId}::${process.env.REACT_APP_AUTH0_AUDIENCE}::openid profile email has-subscription offline_access`;

      try {
        const tokenInfoJSON = localStorage.getItem(authCookieName);
        const tokenInfo = tokenInfoJSON ? JSON.parse(tokenInfoJSON) : undefined;
        const refreshToken = tokenInfo?.body?.refresh_token;

        if (refreshToken) {
          const crypto = getCryptoSubtle();
          const enc = new TextEncoder();
          const publicKey = await crypto.importKey(
            'spki',
            hexDecode(hexedPublicKey),
            algoritm,
            false,
            ['encrypt'],
          );

          const encryptedString = await crypto.encrypt(
            { name: 'RSA-OAEP' },
            publicKey,
            enc.encode(refreshToken),
          );

          Cookies.set(REFRESH_COOKIE_NAME, arrayBufferToHex(encryptedString), {
            expires: 30,
            secure: true,
            sameSite: 'Strict',
          });
        }
      } catch (e) {
        captureException(e);
      }

      return token;
    },
    [client, clientId],
  );

  const getAccessTokenWithPopup = useCallback(
    async (opts?: GetTokenWithPopupOptions, config?: PopupConfigOptions): Promise<string> => {
      if (client) {
        let token;
        try {
          token = await client.getTokenWithPopup(opts, config);
        } catch (error) {
          throw tokenError(error as Error);
        } finally {
          dispatch({
            type: 'GET_ACCESS_TOKEN_COMPLETE',
            user: await client.getUser(),
          });
        }
        return token;
      } else {
        return new Promise<string>((res) => {
          // TODO: ???
          // @ts-ignore
          res();
        });
      }
    },
    [client],
  );

  const updateUserInfo = useCallback((user) => {
    dispatch({
      type: 'UPDATE_USER_INFO',
      userInfo: user,
    });
  }, []);

  const updateLoginDomain = useCallback((loginDomain: string) => {
    dispatch({
      type: 'UPDATE_LOGIN_DOMAIN',
      loginDomain,
    });
    Cookies.set(LOGIN_DOMAIN_COOKIE_NAME, loginDomain, {
      expires: 30,
      secure: true,
      sameSite: 'Strict',
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        getAccessTokenSilently,
        loginWithRedirect,
        logout,
        updateUserInfo,
        loginWithPopup,
        getAccessTokenWithPopup,
        updateLoginDomain,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

if (process.env.REACT_APP_MSW) {
  AuthProvider = ({ children }) => {
    return (
      <AuthContext.Provider
        // @ts-ignore
        value={{
          isAuthenticated: true,
          isLoading: false,
          isInitialized: true,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  };
}

export { AuthProvider };
