import React, { forwardRef } from 'react';

import Button from '@mui/material/Button';

import styled from '../../../styled';

interface IconButtonProps {
  className?: string;
  label?: string;
  icon: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  size: 'small' | 'medium' | 'large';
}

const IconButton: React.FC<IconButtonProps> = forwardRef(
  ({ icon, label, className, onClick, disabled = false, ...rest }, ref) => (
    <StyledButton disabled={disabled} ref={ref} className={className} onClick={onClick} {...rest}>
      {icon}
      <ButtonLabel className={'buttonLabel'}>{label}</ButtonLabel>
    </StyledButton>
  ),
);

export default IconButton;

const StyledButton = styled(Button)`
  min-height: 24px;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  text-decoration: none;
  font-size: 14px;
  position: relative;
  color: ${({ theme }) => theme.palette.primary.light};
  border-radius: 0;
  transition: 0.1s ease all;
  display: flex;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 16px;
  }

  &:hover {
    background: transparent;

    & .buttonLabel {
      color: ${({ theme }) => theme.palette.primary.light};
    }
  }

  &.Mui-disabled {
    opacity: 0.3;
  }
`;
const ButtonLabel = styled('span')`
  margin-left: 6px;
  color: ${({ theme }) => theme.palette.common.white};
`;
