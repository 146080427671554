import React from 'react';

import { styled } from '@mui/material';

import Autocomplete from '../../components/Autocomplete/Autocomplete';
import useTenantTranslation from '../../hooks/useTenantTranslation';
import { Col, Wrapper, StyledTextField, StyledTextFieldWide } from './styles';

const ExtendedFormFields = ({
  values,
  errors,
  handleChange,
  categoriesOptions,
  handleAutocompleteChange,
}) => {
  const { t } = useTenantTranslation();

  return (
    <Wrapper>
      <Col>
        <StyledTextFieldWide
          variant="outlined"
          name="email"
          type="email"
          value={values.email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
          label={`${t('input.placeholder.workEmail')}*`}
        />
      </Col>
      <Col>
        <StyledTextField
          variant="outlined"
          name="name"
          value={values.name}
          onChange={handleChange}
          error={!!errors.name}
          helperText={errors.name}
          label={`${t('input.placeholder.yourName')}*`}
        />
        <StyledTextField
          variant="outlined"
          name="company"
          value={values.company}
          onChange={handleChange}
          error={!!errors.company}
          helperText={errors.company}
          label={`${t('input.placeholder.companyName')}*`}
        />
      </Col>
      <Col>
        <StyledTextField
          variant="outlined"
          name="jobTitle"
          value={values.jobTitle}
          onChange={handleChange}
          error={!!errors.jobTitle}
          helperText={errors.jobTitle}
          label={`${t('input.placeholder.jobTitle')}*`}
        />
        <StyledTextField
          variant="outlined"
          name="country"
          value={values.country}
          onChange={handleChange}
          error={!!errors.country}
          helperText={errors.country}
          label={`${t('input.placeholder.country')}*`}
        />
      </Col>
      <Col>
        <StyledAutocomplete
          multiple
          onChange={handleAutocompleteChange('categoriesInterested')}
          value={values.categoriesInterested}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderInput={(params) => (
            <StyledTextFieldWide
              {...params}
              variant="outlined"
              name="categoriesInterested"
              label={`${t('input.placeholder.categoriesInterested')}`}
            />
          )}
          options={categoriesOptions}
        />
      </Col>
    </Wrapper>
  );
};

export default ExtendedFormFields;

const StyledAutocomplete = styled(Autocomplete)`
  width: 100%;
`;
