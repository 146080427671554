import { phoneNumberRegExp } from '../../utils/regexps';
import { createSchema, createValidation } from '../../utils/validator';

const getBasicFields = (t) => ({
  name: createValidation().required(t('error.fieldRequired')),
  email: createValidation().required(t('error.fieldRequired')).email(t('error.emailIncorrect')),
  company: createValidation().required(t('error.fieldRequired')),
  phone: createValidation().match(phoneNumberRegExp, t('error.invalidPhone')),
});

export const letsTalkValidation = (t) => createSchema(getBasicFields(t));

export const letsTalkExtendedValidation = (t) =>
  createSchema({
    ...getBasicFields(t),
    company: createValidation().required(t('error.fieldRequired')),
    jobTitle: createValidation().required(t('error.fieldRequired')),
    categoriesInterested: createValidation(),
  });
