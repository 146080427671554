import React from 'react';

import { SerializedStyles } from '@emotion/react/dist/emotion-react.cjs';
import { Box, useTheme } from '@mui/material';
import { alpha, Theme } from '@mui/material/styles';

import RepeatIcon from '../../../../assets/icons/RepeatIcon';
import styled from '../../../../styled';

interface CardRepeatIconProps {
  wrapperCss?: SerializedStyles | (({ theme }: { theme: Theme }) => SerializedStyles);
  iconCss?: SerializedStyles | (({ theme }: { theme: Theme }) => SerializedStyles);
}

const CardRepeatIcon: React.FC<CardRepeatIconProps> = ({ wrapperCss, iconCss, ...rest }) => {
  const theme = useTheme();
  return (
    <IconWrapper optionalStyles={wrapperCss} {...rest}>
      <StyledRepeatIcon optionalStyles={iconCss} color={theme.palette.secondary.contrastText} />
    </IconWrapper>
  );
};

export default CardRepeatIcon;

const IconWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})`
  position: absolute;
  width: 80px;
  height: 80px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    border-radius: 50%;
    background: ${({ theme }) => alpha(theme.palette.common.card.overlay.contrast, 0.5)};
  }
  ${({ optionalStyles }) => optionalStyles}
`;
const StyledRepeatIcon = styled(RepeatIcon, {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})`
  width: 24px;
  height: 24px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 37px;
    height: 37px;
  }
`;
