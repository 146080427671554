import React from 'react';

import LandScapeVideo from './LandscapeVideo';
import PortraitVideo from './ProtraitVideo';

export interface VideoInterface {
  orientation: string;
  videoWidth: number | null;
  handleClick?: (id: string) => void;
  bumperUrl?: string | null;
  shouldOpenInModal?: boolean;

  [x: string]: any;
}

const Video = React.memo((props: VideoInterface) => {
  const orientation = props?.orientation;
  const videoWidth = props?.videoWidth;

  if (orientation === 'Portrait') {
    return <PortraitVideo {...props} />;
  }

  return <LandScapeVideo {...props} videoWidth={videoWidth} />;
});

export default Video;
