import React, { FC, ReactNode, RefObject, useEffect, useRef, useState } from 'react';

import { styled } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { m, useMotionValue, useTransform } from 'framer-motion';

import CloseIcon from '../../assets/icons/CloseIcon';
import ExpandPipIcon from '../../assets/icons/ExpandPipIcon';
import useEventListener from '../../hooks/useEventListener';
import useTenantTranslation from '../../hooks/useTenantTranslation';
import {
  BIG_MARGIN,
  HEADER_HEIGHT,
  layers,
  SMALL_MARGIN_PX,
  SMALL_RADIUS_PX,
  useIsWidthUp,
} from '../../theme';
import sendAnalyticEvent from '../../utils/sendAnalyticEvent';
import Ratio from '../Ratio';
import Resizable from '../Resizable';
import CustomButton from '../UI/CustomButton/CustomButton';
import IconButton from '../UI/IconButton/IconButton';
import usePip from './usePip';

interface IPip {
  children: ReactNode;
  displayCenteredButton: boolean;
  pipFooterContent: ReactNode;
  fullSizeWrapperRef: RefObject<HTMLElement>;
  controls: ReturnType<typeof usePip>;
  onExpandClick: () => void;
  videoFinished: boolean;
}

export const PIP_HEADER_HEIGHT = 58;

const Pip: FC<IPip> = ({
  children,
  pipFooterContent,
  fullSizeWrapperRef,
  controls,
  displayCenteredButton,
  onExpandClick,
  videoFinished,
}) => {
  const { t } = useTenantTranslation();
  const [draggingPip, setDraggingPip] = useState(false);
  const pipRef = useRef<HTMLDivElement>(null);
  const isMobile = !useIsWidthUp('md');

  const mPipWidth = useMotionValue(controls.size.w);
  const mPipHeight = useTransform(mPipWidth, (v) => (v * 56) / 100);
  const mPipX = useMotionValue(controls.pos.x);
  const mPipY = useMotionValue(controls.pos.y);
  const constraints = controls.calcConstraints(controls.size.w, controls.size.h, BIG_MARGIN);

  useEventListener('resize', () => controls.disable());

  useEffect(() => {
    if (controls.active) {
      sendAnalyticEvent('Video_Player_MiniPlayer');
    }
  }, [controls.active]);

  if (isMobile) {
    return children;
  }

  const handleExpand = () => {
    onExpandClick();
    sendAnalyticEvent('Video_Player_MiniPlayer_expand');
  };

  return (
    <Resizable
      offsetTop={-PIP_HEADER_HEIGHT}
      mW={mPipWidth}
      mH={mPipHeight}
      mX={mPipX}
      mY={mPipY}
      wMin={controls.size.wMin}
      wMax={controls.size.wMax}
      display={controls.active}
      onResize={({ w, h }) => {
        const constraints = controls.calcConstraints(w, h, BIG_MARGIN);
        controls.updateSize((old) => ({ ...old, w, h }));

        if (mPipX.get() > constraints.right) {
          mPipX.jump(constraints.right);
        }

        if (mPipY.get() > constraints.bottom) {
          mPipY.jump(constraints.bottom);
        }
      }}
      wrapperRef={pipRef}
    >
      {(isDraggingResizeCorner) => (
        <MotionDiv
          resizing={isDraggingResizeCorner}
          onDragStart={() => setDraggingPip(true)}
          onDragEnd={() => setDraggingPip(false)}
          layout
          style={{
            width: controls.active
              ? mPipWidth
              : fullSizeWrapperRef.current?.getBoundingClientRect().width,
            x: mPipX,
            y: mPipY,
          }}
          drag={controls.active}
          transition={{ type: 'spring', bounce: 0.15 }}
          whileDrag={{ scale: 1.1 }}
          dragMomentum={false}
          dragConstraints={{
            top: constraints.top + PIP_HEADER_HEIGHT + HEADER_HEIGHT,
            left: constraints.left,
            right: constraints.right,
            bottom: constraints.bottom - PIP_HEADER_HEIGHT,
          }}
          active={controls.active}
          animate={controls.pos}
        >
          {controls.active && (
            <>
              {videoFinished && <DarkOverlay visible />}
              {displayCenteredButton && (
                <ExpandPipButton onClick={handleExpand}>
                  <StyledExpandPipIcon />
                  {t('page.video.expandVideo')}
                </ExpandPipButton>
              )}
              <PipHeader>
                <IconButton
                  size={'small'}
                  icon={<ExpandPipIcon />}
                  label={t('page.video.expandVideo')}
                  onClick={handleExpand}
                />
                <StyledCloseIcon
                  onClick={() => {
                    controls.disable();
                    sendAnalyticEvent('Video_Player_MiniPlayer_close');
                  }}
                />
              </PipHeader>
            </>
          )}
          <StyledRatio shadow={controls.active} ref={pipRef}>
            {children}
          </StyledRatio>
          {controls.active && <PipFooter>{pipFooterContent}</PipFooter>}
          {
            // workaround to fix inability of dragging when mouse cursor on top of iframe
            <Overlay
              fadeOut={isDraggingResizeCorner}
              visible={draggingPip || isDraggingResizeCorner}
            />
          }
        </MotionDiv>
      )}
    </Resizable>
  );
};

export default Pip;

const MotionDiv = styled(m.div, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'resizing',
})<{ active?: boolean; resizing?: boolean }>`
  position: ${({ active }) => active && 'fixed'};
  z-index: ${layers.pip};
  display: ${({ active }) => active && 'table'};
  border-radius: ${({ active }) => (active ? SMALL_RADIUS_PX : '10px 10px 0 0')};
  overflow: ${({ active }) => !active && 'hidden'};
  width: ${({ active }) => !active && '100% !important'};
`;
const PipHeader = styled(m.div)`
  position: absolute;
  top: -${PIP_HEADER_HEIGHT}px;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  width: 100%;
  height: ${PIP_HEADER_HEIGHT + 1}px;
  padding: ${SMALL_MARGIN_PX};
  border-radius: ${SMALL_RADIUS_PX} ${SMALL_RADIUS_PX} 0 0;
  background: ${({ theme }) => theme.palette.common.blockBackground.light};
  cursor: grab;
  transition: 0.3s background;

  &:hover {
    background: ${({ theme }) => theme.palette.common.card.lighterAccent};
  }

  &:active {
    cursor: grabbing;
  }
`;
const PipFooter = styled(PipHeader)`
  top: unset;
  left: 0;
  bottom: -${PIP_HEADER_HEIGHT - 1}px;
  border-radius: 0 0 ${SMALL_RADIUS_PX} ${SMALL_RADIUS_PX};
`;
const StyledCloseIcon = styled(CloseIcon)`
  width: 20px;
  height: 20px;
  padding: 3px;
  margin-left: auto;
  box-sizing: content-box;
  cursor: pointer;
`;
const ExpandPipButton = styled(CustomButton)`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  white-space: nowrap;
`;
const StyledExpandPipIcon = styled(ExpandPipIcon)`
  margin-right: ${SMALL_MARGIN_PX};
`;
const Overlay = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fadeOut' && prop !== 'visible',
})<{ fadeOut?: boolean; visible?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  background: ${({ fadeOut, theme }) => alpha(theme.palette.common.blackPure, fadeOut ? 0.4 : 0)};
  transition: 0.9s;
`;
const DarkOverlay = styled(Overlay)`
  background: ${({ theme }) => theme.palette.common.blackPure};
`;
const StyledRatio = styled(Ratio, {
  shouldForwardProp: (prop) => prop !== 'shadow',
})<{ shadow: boolean }>`
  &::before {
    content: '';
    position: absolute;
    top: -${PIP_HEADER_HEIGHT}px;
    left: 0;
    width: calc(100% - 5px - 5px);
    margin: 5px;
    height: calc(100% + ${PIP_HEADER_HEIGHT}px + ${PIP_HEADER_HEIGHT}px - 5px - 5px);
    visibility: ${({ shadow }) => (shadow ? 'visible' : 'hidden')};
    box-shadow: ${({ theme }) => `10px 5px 40px 0px ${theme.palette.common.blackPure}`};
  }
`;
