const getLoadUrl = (checkSSR = true) => {
  const isLocalBuild = process.env.REACT_APP_IS_LOCALLY === 'true';
  const isSSR = checkSSR ? window?.isSSR : false;
  const isStorybook = process.env.IS_STORYBOOK;

  return process.env.NODE_ENV === 'production' && !isLocalBuild && !isSSR && !isStorybook
    ? `${process.env.REACT_APP_IMAGEKIT_URL}react-static-files/${process.env.REACT_APP_BUILD_TYPE}`
    : '';
};

export const getLoadPath = () => {
  const url = getLoadUrl();
  const path = `/locales/{{lng}}${
    process.env.NODE_ENV === 'production' && !window?.isSSR && !process.env.IS_STORYBOOK
      ? '.' + process.env.LANG_VERSION
      : ''
  }.json`;
  return `${url}${path}`;
};

export const getTranslationFilePreloadPath = (locale, timestamp) => {
  const url = getLoadUrl(false);
  return `${url}/locales/${locale}${timestamp ? '.' + timestamp : ''}.json`;
};
