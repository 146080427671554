import Cookies from 'js-cookie';

import { LOGIN_DOMAIN_COOKIE_NAME } from '../../const';

export type User = any; // eslint-disable-line @typescript-eslint/no-explicit-any

export interface AuthState {
  error?: Error;
  isAuthenticated: boolean;
  isLoading: boolean;
  isInitialized: boolean;
  user?: User;
  userSub?: string;
  userInfo?: User;
  loginDomain?: string;
  updatingClient?: boolean;
}

export const initialAuthState: AuthState = {
  isAuthenticated: false,
  isLoading: true,
  isInitialized: false,
  loginDomain: Cookies.get(LOGIN_DOMAIN_COOKIE_NAME) || process.env.REACT_APP_AUTH0_DOMAIN,
  updatingClient: false,
};
