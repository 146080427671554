import { captureException } from '@sentry/react';

class ScriptLoader {
  url: string = '';
  name: string = '';
  isProd: boolean = false;
  sentry?: { captureException: typeof captureException };

  constructor(name, url, sentry) {
    this.url = url;
    this.name = name;
    this.isProd = process.env.NODE_ENV === 'production';
    this.sentry = sentry;
  }

  handleError(error) {
    if (this.isProd && this.sentry) {
      this.sentry.captureException(error);
    } else {
      console.error(error);
    }
  }

  mount(callback) {
    try {
      const script = document.createElement('script');
      script.src = this.url;
      script.async = true;

      document.body.appendChild(script);

      script.onload = () => callback(script);
      script.onerror = () => {
        this.handleError(new Error(`Loading error in script "${this.name}"`));
      };
    } catch (error) {
      this.handleError(error);
    }
  }
}

export default ScriptLoader;
