import React from 'react';

import { Element, useSvgColor, Svg } from './styles';

const MenuIcon = ({ color = '', ...props }) => {
  const svgColor = useSvgColor(color);
  return (
    <Svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}
      svgColor={svgColor}
    >
      <Element as="line" x1="1.99999" y1="4" x2="22.00001" y2="4" />
      <Element as="line" x1="1.99999" y1="12.00001" x2="22.00001" y2="12.00001" />
      <Element as="line" x1="1.99999" y1="20.00001" x2="22.00001" y2="20.00001" />
    </Svg>
  );
};

export default MenuIcon;
