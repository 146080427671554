type QueryStringParamsType = { [x: string]: string; [x: number]: string } | null;
const getQueryStringParams = (value: string = ''): QueryStringParamsType => {
  let result: QueryStringParamsType = null;

  if (value) {
    const urlParams = new URLSearchParams(value);
    result = Object.fromEntries(urlParams);
  }

  return result;
};

export default getQueryStringParams;
