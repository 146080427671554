import React, { createContext, FC, ReactNode, useContext } from 'react';

const StaticRouterContext = createContext<null | {
  statusCode?: number;
}>(null);

export const useStaticRouterContext = () => useContext(StaticRouterContext);

type Props = {
  children: ReactNode;
  staticContext: { statusCode?: number };
};

const StaticRouterProvider: FC<Props> = ({ children, staticContext }) => {
  return (
    <StaticRouterContext.Provider value={staticContext}>{children}</StaticRouterContext.Provider>
  );
};

export default StaticRouterProvider;
