import React, { ReactNode } from 'react';

import { SerializedStyles } from '@emotion/react/dist/emotion-react.cjs';
import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';

import styled from '../../../../styled';
import { SMALL_MARGIN_PX } from '../../../../theme';

interface CardLabelsProps {
  wrapperCss?: SerializedStyles | (({ theme }: { theme: Theme }) => SerializedStyles);
  children: ReactNode;
}

const CardLabels: React.FC<CardLabelsProps> = ({ wrapperCss, children }) => {
  return <Wrapper optionalStyles={wrapperCss}>{children}</Wrapper>;
};

export default CardLabels;

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})`
  display: inline-flex;
  flex-wrap: wrap;
  & > a {
    margin-right: 10px;
    margin-bottom: ${SMALL_MARGIN_PX};
  }
  & > div {
    margin-right: 10px;
    margin-bottom: ${SMALL_MARGIN_PX};
    &:last-child {
      margin-right: 0;
    }
  }
  ${({ optionalStyles }) => optionalStyles}
`;
