import { useContext } from 'react';

import { useMutation } from '@apollo/client';
import { captureException } from '@sentry/react';

import { UserInfoCtx } from '../containers/CommonLayout/CommonLayout';
import { useAlert } from '../context/AlertProvider';
import { FOLLOW_EXPERT, UNFOLLOW_EXPERT } from '../graphql/user';
import useTenantTranslation from '../hooks/useTenantTranslation';
import { FollowedEntityType, Mutation, MutationFollowArgs, MutationUnfollowArgs } from '../types';
import { toBoolean } from '../utils/toBoolean';

const useFollowExperts = (ids: (string | undefined | null)[] = []) => {
  const sanitizedIds = ids.filter(toBoolean<string>);
  const { userInfo } = useContext(UserInfoCtx);
  const followed = userInfo?.followings?.find(
    (following) =>
      following?.followed_entity?.id && sanitizedIds.includes(following?.followed_entity?.id),
  );

  const followingIdsMap = userInfo?.followings
    ? Object.fromEntries(
        userInfo?.followings?.map((following) => [[following?.followed_entity?.id], following?.id]),
      )
    : {};

  const { showAlert } = useAlert();
  const { t } = useTenantTranslation();

  const [follow, { loading: followLoading }] = useMutation<
    Pick<Mutation, 'follow'>,
    MutationFollowArgs
  >(FOLLOW_EXPERT);
  const [unfollow, { loading: unfollowLoading }] = useMutation<
    Pick<Mutation, 'unfollow'>,
    MutationUnfollowArgs
  >(UNFOLLOW_EXPERT);

  const handleUnfollow = async () => {
    await unfollow({
      variables: {
        ids: sanitizedIds.map((expertId) => followingIdsMap[expertId]),
      },
      refetchQueries: ['GetLearnerInfo'],
      awaitRefetchQueries: true,
    });
    showAlert({
      message: sanitizedIds.length > 1 ? t('alert.expertsUnfollowed') : t('alert.expertUnfollowed'),
    });
  };

  const toggle = async () => {
    try {
      if (followed) {
        await handleUnfollow();
        return;
      }
      await follow({
        variables: {
          input: {
            followed_entity_id: Number(sanitizedIds.at(0)),
            followed_entity_type: FollowedEntityType.Expert,
          },
        },
        refetchQueries: ['GetLearnerInfo'],
        awaitRefetchQueries: true,
      });
      showAlert({
        message: t('alert.expertFollowed'),
      });
    } catch (e) {
      captureException(e);
    }
  };

  return {
    followed,
    toggleFollowing: toggle,
    unfollow: handleUnfollow,
    loading: followLoading || unfollowLoading,
  };
};

export default useFollowExperts;
