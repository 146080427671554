import JSCookies from 'js-cookie';

class Cookies {
  private token: null;
  private network: null;
  private place: null;

  constructor() {
    this.token = null;
    this.network = null;
    this.place = null;
  }

  set(name, value) {
    JSCookies.set(name, value);
    this[name] = value;
  }

  get(name) {
    if (!this[name]) {
      this[name] = JSCookies.get(name);
    }
    return this[name];
  }

  clear() {
    JSCookies.remove('token');
    JSCookies.remove('network');
    JSCookies.remove('place');
    this.token = null;
    this.network = null;
    this.place = null;
  }
}

export default new Cookies();
