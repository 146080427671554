import React from 'react';

import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import styled from '../../../../styled';

interface CardDurationInfoProps {
  className?: string;
  durationName?: string | null;
  duration?: string | null;
  link?: string | null;
  reverse?: boolean;
}

const CardDurationInfo: React.FC<CardDurationInfoProps> = ({
  className,
  durationName,
  duration,
  link,
  reverse = false,
}) => {
  const name = link ? <Link to={link}>{durationName}</Link> : durationName;

  return (
    <StyledText data-testid="cardInfo" className={className}>
      {reverse ? duration : name}
      {durationName && duration && ' • '}
      {reverse ? name : duration}
    </StyledText>
  );
};

export default CardDurationInfo;

const StyledText = styled(Typography)`
  font-size: 14px;
  line-height: 1.2;
  font-weight: normal;
  color: ${({ theme }) => theme.palette.common.text.meta};
  & > a {
    color: ${({ theme }) => theme.palette.common.text.meta};
  }
`;
