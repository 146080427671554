export const generateContinueWatchingLink = (
  url: string,
  chapter?: string | number | null,
  time?: string | number | null,
) => {
  if ((chapter || (chapter === 0 && time !== '0')) && time) {
    return `${url}?chapter=${chapter}&time=${time}`;
  } else {
    return url;
  }
};
