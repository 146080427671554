import React, { FC, useContext } from 'react';

import { Container, useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import CourseIcon from '../../assets/icons/CourseIcon';
import { IKImage } from '../../components/IKImage';
import PathwayLogo from '../../components/TestResults/components/PathwayLogo';
import { CustomButton } from '../../components/UI/CustomButton';
import { BodyText, ButtonText, H2 } from '../../components/UI/Texts';
import { TenantCtx } from '../../context/TenantProvider';
import useTenantTranslation from '../../hooks/useTenantTranslation';
import styled from '../../styled';
import { BIG_MARGIN_PX, MIDDLE_MARGIN_PX, SMALL_MARGIN_PX } from '../../theme';

interface CourseSlideProps {
  title?: string | null;
  description?: string | null;
  imageUrl?: string | null;
  foregroundImageUrl?: string | null;
  foregroundImageName?: string | null;
  slug?: string | null;
  icon?: string | null;
  isDarkSlide?: boolean;
}

const CourseSlide: FC<CourseSlideProps> = ({
  title,
  description,
  imageUrl,
  foregroundImageUrl,
  foregroundImageName,
  slug,
  icon,
  isDarkSlide = true,
}) => {
  const theme = useTheme();
  const { tenant } = useContext(TenantCtx);
  const { t } = useTenantTranslation();

  const hasForegroundImage = Boolean(foregroundImageUrl);
  const content = (
    <>
      <Subtitle>{t('block.coursesBlock.featuredCourse')}</Subtitle>
      <TitleRow>
        <StyledPathwayLogo
          circleUrl={tenant?.circle_image_url}
          icon={icon || <CourseIcon secondaryColor={alpha(theme.palette.common.white, 0.33)} />}
        />
        <Title>{title}</Title>
      </TitleRow>
      <Description>{description}</Description>
      <Link to={`/courses/${slug}`}>
        <StyledCustomButton>{t('button.exploreCourse')}</StyledCustomButton>
      </Link>
    </>
  );

  return hasForegroundImage ? (
    <WrapperWithForeground>
      <BgImageWrapperWithForeground isDarkSlide={isDarkSlide}>
        <BgImage path={imageUrl} />
        <ForegroundImage alt={foregroundImageName} path={foregroundImageUrl} />
      </BgImageWrapperWithForeground>
      <StyledContainer>
        <ContentWrapperWithForeground>{content}</ContentWrapperWithForeground>
      </StyledContainer>
    </WrapperWithForeground>
  ) : (
    <Wrapper>
      <BgImageWrapper isDarkSlide={isDarkSlide}>
        <BgImage path={imageUrl} />
      </BgImageWrapper>
      <StyledContainer>
        <ContentWrapper>{content}</ContentWrapper>
      </StyledContainer>
    </Wrapper>
  );
};

export default CourseSlide;

const Wrapper = styled('div')`
  padding: 200px 0 ${BIG_MARGIN_PX} 0;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 100px 108px;
  }
`;

const WrapperWithForeground = styled('div')`
  padding-bottom: ${BIG_MARGIN_PX};
  @media (min-width: 1366px) {
    padding: 100px 108px;
  }
`;

const BgImageWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDarkSlide',
})`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 390px;
  overflow: hidden;

  &::before {
    content: '';
    width: 100%;
    height: 180px;
    position: absolute;
    bottom: 0;
    background: linear-gradient(
      0deg,
      ${({ theme }) => theme.palette.common.blueDark} 0%,
      ${({ theme }) => alpha(theme.palette.common.blueDark, 0.0001)} 100%
    );
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    background: linear-gradient(
      0deg,
      ${({ isDarkSlide, theme }) =>
          isDarkSlide
            ? theme.palette.common.blockBackground.light
            : theme.palette.common.blockBackground.main}
        0%,
      ${({ theme }) => alpha(theme.palette.common.blockBackground.main, 0.0001)} 100%
    );
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 100%;

    &::before {
      background: linear-gradient(
        90deg,
        ${({ isDarkSlide, theme }) =>
            alpha(
              isDarkSlide
                ? theme.palette.common.blockBackground.main
                : theme.palette.common.blockBackground.light,
              0.95,
            )}
          31.77%,
        ${({ theme }) => alpha(theme.palette.common.blockBackground.main, 0.0001)} 100%
      );
      height: 100%;
    }
  }
`;

const BgImageWrapperWithForeground = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDarkSlide',
})`
  width: 100%;
  position: relative;
  overflow: hidden;
  max-height: 250px;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    background: ${({ theme }) => alpha(theme.palette.common.blockBackground.main, 0.85)};
  }

  &::after {
    content: '';
    width: 100%;
    height: 60px;
    position: absolute;
    bottom: 0;
    display: block;
    background: linear-gradient(
      0deg,
      ${({ theme }) => theme.palette.common.blockBackground.main} 0%,
      ${({ theme }) => alpha(theme.palette.common.blockBackground.main, 0)} 100%
    );
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-height: 390px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    &::before {
      background: ${({ theme, isDarkSlide }) =>
        alpha(
          isDarkSlide
            ? theme.palette.common.blockBackground.main
            : theme.palette.common.blockBackground.light,
          0.85,
        )};
      height: 100%;
    }
    &::after {
      height: 180px;
      background: linear-gradient(
        0deg,
        ${({ theme, isDarkSlide }) =>
            isDarkSlide
              ? theme.palette.common.blockBackground.main
              : theme.palette.common.blockBackground.light}
          0%,
        ${({ theme }) => alpha(theme.palette.common.blockBackground.main, 0)} 100%
      );
    }
  }

  @media (min-width: 1366px) {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    max-height: 100%;
  }
`;

const BgImage = styled(IKImage, {
  shouldForwardProp: (prop) => prop !== 'wrapperWidth',
})`
  object-fit: cover;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 100%;
  }
`;

const ForegroundImage = styled(IKImage)`
  position: absolute;
  bottom: 0;
  right: 50%;
  transform: translateX(50%);
  max-height: 100%;
  max-width: 100%;
  ${({ theme }) => theme.breakpoints.up('md')} {
    right: -15px;
    transform: none;
  }
  @media (min-width: 1366px) {
    max-width: 55%;
  }
`;

const StyledContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'wrapperWidth',
})`
  display: flex;
  align-items: center;
  margin: 0;

  ${({ theme }) => theme.breakpoints.up('xl')} {
    margin: 0 auto;
  }
`;

const ContentWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'wrapperWidth',
})`
  z-index: 2;

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 600px;
    height: 420px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const ContentWrapperWithForeground = styled('div', {
  shouldForwardProp: (prop) => prop !== 'wrapperWidth',
})`
  z-index: 2;

  @media (min-width: 1366px) {
    max-width: 600px;
    height: 420px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const Subtitle = styled(ButtonText, {
  shouldForwardProp: (prop) => prop !== 'wrapperWidth',
})`
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.common.text.metaDark};
  font-weight: bold;
  margin-bottom: ${SMALL_MARGIN_PX};
`;

const TitleRow = styled('div', {
  shouldForwardProp: (prop) => prop !== 'wrapperWidth',
})`
  margin-bottom: ${SMALL_MARGIN_PX};
  display: flex;
  align-items: center;
`;

const StyledPathwayLogo = styled(PathwayLogo)`
  width: 36px;
  min-width: 36px;
  height: 36px;
  margin-right: ${SMALL_MARGIN_PX};
  margin-bottom: 0 !important;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 54px;
    min-width: 54px;
    height: 54px;
  }
`;
const Title = styled(H2)`
  font-family: ${({ theme }) => theme.typography.specialFontFamily};
`;
const Description = styled(BodyText, {
  shouldForwardProp: (prop) => prop !== 'wrapperWidth',
})`
  margin-bottom: ${MIDDLE_MARGIN_PX};
  color: ${({ theme }) => theme.palette.common.text.secondaryToPrimary};
`;

const StyledCustomButton = styled(CustomButton, {
  shouldForwardProp: (prop) => prop !== 'wrapperWidth',
})`
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: unset;
  }
`;
