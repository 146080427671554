import React, {
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useQuery } from '@apollo/client';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { alpha } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';

import CloseIcon from '../../assets/icons/CloseIcon';
import MenuIcon from '../../assets/icons/MenuIcon';
import Moon from '../../assets/icons/Moon';
import Sun from '../../assets/icons/Sun';
import { ConditionalLink } from '../../components/ConditionalLink';
import IKImage from '../../components/IKImage/IKImage';
import LangWidget from '../../components/LangWidget/LangWidget';
import NavigationOverlay from '../../components/NavigationOverlay/NavigationOverlay';
import Switch from '../../components/Switch/Switch';
import { UserInfoCtx } from '../../containers/CommonLayout/CommonLayout';
import { CourseDropdownItem } from '../../containers/Layout/Layout';
import useScriptLoader, { SCRIPTS } from '../../context/ScriptLoader/useScriptLoader';
import { TenantCtx } from '../../context/TenantProvider';
import { ThemeCtx } from '../../context/ThemeProvider/ThemeProvider';
import { GET_LIST_BASE_WITH_SORT } from '../../graphql/analytics';
import { GET_TWO_LATEST_VIDEOS } from '../../graphql/videos';
import useIsSsku from '../../hooks/tenant/useIsSsku';
import useAuth from '../../hooks/useAuth';
import useEventListener from '../../hooks/useEventListener';
import useIsIframed from '../../hooks/useIsIframed';
import useOverflowHidden from '../../hooks/useOverflowHidden';
import useTenantTranslation from '../../hooks/useTenantTranslation';
import useClickOutside from '../../pages/AnalyticsPage/components/PickerWithRanges/useClickOutside';
import styled, { css } from '../../styled';
import {
  BIG_MARGIN_PX,
  MIDDLE_MARGIN_PX,
  SMALL_MARGIN_PX,
  SUBMIDDLE_MARGIN_PX,
  TINY_MARGIN_PX,
  useIsWidthUp,
} from '../../theme';
import {
  Navigation,
  NavigationItem,
  NavigationItemType,
  Query,
  QueryListBaseVideoCategoriesArgs,
} from '../../types';
import UserCom from '../../utils/UserCom';
import { checkIsIframed } from '../../utils/checkIsIframed';
import { sortByString } from '../../utils/sortUtils/sortByString';
import { TENANTS_CONFIG } from '../../utils/tenantsConfig';
import LogoutMegaMenuFooter from './components/LogoutMegaMenuFooter';
import MenuItems from './components/MenuItems';
import NotificationsBlock from './components/NotificationsBlock';
import UnauthorizedHeaderActions from './components/UnauthorizedHeaderActions';
import UserInfoDropdown from './components/UserInfoDropdown';
import UserOverlayContent from './components/UserOverlayContent';
import { getButtonProps, getHeaderActions } from './const';
import useMenuItems from './useMenuItems';
import usePaginatedNotifications from './usePaginatedNotifications';

const SearchInput = lazy(() => import('../../components/SearchInput/SearchInput'));

export const LOGO_TRANSFORMATION = [
  {
    height: 120,
  },
];

interface HeaderProps {
  logoUrl?: string | null;
  onlyLogo?: boolean;
  navigation?: Navigation;
  assignedLearningsName?: string;
  dropdownCourses?: CourseDropdownItem[];
  isHeaderHidden?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  logoUrl,
  navigation,
  onlyLogo = false,
  assignedLearningsName,
  dropdownCourses,
  isHeaderHidden = false,
}) => {
  const { t } = useTenantTranslation();
  const [pageYOffset, setPageYOffset] = useState(0);
  const [isSearchOpen, setSearchOpenState] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);
  const [userInfoOpened, setUserInfoOpened] = useState(false);
  const { toggleColorMode, mode } = useContext(ThemeCtx);

  const isSsku = useIsSsku();
  const location = useLocation();
  const { userInfo } = useContext(UserInfoCtx);
  const { tenant, config } = useContext(TenantCtx);
  const [showNotifications, setShowNotifications] = useState(false);
  const { isAuthenticated, loginWithRedirect, loginWithPopup, logout } = useAuth();
  const { isLoaded: isUserComLoaded } = useScriptLoader(SCRIPTS.USER_COM);
  useClickOutside('#notificationsCenter', () => setShowNotifications(false));
  const langSwitchingAllowed = tenant?.name && TENANTS_CONFIG[tenant?.name]?.langSwitchingAllowed;

  const isTablet = !useIsWidthUp('xl');
  const isMobile = !useIsWidthUp('md');
  const isIframed = useIsIframed();

  const toggleMenu = () => setMenuOpened((prevState) => !prevState);
  const toggleUserInfo = () => setUserInfoOpened((prevState) => !prevState);
  useOverflowHidden(isMobile && showNotifications);

  const { data: baseCategoriesData } = useQuery<
    Pick<Query, 'listBaseVideoCategories'>,
    QueryListBaseVideoCategoriesArgs
  >(GET_LIST_BASE_WITH_SORT, {
    skip: !userInfo,
  });

  const { data: twoLatestVideosData } = useQuery<
    Pick<Query, 'getLastAddedTwoVideosFromDifferentExperts'>
  >(GET_TWO_LATEST_VIDEOS, {
    skip: !userInfo,
  });

  const {
    data: announcementsData,
    loading: loadingAnnouncementsData,
    ableFetchMore,
    fetchMore,
  } = usePaginatedNotifications();

  const notificationsList = announcementsData?.getAnnouncementsList?.data || [];

  //close one menu if other is opened
  useEffect(() => {
    if (menuOpened) {
      setUserInfoOpened(false);
    }
  }, [menuOpened]);

  useEffect(() => {
    if (userInfoOpened) {
      setMenuOpened(false);
    }
  }, [userInfoOpened]);

  const navigationItems: NavigationItem[] = useMemo(() => {
    return navigation?.items
      ? (navigation?.items.filter(
          (item) =>
            item?.item_type === NavigationItemType.Item ||
            item?.item_type === NavigationItemType.MegaMenuItem,
        ) as NavigationItem[])
      : [];
  }, [navigation]);

  const toggleSearch = () => {
    setSearchOpenState(!isSearchOpen);
  };

  const handleClickContact = () => {
    UserCom.openWidget();
    toggleUserInfo();
  };

  useEventListener(
    'scroll',
    () => {
      if (window) {
        const newPageYOffset = window.pageYOffset;

        if (pageYOffset === 0 && newPageYOffset > 0) {
          setPageYOffset(newPageYOffset);
        } else if (pageYOffset > 0 && newPageYOffset === 0) {
          setPageYOffset(newPageYOffset);
        }
      }
    },
    undefined,
    { passive: true },
  );

  const sortedCategories = useMemo(() => {
    return baseCategoriesData?.listBaseVideoCategories
      ? sortByString([...baseCategoriesData.listBaseVideoCategories], 'title', true)
      : [];
  }, [baseCategoriesData]);

  const { buttonData, filledButtonData } = getHeaderActions(navigationItems);

  const menuItems = useMenuItems({
    navigationItems: navigationItems,
    sortedCategories: sortedCategories,
    dropdownCourses: dropdownCourses,
    twoLatestVideos: twoLatestVideosData?.getLastAddedTwoVideosFromDifferentExperts,
    assignedLearningsName: assignedLearningsName,
  });

  const handleClickMenuLink = useCallback(() => {
    setMenuOpened(false);
  }, []);

  const handleLogout = useCallback(async () => {
    if (isUserComLoaded) {
      await UserCom.resetAuth();
    }

    logout(
      checkIsIframed()
        ? {
            returnTo: `${window?.location?.origin}/?logout`,
          }
        : undefined,
    );
  }, [logout, isUserComLoaded]);

  const handleLogin = () => {
    const loginOptions = {
      redirectUri:
        location.pathname !== config?.appMainPage
          ? `${location.pathname}${location.search}`
          : config?.appMainPage,
      isDirectUrl: location.pathname === config?.appMainPage || location.pathname === '/',
    };

    if (checkIsIframed()) {
      loginWithPopup();
      setMenuOpened(false);
    } else {
      loginWithRedirect(loginOptions);
    }
  };

  //used to close mobile menu when click on link
  useEffect(() => {
    setMenuOpened(false);
  }, [location]);

  const pageYOffsetGreaterZero = pageYOffset > 0;

  return (
    <StyledAppBar
      position="fixed"
      elevation={0}
      isHeaderHidden={isHeaderHidden}
      pageYOffsetGreaterZero={pageYOffsetGreaterZero}
      data-testid="appHeader"
    >
      <StyledToolBar
        className={pageYOffsetGreaterZero ? 'scrolled-toolbar' : ''}
        pageYOffsetGreaterZero={pageYOffsetGreaterZero}
      >
        <LeftPart>
          <LogoWrapper
            pageYOffsetGreaterZero={pageYOffsetGreaterZero}
            openSearch={isSearchOpen}
            className={pageYOffsetGreaterZero ? 'header_logo_small' : 'header_logo'}
          >
            <StyledConditionalLink
              aria-label="logo"
              withLink={!onlyLogo}
              to={userInfo ? config?.appMainPage : '/'}
            >
              {logoUrl && (
                <IKImage
                  path={logoUrl || ''}
                  draggable={false}
                  alt={'logo'}
                  transformation={LOGO_TRANSFORMATION}
                  data-testid="headerLogo"
                  // We want to disable srcset for logo for now. It causes an issue when we have custom CSS styles for diff logo and they change the width
                  // srcset={[{ width: 141 }, { width: 175 }]}
                  // sizes={`(min-width:${BREAKPOINTS_VALUES["sm"]}px) 175px, 141px`}
                />
              )}
            </StyledConditionalLink>
          </LogoWrapper>
          {!isSearchOpen && !onlyLogo && (
            <TopNavMenu data-testid="topNavMenu">
              <MenuItems
                items={menuItems}
                hasSubscription={Boolean(userInfo?.has_subscription)}
                isMobile={isTablet}
                scrolled={pageYOffsetGreaterZero}
                isManager={userInfo?.is_manager}
              />
            </TopNavMenu>
          )}
        </LeftPart>
        {onlyLogo && <div></div>}
        {!onlyLogo && (
          <>
            <TopBar data-testid="headerActions" className={isSearchOpen ? 'openSearch' : ''}>
              {!userInfo && (
                <>
                  {isSsku && (
                    <StyledSwitch
                      id="modeSwitcher"
                      value={mode === 'light'}
                      onChange={toggleColorMode}
                      icon={mode === 'light' ? <StyledSun /> : <StyledMoon />}
                      mr
                    />
                  )}
                  {langSwitchingAllowed && !isSsku && <StyledLangWidget abbr />}
                  <UnauthorizedHeaderActions
                    isSearchOpen={isSearchOpen}
                    navigationItems={navigationItems}
                    handleLogin={handleLogin}
                  />
                </>
              )}
              {userInfo && (
                <>
                  <Suspense>
                    <SearchInput
                      wrapperCss={searchWrapperCss(isSearchOpen)}
                      isOpen={isSearchOpen}
                      toggle={toggleSearch}
                    />
                  </Suspense>
                  {Boolean(notificationsList?.length) && (
                    <NotificationsBlock
                      notificationsList={notificationsList}
                      loading={loadingAnnouncementsData}
                      fetchMore={fetchMore}
                      showNotifications={showNotifications}
                      setShowNotifications={setShowNotifications}
                      ableFetchMore={ableFetchMore}
                    />
                  )}
                  <UserInfoDropdown
                    scrolled={pageYOffsetGreaterZero}
                    handleLogout={handleLogout}
                    onClick={toggleUserInfo}
                  />
                  {isSsku && (
                    <StyledSwitch
                      id="modeSwitcher"
                      value={mode === 'light'}
                      onChange={toggleColorMode}
                      icon={mode === 'light' ? <StyledSun /> : <StyledMoon />}
                    />
                  )}
                </>
              )}
              {menuOpened ? (
                <StyledCloseIcon onClick={toggleMenu} />
              ) : (
                <StyledMenuIcon onClick={toggleMenu} />
              )}
            </TopBar>
            <NavigationOverlay opened={menuOpened} startBreakpoint="xl">
              <OverlayContent className={userInfo ? 'without-last-border' : ''}>
                <OverlayContainer>
                  <MenuItems
                    wrapperCss={userInfo && buttonData ? menuItemsWrapperCss : undefined}
                    items={menuItems}
                    isManager={userInfo?.is_manager}
                    hasSubscription={Boolean(userInfo?.has_subscription)}
                    isMobile={isTablet}
                    handleClickLink={handleClickMenuLink}
                  />
                  {isSsku && (
                    <MenuActionBar>
                      {langSwitchingAllowed && <StyledLangWidget />}
                      <StyledSwitch
                        id="modeSwitcher"
                        showInMenu
                        value={mode === 'light'}
                        onChange={toggleColorMode}
                        icon={mode === 'light' ? <StyledSun /> : <StyledMoon />}
                      />
                    </MenuActionBar>
                  )}
                </OverlayContainer>
                {isAuthenticated ? (
                  !isIframed ? (
                    <OverlayBottomLink data-testid="logoutBtn" onClick={handleLogout}>
                      {t('header.logout')}
                    </OverlayBottomLink>
                  ) : null
                ) : (
                  <StyledLogoutMegaMenuFooter
                    mainBtnProps={getButtonProps(buttonData?.url, location, loginWithPopup)}
                    mainBtnText={buttonData?.title}
                    additionalBtnLink={filledButtonData?.url}
                    additionalBtnText={filledButtonData?.title}
                  />
                )}
              </OverlayContent>
            </NavigationOverlay>
            {userInfo && (
              <NavigationOverlay opened={userInfoOpened} startBreakpoint="xl">
                <OverlayContent className={userInfo ? 'without-last-border' : ''}>
                  <UserOverlayContent
                    isScrolled={pageYOffsetGreaterZero}
                    handleClickLink={() => setUserInfoOpened(false)}
                    handleClickContactBtn={handleClickContact}
                  />
                  {!isIframed && (
                    <OverlayBottomLink onClick={handleLogout}>
                      {t('header.logout')}
                    </OverlayBottomLink>
                  )}
                </OverlayContent>
              </NavigationOverlay>
            )}
            <HeaderBackground
              show={menuOpened || userInfoOpened || (isMobile && showNotifications)}
              scrolled={pageYOffsetGreaterZero}
            />
          </>
        )}
      </StyledToolBar>
      {isSearchOpen && <FullscreenOverlay onClick={toggleSearch} />}
    </StyledAppBar>
  );
};

export default Header;

const FullscreenOverlay = styled('div')`
  position: fixed;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => alpha(theme.palette.common.blueDark, 0.82)};
`;

const createMenuIcon = (tag) => styled(tag)`
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-left: ${SMALL_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 36px;
    min-width: 36px;
    height: 36px;
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    display: none;
  }
`;

const StyledMenuIcon = createMenuIcon(MenuIcon);
const StyledCloseIcon = createMenuIcon(CloseIcon);

const MenuActionBar = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${BIG_MARGIN_PX};
  margin-top: auto;
`;
const StyledLogoutMegaMenuFooter = styled(LogoutMegaMenuFooter, {
  shouldForwardProp: (prop) => prop !== 'wrapperWidth',
})`
  ${({ theme }) => theme.breakpoints.up('xl')} {
    display: none;
  }
`;

const HeaderBackground = styled('div', {
  shouldForwardProp: (prop) => prop !== 'scrolled' && prop !== 'show',
})`
  width: ${({ show }) => (show ? '100%' : '0')};
  height: ${({ scrolled }) => (scrolled ? '74px' : '114px')};
  position: absolute;
  top: 0;
  right: 0;
  background: ${({ theme }) => theme.palette.common.header.overlay};
  z-index: 1200;
  transition: 0.5s ease-in-out;
`;
const StyledConditionalLink = styled(ConditionalLink)`
  max-width: 100%;
`;

const searchWrapperCss =
  (isOpened) =>
  ({ theme }) =>
    css`
      ${isOpened ? 'margin-right: ' + TINY_MARGIN_PX + ';' : ''}
      && {
        ${theme.breakpoints.up('md')} {
          margin-left: ${SMALL_MARGIN_PX};
          margin-right: ${SMALL_MARGIN_PX};
        }
      }
    `;
const menuItemsWrapperCss = css`
  margin-bottom: ${SMALL_MARGIN_PX};
`;
const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'pageYOffsetGreaterZero' && prop !== 'isHeaderHidden',
})`
  ${({ isHeaderHidden }) => (isHeaderHidden ? 'display: none;' : '')}
  transition: background .5s ease-in-out;
  background: ${({ pageYOffsetGreaterZero, theme }) =>
    pageYOffsetGreaterZero
      ? theme.palette.common.header.background
      : theme.palette.common.header.staticBackground};
  box-shadow: ${({ pageYOffsetGreaterZero }) =>
    pageYOffsetGreaterZero
      ? `0px 3px 6px ${({ theme }) => alpha(theme.palette.common.blackPure, 0.4)}`
      : 'none'};

  ${({ theme }) => theme.breakpoints.up('md')} {
    background: ${({ pageYOffsetGreaterZero, theme }) =>
      pageYOffsetGreaterZero
        ? alpha(theme.palette.common.header.background, 0.85)
        : theme.palette.common.header.staticBackground};
    box-shadow: none;
  }
`;
const StyledToolBar = styled(Toolbar, {
  shouldForwardProp: (prop) => prop !== 'pageYOffsetGreaterZero',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  font-size: 18px;
  transition: all 0.5s ease-in-out;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: ${({ pageYOffsetGreaterZero }) =>
      pageYOffsetGreaterZero ? '10px 16px' : '16px 16px'};
    font-size: 16px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${({ pageYOffsetGreaterZero }) =>
      pageYOffsetGreaterZero ? '10px 24px' : '30px 24px'};
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    padding: ${({ pageYOffsetGreaterZero }) => (pageYOffsetGreaterZero ? '10px 30px' : '30px')};
  }

  ${({ theme }) => theme.breakpoints.up('ml')} {
    width: 100%;
    max-width: ${({ theme }) => theme.breakpoints.values.ml};
    margin: 0 auto;
  }
`;
const LeftPart = styled('div')`
  display: flex;
  align-items: center;
  max-width: calc(100% - 160px);
`;
const LogoWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'pageYOffsetGreaterZero' && prop !== 'openSearch',
})`
  display: ${({ openSearch }) => (openSearch ? 'none' : 'flex')};
  height: 45px;
  max-width: 100%;
  align-items: center;
  transition: all 0.5s ease-in-out;
  z-index: 1300;

  & img {
    max-width: 100%;
    object-fit: contain;
    height: 32px;
  }

  @media only screen and (min-width: 375px) {
    & img {
      height: 40px;
    }
  }
  @media only screen and (min-width: 425px) {
    & img {
      height: 45px;
    }
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: ${({ openSearch }) => (openSearch ? 'block' : 'flex')};
    ${({ openSearch }) => (openSearch ? 'flex-grow: 0;' : '')}
    margin-right: ${SMALL_MARGIN_PX};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 60px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    min-width: 180px;

    & img {
      height: ${({ pageYOffsetGreaterZero }) => (pageYOffsetGreaterZero ? '45px' : '60px')};
      transition: all 0.5s ease-in-out;
    }

    margin-right: ${MIDDLE_MARGIN_PX};
  }
`;
const TopNavMenu = styled(Box)`
  display: none;

  ${({ theme }) => theme.breakpoints.up('xl')} {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;
const TopBar = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  z-index: 1300;
  height: 54px;
  margin-left: ${SMALL_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-left: ${MIDDLE_MARGIN_PX};
  }

  &.openSearch {
    flex-grow: 1;
    padding-left: 40px;

    ${({ theme }) => theme.breakpoints.up('sm')} {
      justify-content: flex-end;
    }

    ${({ theme }) => theme.breakpoints.up('md')} {
      margin-right: 0;
    }
  }

  & > .header_username {
    display: none;
    @media only screen and (min-width: 1600px) {
      display: block;
    }
  }
`;
const OverlayContent = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const OverlayContainer = styled(Box)`
  margin-bottom: ${SMALL_MARGIN_PX};
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const OverlayBottomLink = styled('button')`
  padding: 24px;
  color: ${({ theme }) => theme.palette.common.white};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  background: ${({ theme }) => theme.palette.common.card.lightAccent};
  display: block;
  width: 100vw;
  margin-left: -24px;
  margin-bottom: -24px;
  border: none;

  &:focus {
    outline: none;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-left: -30px;
    margin-bottom: -30px;
    padding: 24px 30px;
  }
`;
const StyledLangWidget = styled(LangWidget, {
  shouldForwardProp: (prop) => prop !== 'ml' && prop !== 'hideOnMob',
})<{ ml?: boolean; hideOnMob?: boolean }>`
  display: ${({ hideOnMob }) => hideOnMob && 'none'};
  margin-left: ${({ ml }) => ml && SUBMIDDLE_MARGIN_PX};
  margin-right: ${({ ml }) => !ml && SUBMIDDLE_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: flex;
  }
`;
const StyledSwitch = styled(Switch, {
  shouldForwardProp: (prop) => prop !== 'mr',
})<{ mr?: boolean }>`
  margin-left: ${({ mr }) => !mr && SMALL_MARGIN_PX};
  margin-right: ${({ mr }) => mr && SMALL_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.down('xl')} {
    display: ${({ showInMenu }) => (showInMenu ? 'block' : 'none')};
  }

  label {
    box-sizing: content-box;
    background: ${({ theme }) => theme.palette.common.header.switcherBg};
  }

  & label span,
  & input:checked + .switch_label span {
    background: ${({ theme }) => theme.palette.common.header.switcher};
  }
`;
const StyledSwitchIcon = (tag) => styled(tag)`
  position: absolute;
  left: calc(50% - 1px);
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
`;
const StyledMoon = StyledSwitchIcon(Moon);
const StyledSun = styled(StyledSwitchIcon(Sun))`
  left: 50%;
`;
