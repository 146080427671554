import React, { FC } from 'react';

import { Container } from '@mui/material';
import { alpha } from '@mui/material/styles';
import cx from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SwiperOptions } from 'swiper/types/swiper-options';

import styled, { css } from '../../styled';
import { LARGE_MARGIN_PX, MIDDLE_MARGIN_PX } from '../../theme';
import { Background, QuotesListBlock } from '../../types';
import { LazyLoadImage } from '../LazyloadImage';
import { QuoteItem } from '../QuoteItem';

const swiperSettings: SwiperOptions = {
  slidesPerView: 1,
  navigation: true,
  observer: true,
};

interface QuotesBlockProps {
  data: QuotesListBlock;
}

const QuotesBlock: FC<QuotesBlockProps> = ({ data }) => {
  const background = data?.background;
  const backgroundImage = data?.background_image;
  const items = data?.quotes || [];
  const isSingleQuoteBlock = items?.length === 1;

  return (
    <Wrapper
      isSingleQuoteBlock={isSingleQuoteBlock}
      background={background}
      className={cx(
        'quotes-block',
        !backgroundImage && (background === Background.Dark ? 'dark-block' : 'light-block'),
      )}
    >
      {backgroundImage && (
        <ImageWrapper className="quotes-block__image-wrapper">
          <StyledLazyLoadImage
            className="quotes-block__image"
            path={backgroundImage}
            alt={'quotes'}
            sizes="100vw"
            draggable={false}
            data-testid="backgroundImage"
            wrapperCss={imageLazyloadWrapperCss}
          />
        </ImageWrapper>
      )}
      <StyledContainer isSingleQuoteBlock={isSingleQuoteBlock}>
        {isSingleQuoteBlock ? (
          <QuoteItem
            title={items[0]?.title}
            subtitle={items[0]?.sub_title}
            highLightColor={items[0]?.color_text}
            buttonLink={items[0]?.cta_button_link}
            buttonText={items[0]?.cta_button_text}
          />
        ) : (
          <Swiper {...swiperSettings}>
            {items.map((item, i) => (
              <SwiperSlide key={i}>
                <QuoteItem
                  title={item?.title}
                  subtitle={item?.sub_title}
                  isSingleItem={false}
                  highLightColor={item?.color_text}
                  buttonLink={item?.cta_button_link}
                  buttonText={item?.cta_button_text}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </StyledContainer>
    </Wrapper>
  );
};

export default QuotesBlock;

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSingleQuoteBlock' && prop !== 'background',
})`
  z-index: 1;
  position: relative;
  padding-top: ${MIDDLE_MARGIN_PX};
  padding-bottom: ${MIDDLE_MARGIN_PX};
  background: ${({ theme }) => theme.palette.secondary.dark};
  & .swiper-wrapper {
    align-items: center;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-top: ${({ isSingleQuoteBlock }) => (isSingleQuoteBlock ? '108px' : LARGE_MARGIN_PX)};
    padding-bottom: ${({ isSingleQuoteBlock }) => (isSingleQuoteBlock ? '108px' : LARGE_MARGIN_PX)};
    background-color: ${({ background, theme }) =>
      background === 'Dark' ? theme.palette.secondary.dark : theme.palette.secondary.light};
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    & .swiper-button-prev::after,
    & .swiper-button-next::after {
      width: 12px;
      height: 24px;
      font-size: 24px;
    }
    & .swiper-button-prev,
    & .swiper-button-next {
      width: 30px;
      height: 86px;
      display: flex;
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    & .swiper-button-prev::after,
    & .swiper-button-next::after {
      width: 9px;
      height: 16px;
      font-size: 16px;
    }
  }
`;
const ImageWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: ${({ theme }) => alpha(theme.palette.common.blueDark, 0.33)};
  }
`;
const StyledLazyLoadImage = styled(LazyLoadImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const imageLazyloadWrapperCss = css`
  width: 100%;
  height: 100%;
`;
const StyledContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'isSingleQuoteBlock',
})`
  && {
    ${({ isSingleQuoteBlock }) => (isSingleQuoteBlock ? 'max-width: 800px;' : '')}
  }
`;
