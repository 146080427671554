import React, { createContext, FC, ReactNode, useMemo } from 'react';

import { Modal as MUIModal } from '@mui/material';

import LinkedInModal from '../components/LinkedInModal';
import { OnboardingModal } from '../components/OnboardingModal/OnboardingModal';
import { SetGoalsLaterModal } from '../components/OnboardingModal/SetGoalsLaterModal';
import ShareModal from '../components/ShareModal/ShareModal';
import LeaveModal from '../components/Test/LeaveModal';
import VideoModal from '../components/VideoModal/VideoModal';
import ExtendDueDateModal from '../pages/AssignedLearningPage/components/ExtendDueDateModal';
import { NotificationsModal } from '../pages/AssignedLearningPage/components/NotificationsModal';
import ChangeLearnerTeamModal from '../pages/MyAccountPage/components/ChangeLearnerTeamModal';
import CreateTeamModal from '../pages/MyAccountPage/components/CreateTeamModal';
import EditTeamModal from '../pages/MyAccountPage/components/EditTeamModal';
import styled from '../styled';
import { SMALL_MARGIN_PX } from '../theme';
import Confirm from './Confirm';
import useMultipleModals from './useMultipleModals';

export enum Modals {
  LinkedInModal = 'LinkedInModal',
  ShareModal = 'ShareModal',
  LeaveModal = 'LeaveModal',
  VideoModal = 'VideoModal',
  Confirm = 'Confirm',
  ChangeLearnerTeamModal = 'ChangeLearnerTeamModal',
  EditTeamModal = 'EditTeamModal',
  CreateTeamModal = 'CreateTeamModal',
  NotificationsModal = 'NotificationsModal',
  ExtendDueDateModal = 'ExtendDueDateModal',
  OnboardingModal = 'OnboardingModal',
  SetGoalsLaterModal = 'SetGoalsLaterModal',
}

export type IChildrenParams = {
  closeModal: () => void;
  openModal: (name: Modals, params?: {}) => void;
  closeAllModals: () => void;
};
export type IModal = {
  closeModal?: IChildrenParams['closeModal'];
  openModal?: IChildrenParams['openModal'];
  closeAllModals?: IChildrenParams['closeAllModals'];
};

const modalViews = {
  LinkedInModal,
  ShareModal,
  LeaveModal,
  VideoModal,
  Confirm,
  ChangeLearnerTeamModal,
  EditTeamModal,
  CreateTeamModal,
  NotificationsModal,
  ExtendDueDateModal,
  OnboardingModal,
  SetGoalsLaterModal,
};

export const ModalCtx = createContext<IModal>({
  openModal: () => {},
  closeModal: () => {},
  closeAllModals: () => {},
});

function Modal<T extends unknown>({
  children,
  preventCloseOnOutsideClick = false,
  ...rest
}: T & {
  children: ReactNode | ((params: IChildrenParams) => ReactNode);
  preventCloseOnOutsideClick?: boolean;
}) {
  const { openModal, closeModal, queue, modalProps, closeAllModals } = useMultipleModals(
    preventCloseOnOutsideClick,
  );

  const memoizedValue = useMemo(
    () => ({
      openModal,
      closeModal,
      closeAllModals,
    }),
    [openModal, closeModal, closeAllModals],
  );

  return (
    <ModalCtx.Provider value={memoizedValue}>
      {typeof children === 'function'
        ? children({ openModal, closeModal, closeAllModals })
        : children}
      <>
        {queue.map((name, index) => {
          if (!name) {
            return null;
          }
          const CurrentModal = modalViews[name] as FC<T>;
          return (
            <StyledMUIModal
              key={name}
              open={!!queue.length}
              onClose={preventCloseOnOutsideClick ? undefined : closeModal}
            >
              <>
                <CurrentModal
                  key={index}
                  closeModal={closeModal}
                  openModal={openModal}
                  closeAllModals={closeAllModals}
                  {...rest}
                  {...modalProps[name]}
                />
              </>
            </StyledMUIModal>
          );
        })}
      </>
    </ModalCtx.Provider>
  );
}

export default Modal;

const StyledMUIModal = styled(MUIModal)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${SMALL_MARGIN_PX};
`;
