import React, { createContext, useContext, useEffect, useMemo, useReducer } from 'react';

import { addBreadcrumb, captureException } from '@sentry/react';

import { Maybe } from '../../types';
import { TenantsRefs } from '../../utils/tenantsConfig';
import { TenantCtx } from '../TenantProvider';
import ScriptLoader from './ScriptLoader';

export enum SCRIPTS {
  VERSE = 'verse',
  MATH_JAX = 'mathJax',
  TEAMS_SHARE = 'teamsShare',
  CHARGEBEE = 'chargebee',
  USER_COM = 'userCom',
}

export const ANALYTICS_LIB_LOADED_EVENT = 'analyticLibraryLoaded';

const scriptsMetadata = (tenantName?: Maybe<string>) => ({
  [SCRIPTS.VERSE]: {
    url: process.env.REACT_APP_VERSE_SCRIPT,
    init: () => {
      window.addEventListener('analytic-lib-error', (e) => {
        // @ts-ignore
        if (e?.detail?.data) {
          addBreadcrumb({
            category: 'log',
            message: 'Error details:',
            // @ts-ignore
            data: e?.detail?.data,
          });
        }
        // @ts-ignore
        captureException(e?.detail?.error, (scope) => {
          scope.setTag('isAnalyticLibError', true);
          return scope;
        });
      });
      const videoEvents = window?.videoEvents?.default;
      return new videoEvents(null);
    },
  },
  [SCRIPTS.MATH_JAX]: {
    url: `${process.env.REACT_APP_IMAGEKIT_URL}react-static-files/${process.env.REACT_APP_BUILD_TYPE}/tex-svg-full.js`,
    init: null,
  },
  [SCRIPTS.TEAMS_SHARE]: {
    url: 'https://teams.microsoft.com/share/launcher.js',
    init: null,
  },
  [SCRIPTS.CHARGEBEE]: {
    url: 'https://js.chargebee.com/v2/chargebee.js',
    init: null,
  },
  [SCRIPTS.USER_COM]: {
    url:
      tenantName === TenantsRefs.Ssku
        ? 'https://openacademyskills.user.com/widget.js'
        : 'https://xunlocked.user.com/widget.js',
    init: null,
  },
});

function reducer(state, action) {
  switch (action.type) {
    case SCRIPTS.VERSE:
    case SCRIPTS.MATH_JAX:
    case SCRIPTS.TEAMS_SHARE:
    case SCRIPTS.CHARGEBEE:
    case SCRIPTS.USER_COM:
      return { ...state, [action.type]: action.payload };
    default:
      return state;
  }
}

const initialState = {
  [SCRIPTS.VERSE]: null,
  [SCRIPTS.MATH_JAX]: null,
  [SCRIPTS.TEAMS_SHARE]: null,
  [SCRIPTS.CHARGEBEE]: null,
  [SCRIPTS.USER_COM]: null,
};

export const scriptsCtx = createContext<{
  state: typeof initialState;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => {},
});

export const ScriptsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { Provider } = scriptsCtx;

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

let MOUNTED_SCRIPTS: string[] = [];

function useScriptLoader(type: SCRIPTS, skip = false) {
  const { state, dispatch } = useContext(scriptsCtx);
  const { tenant } = useContext(TenantCtx);
  const isLoaded = state[type];
  const { url, init } = useMemo(() => scriptsMetadata(tenant?.name)[type], [type, tenant]);

  useEffect(() => {
    if (skip || !url) {
      return;
    }

    const isMounted = MOUNTED_SCRIPTS.includes(type);

    if (!isLoaded && !isMounted && window) {
      // mark as mounted
      MOUNTED_SCRIPTS.push(type);

      const loader = new ScriptLoader(type, url, { captureException });

      loader.mount((script) => {
        const dispatchInit = () => dispatch({ type, payload: init?.() || script });

        const verseScriptLoadedHandler = () => {
          dispatchInit();
          window.removeEventListener(ANALYTICS_LIB_LOADED_EVENT, verseScriptLoadedHandler);
        };

        if (type === SCRIPTS.VERSE) {
          //use old approach
          if (window?.videoEvents) {
            dispatchInit();
          } else {
            window.addEventListener(ANALYTICS_LIB_LOADED_EVENT, verseScriptLoadedHandler);
          }
        } else {
          dispatchInit();
        }
      });
    }
  }, [dispatch, isLoaded, type, skip, url, init]);

  const cleanMountedType = () => {
    MOUNTED_SCRIPTS = MOUNTED_SCRIPTS.filter((mountedType) => mountedType !== type);
  };

  return { isLoaded, cleanMountedType };
}

export default useScriptLoader;
