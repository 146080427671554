import styled from '../../../styled';
import CustomButton from '../CustomButton/CustomButton';

const SecondaryButton = styled(CustomButton)`
  &.btnOutlinedPrimary {
    border-color: ${({ theme }) => theme.palette.common.blue};

    &:hover {
      background: ${({ theme }) => theme.palette.common.blue};
      color: ${({ theme }) => theme.palette.common.white};
    }
  }
`;

export default SecondaryButton;
