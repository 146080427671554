import React, { useEffect, useRef, useState } from 'react';

import { createPortal } from 'react-dom';

import useIsSSR from '../hooks/useIsSSR';
import styled from '../styled';
import { layers } from '../theme';

const HidingStickyBlock = ({ children, visible }) => {
  const belowFooterNodeRef = useRef(window?.document?.querySelector('#below-footer-content'));
  const blockRef = useRef<HTMLDivElement | null>(null);
  const [blocksHeight, setBlocksHeight] = useState<number>();
  const isSSR = useIsSSR();

  useEffect(() => {
    if (!blocksHeight) {
      const rect = blockRef?.current?.getBoundingClientRect();
      if (rect?.height) {
        setBlocksHeight(rect.height);
      }
    }
  }, [blockRef, blocksHeight, isSSR]);

  const layout = (
    <Wrapper h={blocksHeight}>
      <Block ref={blockRef} visible={visible}>
        {children}
      </Block>
    </Wrapper>
  );

  return !isSSR && belowFooterNodeRef.current
    ? createPortal(layout, belowFooterNodeRef.current)
    : null;
};

export default HidingStickyBlock;

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'visible' && prop !== 'h',
})`
  height: ${({ visible, h }) => (visible ? 0 : h)}px;
`;
const Block = styled('div', {
  shouldForwardProp: (prop) => prop !== 'visible',
})`
  position: fixed;
  z-index: ${layers.stickyFooter};
  width: 100%;
  bottom: 0;
  transform: translateY(${({ visible }) => (visible ? 0 : 100)}%);
  transition: 0.3s;
`;
