import React, { useState } from 'react';

import { Container, Typography } from '@mui/material';
import cn from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SwiperOptions } from 'swiper/types/swiper-options';

import ChevronLeftIcon from '../../assets/icons/ChevronLeftIcon';
import ChevronRightIcon from '../../assets/icons/ChevronRightIcon';
import useTenantTranslation from '../../hooks/useTenantTranslation';
import styled from '../../styled';
import {
  BREAKPOINTS_VALUES,
  LARGE_MARGIN_PX,
  MIDDLE_MARGIN_PX,
  SMALL_MARGIN,
  SMALL_MARGIN_PX,
  TINY_MARGIN_PX,
  useIsWidthUp,
} from '../../theme';
import { Background, TestimonialsViewType } from '../../types';
import TestimonialCard from './TestimonialCard';

const swiperSettings: SwiperOptions = {
  slidesPerView: 1,
  spaceBetween: SMALL_MARGIN,
  observer: true,
  pagination: {
    clickable: true,
  },
  mousewheel: {
    releaseOnEdges: true,
    forceToAxis: true,
    invert: true,
  },
  breakpoints: {
    [BREAKPOINTS_VALUES.md]: {
      slidesPerView: 2,
    },
  },
};

const Testimonials = ({ data }) => {
  const { t } = useTenantTranslation();
  const isDark = data?.background === Background.Dark;
  const isGridView = data?.testim_view_type === TestimonialsViewType.Grid;
  const isMobile = !useIsWidthUp('md');
  const isSmallScreen = !useIsWidthUp('lg');

  const [controlledSwiper, setControlledSwiper] = useState<any>(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const goNext = () => controlledSwiper?.slideNext();
  const goPrev = () => controlledSwiper?.slidePrev();

  const sortedTestimonials = [...(data.testimonials || [])].sort((a, b) => a.ranking - b.ranking);

  const shouldBeWithArrows = !isMobile && sortedTestimonials.length > 2;

  return (
    <Wrapper
      isDark={isDark}
      className={cn('testimonials-block', isDark ? 'dark-block' : 'light-block')}
    >
      <Container>
        <Title align="center" variant="h2">
          {t('block.testimonials.testimonials')}
        </Title>
        {isGridView && !isSmallScreen ? (
          <GridWrapper>
            {sortedTestimonials.map((item, index) => (
              <TestimonialCard
                key={index}
                isDark={!isDark}
                personAvatar={item?.person_avatar}
                personAvatarSeoName={item?.person_avatar_seo_name}
                personName={item?.person_name}
                jobTitle={item?.job_title}
                companyName={item?.company_name}
                companyLogo={item?.company_logo}
                companyLogoSeoName={item?.company_logo_seo_name}
                quote={item?.quote}
                linkUrl={item?.link_url}
                linkTitle={item?.link_url}
              />
            ))}
          </GridWrapper>
        ) : (
          <SwiperWrapper>
            {shouldBeWithArrows && (
              <Nav disabled={currentIndex === 0} onClick={goPrev}>
                <StyledLeftChevron />
              </Nav>
            )}
            <StyledSwiper
              onSwiper={setControlledSwiper}
              onSlideChange={(swiper) => setCurrentIndex(swiper?.activeIndex)}
              {...swiperSettings}
            >
              {sortedTestimonials.map((item, index) => (
                <SwiperSlide key={index} style={{ height: 'unset' }}>
                  <TestimonialCard
                    isDark={!isDark}
                    personAvatar={item?.person_avatar}
                    personAvatarSeoName={item?.person_avatar_seo_name}
                    personName={item?.person_name}
                    jobTitle={item?.job_title}
                    companyName={item?.company_name}
                    companyLogo={item?.company_logo}
                    companyLogoSeoName={item?.company_logo_seo_name}
                    quote={item?.quote}
                    linkUrl={item?.link_url}
                    linkTitle={item?.link_url}
                  />
                </SwiperSlide>
              ))}
            </StyledSwiper>
            {shouldBeWithArrows && (
              <Nav disabled={currentIndex === sortedTestimonials.length - 2} onClick={goNext}>
                <StyledRightChevron />
              </Nav>
            )}
          </SwiperWrapper>
        )}
      </Container>
    </Wrapper>
  );
};

export default Testimonials;

const PAGINATION_HEIGHT = 60;
const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDark',
})`
  background: ${({ theme }) => theme.palette.secondary.dark};
  padding: ${MIDDLE_MARGIN_PX} 0 ${MIDDLE_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${LARGE_MARGIN_PX} 0 ${LARGE_MARGIN_PX};
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    background: ${({ isDark, theme }) =>
      isDark ? theme.palette.secondary.dark : theme.palette.secondary.light};
  }
`;
const Title = styled(Typography)`
  margin-bottom: ${MIDDLE_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-bottom: ${MIDDLE_MARGIN_PX};
  }
`;
const SwiperWrapper = styled('div')`
  display: flex;
  align-items: center;
`;
const Nav = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disabled',
})`
  display: none;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'initial')};

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: flex;
    align-items: center;
    transform: translateY(calc(50% - ${PAGINATION_HEIGHT}px));
  }

  &:hover svg {
    opacity: ${({ disabled }) => (!disabled ? 1 : 0.5)};
  }
`;
const styleIcon = (Icon) => styled(Icon)`
  width: 44px;
  margin-left: ${TINY_MARGIN_PX};
  opacity: 0.5;
  transition: 0.3s opacity;
`;
const StyledRightChevron = styleIcon(ChevronRightIcon);
const StyledLeftChevron = styled(styleIcon(ChevronLeftIcon))`
  margin-left: 0;
  margin-right: ${TINY_MARGIN_PX};
`;
const GridWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'wrapperWidth',
})`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: ${SMALL_MARGIN_PX};
  row-gap: ${SMALL_MARGIN_PX};
  justify-items: stretch;
  align-items: stretch;

  & > div {
    width: 100%;
    max-width: 780px;
    margin: 0 auto;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    grid-template-columns: 1fr 1fr;
    row-gap: ${MIDDLE_MARGIN_PX};
    max-width: unset;
  }
`;
const StyledSwiper = styled(Swiper)`
  width: 0;
  flex-grow: 1;
`;
