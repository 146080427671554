import { gql } from '@apollo/client';

export const GET_ALL_NEWS = gql`
  query GetAllNews($page: Int!, $first: Int!, $sort: [OrderByClause!]) {
    listPosts(first: $first, page: $page, sort: $sort) {
      data {
        id
        category_id
        title
        slug
        post_content
        published_at
        seo_title
        seo_description
        seo_image
        data
        post_introduction
        is_pinned
        image_url
        url_text
        url_link
        user {
          id
          name
          avatar
        }
      }
    }
  }
`;

export const GET_LATEST_POSTS = gql`
  query GetLatest($first: Int!, $column: String!) {
    listPosts(first: $first, page: 1, sort: { column: $column, order: DESC }) {
      data {
        id
        title
        slug
        post_introduction
        image_url
        published_at
        user {
          name
        }
      }
    }
  }
`;

export const GET_NEWS_BY_ID = gql`
  query NewsById($id: ID, $slug: String) {
    getBlogPost(id: $id, slug: $slug) {
      id
      user {
        id
        avatar
        name
        description
        tenant_id
        slug
        job_title
      }
      tenant {
        id
        name
      }
      category_id
      title
      slug
      post_content
      published_at
      seo_title
      seo_description
      seo_image
      data
      time_to_read
      post_introduction
      is_pinned
      image_url
      url_text
      url_link
      videos {
        id
      }
      categories {
        id
        title
        slug
      }
    }
  }
`;
