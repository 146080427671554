import { useCallback, useEffect, useRef, useState } from 'react';

import { captureException } from '@sentry/react';
import isEqual from 'lodash/isEqual';

import useTenantTranslation from '../hooks/useTenantTranslation';
import isPromise from '../utils/isPromise';

export const hasErrors = (errors: (string | string[])[] = []) =>
  errors.filter((item) => {
    if (Array.isArray(item)) {
      return hasErrors(item);
    }

    return Boolean(item);
  }).length !== 0;

const removeFalsy = (obj) => {
  for (const objKey in obj) {
    const val = obj[objKey];
    let emptyError = !val;

    if (Array.isArray(val)) {
      emptyError = val.filter(Boolean).length === 0;
    }

    if (emptyError) {
      delete obj[objKey];
    }
  }

  return obj;
};

const useValidation = <T>(initialValues: T, validationSchema, onSubmit) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState<Partial<T>>({});
  const [tryingToSubmit, setTryingToSubmit] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [touched, setTouched] = useState(false);
  const initialDataRef = useRef(initialValues);
  const validationSchemaRef = useRef(validationSchema);
  const { t } = useTenantTranslation();
  const onSubmitRef = useRef(onSubmit);
  onSubmitRef.current = onSubmit;

  useEffect(() => {
    setTouched(!isEqual(values, initialValues));
  }, [values, initialValues]);

  const setErrorManually = useCallback((key: string, value?) => {
    setErrors((old) => ({ ...old, [key]: value }));
  }, []);

  const handleChange = useCallback(
    (e) => {
      const { name, value, type } = e.target;
      setValues((old) => ({ ...old, [name]: type === 'number' && value !== '' ? +value : value }));
      // hide error after changing input value
      setErrorManually(name);
    },
    [setErrorManually],
  );

  const setFieldManually = useCallback(
    (key: string, value) => {
      setValues((old) => ({ ...old, [key]: value }));
      // hide error after changing input value
      setErrorManually(key);
    },
    [setErrorManually],
  );

  const handleAutocompleteChange = useCallback(
    (fieldName: string) => (e, value) => setFieldManually(fieldName, value),
    [setFieldManually],
  );

  const setValuesManually = useCallback(
    (values) => {
      setValues(values);
      // hide errors related to changed values
      Object.entries(values).forEach(([key]) => setErrorManually(key));
    },
    [setErrorManually],
  );

  const checkErrors = useCallback(() => {
    if (!validationSchemaRef.current) {
      return false;
    }
    const schemaWithTranslations = validationSchemaRef.current(t);
    const errors = schemaWithTranslations.cast(values);
    setErrors((oldErrors) => ({
      ...errors,
      // oldErrors might include errors settled manually
      ...removeFalsy(oldErrors),
    }));
  }, [validationSchemaRef, values, t]);

  const handleSubmit = useCallback(() => {
    checkErrors();
    setTryingToSubmit(true);
  }, [checkErrors]);

  const resetToDefault = useCallback(() => setValues(initialDataRef.current), [initialDataRef]);

  const resetErrors = useCallback(() => setErrors({}), []);

  useEffect(() => {
    (async () => {
      if (!tryingToSubmit) {
        return;
      }

      setTryingToSubmit(false);

      if (hasErrors(Object.values(errors))) {
        return;
      }

      try {
        const result = onSubmitRef.current(values, {
          setValue: setFieldManually,
          setValuesManually,
          resetToDefault,
        });

        if (isPromise(result)) {
          setSubmitting(true);
          await result;
        }

        setSubmitting(false);
        setSubmitted(true);
      } catch (e) {
        setSubmitting(false);
        captureException(e);
      }
    })();
  }, [
    errors,
    tryingToSubmit,
    values,
    onSubmitRef,
    setFieldManually,
    resetToDefault,
    setValuesManually,
    setSubmitting,
  ]);

  return {
    handleSubmit,
    handleChange,
    setFieldManually,
    handleAutocompleteChange,
    setErrorManually,
    setValuesManually,
    resetToDefault,
    resetErrors,
    errors,
    hasErrors: hasErrors(Object.values(errors)),
    values,
    touched,
    submitted,
    submitting,
  };
};

export default useValidation;
