import React from 'react';

import Tooltip from '@mui/material/Tooltip';

import styled from '../../../styled';

export const LightTooltip = styled(({ className, popperClassName, ...props }) => (
  // @ts-ignore
  <Tooltip {...props} componentsProps={{ tooltip: { className } }} />
))`
  position: relative;
  background-color: ${({ theme }) => theme.palette.common.tooltip.background};
  border: 1px solid ${({ theme }) => theme.palette.common.tooltip.background};
  color: ${({ theme }) => theme.palette.common.tooltip.color};
  box-shadow: ${({ theme }) => theme.shadows[1]};
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  font-family: ${({ theme }) => theme.typography.defaultFontFamily};

  &:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    ${({ placement, theme }) =>
      placement === 'top' && `border-top: 5px solid ${theme.palette.common.tooltip.background};`}
    ${({ placement }) => placement === 'top' && 'bottom: -5px;'}
    ${({ placement, theme }) =>
      placement === 'bottom' &&
      `border-bottom: 5px solid ${theme.palette.common.tooltip.background};`}
    ${({ placement }) => placement === 'bottom' && 'top: -5px;'}
    left: 50%;
    transform: translateX(-50%);
  }
`;
