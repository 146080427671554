import React from 'react';

import { Svg } from './styles';

const VideoFollowingIcon = ({ color = '', ...props }) => {
  return (
    <Svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      svgColor={color}
      {...props}
    >
      <path
        d="M1 6.4V17C1 18.6569 2.34315 20 4 20H12M1 6.4V4.7C1 3.76112 1.76112 3 2.7 3V3M1 6.4H6.3125M21.4 6.4V6C21.4 4.34315 20.0569 3 18.4 3H16.725M21.4 6.4H20.125M21.4 6.4V12M12.05 3L15.5208 6.4M12.05 3H7.375M12.05 3H16.725M15.5208 6.4H10.9167M15.5208 6.4H20.125M7.375 3L10.9167 6.4M7.375 3H2.7M10.9167 6.4H6.3125M16.725 3L20.125 6.4M2.7 3L6.3125 6.4"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M14.5 11.634C15.1667 12.0189 15.1667 12.9811 14.5 13.366L10.75 15.5311C10.0833 15.916 9.25 15.4349 9.25 14.6651L9.25 10.3349C9.25 9.56513 10.0833 9.08401 10.75 9.46891L14.5 11.634Z"
        stroke="white"
      />
      <path
        d="M19.8872 22.1094H18.1094V22.1103C18.1094 22.2269 18.1323 22.3424 18.1769 22.4501C18.2216 22.5579 18.287 22.6558 18.3694 22.7382C18.4519 22.8207 18.5498 22.8861 18.6575 22.9307C18.7653 22.9753 18.8808 22.9983 18.9974 22.9983H18.9992C19.1158 22.9983 19.2313 22.9753 19.339 22.9307C19.4467 22.8861 19.5446 22.8207 19.6271 22.7382C19.7096 22.6558 19.775 22.5579 19.8196 22.4501C19.8642 22.3424 19.8872 22.2269 19.8872 22.1103V22.1094Z"
        fill="#7EFCF0"
        stroke="#7EFCF0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9863 17.7583C21.9357 17.0103 21.5992 16.3093 21.0449 15.7971C20.4905 15.285 19.7599 15 19.0008 15H18.9992C18.2401 15 17.5095 15.285 16.9551 15.7971C16.4008 16.3093 16.0643 17.0103 16.0137 17.7583C15.9728 18.3628 15.9313 18.9791 15.8958 19.5053C15.8455 20.2486 15.6385 20.9712 15.2904 21.6274C15.1778 21.8398 15.324 22.1111 15.5644 22.1111H22.4356C22.676 22.1111 22.8222 21.8398 22.7096 21.6274C22.3615 20.9712 22.1545 20.2486 22.1042 19.5053C22.0687 18.9791 22.0272 18.3628 21.9863 17.7583Z"
        fill="#7EFCF0"
        stroke="#7EFCF0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default VideoFollowingIcon;
