import React, { useContext, useEffect, useMemo } from 'react';

import { useMutation } from '@apollo/client';
import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import CircleCheckIcon from '../../../assets/icons/CircleCheckIcon';
import InfoIcon from '../../../assets/icons/InfoIcon';
import { StyledTextFieldWide } from '../../../blocks/LetsTalkBlock/styles';
import { DEFAULT_LOCALE } from '../../../const';
import { LanguageCtx } from '../../../context/LanguageProvider';
import { OnboardingValidationValues } from '../../../context/OnboardingContext';
import { UPDATE_LEARNER_INFO } from '../../../graphql/user';
import useIsSsku from '../../../hooks/tenant/useIsSsku';
import useTenantTranslation from '../../../hooks/useTenantTranslation';
import {
  MIDDLE_MARGIN_PX,
  SMALL_MARGIN_PX,
  SUBMIDDLE_MARGIN_PX,
  TINY_MARGIN_PX,
} from '../../../theme';
import { Mutation, MutationUpdateLearnerInfoArgs } from '../../../types';
import Autocomplete from '../../Autocomplete/Autocomplete';
import Checkbox from '../../Checkbox/Checkbox';
import { getLangOptions } from '../../LangWidget/const';
import RadioButton from '../../RadioButton/RadioButton';
import { BodyIntroText, BodyText, H2, MetaText } from '../../UI/Texts';

const toOption = (item: ReturnType<typeof getLangOptions>[number]) => ({
  value: item.value,
  display: item.label,
});

const Welcome = () => {
  const { t } = useTenantTranslation();
  const theme = useTheme();

  return (
    <>
      <StyledBodyIntroText data-testid="onboardingWelcomeText">
        {t('modal.onboarding.personaliseLearning')}
      </StyledBodyIntroText>
      <Tip>
        <StyledInfoIcon color={theme.palette.common.primary} />
        <StyledBodyIntroText data-testid="onboardingTip">
          {t('modal.onboarding.tip')}
        </StyledBodyIntroText>
      </Tip>
    </>
  );
};

const stepOptionKey: keyof OnboardingValidationValues = 'categories';

export const InterestedIn = ({ goalOptions, setFieldManually, values }) => {
  const { t } = useTenantTranslation();
  const isSsku = useIsSsku();
  const { changeLanguage, language } = useContext(LanguageCtx);

  const langOptions = useMemo(() => getLangOptions(t).map(toOption), [t]);
  const targetOpt = langOptions.find((opt) =>
    language ? opt.value === language : opt.value === DEFAULT_LOCALE,
  );
  const categories = goalOptions?.categories;

  const [updateLearnerInfo] = useMutation<
    Pick<Mutation, 'updateLearnerInfo'>,
    MutationUpdateLearnerInfoArgs
  >(UPDATE_LEARNER_INFO);

  const handleLangSelect = (value) => {
    updateLearnerInfo({
      variables: {
        input: {
          language: value,
        },
      },
      refetchQueries: process.env.NODE_ENV !== 'test' ? ['GetLearnerInfo'] : undefined,
      awaitRefetchQueries: true,
    });
    changeLanguage(value);
  };

  const handleSelectAllCategories = () => {
    setFieldManually(
      stepOptionKey,
      categories?.map((item) => Number(item?.id)),
    );
  };

  if (isSsku) {
    return (
      <Content>
        <Title data-testid="onboardingStepTitle">{t('modal.onboarding.confirmYourLanguage')}</Title>
        <ContentWrapper gap={TINY_MARGIN_PX}>
          {langOptions?.map(({ value, display }) => (
            <StyledRadioButton
              key={value}
              data-testid="langRadioBtn"
              label={display}
              name={value}
              checked={targetOpt?.value === value}
              onClick={() => handleLangSelect(value)}
            >
              {display}
            </StyledRadioButton>
          ))}
        </ContentWrapper>
      </Content>
    );
  }

  return (
    <Content>
      <Title data-testid="onboardingStepTitle">
        {t('modal.onboarding.areasOfInterest')}
        <SelectAll as="span" onClick={handleSelectAllCategories}>
          <StyledCircleCheckIcon />
          {t('modal.onboarding.selectAll')}
        </SelectAll>
      </Title>
      <CheckboxGrid>
        {categories?.map((item, index) => (
          <StyledCheckbox
            data-testid="onboardingCheckbox"
            key={item?.id}
            totalItems={categories.length}
            order={index}
            name={item?.title || ''}
            onChange={() => {
              let updatedInterestedIn;
              const categoryId = Number(item?.id);

              if (values?.[stepOptionKey]?.includes(categoryId)) {
                updatedInterestedIn = values?.[stepOptionKey].filter((id) => id !== categoryId);
              } else {
                updatedInterestedIn = [...(values?.[stepOptionKey] || []), categoryId];
              }

              setFieldManually(stepOptionKey, updatedInterestedIn);
            }}
            checked={values?.[stepOptionKey]?.includes(Number(item?.id)) || false}
          >
            {item?.title}
          </StyledCheckbox>
        ))}
      </CheckboxGrid>
    </Content>
  );
};

const TellAbout = ({ values, setFieldManually, goalOptions }) => {
  const stepOptionKey: keyof OnboardingValidationValues = 'answers';
  const { t } = useTenantTranslation();

  const goals = goalOptions?.options;
  const handleOnChange = (
    name: typeof stepOptionKey,
    value: { [key in keyof OnboardingValidationValues['answers']]?: number },
  ) => {
    if (value) {
      setFieldManually(name, { ...values[stepOptionKey], ...value });
    } else {
      setFieldManually(name, []);
    }
  };
  const reasonItems = goals?.main_reason.answers;
  const educationItems = goals?.education_level.answers;
  const employmentItems = goals?.employment_status.answers;

  return (
    <Content>
      <Title data-testid="onboardingStepTitle">{t('modal.onboarding.tellAbout')}</Title>
      <ContentWrapper>
        {reasonItems && (
          <StyledAutoComplete
            data-testid="onboardingSelect"
            getOptionLabel={(option) => option?.title || ''}
            value={reasonItems.find(({ id }) => +id === values.answers.main_reason)}
            renderInput={(params) => (
              <StyledTextFieldWide
                {...params}
                variant="outlined"
                name="categoriesInterested"
                label={goals?.main_reason.question}
              />
            )}
            onChange={(_, { id }) => handleOnChange(stepOptionKey, { main_reason: +id })}
            options={reasonItems}
          />
        )}
        {educationItems && (
          <StyledAutoComplete
            data-testid="onboardingSelect"
            getOptionLabel={(option) => option?.title || ''}
            value={educationItems.find(({ id }) => +id === values.answers.education_level)}
            renderInput={(params) => (
              <StyledTextFieldWide
                {...params}
                variant="outlined"
                name="categoriesInterested"
                label={goals?.education_level.question}
              />
            )}
            onChange={(_, { id }) => handleOnChange(stepOptionKey, { education_level: +id })}
            options={educationItems}
          />
        )}
        {employmentItems && (
          <StyledAutoComplete
            data-testid="onboardingSelect"
            getOptionLabel={(option) => option?.title || ''}
            value={employmentItems.find(({ id }) => +id === values.answers.employment_status)}
            renderInput={(params) => (
              <StyledTextFieldWide
                {...params}
                variant="outlined"
                name="categoriesInterested"
                label={goals?.employment_status.question}
              />
            )}
            onChange={(_, { id }) => handleOnChange(stepOptionKey, { employment_status: +id })}
            options={employmentItems}
          />
        )}
      </ContentWrapper>
    </Content>
  );
};

const WeeklyGoal = ({ values, setFieldManually, goalOptions }) => {
  const stepOptionKey: keyof OnboardingValidationValues = 'timeForLearningGoals';
  const { t } = useTenantTranslation();
  const weeklyGoals = goalOptions?.timeForLearningGoals;

  return (
    <Content>
      <Title data-testid="onboardingStepTitle">{t('modal.onboarding.weeklyGoal')}</Title>
      <ContentWrapper gap={TINY_MARGIN_PX}>
        {weeklyGoals?.map((item) => (
          <StyledRadioButton
            key={item.value}
            data-testid="onboardingRadioButton"
            label={t(`common.minute${item.value === 60 ? '_sixty' : ''}`, { count: item.value })}
            name={String(item.value)}
            checked={values.timeForLearningGoals === +item.value}
            onClick={() => setFieldManually(stepOptionKey, +item.value)}
          />
        ))}
      </ContentWrapper>
    </Content>
  );
};

const FinalStep = ({ onboardingComplete, handleSetLearnerGoals }) => {
  const { t } = useTenantTranslation();

  useEffect(() => {
    handleSetLearnerGoals?.();
  }, [handleSetLearnerGoals]);

  const text = onboardingComplete
    ? t('modal.onboarding.thanks_updated')
    : t('modal.onboarding.thanks');

  return <StyledText data-testid="onboardingStepTitle">{text}</StyledText>;
};

export const steps = [
  (props) => <Welcome {...props} />,
  (props) => <InterestedIn {...props} />,
  (props) => <TellAbout {...props} />,
  (props) => <WeeklyGoal {...props} />,
  (props) => <FinalStep {...props} />,
];

const Title = styled(H2)`
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  margin-bottom: ${SUBMIDDLE_MARGIN_PX};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: ${SMALL_MARGIN_PX};
  z-index: 1;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-bottom: ${MIDDLE_MARGIN_PX};
  }
`;
const Content = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: ${SUBMIDDLE_MARGIN_PX};
`;
const CheckboxGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 18px;
  margin-bottom: ${SMALL_MARGIN_PX};  
  ${({ theme }) => theme.breakpoints.up('sm')} {
      margin-bottom: 0;
    grid-template-columns: repeat(2, 1fr);
      gap: 24px;
  }

,
`;
const ContentWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'gap',
})<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap || SMALL_MARGIN_PX};
`;
const StyledCheckbox = styled(Checkbox, {
  shouldForwardProp: (prop) => prop !== 'order',
})<{ order: number; totalItems: number }>`
  order: ${({ order, totalItems }) => {
    const half = Math.ceil(totalItems / 2);
    return (order % half) * 2 + Math.floor(order / half);
  }};
`;
const Tip = styled('div')`
  background: ${({ theme }) =>
    theme.isSsku ? theme.palette.common.blackPure : theme.palette.common.header.overlay};
  padding: 24px 16px;
  border-radius: 8px;
  margin-top: 54px;
  display: flex;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 24px 32px;
    margin-top: 36px;
  }

,
`;
const StyledInfoIcon = styled(InfoIcon)`
  width: 30px;
  height: 24px;
  margin-right: 20px;
  margin-top: 8px;
`;
const StyledRadioButton = styled(RadioButton)`
  & .MuiTypography-root {
    color: ${({ theme }) => theme.palette.common.text.secondaryToWhite};
  }
  & .Mui-checked .MuiSvgIcon-root {
    fill: ${({ theme }) => theme.palette.common.accentMain};
  }

  & span .MuiSvgIcon-root {
    fill: ${({ theme }) => theme.palette.secondary.contrastText};
  }
`;
const StyledText = styled(BodyText)`
  color: ${({ theme }) => theme.palette.secondary.contrastText};
`;
const StyledCircleCheckIcon = styled(CircleCheckIcon)`
  width: 14px;
  height: 14px;
  margin-top: 2px;
  margin-right: 4px;
`;
const SelectAll = styled(MetaText)`
  font-weight: normal;
  color: ${({ theme }) => theme.palette.common.violet};
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const StyledAutoComplete = styled(Autocomplete)`
  & .MuiFormLabel-root,
  & .MuiInputBase-input,
  & .MuiSvgIcon-root {
    color: ${({ theme }) => theme.isSsku && theme.palette.common.icon.gray};
  }
  & .MuiInputBase-input {
    border-color: ${({ theme }) => theme.isSsku && theme.palette.common.icon.gray};
  }
`;
const StyledBodyIntroText = styled(BodyIntroText)`
  color: ${({ theme }) => theme.palette.common.text.white};
`;
