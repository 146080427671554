import React from 'react';

import { useTheme } from '@mui/material';

import { Svg } from './styles';

const FacebookIcon = ({ color = '', ...props }) => {
  const theme = useTheme();
  const svgColor = color || theme.palette.common.violet;
  return (
    <Svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      svgColor={svgColor}
      {...props}
    >
      <path
        fill="currentColor"
        data-name="Icon awesome-facebook-square"
        d="M21.43,0H2.57A2.57,2.57,0,0,0,0,2.57V21.43A2.57,2.57,0,0,0,2.57,24H9.92V15.84H6.55V12H9.93V9.07A4.68,4.68,0,0,1,14.1,3.92a4.81,4.81,0,0,1,.85,0,22.75,22.75,0,0,1,3,.26V7.43H16.25a1.92,1.92,0,0,0-2.16,1.65,2,2,0,0,0,0,.43V12h3.68l-.59,3.84H14.08V24h7.35A2.57,2.57,0,0,0,24,21.43h0V2.57A2.57,2.57,0,0,0,21.43,0Z"
      />
    </Svg>
  );
};

export default FacebookIcon;
