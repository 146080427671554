import { useContext } from 'react';

import { useQuery } from '@apollo/client';

import { UserInfoCtx } from '../containers/CommonLayout/CommonLayout';
import { GET_VIDEOS_EXIST_IN_USER_WATCH_LIST } from '../graphql/pathways';
import { Query, QueryVideosExistInUserWatchListArgs } from '../types';
import { collectValueByKey } from '../utils/collectValueByKey';

const useWatchList = (videosIds: string[] | null | undefined) => {
  const { userInfo } = useContext(UserInfoCtx);
  const { data: videosInWatchlist } = useQuery<
    Pick<Query, 'VideosExistInUserWatchList'>,
    QueryVideosExistInUserWatchListArgs
  >(GET_VIDEOS_EXIST_IN_USER_WATCH_LIST, {
    variables: {
      video_ids: videosIds,
    },
    skip: !videosIds || !videosIds?.length || !userInfo,
  });

  return (
    videosInWatchlist?.VideosExistInUserWatchList?.reduce(
      collectValueByKey('video_id', 'exists'),
      {},
    ) || {}
  );
};

export default useWatchList;
