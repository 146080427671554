import { keyframes } from '@mui/material/styles';

export const ringing = keyframes`
  0% {
    transform: rotateZ(0);
  }
  13% {
    transform: rotateZ(22deg);
  }
  18% {
    transform: rotateZ(-20deg);
  }
  23% {
    transform: rotateZ(18deg);
  }
  28% {
    transform: rotateZ(-16deg);
  }
  33% {
    transform: rotateZ(14deg);
  }
  38% {
    transform: rotateZ(-12deg);
  }
  43% {
    transform: rotateZ(10deg);
  }

  50% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(0);
  }
`;
