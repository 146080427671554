import { alpha, keyframes, styled } from '@mui/material/styles';

export const blink = (colorInitial, colorHighlighted) => keyframes`
  0% {
    background: ${colorInitial};
  }
  35% {
    background: ${colorHighlighted};
  }
  100% {
    background: ${colorInitial};
  }
`;

const Blink = styled('div', {
  shouldForwardProp: (prop) => prop !== 'blinking',
})<{ blinking: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: ${({ theme }) =>
      blink(alpha(theme.palette.common.white, 0), alpha(theme.palette.common.white, 0.2))}
    0.6s infinite ease-in-out;
  animation-iteration-count: 2;
  animation-delay: 0.3s;
  animation-play-state: ${({ blinking }) => (blinking ? 'running' : 'paused')};
  border-radius: inherit;
`;

export default Blink;
