import { useEffect } from 'react';

import { Location, useLocation, useNavigate } from 'react-router-dom';

import usePrevious from './usePrevious';

const useLang = () => {
  const location = useLocation();
  const prevLocation = usePrevious<Location<any>>(location);
  const navigate = useNavigate();

  useEffect(() => {
    if (prevLocation) {
      const isDifferentRoute =
        prevLocation.search !== location.search || prevLocation.pathname !== location.pathname;
      const hadLangParam = prevLocation.search.includes('lang');
      const hasLangParam = location.search.includes('lang');

      if (hadLangParam && !hasLangParam && isDifferentRoute) {
        const prevSearchParams = new URLSearchParams(prevLocation.search);
        const currSearchParams = new URLSearchParams(location.search);

        const lang = prevSearchParams.get('lang');

        if (lang) {
          currSearchParams.append('lang', lang);
        }

        const stringifiedParams = currSearchParams.toString();
        navigate(location.pathname + stringifiedParams ? '?' + stringifiedParams : '', {
          replace: true,
        });
      }
    }
  }, [location, prevLocation, navigate]);
};

export default useLang;
