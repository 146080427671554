import React, { FC, ReactNode } from 'react';

import { Box } from '@mui/material';
import { Breakpoint } from '@mui/material/styles';

import styled from '../../styled';
interface OverlayWrapperProps {
  opened: boolean;
  children?: ReactNode;
  startBreakpoint?: Breakpoint;
}

const NavigationOverlay: FC<OverlayWrapperProps> = ({
  opened,
  children,
  startBreakpoint = 'md',
}) => {
  return (
    <Wrapper opened={opened} startBreakpoint={startBreakpoint}>
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default NavigationOverlay;

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'opened' && prop !== 'startBreakpoint',
})`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1200;
  top: 0;
  right: ${({ opened }) => (opened ? 0 : '-100%')};
  overflow-x: hidden;
  transition: 0.5s ease-in-out;
  background: ${({ theme }) => theme.palette.common.header.overlay};
  display: flex;
  flex-direction: column;
  padding: 24px;

  ${({ theme, startBreakpoint }) => theme.breakpoints.up(startBreakpoint)} {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 30px;
  }
`;
const Content = styled(Box)`
  position: relative;
  width: 100%;
  margin-top: 70px;
  flex: 1;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 100px;
  }
`;
