import type { SendEventForHits } from 'instantsearch.js/cjs/lib/utils';

import { HITS_TYPES } from '../const';

export type SendHitsEvent = SendEventForHits;

export const HITS_TYPES_BY_URL = {
  search: '',
  videos: HITS_TYPES.VIDEO,
  pathways: HITS_TYPES.PATHWAY,
  insights: HITS_TYPES.NEWS,
  glossary: HITS_TYPES.GLOSSARY,
  courses: HITS_TYPES.COURSE,
};

export const GLOBAL_TENANT = 'GLOBAL';

export const LIST_VIEW = 'list';
export const GRID_VIEW = 'grid';

export const DURATION_FILTER = 'duration_interval';
export const DURATION_FILTER_ORDER = [
  '0 - 15 minutes',
  '15 - 60 minutes',
  '1 - 2 hours',
  '3 - 4 hours',
  '4 hours +',
];

export type AggregatorHit = {
  id: number;
  searchable_type: number;
  title?: string;
  name?: string;
  slug?: string;
  description?: string;
  duration?: number;
  duration_interval?: string;
  user?: string;
  executive_summary?: string;
  key_learning_objectives?: string;
  categories?: {
    lvl0?: string[];
    lvl1?: string[];
  };
  difficulty?: {
    id?: number;
    title?: string;
    icon_url?: string;
  };
  preview_url?: string;
  image_url?: string;
  video_url?: string;
  user_slug?: string;
  categories_array?: [
    id?: number,
    title?: string,
    slug?: string,
    parent?: {
      id?: number;
      slug?: string;
    },
  ];
  count_videos?: number;
  cpd_credits?: number;
  post_content?: string;
  post_introduction?: string;
  published_at?: number;
  category?: string;
  letter_index?: string;
  subtitle?: string;
  definition?: string;
  content?: string;
  updated_at?: number;
  publish_status?: string;
};
