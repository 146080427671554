import React, { FC } from 'react';

import { Element, useSvgColor, Svg } from './styles';
import { TempColorIconProps } from './types';

const DownloadIcon: FC<TempColorIconProps> = ({ updatedColor, color = '', ...props }) => {
  const svgColor = useSvgColor(color);

  return (
    <Svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}
      svgColor={updatedColor || svgColor}
    >
      <Element d="M2,18v2a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V18" />
      <Element as="line" x1="12" y1="2.00001" x2="12" y2="16.00001" />
      <Element as="polyline" points="6 10 12 16 18 10" />
    </Svg>
  );
};
export default DownloadIcon;
