import React from 'react';

import { Box } from '@mui/material';

import styled from '../../styled';
import { MIDDLE_MARGIN_PX } from '../../theme';

const Card = (props) => <Wrapper {...props} />;

export default Card;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${MIDDLE_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: 0;
    flex-direction: row;
  }
`;
