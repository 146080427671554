import React from 'react';

import { Container, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';

import { CustomButton } from '../../components/UI/CustomButton';
import { H3 } from '../../components/UI/Texts';
import styled from '../../styled';
import {
  MIDDLE_MARGIN_PX,
  MIDDLE_RADIUS_PX,
  SMALL_MARGIN_PX,
  SUBMIDDLE_MARGIN_PX,
} from '../../theme';

const StickyBlockContent = ({ title, subtitle, btnTitle, handleClick }) => {
  return (
    <Wrapper>
      <StyledContainer>
        <TextWrapper>
          <Title variant="h3">{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </TextWrapper>
        <BtnWrapper>
          <CustomButton onClick={handleClick}>{btnTitle}</CustomButton>
        </BtnWrapper>
      </StyledContainer>
    </Wrapper>
  );
};

export default StickyBlockContent;

const Wrapper = styled('div')`
  width: 100vw;
  padding: ${SMALL_MARGIN_PX} 0 ${SUBMIDDLE_MARGIN_PX} 0;
  border-radius: ${MIDDLE_RADIUS_PX} ${MIDDLE_RADIUS_PX} 0 0;
  background-color: ${({ theme }) => theme.palette.common.violetAnalyticsCard};
  z-index: 10;
  transition: 0.3s;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${SUBMIDDLE_MARGIN_PX} 0;
    border-radius: 0;
    box-shadow: 10px 5px 40px ${({ theme }) => alpha(theme.palette.common.blackPure, 0.4)};
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const TextWrapper = styled('div')`
  margin-bottom: ${SUBMIDDLE_MARGIN_PX};
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: 0;
    margin-right: ${MIDDLE_MARGIN_PX};
  }
`;

const Title = styled(H3)`
  margin-bottom: 4px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: 0;
  }
`;

const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.gray};
  font-size: 14px;
  line-height: 1.2;
`;

const BtnWrapper = styled('div')`
  flex-shrink: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  & > button {
    width: 100%;
  }
  ${({ theme }) => theme.breakpoints.up('sm')} {
    & > button {
      width: unset;
    }
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: unset;
  }
`;
