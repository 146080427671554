import { gql } from '@apollo/client';

export const GET_TEAMS_MEMBERS = gql`
  query GetMembersByTeamIdsArray($team_ids: [Int]) {
    getMembersByTeamIdsArray(team_ids: $team_ids) {
      id
      name
      email
      teams {
        id
        name
      }
    }
  }
`;

export const GET_ALGOLIA_API_KEY = gql`
  query GetAlgoliaApiKey {
    getAlgoliaApiKey {
      api_key
    }
  }
`;

export const GET_EXPERT_BY_SLUG = gql`
  query GetExpertBySlug($filter: UserSearchFilter) {
    getFilteredUsers(filter: $filter) {
      id
      name
      avatar
      job_title
      description
      count_videos
      slug
      tenants {
        id
        name
      }
    }
  }
`;

export const GET_LEARNER_INFO = gql`
  query GetLearnerInfo {
    getLearnerInfo {
      id
      name
      email
      auth_source
      first_name
      last_name
      auth0_id
      is_manager
      role
      has_subscription
      had_subscription
      sub_provided_by_chargebee_site
      owned_subscription
      registration_date
      active_days_count
      chargebee_tenant_name
      job
      interests {
        id
        title
      }
      company {
        id
        name
        owner_id
        owner {
          name
          email
        }
        short_name
        assignment_description
        logo_url
      }
      teams {
        id
        name
        learner_role
        all_members_count
        managers {
          id
          role
          name
        }
      }
      settings {
        language
        captions
      }
      followings {
        id
        followed_entity {
          ... on Expert {
            id
            name
            avatar
          }
        }
      }
    }
  }
`;

export const GET_INVITE_INFO_BY_HASH = gql`
  query GetInviteInfoByHash($hash: String!) {
    getInviteInfoByHash(hash: $hash) {
      team_id
      team_name
      company {
        id
        name
        logo_url
        auth0_connection
      }
      seats_available
    }
  }
`;

export const GET_COMPANY_OWNER_SUBSCRIPTION_INFORMATION = gql`
  query GetCompanyOwnerChargebeeInfo {
    getMySubscriptionCompanyOwnerChargebeeInfo {
      type
      renews_automaticly_on
      pay_frequency
      remaining_trials {
        left
        total
        left_unit
        total_unit
        days_from_start_subscription
        total_amount_of_trial_days
      }
      seats {
        occupied_seats
        bought_seats
        max_count_seats_in_plan
      }
      subscription_id
      subscription_group_type
      current_term_end
      is_cancelled
    }
  }
`;

export const GET_MANAGER_SUBSCRIPTION_INFORMATION = gql`
  query GetMySubscriptionTeamManagerChargebeeInfo {
    getMySubscriptionTeamManagerChargebeeInfo {
      type
      renews_automaticly_on
      pay_frequency
      subscription_group_type
    }
  }
`;

export const JOIN_TEAM_BY_HASH = gql`
  mutation JoinTeamByInviteHash($hash: String!) {
    joinTeamByInviteHash(hash: $hash) {
      id
    }
  }
`;

export const GET_B2B_LEARNER_SUBSCRIPTION_INFORMATION = gql`
  query GetB2BLearnerChargebeeInfo {
    getMySubscriptionB2BLearnerChargebeeInfo {
      type
      renews_automaticly_on
      pay_frequency
      subscription_group_type
    }
  }
`;

export const GET_B2C_LEARNER_SUBSCRIPTION_INFORMATION = gql`
  query GetB2CLearnerChargebeeInfo {
    getMySubscriptionB2CLearnerChargebeeInfo {
      type
      renews_automaticly_on
      pay_frequency
      remaining_trials {
        left
        total
        left_unit
        total_unit
        days_from_start_subscription
        total_amount_of_trial_days
      }
      subscription_group_type
      subscription_id
      current_term_end
      is_cancelled
    }
  }
`;

export const UPDATE_COMPANY_INFO = gql`
  mutation EditCompany($input: CompanyEditInput!) {
    editCompany(input: $input) {
      id
      logo_url
      name
      owner_id
      short_name
    }
  }
`;

export const GET_TEAM_MANAGERS = gql`
  query GetTeamManagers {
    getTeamManagers {
      id
      name
    }
  }
`;

export const GET_TEAM_INVITE_LINK = gql`
  query GetTeamInviteLink($team_id: Int!) {
    getTeamInviteLink(team_id: $team_id) {
      id
      hash
      team_id
    }
  }
`;

export const GET_TEAM_MEMBERS_PAGINATED = gql`
  query GetTeamMembersPaginated(
    $filter: TeamMembersFilter
    $first: Int!
    $page: Int
    $sort: SortOption
    $team_id: Int!
  ) {
    getTeamMembersPaginated(
      filter: $filter
      first: $first
      page: $page
      sort: $sort
      team_id: $team_id
    ) {
      data {
        role
        learner {
          id
          name
          email
          role
          last_login
          teams {
            id
          }
        }
      }
      paginatorInfo {
        total
      }
    }
  }
`;
// returns one team
export const GET_TEAMS_LIST = gql`
  query GetTeamList {
    getTeamsList {
      all_members_count
      id
      learner_role
      name
      managers {
        id
        name
      }
    }
  }
`;

export const GET_LIST_TEAMS_LEARNER_RELATED = gql`
  query GetListTeamsLearnerRelated {
    getListTeamsLearnerRelated {
      all_members_count
      id
      learner_role
      name
      managers {
        id
        name
        role
      }
    }
  }
`;

export const GET_TEAMS_LIST_PAGINATED = gql`
  query GetTeamsListPaginated($filter: TeamsFilter, $first: Int!, $page: Int, $sort: SortOption) {
    getTeamsListPaginated(filter: $filter, first: $first, page: $page, sort: $sort) {
      data {
        all_members_count
        id
        learner_role
        name
        managers {
          id
          name
          role
        }
      }
      paginatorInfo {
        total
      }
    }
  }
`;

export const GET_TEAMS_LIST_FOR_LEARNER = gql`
  query GetTeamsListForLearner($filter: TeamsFilter, $first: Int!, $page: Int, $sort: SortOption) {
    getTeamsListForLearner(filter: $filter, first: $first, page: $page, sort: $sort) {
      data {
        all_members_count
        id
        learner_role
        name
        managers {
          id
          name
          role
        }
      }
      paginatorInfo {
        total
      }
    }
  }
`;

export const GET_TEAM = gql`
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      all_members_count
      company_id
      name
      id
    }
  }
`;

export const CREATE_TEAM = gql`
  mutation CreateTeam($input: TeamCreateInput!) {
    createTeam(input: $input) {
      all_members_count
      company_id
      id
      name
    }
  }
`;

export const UPDATE_TEAM = gql`
  mutation UpdateTeam($input: TeamUpdateInput!) {
    updateTeam(input: $input)
  }
`;

export const DELETE_TEAM = gql`
  mutation DeleteTeam($input: TeamDeleteInput!) {
    deleteTeam(input: $input)
  }
`;

export const UPDATE_MEMBER_ROLE = gql`
  mutation UpdateMemberRole($input: TeamMemberRole!) {
    updateMemberRole(input: $input)
  }
`;

export const UPDATE_MEMBER_ROLE_IN_MANY_TEAMS = gql`
  mutation UpdateMemberRoleInManyTeams($input: TeamMemberRoles!) {
    updateMemberRoleInManyTeams(input: $input)
  }
`;

export const CHANGE_MEMBER_TEAM = gql`
  mutation ChangeMemberTeam($input: MoveLearnerInOtherTeamInput) {
    moveLearnerToOtherTeam(input: $input)
  }
`;

export const REMOVE_LEARNER_FROM_ORG = gql`
  mutation RemoveLearnerFromCompany($learner_id: Int!) {
    removeLearnerFromCompany(learner_id: $learner_id)
  }
`;

export const REMOVE_LEARNER_FROM_TEAM = gql`
  mutation RemoveLearnerFromTeam($team_id: Int!, $member_id: Int!) {
    removeLearnerFromTeam(team_id: $team_id, member_id: $member_id)
  }
`;

export const SEND_TEAM_INVITE_EMAILS = gql`
  mutation SendTeamInviteEmails($team_id: Int!, $emails: [String]!) {
    sendTeamInviteEmails(team_id: $team_id, emails: $emails) {
      hash
      id
      team_id
    }
  }
`;

export const UPDATE_LEARNER_INFO = gql`
  mutation UpdateLearnerInfo($input: LearnerEditInput!) {
    updateLearnerInfo(input: $input) {
      id
      first_name
      last_name
      name
      job
      interests {
        id
        title
      }
      settings {
        language
      }
    }
  }
`;

export const GET_COMPANY_LEARNERS = gql`
  query GetCompanyLearners($team_id: Int, $learner_email: String!) {
    getCompanyLearners(team_id: $team_id, learner_email: $learner_email) {
      id
      name
      email
    }
  }
`;

export const ADD_LEARNER_TO_TEAM = gql`
  mutation AddLearnerToTeam($team_id: Int!, $learner_id: Int!) {
    addLearnerToTeam(team_id: $team_id, learner_id: $learner_id) {
      id
    }
  }
`;

export const SET_LEARNER_AS_COMPANY_MANAGER = gql`
  mutation SetLearnerAsCompanyManager($learner_id: Int!, $company_id: Int!) {
    setLearnerAsCompanyManager(learner_id: $learner_id, company_id: $company_id)
  }
`;
export const REMOVE_LEARNER_COMPANY_MANAGER_ROLE = gql`
  mutation RemoveLearnerCompanyManagerRole($learner_id: Int!, $company_id: Int!) {
    removeLearnerCompanyManagerRole(learner_id: $learner_id, company_id: $company_id)
  }
`;

export const GET_LIST_MANAGERS_IN_COMPANY = gql`
  query GetListManagersInCompany {
    getlistManagersInCompany {
      id
      name
      email
      last_login
      role
      teams {
        id
        name
      }
    }
  }
`;

export const GET_AUTH0_LOGIN_DOMAIN = gql`
  query GetAuth0LoginDomain($connection: String!) {
    getAuth0LoginDomain(connection: $connection)
  }
`;

export const FOLLOW_EXPERT = gql`
  mutation FollowExpert($input: FollowInput!) {
    follow(input: $input)
  }
`;

export const UNFOLLOW_EXPERT = gql`
  mutation UnfollowExpert($ids: [ID]!) {
    unfollow(ids: $ids)
  }
`;

export const GET_USER_ATTRIBUTES_FROM_USER_COM = gql`
  query GetUserAttributesFromUserCom($attributes: [String!], $user_key: String!) {
    getUserAttributesFromUserCom(attributes: $attributes, user_key: $user_key)
  }
`;
