import React, { FC, ReactNode } from 'react';

import { Link } from 'react-router-dom';

interface ConditionalLinkProps {
  withLink: boolean;
  to?: string;
  className?: string;
  children: ReactNode;
}

const ConditionalLink: FC<ConditionalLinkProps> = ({ withLink, children, to, ...props }) => {
  return withLink && to ? (
    <Link to={to} {...props}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
};

export default ConditionalLink;
