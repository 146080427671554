import { addBreadcrumb, captureException, setTag } from '@sentry/react';

import GraphQlError from './errors/GraphQlError';

const handleGraphQlError = (error, operation) => {
  if (process.env.NODE_ENV === 'production') {
    if (operation?.operationName) {
      setTag('operation_name', operation?.operationName);
    }
    if (operation?.variables) {
      addBreadcrumb({
        category: 'log',
        message: `Request - "${operation?.operationName}". Variables: `,
        data: operation.variables,
      });
    }
    captureException(new GraphQlError(JSON.stringify(error)));
  }
};

export default handleGraphQlError;
